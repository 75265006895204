import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsDispatchers } from '../../store/services/analytics/analytics.dispatchers';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { HelperService } from '~app/services/helper.service';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
@ContentSchema({
  description: '',
  name: 'GenericError',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'messageAuthTokenError', type: PropertyType.Text },
    { name: 'messageGetByPnrError', type: PropertyType.Text },
    { name: 'messageGetAircraftImageError', type: PropertyType.Text },
    { name: 'messageGetFlightError', type: PropertyType.Text },
    { name: 'messageCheckinError', type: PropertyType.Text },
    { name: 'messageGetCatalogError', type: PropertyType.Text },
    { name: 'messageRemoveInfantError', type: PropertyType.Text },
    { name: 'tryAgainLater', type: PropertyType.Text },
    { name: 'btnClose', type: PropertyType.Text },
    { name: 'alerts', type: PropertyType.Object },
  ]
})
export class GenericErrorComponent implements OnInit {
  rendering: ComponentRendering;
  title: string;
  message: string;
  tryAgainLater: string;
  btnClose: string;
  airportTime: any;

  constructor(
    public dialogRef: MatDialogRef<GenericErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalContentsSvc: ModalContentsService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private dynamicContentService: DynamicContentService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    if (this.data.airportTime) {
      this.airportTime = this.helperService.getLocalFullDateString(this.data.airportTime);
    }
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('generic-error-modal');
    // check this.data.error is defined in alert-reason-code content
    const alertCode = this.rendering && this.rendering.props && this.rendering.props['alerts'][0].props[this.data.error];

    if (alertCode) {
      this.analyticsDispatchers.referOutAlertCode({code: this.data.error, message: alertCode.content.join(" "), segmentId: ""});
      this.data = alertCode;
    }

    // log error to App Insights
    this.analyticsDispatchers.logAlertError(this.data);
    if (!!this.data && !!this.data.title) {
      this.title = this.data.title;
    } else {
      this.title = this.rendering.props && this.rendering.props['title'];
    }

    if (!!this.data && !!this.data.content) {
      this.message = Array.isArray(this.data.content)
        ? this.data.content.reduce((sen1: string, sen2: string) => sen1 + ' ' + sen2) : this.data.content;
      this.message = this.dynamicContentService.getContentString(
        this.message, 
        { time: this.airportTime, 
          origin: !!this.data.origin ? this.data.origin : "", destination: !!this.data.destination ? this.data.destination : ""}
        );
    } else {
      this.message = this.data.error;
      if (!!this.data.contentProps) {
        this.message = this.rendering.props && this.rendering.props[this.data.contentProps];
      }
      this.tryAgainLater = this.rendering.props && this.rendering.props['tryAgainLater'];
    }

    this.btnClose = this.rendering.props && this.rendering.props['btnClose'];
    // <<< load contents
  }
}
