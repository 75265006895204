import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '~app/modals/modal-contents.service';

@Component({
  selector: 'app-passenger-faq',
  templateUrl: './passenger-faq.component.html',
  styleUrls: [ './passenger-faq.component.scss' ],
})
@ContentSchema({
  description: 'Passenger Faq Component',
  name: 'PassengerFaq',
  props: [
    { name: 'ok', type: PropertyType.Text },
    { name: 'hawaiianMilesNumber', type: PropertyType.Text },
    { name: 'hawaiianMilesDesc', type: PropertyType.RichText },
    { name: 'redressNumber', type: PropertyType.Text },
    { name: 'redressDesc', type: PropertyType.RichText },
    { name: 'knownTravelerNumber', type: PropertyType.Text },
    { name: 'knownTravelerDesc', type: PropertyType.RichText },
  ]
})
export class PassengerFaqComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    private modalContentsSvc: ModalContentsService,
    @Inject(MAT_DIALOG_DATA) public showHM: boolean,
  ) {}

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('passenger-faq');
  }
}
