import { createAction, props } from '@ngrx/store';
import { Airport } from '~app/models/airport.model';
import { StationCode } from '~app/models/stationCode.model';
import { Response } from '~app/models/response.model';
import { Country } from "~app/models/country.model";

export const getCountries = createAction(
  'Get Countries'
);

export const getCountriesSuccess = createAction(
  '[Checkin Start] Get Countries Success',
  props<{ countries: Response<Country>}>()
);

export const getCountriesError = createAction(
  '[Checkin Start] Get Countries Error', props<{ error: any }>()
);

export const getAirports = createAction(
  '[Checkin Start] Get Airports'
);

export const getAirportsSuccess = createAction(
  '[Checkin Start] Get Airports Success',
  props<{ airports: Response<Airport>, airportsList: string[]}>()
);

export const getAirportsError = createAction(
  '[Checkin Start] Get Airports Error', props<{ error: any }>()
);

export const getStationCode = createAction(
  '[Checkin Start] Get Station Code',
  props<{ cityCode: string}>()
);
  
export const getStationCodeSuccess = createAction(
  '[Checkin Start] Get Station Code Success',
  props<{ stationCode: Response<StationCode>}>()
);
  
export const getStationCodeError = createAction(
  '[Checkin Start] Get Station Code Error', props<{ error: any }>()
);
