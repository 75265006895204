import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { BoardingPassPageComponent } from './boarding-pass-page/boarding-pass-page.component';
import { DesktopBoardingPassComponent } from './desktop-boarding-pass/desktop-boarding-pass.component';
import { MobileBoardingPassComponent } from './mobile-boarding-pass/mobile-boarding-pass.component';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { ResultComponent } from './result/result.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        CoreModule.forChild({
            components: [
                ResultComponent,
                MobileBoardingPassComponent,
                BoardingPassPageComponent,
                DesktopBoardingPassComponent
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        RouterModule
    ],
    declarations: [
        ResultComponent,
        MobileBoardingPassComponent,
        BoardingPassPageComponent,
        DesktopBoardingPassComponent
    ],
})
export class ResultModule { }
