<div class="flight-status row d-none d-md-block mr-0">
  <div id="flight-status-container" class="col">
    <div *ngIf="flightLoading$ | async; else flightInfo" id="flight-status-spinner" class="spinner-border"
      role="status"></div>
    <ng-template #flightInfo>
      <div id="airport-legs" *ngIf="(airports$ | async)" class="airport-information d-flex align-items-center">
        <p id="origin" class="origin call-out-bold">{{ (airports$ | async)[originShortName]?.airportCityAndCode }}</p>
        <i class="ha-icon icon-long-arrow-right"></i>
        <p id="destination" class="destination call-out-bold">
          {{ (airports$ | async)[destinationShortName]?.airportCityAndCode }}
        <p>
        <div *ngIf="flightStops >= 1" class="flight-stop">
          <p id="stops" class="small-tertiary-text light-text stops" *haText="rendering?.props['stopCounter']"></p>
        </div>
      </div>
      <div class="flight-details d-flex align-items-center justify-content-between">
        <div class="flight-error" *ngIf="flightError$ | async; else noFlightError">
          <i class="ha-icon icon-attention aloha-pink"></i>
          <small id="departure-error" *haText="rendering.props['flightError']"></small>
        </div>
        <ng-template #noFlightError>
          <div class="departure">
            <p id="departure" class="small" *haText="rendering.props['departure']"></p>
            <p id="departure-time" class="departure-info body-copy-bold"> {{ departure | date:'shortTime' }} </p>
          </div>
          <div class="date text-center">
            <p id="date-header" class="small" *haText="rendering.props['date']"></p>
            <p id="date" class="departure-info body-copy-bold">{{ departure | date: 'MMM dd' }}</p>
          </div>
          <div class="flight-info-box align-self-end">
            <p id="flight-information-link" class="flight-info-link foot-note-bold" (click)="openFlightInformation()"
              (keydown.enter)="openFlightInformation()" role="button" tabindex="0" aria-label="Flight Info">
              {{ rendering.props['flightInfo'] }}<i class="ha icon icon-angle-right"></i>
            </p>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
<mat-expansion-panel #panel class="d-sm-block d-md-none flight-panel" (opened)="openPanelEvent()"
  (closed)="closedPanelEvent()">
  <mat-expansion-panel-header class="flight-status-header" [expandedHeight]="'48px'">
    <mat-panel-title>
      <div class="container flight-status-container pl-0">
        <div class="row airport-information m-auto align-items-center">
          <p id="origin-mobile" class="origin body-copy-bold medium">{{ originShortName }}</p>
          <i id="long-arrow" class="ha-icon icon-long-arrow-right align-self-center mx-3"></i>
          <p id="destination-mobile" class="destination body-copy-bold medium">{{ destinationShortName }}
          <p>
          <div *ngIf="flightStops >= 1; else nonStop" class="flight-stop ml-3">
            <p id="stops" class="small-white-text stops" *haText="rendering?.props['stopCounter']"></p>
          </div>
          <ng-template #nonStop>
            <p id="stops" class="small-white-text stops flight-stop ml-3" *haText="rendering.props['nonstop']"></p>
          </ng-template>
          <div *ngIf="!panel.expanded" class="col d-flex justify-content-end align-self-center pr-2">
            <span class="small-white-text" *haText="rendering.props['info']"></span>
          </div>
          <div *ngIf="panel.expanded" class="col d-flex justify-content-end align-self-center pr-2">
            <span class="small-white-text" *haText="rendering.props['close']"></span>
          </div>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="overlay">
    <div class="flight-detail"
      *ngFor="let flight of allFlights$ | async; let lastFlight = last; let count = count; index as flightIndex">
      <div class="operated-by d-flex pt-5" *ngIf="count > 1">
        <p id="number-of-flight-{{flightIndex}}" class="foot-note" *haText="flightCounter[flightIndex]"></p>
        <p id="operated-by-description-{{flightIndex}}" class="foot-note operated-by-desc pl-1">-
          {{ rendering.props['operatedBy'] }} {{ flight.operatedByDescription }}</p>
      </div>
      <div class="leg-information" *ngIf="(airports$ | async)">
        <p id="departure-date-{{flightIndex}}" class="foot-note departure-date">{{
          flight.scheduledDeparture?.airportDateTimeString | date: 'MMM dd'}}</p>
        <div class="origin-destination-box text-left">
          <span id="origin-{{flightIndex}}" class="body-text-medium">
            {{ (airports$ | async)[flight.origin]?.airportCityAndCode }}</span>
          <i class="ha-icon icon-long-arrow-right"></i>
          <span id="destination-{{flightIndex}}" class="body-text-medium">
            {{ (airports$ | async)[flight.scheduledDestination]?.airportCityAndCode }}</span>
        </div>
      </div>
      <div class="flight-information row pt-4 pb-5">
        <div class="airline-box col">
          <p id="flight-title-{{flightIndex}}" class="small">{{ rendering.props['flight'] | uppercase }}</p>
          <p id="airline-description-{{flightIndex}}" class="body-text-medium text-nowrap">
            {{ flight.operatedBy }} {{ flight.flightNumber }}</p>
        </div>
        <div class="departure-box col">
          <p id="departure-title-{{flightIndex}}" class="small">{{ rendering.props['departure'] | uppercase }}</p>
          <p id="departure-time-{{flightIndex}}" class="body-text-medium">
            {{ flight.scheduledDeparture?.airportDateTimeString | date:'h:mma' | lowercase }}</p>
        </div>
        <div class="boarding-box col">
          <p id="boarding-title-{{flightIndex}}" class="small">{{ rendering.props['boarding'] | uppercase }}</p>
          <p id="boarding-time-{{flightIndex}}" class="body-text-medium">
            {{ flight.status?.boardingStartTime?.airportDateTimeString | date:'h:mma'| lowercase }}</p>
        </div>
        <div class="gate-box col">
          <p id="gate-title-{{flightIndex}}" class="small">{{ rendering.props['gate'] | uppercase }}</p>
          <p id="gate-information-{{flightIndex}}" class="body-text-medium">{{ flight.status?.originGate }}</p>
        </div>
      </div>
      <div *ngIf="!lastFlight">
        <div class="layover-information-container" *ngIf="flight.layoverTime !== false && (airports$ | async)">
          <div id="layover-information-{{flightIndex}}" class="layover d-flex align-items-center">
            <p class="foot-note light-text">{{ (airports$ | async)[flight.scheduledDestination]?.airportCityAndCode }}
            </p>
            <p class="dash foot-note light-text pl-1">-</p>
            <p class="foot-note light-text pl-1">{{ flight.layoverTime }} {{ rendering.props['layover'] }}
            <p>
          </div>
        </div>
      </div>
      <button *ngIf="lastFlight" ha-button (click)="panel.expanded = false;">
        {{ 'Ok' | translate }}
      </button>
    </div>
  </div>
</mat-expansion-panel>
