import { ModalContentsService } from '../modal-contents.service';
import {
  RANGE_REF_BAG_FULFILLMENT,
  RANGE_REF_BAG_PAYMENT,
  RANGE_REF_SEAT_ASSIGNMENT,
  RANGE_REF_SEAT_PAYMENT } from '../../models/partialsuccess.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';


@Component({
  selector: 'app-partial-carts-modal',
  templateUrl: './partial-carts-modal.component.html',
  styleUrls: ['./partial-carts-modal.component.scss'],
})
@ContentSchema({
  description: 'Partial Carts Modal Component',
  name: 'PartialCartsModal',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: RANGE_REF_BAG_FULFILLMENT, type: PropertyType.Text },
    { name: RANGE_REF_BAG_PAYMENT, type: PropertyType.Text },
    { name: RANGE_REF_SEAT_ASSIGNMENT, type: PropertyType.Text },
    { name: RANGE_REF_SEAT_PAYMENT, type: PropertyType.Text },
    { name: 'seatBagPayment', type: PropertyType.Text },
    { name: 'paymentInfo', type: PropertyType.Text },
    { name: 'and', type: PropertyType.Text },
    { name: 'tryAgain', type: PropertyType.Text },
    { name: 'btnOk', type: PropertyType.Text },
  ]
})
export class PartialCartsModalComponent implements OnInit {
  rendering: ComponentRendering;
  failureMessage: string;

  constructor(
    public dialogRef: MatDialogRef<PartialCartsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public rangeReferences: string[],
    private modalContentsSvc: ModalContentsService,
  ) { }

  ngOnInit() {
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('partial-cart-modal');

    // set failureMessage messaging for 1 error
    let bagFail: string;
    let seatFail: string;
    this.rangeReferences.forEach(fail => {
      this.failureMessage = this.rendering.props[fail];
      if (fail === RANGE_REF_SEAT_ASSIGNMENT || fail === RANGE_REF_SEAT_PAYMENT) {
        seatFail = fail;
      } else if (fail === RANGE_REF_BAG_FULFILLMENT || fail === RANGE_REF_BAG_PAYMENT) {
        bagFail = fail;
      }
    });

    // set failureMessage messaging for 2 errors
    if (bagFail === RANGE_REF_BAG_PAYMENT && seatFail === RANGE_REF_SEAT_PAYMENT) {
      this.failureMessage = this.rendering.props['seatBagPayment'];
    } else if (!!bagFail && !!seatFail) {
      this.failureMessage = `${this.rendering.props[seatFail]} ${this.rendering.props['and']} ${this.rendering.props[bagFail]}`;
    }
  }
}
