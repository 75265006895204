<div id="link-minor-modal">
    <h6 id="link-minor-heading1" class="bold medium mt-3" *haText="rendering?.props['linkMinorHeading1']">
    </h6>
    <h6 id="link-minor-heading2" class="bold medium link-minor-heading2"
        *haText="rendering?.props['linkMinorHeading2']">
    </h6>
    <mat-dialog-actions align="center" id="link-minor-buttons">
        <button id="traveling-alone-button" mat-button mat-dialog-close class="btn btn-default traveling-alone-btn">
            <span *haText="rendering?.props['travelingAloneButton']"></span>
        </button>
        <button id="traveling-with-adult-button" mat-button [mat-dialog-close]="true" class="btn btn-default">
            <span *haText="rendering?.props['travelingWithAdultButton']"></span>
        </button>
    </mat-dialog-actions>
</div>