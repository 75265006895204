import { AdultSearchCardComponent } from "./adult-search-card/adult-search-card.component";
import { AppSharedModule } from "~app/shared/app.shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { NgModule } from "@angular/core";
import { CoreModule } from "@hawaiianair/core";
import { LinkMinorToAdultComponent } from "./link-minor-to-adult/link-minor-to-adult.component";

@NgModule({
    imports: [
        AppSharedModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule.forChild({
            components: [
                LinkMinorToAdultComponent,
                AdultSearchCardComponent
            ]
        }),
    ],
    exports: [
        MatCardModule,
        FormsModule
    ],
    declarations: [
        LinkMinorToAdultComponent,
        AdultSearchCardComponent
    ],
})
export class MinorModule { }
