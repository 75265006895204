import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { Observable } from 'rxjs';
import { SessionSelectors, RouterDispatchers, AnalyticsDispatchers } from '~app/store';
import { Passenger } from '~app/models/passenger.model';
import { Trip } from '~app/models/trip.model';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-infant',
  templateUrl: './infant.component.html',
  styleUrls: [ './infant.component.scss' ],
})
@ContentSchema({
  name: 'Infant',
  description: 'Add Infant',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})
export class InfantComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  selectedPassengers$: Observable<Passenger[]>;
  sessionTrip2CheckIn$: Observable<Trip>;
  passengersWithoutInfant: Passenger[];

  constructor(
    private sessionSelectors: SessionSelectors,
    private routerDispatchers: RouterDispatchers,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.selectedPassengers$ = this.sessionSelectors.selectedPassengers$;
    this.sessionTrip2CheckIn$ = this.sessionSelectors.sessionTrip2Checkin$;
  }

  ngOnInit(): void {
    this.selectedPassengers$.pipe(take(1)).subscribe(selectedPax => {
      this.passengersWithoutInfant = selectedPax && selectedPax.filter(pax => !pax.hasInfant && this.isPaxAdult(pax.dateOfBirth));
    });
    this.analyticsDispatchers.infantPageLoaded();
  }

  goToInfantInfo(passengerID: string): void {
    this.routerDispatchers.routeToInfantAdditionalInfo(CHECKIN_ROUTES.ROUTE_INFANT.component, passengerID);
  }

  private isPaxAdult(birthDate: string) {
    let departDate: Date;
    this.sessionTrip2CheckIn$.pipe(take(1)).subscribe(trip => {
      departDate = new Date(trip.segments.entries[0].departure.airportDateTimeString.split('T').shift());
      departDate.setFullYear(departDate.getFullYear() - 15);
    });
    return new Date(birthDate) < departDate;
  }
}
