import { Component, Input, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { RegexPatterns } from '~app/constants/regex-constants';
import { CustomValidators } from '~app/validators/noMatch.validator';
import { PassportScanDispatchers, ReferenceDataSelectors } from '~app/store';
import { InternationalPassenger } from '~app/store/reducers/international details/international-details.reducers';
import { Country3CodeMap, CountryMap, CountryName } from '~app/models/country.model';
import { take } from 'rxjs';

@Component({
  selector: 'app-manual-passport-form',
  templateUrl: './manual-passport-form.component.html',
  styleUrls: ['./manual-passport-form.component.scss']
})
@ContentSchema({
  name: 'ManualPassportForm',
  description: 'Manual Passport Form',
  props: [
    { name: 'dateOfBirth', type: PropertyType.Text },
    { name: 'expirationDate', type: PropertyType.Text },
    { name: 'gender', type: PropertyType.Text },
    { name: 'genders', type: PropertyType.Object },
    { name: 'issuanceCountry', type: PropertyType.Text },
    { name: 'firstName', type: PropertyType.Text },
    { name: 'lastName', type: PropertyType.Text },
    { name: 'nationality', type: PropertyType.Text },
    { name: 'passportNumber', type: PropertyType.Text },
    { name: 'save', type: PropertyType.Text },
    { name: 'testEnvironmentOnly', type: PropertyType.Text },
  ]
})
export class ManualPassportFormComponent implements OnInit {
  @Input() passenger: InternationalPassenger;
  @Input() rendering: ComponentRendering;
  togglePassportForm = false;
  manualPassportForm: FormGroup;
  countriesDisplayNames: CountryName[];
  countryMap: CountryMap;
  country3CodeMap: Country3CodeMap;

  constructor(
    private passportScanDispatchers: PassportScanDispatchers,
    private referenceDataSelectors: ReferenceDataSelectors
  ) { }

  ngOnInit(): void {
    this.referenceDataSelectors.countries$.pipe(take(1)).subscribe((countries) => {
      this.countriesDisplayNames = countries.countryDisplayName;
      this.countryMap = countries.countryMap;
      this.country3CodeMap = countries.country3CodeMap;
    });
  }

  setTogglePassportForm(): void {
    this.togglePassportForm = !this.togglePassportForm;
    if (this.togglePassportForm) {
      this.manualPassportForm = new FormGroup({
        dob: new FormControl(this.passenger?.dateOfBirth ? moment(this.passenger.dateOfBirth).format('yyyy-MM-DD') : '', [Validators.required]),
        expiry: new FormControl(this.passenger?.dateOfBirth ? moment(`${moment(new Date(), 'yyyy').year() + 10}-${moment(this.passenger.dateOfBirth).format('MM-DD')}`).format('yyyy-MM-DD') : moment(new Date(), 'yyyy').add(10, 'years').format('yyyy-MM-DD') , [Validators.required]),
        gender: new FormControl(this.passenger?.gender || '', [Validators.required]),
        issuingCountry: new FormControl('United States of America', [Validators.required, CustomValidators.noMatch(this.countriesDisplayNames)]),
        firstName: new FormControl(this.passenger?.passengerName.firstName || '', [Validators.required, CustomValidators.characterCountError(RegexPatterns.alphaValidation)]),
        lastName: new FormControl(this.passenger?.passengerName.lastName || '', [Validators.required, CustomValidators.characterCountError(RegexPatterns.alphaValidation)]),
        nationality: new FormControl('United States of America', [Validators.required, CustomValidators.noMatch(this.countriesDisplayNames)]),
        number: new FormControl('12345678', [Validators.required, CustomValidators.characterCountError(RegexPatterns.passportNumberValidation)]),
      });
    }
  }

  savePassportForm() {
    const passportDocument = {
      type: 'PASSPORT',
      status: 'APPROVED_VERIFIED',
      ...this.manualPassportForm.value,
      issuingCountry: Object.keys(this.country3CodeMap).find(key => this.country3CodeMap[key] === this.countryMap[this.manualPassportForm.get('issuingCountry').value]),
      nationality: Object.keys(this.country3CodeMap).find(key => this.country3CodeMap[key] === this.countryMap[this.manualPassportForm.get('nationality').value])
    };
    this.passportScanDispatchers.getPassportScanData(null, { paxId: this.passenger.id, passportDocument });
  }
}

