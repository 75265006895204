<div class="container" *ngFor="let flight of this.allFlights$ | async; let lastFlight = last; let c = count; let i = index ; index as flightIndex">
    <div class="operated-by" *ngIf="c > 1 && (airports$ | async)">
        <p id="number-of-flight-{{flightIndex}}" class="body-copy-bold" *haText="flightCounter[i]"></p>
        <p id="operated-by-description-{{flightIndex}}"class="body-copy-bold operated-by-desc">- {{ rendering.props['operatedBy'] }} {{ flight.operatedByDescription }}</p>
     </div>
     <div class="flight-leg-box">
        <p id="departure-date-{{flightIndex}}" class="foot-note light-text">{{ flight.scheduledDeparture?.airportDateTimeString | date: 'MMM dd' }}</p>
        <div class="origin-destination-box">
            <h6 id="origin-{{flightIndex}}" class="bold">{{(airports$ | async)[flight.origin]?.airportCityAndCode}}</h6>
            <i class="ha-icon icon-long-arrow-right"></i>
            <h6 id="destination-{{flightIndex}}"class="bold">{{(airports$ | async)[flight.scheduledDestination]?.airportCityAndCode }}</h6>
        </div>
     </div>
     <div class="flight-information row">
        <div class="airline-box col">
            <p id="flight-title-{{flightIndex}}" class="small light-text">{{ rendering.props['flight'] | uppercase }}</p>
            <p id="airline-description-{{flightIndex}}"class="call-out-bold">{{ flight.operatedBy }} {{ flight.flightNumber }}</p>
        </div>
        <div class="departure-box col">
            <p id="departure-title-{{flightIndex}}" class="small light-text">{{ rendering.props['departure'] | uppercase }}</p>
            <p id="departure-time-{{flightIndex}}"class="call-out-bold">{{ flight.scheduledDeparture?.airportDateTimeString | date:'h:mma' | lowercase}}</p>
        </div>
        <div class="arrival-box col">
            <p id="arrival-title-{{flightIndex}}" class="small light-text">{{ rendering.props['arrival'] | uppercase }}</p>
            <p id="arival-time-{{flightIndex}}" class="call-out-bold">
                {{ flight.scheduledArrival?.airportDateTimeString | date:'h:mma' | lowercase}}
                <span class="small" *ngIf="flight.travelDays !== 0">
                    ({{ flight.travelDays > 0 ? '+' : ''}}{{flight.travelDays}})
                </span>
            </p>
        </div>
        <div class="boarding-box col">
            <p id="boarding-title-{{flightIndex}}" class="small light-text">{{ rendering.props['boarding'] | uppercase }}</p>
            <p id="boarding-time-{{flightIndex}}" class="call-out-bold">{{ flight.status?.boardingStartTime?.airportDateTimeString | date:'h:mma'| lowercase}}</p>
        </div>
        <div class="gate-box col">
            <p id="gate-title-{{flightIndex}}" class="small light-text">{{ rendering.props['gate'] | uppercase }}</p>
            <p id="gate-information-{{flightIndex}}" class="call-out-bold"> {{flight.status?.originGate }}</p>
        </div>
     </div>
     <hr class="border-gray" *ngIf="!lastFlight">
     <div class="layover-information-container" *ngIf="flight.layoverTime !== false">
         <div id="layover-information-{{flightIndex}}" class="layover">
            <p class="foot-note light-text">{{ (airports$ | async)[flight.scheduledDestination]?.airportCityAndCode}}</p>
            <p class="dash foot-note light-text"> - </p>
            <p class="foot-note light-text">{{ flight.layoverTime}} {{ rendering.props['layover'] }}<p>
         </div>
     </div>
     <hr class="border-gray" *ngIf="!lastFlight">
</div>
<button mat-dialog-close mat-button id="close-flight-information-button" class="btn btn-default w-50">{{ 'Ok' | translate }}</button>
