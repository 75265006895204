import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { catchError, concatMap, map, tap, withLatestFrom } from "rxjs/operators";
import { EntityState, TripDataService } from '~app/store';
import * as SecurityInfoActions from '../../actions/security-info.actions';
import * as fromSecurityInfo from '~app/store/services/security-info/security-info.selectors';
import * as fromSession from '~app/store/services/session/session.selectors';
import * as TripActions from '~app/store/actions/trip.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import { Action, select, Store } from '@ngrx/store';
import { PassengerSecurityInfo } from '~app/models/passengersecurityinfo.model';
import { Passenger } from '~app/models/passenger.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { ModalsService } from '~app/modals/modals.service';
import { ALERTS, Constants } from '~app/constants/ha-constants';
import { AddressInfo } from '~app/models/addressinfo.model';
import { SecurityInfo } from '~app/models/securityinfo.model';

@Injectable()
export class SecurityInfoEffects {
    getTripPassengers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SecurityInfoActions.passengersGetTripPassengers, SecurityInfoActions.travelerInfoGetPassenger),
            withLatestFrom(
                this.store.pipe(select(fromSession.selectedPassengers)),
                this.store.pipe(select(fromSecurityInfo.passengerInfo)),
            ),
            concatMap(([action, selectedPassengers, paxInfo]) =>
                this.tripDataService.getTripPassengersInfo().pipe(
                    concatMap(response => {
                        const actionDispatcher = this.actionDispatcher(action.type);
                        const passengerInfo: PassengerSecurityInfo[] = this.filterPax(selectedPassengers, response.results);
                        const actions: Action[] = [
                          SecurityInfoActions.getTripPassengersSuccess(`${actionDispatcher}`)(
                            { passengerInfo, correlationId: response.correlationId })
                        ];

                        // check if updated paxInfo only needs DOCV after updating one's pax securityInfo
                        if (action.type === SecurityInfoActions.travelerInfoGetPassenger.type) {
                            const selectedPaxInfo = passengerInfo.find(pax => pax.passengerInfo.id === paxInfo.id);
                            selectedPaxInfo.requiredInfoSummary = selectedPaxInfo.passengerInfo.isInfant
                                ? selectedPaxInfo.requiredInfoSummary.filter(req =>
                                    req.additionalInfo.code.includes(Constants.codeInfant)
                                    || req.additionalInfo.code.includes(Constants.validationTypeDocv))
                                : selectedPaxInfo.requiredInfoSummary.filter(req =>
                                    !req.additionalInfo.code.includes(Constants.codeInfant));
                            const paxOnlyNeedsDOCV = selectedPaxInfo && selectedPaxInfo.requiredInfoSummary.every(
                                verifyStatusCode => verifyStatusCode.additionalInfo.code === Constants.validationTypeDocv
                            );
                            if (!paxOnlyNeedsDOCV) {
                                throw ({ error: 'requiredInfoSummary error for updated pax',
                                  data: selectedPaxInfo.requiredInfoSummary,
                                  correlationId: response.correlationId });
                            } else if (!!selectedPaxInfo.alertCodes && !!selectedPaxInfo.alertCodes.length) {
                                throw ({ error: selectedPaxInfo.alertCodes.map(alert => alert.reason),
                                  correlationId: response.correlationId });
                            }
                        }

                        return actions;
                    }),
                    catchError(error => of(SecurityInfoActions.getTripPassengersError(
                        `${this.actionDispatcher(action.type)}`)(error)
                    )),
                )
            )
        )
    );

    getTripPassengersDocv$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SecurityInfoActions.guidelinesGetTripPassengers, SecurityInfoActions.dashboardGetTripPassengers),
            withLatestFrom(this.store.pipe(select(fromSession.selectedPassengers))),
            concatMap(([action, selectedPassengers]) =>
                this.tripDataService.getTripPassengersInfo().pipe(
                    concatMap(response => {
                        const actionDispatcher = this.actionDispatcher(action.type);
                        const passengerInfo: PassengerSecurityInfo[] = this.filterPax(selectedPassengers, response.results);
                        if (passengerInfo.some(pax => pax.requiredInfoSummary.length)) {
                            throw ({ error: 'DOCV not Valid' });
                        } else if (response.results.some(paxSecurityInfo =>
                            !!paxSecurityInfo.alertCodes && !!paxSecurityInfo.alertCodes.length)) {
                            throw ({ error: response.results.flatMap(pax => pax.alertCodes.flatMap(alert => alert.reason)) });
                        }

                        const actions: Action[] = [SecurityInfoActions.getTripPassengersSuccess(`${actionDispatcher}`)({
                             passengerInfo, correlationId: response.correlationId })];

                        if (action.type === SecurityInfoActions.guidelinesGetTripPassengers.type) {
                          actions.push(TripActions.guidelinesCheckin());
                        }

                        return actions;
                    }),
                    catchError(error => of(SecurityInfoActions.getTripPassengersError(
                        `${this.actionDispatcher(action.type)}`)(error)
                    )),
                )
            )
        )
    );

    getTripPassengersError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SecurityInfoActions.travelerInfoGetPassengerError,
                SecurityInfoActions.guidelinesGetTripPassengersError,
                SecurityInfoActions.dashboardGetTripPassengersError
            ),
            tap(_ => this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToAgent }))
        ), { dispatch: false }
    );

    postSecurityInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SecurityInfoActions.guidelinesPostSecurityInfo,
                SecurityInfoActions.travelerInfoPostSecurity,
                SecurityInfoActions.dashboardPostSecurityInfo
            ),
            withLatestFrom(this.store.pipe(select(fromSecurityInfo.getSecurityInfo))),
            concatMap(([action, securityInfo]) => {
                const securityInfoObject: SecurityInfo[] = JSON.parse(JSON.stringify(securityInfo));
                securityInfoObject.forEach(paxSecurityInfo =>
                    paxSecurityInfo.passengerInfo.id = paxSecurityInfo.passengerInfo.isInfant
                        ? paxSecurityInfo.passengerInfo.id.replace(Constants.codeInfant, '')
                        : paxSecurityInfo.passengerInfo.id
                );
                return this.tripDataService.submitSecurityInfo(securityInfoObject).pipe(
                    concatMap(response => {
                        const actionDispatcher = this.actionDispatcher(action.type);
                        const actions: Action[] = [
                            SecurityInfoActions.getTripPassengers(`${actionDispatcher}`)(),
                            SecurityInfoActions.postSecurityInfoSuccess(`${actionDispatcher}`)({ securityInfoResponse: response })
                        ];
                        if (action.type === SecurityInfoActions.travelerInfoPostSecurity.type) {
                            actions.push(RouterActions.routeToPassengerInformation({
                                componentName: CHECKIN_ROUTES.ROUTE_INTERNATIONAL_TRAVELER_INFO.component
                            }));
                        }
                        return actions;
                    }),
                    catchError(error => {
                        return of(SecurityInfoActions.postSecurityInfoError(`${this.actionDispatcher(action.type)}`)(error));
                    })
                );
            })
        )
    );

    postSecurityInfoError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SecurityInfoActions.travelerInfoPostSecurityError,
                SecurityInfoActions.guidelinesPostSecurityInfoError,
                SecurityInfoActions.dashboardPostSecurityInfoError
            ),
            tap(_ => this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }))
        ), { dispatch: false }
    );

    addAddressInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                SecurityInfoActions.addDestinationInfo,
                SecurityInfoActions.addResidentInfo
            ),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromSecurityInfo.getAddressInfo))),
                map(([_, addressInfo]) => {
                let updatedAddressInfo: AddressInfo[] = [];
                if (!addressInfo || !addressInfo.length) {
                    return SecurityInfoActions.addAddressInfoSuccess({addressInfo: [action.addressInfo]});
                } else {
                    const filteredAddressInfo = addressInfo.filter(info => info.addressType !== action.addressInfo.addressType);
                    updatedAddressInfo = [action.addressInfo, ...filteredAddressInfo];
                    return SecurityInfoActions.addAddressInfoSuccess({addressInfo: updatedAddressInfo});
                }
            })
        )))
    );

    constructor(
        private actions$: Actions,
        private tripDataService: TripDataService,
        private store: Store<EntityState>,
        private modalsService: ModalsService
    ) { }

    actionDispatcher(actionType: string) {
        return actionType.split(']')[0].concat(']');
    }

    private filterPax(selectedPassengers: Passenger[], passengersecurityinfo: PassengerSecurityInfo[]) {
        const response = [];
        selectedPassengers.forEach(pax => {
            const paxSecurityInfo = passengersecurityinfo.find(securityInfo =>
                securityInfo.passengerInfo.firstName === pax.passengerName.firstName
                && securityInfo.passengerInfo.lastName === pax.passengerName.lastName);
            response.push(paxSecurityInfo);

            if (pax.hasInfant) {
                response.push({
                    ...paxSecurityInfo,
                    passengerInfo: {
                        firstName: pax.lapInfant.infantName.firstName,
                        lastName: pax.lapInfant.infantName.lastName,
                        id: paxSecurityInfo.passengerInfo.id + Constants.codeInfant,
                        isInfant: true
                    }
                });
            }
        });
        return response;
    }
}
