import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-infant-aged-out-modal',
  templateUrl: './infant-aged-out-modal.component.html',
  styleUrls: ['./infant-aged-out-modal.component.scss']
})
@ContentSchema({
  name: 'InfantAgedOutModal',
  description: 'InfantAgedOutModalComponent',
  props: [
    { name: 'content', type: PropertyType.RichText }
  ]
})
export class InfantAgedOutModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<InfantAgedOutModalComponent>,
    private modalContentsSvc: ModalContentsService
  ) { }

  ngOnInit() {
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('infant-aged-out-modal');
  }
}
