import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-seat-unavailable-modal',
  templateUrl: './seat-unavailable-modal.component.html',
  styleUrls: [ './seat-unavailable-modal.component.scss' ],
})
@ContentSchema({
  name: 'SeatUnavailableModal',
  description: 'SeatUnavailableModalComponent',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'content', type: PropertyType.RichText },
    { name: 'btnContinue', type: PropertyType.Text },
  ]
})
export class SeatUnavailableModalComponent implements OnInit {
  rendering: ComponentRendering;
  header: string;
  content: string;
  btnContinue: string;

  constructor(
    public dialogRef: MatDialogRef<SeatUnavailableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalContentsSvc: ModalContentsService,
  ) {}

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('seat-unavailable-modal');

    this.header = this.rendering.props['header'];
    this.content = this.rendering.props['content'];
    this.btnContinue = this.rendering.props['btnContinue'];
    // <<< load contents
  }
}
