import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';

@Component({
  selector: 'app-exit-row-modal',
  templateUrl: './exit-row-modal.component.html',
  styleUrls: [ './exit-row-modal.component.scss' ],
})
@ContentSchema({
  name: 'ExitRowModal',
  description: 'Exit Row Modal Component',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'content', type: PropertyType.RichText },
    { name: 'btnDecline', type: PropertyType.Text },
    { name: 'btnAccept', type: PropertyType.Text },
    { name: 'acceptance', type: PropertyType.Text },
    { name: 'notice', type: PropertyType.Text },
    { name: 'criteria', type: PropertyType.Object }
  ]
})
export class ExitRowModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<ExitRowModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentRendering
  ) {}

  ngOnInit() {
    this.rendering = this.data;
  }
}
