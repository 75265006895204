import { Component, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { SessionDispatchers, SessionSelectors, TripDispatchers, TripSelectors,
  AuthTokenDispatchers, AuthTokenSelectors, RouterSelectors, AnalyticsDispatchers } from '~app/store';
import { CHECKIN_ROUTES, ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import { HeaderContentService } from '~app/services/header-content.service';
import { Observable, Subscription } from 'rxjs';
import { SessionData } from '~app/models/sessiondata.model';
import { DetectDeviceService } from '~app/services/device-detector.service';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { HelperService } from '~app/services/helper.service';
import { Constants } from '~app/constants/ha-constants';
import { take } from 'rxjs/operators';
import { ModalContentsService } from '~app/modals/modal-contents.service';


@Component({
  selector: 'app-checkin-start',
  templateUrl: './checkin-start.component.html',
  styleUrls: ['./checkin-start.component.scss'],
})
@ContentSchema({
  name: 'CheckinStart',
  description: 'CheckinStartComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'resultPageHeaderText', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'footerDescription', type: PropertyType.RichText },
    { name: 'appMessage', type: PropertyType.Text },
    { name: 'learnMore', type: PropertyType.Text },
  ],
})
export class CheckinStartComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  getOurAppRendering: ComponentRendering;
  footerRendering: ComponentRendering;
  classicWebLoginRendering: ComponentRendering;
  findTripSearchRendering: ComponentRendering;
  subscriptions: Subscription = new Subscription();
  sessionData: SessionData;
  sessionState$: Observable<SessionData>;
  isCheckInView$: Observable<boolean>;
  authLoading$: Observable<boolean>;
  tripLoading$: Observable<boolean>;
  isCheckInView: boolean;
  bodyTag: any;
  airportLoading$: Observable<boolean>;
  routeState$: Observable<ICheckinRouteSerializerState>;
  destinationCode: string;

  constructor(
    private sessionDispatchers: SessionDispatchers,
    private tripDispatchers: TripDispatchers,
    private authTokenDispatchers: AuthTokenDispatchers,
    private modalContentsService: ModalContentsService,
    private headerContentService: HeaderContentService,
    private sessionSelectors: SessionSelectors,
    private renderer: Renderer2,
    private deviceDetector: DetectDeviceService,
    private helperService: HelperService,
    private routerSelector: RouterSelectors,
    private authTokenSelector: AuthTokenSelectors,
    private tripSelector: TripSelectors,
    private airportSelector: ReferenceDataSelectors,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.sessionState$ = this.sessionSelectors.session$;
    this.isCheckInView$ = this.routerSelector.isCheckInView$;
    this.authLoading$ = this.authTokenSelector.authTokenLoading$;
    this.tripLoading$ = this.tripSelector.tripLoading$;
    this.airportLoading$ = this.airportSelector.airportLoading$;
    this.routeState$ = this.routerSelector.routerState$;
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'ha-floral-background');
    const defaultHeaderContent = {
      destinationImgUrl: Constants.defaultdestinationImgUrl,
      headerText: this.rendering.props['heading']
    };
    this.headerContentService.setHeaderContent(defaultHeaderContent);

    // when user landing checkin root, Session and Trip data will be reset
    // get the latest session data
    this.sessionDispatchers.getState();
    this.subscriptions.add(this.isCheckInView$.subscribe(bool => this.isCheckInView = bool));
    this.subscriptions.add(this.sessionState$.subscribe(session => {
      this.sessionData = session;
      if (!!session) {
        this.destinationCode = session.destination;
        if (!!this.destinationCode) {
          const airportDetails = this.helperService.getAirportDetails(this.destinationCode);
          if (!!airportDetails && airportDetails.destinationImgUrl !== "") {
            const resultHeaderContent = {
              destinationImgUrl: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)), url('${airportDetails.destinationImgUrl}')`,
              headerText: this.rendering.props['resultPageHeaderText']
            };
            this.headerContentService.setHeaderContent(resultHeaderContent);
          }
        }
      }
    }));

    if (this.sessionData.appBannerClose) {
      this.clearSessionData();
      this.sessionDispatchers.setSessionAppBannerClose(true);
    } else {
      if (!this.isCheckInView) {
        this.clearSessionData();
      }
    }

    this.routeState$.pipe(take(1)).subscribe(routeState => {
      if (!!routeState?.queryParams?.name && !!routeState?.queryParams?.code) {
        this.authTokenDispatchers.getAuthToken({
            confirmationCode: routeState.queryParams.code,
            lastName: routeState.queryParams.name
          },
          CHECKIN_ROUTES.ROUTE_CHECKIN.component
        );
      }
    });

    this.deviceDetector.checkDeviceInfo();

    // loader displayed: removes floral background from loading screen
    this.subscriptions.add(this.authLoading$.subscribe(bool => {
      if (!!bool) {
        this.renderer.removeClass(document.body, 'ha-floral-background');
      } else {
        // returns back to checkin-start page
        this.renderer.addClass(document.body, 'ha-floral-background');
      }
    }));
    this.subscriptions.add(this.tripLoading$.subscribe(bool => {
      if (!!bool) {
        this.renderer.removeClass(document.body, 'ha-floral-background');
      }
    }));

    this.setErrorModalContents();
    this.footerRendering = (this.rendering.components['web-footer'] || [{}])[0];
    this.getOurAppRendering = (this.rendering.components['get-our-app'] || [{}])[0];
    this.classicWebLoginRendering = (this.rendering.components['classic-web-login'] || [{}])[0];
    this.findTripSearchRendering = (this.rendering.components['find-trip-search'] || [{}])[0];
    this.analyticsDispatchers.checkInPageLoaded();
  }

  private clearSessionData() {
    this.sessionDispatchers.clearSessionDataCheckinStart();
    this.tripDispatchers.clearTripDataCheckinStart();
  }

  private setErrorModalContents() {
    // Whenever a new error modal added to Store service, set its contents here in this fashion
    this.modalContentsService.addRendering('generic-error-modal', this.rendering.components['generic-error-modal']);
    this.modalContentsService.addRendering('auth-token-expiration-modal', this.rendering.components['auth-token-expiration-modal']);
    this.modalContentsService.addRendering('upgrade-all-pax-modal', this.rendering.components['upgrade-all-pax-modal']);
    this.modalContentsService.addRendering('quit-modal', this.rendering.components['quit-modal']);
    this.modalContentsService.addRendering('flight-delayed-modal', this.rendering.components['flight-delayed-modal']);
    this.modalContentsService.addRendering('flight-status', this.rendering.components['flight-status']);
    this.modalContentsService.addRendering('remove-infant-modal', this.rendering.components['remove-infant-modal']);
    this.modalContentsService.addRendering('offers-error-modal', this.rendering.components['offers-error-modal']);
    this.modalContentsService.addRendering('target-message-modal', this.rendering.components['target-message-modal']);
    this.modalContentsService.addRendering('link-minor-modal', this.rendering.components['link-minor-modal']);
    this.modalContentsService.addRendering('minor-traveling-alone-modal', this.rendering.components['minor-traveling-alone-modal']);
    this.modalContentsService.addRendering('military-save-type-modal', this.rendering.components['military-save-type-modal']);
    this.modalContentsService.addRendering('military-type-empty-modal', this.rendering.components['military-type-empty-modal']);
    this.modalContentsService.addRendering('military-type-success-modal', this.rendering.components['military-type-success-modal']);
    this.modalContentsService.addRendering('military-refer-out-modal', this.rendering.components['military-refer-out-modal']);
    this.modalContentsService.addRendering('passenger-update-error-modal', this.rendering.components['passenger-update-error-modal']);
  }

  appBannerClose() {
    this.sessionDispatchers.setSessionAppBannerClose(true);
  }

  scrollToTop() {
    document.body.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  public ngOnDestroy() {
    this.renderer.removeClass(document.body, 'ha-floral-background');
    this.subscriptions.unsubscribe();
  }
}
