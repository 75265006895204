import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { SessionData } from '~app/models/sessiondata.model';
import { SessionSelectors } from '../../store/services';
import { TripSelectors } from '../../store/services';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-boarding-pass-next-steps-modal',
  templateUrl: './boarding-pass-next-steps-modal.component.html',
  styleUrls: ['./boarding-pass-next-steps-modal.component.scss']
})
@ContentSchema({
  description: 'Concurrency Modal Component',
  name: 'BoardingPassNextStepsModal',
  props: [
    { name: 'nextStepsTitle', type: PropertyType.Text },
    { name: 'bagsToCheckSubTitle', type: PropertyType.Text },
    { name: 'noBagsToCheckSubTitle', type: PropertyType.Text },
    { name: 'kioskInstructions', type: PropertyType.Text },
    { name: 'tsaInstructions', type: PropertyType.Text },
    { name: 'saveTimeInstructions', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsInternational', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItemsInternational', type: PropertyType.Text },
    { name: 'boardingPassBaggageInternational', type: PropertyType.Text },
    { name: 'btnOk', type: PropertyType.Text }
  ]
})
export class BoardingPassNextStepsModalComponent implements OnInit, OnDestroy {
  rendering: ComponentRendering;
  nextStepsTitle: string;
  bagsToCheckSubTitle: string;
  noBagsToCheckSubTitle: string;
  kioskInstructions: string;
  tsaInstructions: string;
  saveTimeInstructions: string;
  boardingPassWithoutCheckinItemsTitle: string;
  boardingPassWithCheckinItemsTitle: string;
  boardingPassWithoutCheckinItemsInternational: string;
  boardingPassWithCheckinItemsInternational: string;
  boardingPassBaggageInternational: string;
  btnOk: string;

  sessionData: SessionData;
  sessionData$: Observable<SessionData>;
  isInternational$: Observable<boolean>;
  isInternational: boolean;
  subs = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<BoardingPassNextStepsModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.isInternational$ = this.tripSelectors.isInternational$;
  }

  ngOnInit() {
    this.subs.add(this.sessionData$.subscribe(sessionData => this.sessionData = sessionData));
    this.subs.add(this.isInternational$.subscribe(isInternational => this.isInternational = isInternational));

    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('boarding-pass-next-steps-modal');

    this.nextStepsTitle = this.rendering.props['nextStepsTitle'];
    this.bagsToCheckSubTitle = this.rendering.props['bagsToCheckSubTitle'];
    this.noBagsToCheckSubTitle = this.rendering.props['noBagsToCheckSubTitle'];
    this.kioskInstructions = this.rendering.props['kioskInstructions'];
    this.tsaInstructions = this.rendering.props['tsaInstructions'];
    this.saveTimeInstructions = this.rendering.props['saveTimeInstructions'];
    this.boardingPassWithoutCheckinItemsTitle = this.rendering.props['boardingPassWithoutCheckinItemsTitle'];
    this.boardingPassWithCheckinItemsTitle = this.rendering.props['boardingPassWithCheckinItemsTitle'];
    this.boardingPassWithoutCheckinItemsInternational = this.rendering.props['boardingPassWithoutCheckinItemsInternational'];
    this.boardingPassWithCheckinItemsInternational = this.rendering.props['boardingPassWithCheckinItemsInternational'];
    this.boardingPassBaggageInternational = this.rendering.props['boardingPassBaggageInternational'];
    this.btnOk = this.rendering.props['btnOk'];
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
