import { Component, Input, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { ContentSchema, ComponentRendering } from '@hawaiianair/core';
import { PassportScanDispatchers } from '~app/store/services/passport-scan/passport-scan.dispatchers';
import { Subscription, Observable } from 'rxjs';
import { InternationalDetailsSelectors } from '~app/store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PassportScanSetup } from '~app/models/passportscansetup.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'passport-scan',
  templateUrl: './passport-scan.component.html',
  styleUrls: ['./passport-scan.component.scss']
})
@ContentSchema({
  name: 'PassportScan',
  description: 'Passport Scan Component',
})
export class PassportScanComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  subs = new Subscription();
  iframeSrc: SafeResourceUrl;
  passportScanSetup: PassportScanSetup;
  passportScanSetup$: Observable<PassportScanSetup>;

  constructor(
    public dialogRef: MatDialogRef<PassportScanComponent>,
    private passportScanDispatchers: PassportScanDispatchers,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public dialogData: { paxId: string }
  ) {
    this.passportScanSetup$ = this.internationalDetailsSelectors.passportScanSetup$;
  }

  ngOnInit() {
    this.passportScanDispatchers.initiatePassportScan(this.dialogData.paxId);
    this.subs.add(this.passportScanSetup$.subscribe(passportScanSetup => {
      this.passportScanSetup = passportScanSetup;
      if (!!passportScanSetup) {
        this.iframeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(this.passportScanSetup.redirectUrl);
        if (this.passportScanSetup.scanComplete) {
          this.dialogRef.close();
        }
      }
    }));
  }

  @HostListener("window:message", ["$event"])
  verifyPassportScan(event: MessageEvent) {
    if (!!event.data && typeof event.data === 'string' && !!this.passportScanSetup
      && JSON.parse(event.data).transactionReference === this.passportScanSetup.scanReference) {
      const data = JSON.parse(event.data);
      if (data.payload.value === 'success') {
        this.passportScanDispatchers.getPassportScanStatus(data.transactionReference);
      } else if (data.payload.value === 'error') {
        this.passportScanDispatchers.showMaxPassportScanError(data);
      }
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
