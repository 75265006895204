import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import {timer, Observable, fromEvent, merge, Subject, Subscription} from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators';
import { Catalog } from '../../models/catalog.model';
import { SEAT_TYPES, SeatAssignment } from '../../models/seatassignment';

export const ATTR_TABINDEX = 'tabindex';
export const ATTR_HEIGHT = 'height';
export const CLASS_DISABLED = 'disabled';
export const CLASS_SELECTED = 'selected';
export const COLOR_BASIC_SEAT = '#b83292';
export const COLOR_DISABLED = '#ddd';
export const COLOR_EXTRA_COMFORT = '#007db1';
export const COLOR_FIRST_CLASS = '#dfcb9a';
export const COLOR_PREFERED_SEAT = '#008481';
export const SVG_ATTR_D = 'd';
export const SVG_ATTR_FILL = 'fill';
export const SVG_ATTR_SCALE = 'scale';
export const SVG_COORD_X = 'x';
export const SVG_COORD_Y = 'y';
export const SVG_DISABLED_X_PATH = 'M17,15.4L28,4.5l1.5,1.6L18.6,17l10.9,11L28,29.5L17,18.6L6.1,29.5L4.5,28l10.9-11L4.5,6.1l1.6-1.6L17,15.4z';
export const SVG_ELEMENT = 'svg';
export const SVG_ELEMENT_PATH = 'path';
export const SVG_ELEMENT_RECT = 'rect';
export const SVG_ELEMENT_TITLE = 'title';
export const TABINDEX_0 = '0';

// PAX SVG
export const SVG_NS = 'http://www.w3.org/2000/svg';
export const CLASS_PAX_SVG = 'pax-svg';
export const SELECTED_OTHER_PAX_OPACITY = '0.5';
export const COLOR_PAX = '#fff';
export const PAX_SCALE = '1.9';
export const PAX_SHIFT = -6;
export const PAX_SHIFT_FIRST_REGULAR = -1;
export const SVG_ATTR_TRANSFORM = 'transform';
export const SVG_ATTR_FILL_OPACITY = 'fill-opacity';
export const PAX_SVG_PATH =
  'M15,9 C15,10.66 13.66,12 12,12 C10.34,12 9,10.66 9,9 C9,7.34 10.34,6 12,6 C13.66,6 15,7.34 15,9 Z'
+ 'M6,17 C6,15 10,13.9 12,13.9 C14,13.9 18,15 18,17 L18,18 L6,18 L6,17 Z';

/**
 * SeatmapService
 * sets seatmap SVG able to interact.
 */
@Injectable({
  providedIn: 'root'
})
export class SeatmapService {
  private renderer: Renderer2;

  // data
  private seatmap: Element;
  private flightId: string;
  private paxId: string;
  private catalog: Catalog;
  private originalAssignments: SeatAssignment[] = [];
  private newAssignments: SeatAssignment[] = [];
  public noticeModalToSubscribeClickEvent: Subject<string> = new Subject<''>();
  private subscription: Subscription;

  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    // load Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  // seatmapWrapperId: An element id with svg binding into [innerHTML]
  // flightId: current seatmap flight id
  // paxId: current pax id
  // catalog: seatmap Catalog
  // seatmapAssignments: seatmap assignment object
  public initSeatmap(seatmapWrapperId: string,
                     flightId: string,
                     paxId: string,
                     catalog: Catalog,
                     originalSeatAssignments: SeatAssignment[],
                     currentSeatAssignments: SeatAssignment[],
                     browser?: string) {
    this.originalAssignments = [];
    this.newAssignments = [];
    this.subscription = this.loadSeatmap(seatmapWrapperId).subscribe(
      seatmap => {
        // clean up
        this.subscription.unsubscribe();
        if (!!seatmap) {
          // SVG successfully loaded!
          this.seatmap = seatmap;

          // set data
          this.flightId = flightId;
          this.paxId = paxId;
          // save seat assignments
          this.originalAssignments = originalSeatAssignments;
          this.newAssignments = currentSeatAssignments;
          this.catalog = catalog;
          this.setSeatmapStyles();
          this.setSeatmapResponsive(browser);
          this.setUnavailableSeats();
          this.setSelectedSeats();
          this.noticeModalToSubscribeClickEvent.next(this.flightId);
        }
      },
      err => {
        this.subscription.unsubscribe();
        console.error('SeatmapService loadSeatmap: ', err);
      }
    );
  }

  public updateSelectedSeats(newSeatAssignments: SeatAssignment[]) {
    if (!newSeatAssignments) {
      return;
    }
    this.newAssignments = newSeatAssignments;
    this.setUnavailableSeats();

    this.setSelectedSeats();
  }

  public updatePassengerId(paxId: string) {
    if (!paxId) {
      return;
    }
    this.paxId = paxId;
    this.setUnavailableSeats();

    this.setSelectedSeats();
  }

  public resetSelection() {
    // reset newAssignment
    this.newAssignments = [];
    this.originalAssignments.forEach((assign) => {
      this.newAssignments.push({
        paxId: assign.paxId,
        flightId: assign.flightId,
        seatId: assign.seatId
      });
    });
  }

  // seatmapWrapper: An element with svg binding into [innerHTML]
  private loadSeatmap(seatmapWrapperId: string): Observable<any> {
    // try every tick (100ms) for 1 seconds
    const source = timer(0, 100);
    const countTen = source.pipe(filter(val => val > 10));
    return source.pipe(
      map(
        (val) => {
          const seatmapWrapper = document.getElementById(seatmapWrapperId);
          if (!!seatmapWrapper) {
            const seatmap = seatmapWrapper.firstElementChild;
            if (!!seatmap) {
              return seatmap;
            }
          }

          return null;
        }
      ),
      takeUntil(countTen)
    );
  }

  private setSeatmapStyles() {
    // insert style block (unable to use .scss due to svg namespace)
    const cssBlock = this.renderer.createElement('style', SVG_NS) as HTMLStyleElement;
    cssBlock.innerHTML = `
      g[id^=seat] {cursor: pointer}
      g[id^=seat].disabled *, g[id^=seat].selected * {cursor: not-allowed}
      g[id^=seat].disabled svg {fill: #AAA}
    `;
    this.seatmap.appendChild(cssBlock);
  }

  private setSeatmapResponsive(browser: string) {
    const seatMap = this.seatmap;
    const seatMapTitle = seatMap.querySelector(SVG_ELEMENT_TITLE);
    // IE: keep "height" attribute for seatmap to have proper seatmap size
    if (browser !== 'IE') {
      // Other: remove "height" attribute for better responsive
      this.renderer.removeAttribute(seatMap, ATTR_HEIGHT);
    }
    // make width responsive
    this.renderer.setStyle(seatMap, 'max-width', '100%');
    // remove tooltip label title
    if (!!seatMapTitle) {
      this.renderer.removeChild(seatMap, seatMapTitle);
    }
  }

  // set all css to unavailable
  private setUnavailableSeats() {
    if (!this.seatmap) {
      return;
    }
    // get all seat ids from svg
    const allSeatsSelector = `g[id^=seat-]`;
    const allSeats = Array.from(this.seatmap.querySelectorAll(allSeatsSelector));
    // reset all disabled & all selected if any
    allSeats.forEach(
      seat => {
        // reset disabled
        this.renderer.removeClass(seat, CLASS_DISABLED);
        const seatIdSplits = seat.id.split('-');
        const selector = SVG_ELEMENT_RECT;
        const oldSeatRect = seat.querySelector(selector);
        if (seatIdSplits.length === 3) {
          const seatProps = seatIdSplits[2].split('');
          if (seatProps.includes('f')) { // first class
            this.renderer.setAttribute(oldSeatRect, SVG_ATTR_FILL, COLOR_FIRST_CLASS);
          } else if (seatProps.includes('e')) { // extra comfort
            this.renderer.setAttribute(oldSeatRect, SVG_ATTR_FILL, COLOR_EXTRA_COMFORT);
          } else if (seatProps.includes('p')) { // prefered seat
            this.renderer.setAttribute(oldSeatRect, SVG_ATTR_FILL, COLOR_PREFERED_SEAT);
          } else {
            this.renderer.setAttribute(oldSeatRect, SVG_ATTR_FILL, COLOR_BASIC_SEAT);
          }
        } else { // no seat properties
          this.renderer.setAttribute(oldSeatRect, SVG_ATTR_FILL, COLOR_BASIC_SEAT);
        }

        // reset selected
        this.renderer.removeClass(seat, CLASS_SELECTED);
        const selectorPaxSvg = `g[class=${CLASS_PAX_SVG}]`;
        const thePaxSvg = seat.querySelector(selectorPaxSvg);
        if (!!thePaxSvg) {
          this.renderer.removeChild(seat, thePaxSvg);
        }
        const iconX = seat.querySelector(SVG_ELEMENT);
        if (!!iconX) {
          this.renderer.removeChild(seat, iconX);
        }

        // a11y: add tabindex to all seats
        this.renderer.setAttribute(seat, ATTR_TABINDEX, TABINDEX_0);

        // a11y: add title tooltip for screen readers
        if (!!!seat.querySelector(SVG_ELEMENT_TITLE)) {
          const seatTitle = this.renderer.createElement(SVG_ELEMENT_TITLE, SVG_NS) as SVGTitleElement;
          seatTitle.innerHTML = seatIdSplits[1];
          this.renderer.appendChild(seat, seatTitle);
        }
      }
    );

    // get all Seat Ids
    const allSeatIds = allSeats.map(seat => seat.id.split('-')[1]);

    // get all available seat from catalog for the pax on the flight
    const allAvailIds = this.getAllAvailableSeats();

    // get all unavailabilities
    const unavailabilities = allSeatIds.filter(seat => !allAvailIds.includes(seat));
    unavailabilities.forEach(seatNum => {
      // validate seatNum
      if (!!seatNum) {
        const selector = `g[id^=seat-${seatNum}]`;
        const theSeat = this.seatmap.querySelector(selector);
        if (!!theSeat) {
          this.renderer.addClass(theSeat, CLASS_DISABLED);
          this.renderer.removeAttribute(theSeat, ATTR_TABINDEX);
          const select = SVG_ELEMENT_RECT;
          const seatRect = theSeat.querySelector(select);
          if (!!seatRect) {
            this.renderer.setAttribute(seatRect, SVG_ATTR_FILL, COLOR_DISABLED);

            // Add the 'X' to the unavailable seats
            const svgElement = this.renderer.createElement(SVG_ELEMENT, SVG_NS) as SVGElement;
            this.renderer.setAttribute(svgElement, SVG_COORD_X, seatRect.getAttribute(SVG_COORD_X));
            this.renderer.setAttribute(svgElement, SVG_COORD_Y, seatRect.getAttribute(SVG_COORD_Y));
            this.renderer.appendChild(theSeat, svgElement);
            const svgPathX = this.renderer.createElement(SVG_ELEMENT_PATH, SVG_NS) as SVGPathElement;
            const relativeSize = parseInt(seatRect.getAttribute(ATTR_HEIGHT), 10);
            const svgPathXScale = relativeSize / 34;
            this.renderer.setAttribute(svgPathX, SVG_ATTR_TRANSFORM, 'scale(' + svgPathXScale.toString() + ')');
            this.renderer.setAttribute(svgPathX, SVG_ATTR_D, SVG_DISABLED_X_PATH);
            this.renderer.appendChild(svgElement, svgPathX);
          }
        }
      }
    });
  }


  // set css for all assigned seats
  private setSelectedSeats() {
    this.newAssignments.forEach(
      assign => {
        // check flight id
        if (assign.flightId === this.flightId) {
          const curSeatId = assign.seatId;
          if (!!curSeatId) {
            const selector = `g[id^=seat-${curSeatId}]`;
            const selSeat = this.seatmap.querySelector(selector);
            this.setSeatSelected(selSeat, assign, this.paxId);
          }
        }
      }
    );
  }

  public getSeatClick(): Observable<SeatAssignment> {
    const allSeats = Array.from(this.seatmap.querySelectorAll('g[id^=seat-]'));
    let selectSeat$: Observable<Event>;
    allSeats.forEach(seat => {
      const seatClick$ = fromEvent(seat, 'click');
      const seatTouchEnd$ = fromEvent(seat, 'touchend');

      if (!selectSeat$) {
        selectSeat$ = merge(seatClick$, seatTouchEnd$);
      } else {
        selectSeat$ = merge(selectSeat$, seatClick$, seatTouchEnd$);
      }
    });

    return selectSeat$.pipe(
      filter(event => {
        const clickedSeat = (event.target as Element).parentNode as HTMLElement;
        return (!!clickedSeat
          && !clickedSeat.classList.contains(CLASS_DISABLED)
          && !clickedSeat.classList.contains(CLASS_SELECTED)
          && clickedSeat.id.split('-').length > 1);
      }),
      map(event => {
        // get all ids and properties of new and old seat
        const clickedSeat = (event.target as Element).parentNode as HTMLElement;
        const arrNewSeatId = clickedSeat.id.split('-');
        let arrNewSeatProps = [];
        if (arrNewSeatId.length > 2) {
          arrNewSeatProps = arrNewSeatId[2].split('');
        }

        let arrOldSeatProps = [];
        const oldSeat = this.originalAssignments.find(
          assign => assign.paxId === this.paxId && assign.flightId === this.flightId
        );
        if (!!oldSeat && !!oldSeat.seatId) {
          // get old id from the seatmap
          const selector = `g[id^=seat-${oldSeat.seatId}]`;
          const oldSeatEl = this.seatmap.querySelector(selector);
          if (!!oldSeatEl && !!oldSeatEl.id) {
            const arrOldSeatId = oldSeatEl.id.split('-');
            if (arrOldSeatId.length > 2) {
              arrOldSeatProps = arrOldSeatId[2].split('');
            }
          }
        }

        // get new/old seat class
        let newSeatClass = SEAT_TYPES.COACH; // coach
        let oldSeatClass = SEAT_TYPES.COACH; // coach
        if (arrNewSeatProps.includes('e') || arrNewSeatProps.includes('p')) { newSeatClass = SEAT_TYPES.EXTRA_COMFORT; }
        if (arrNewSeatProps.includes('f')) { newSeatClass = SEAT_TYPES.FIRST_CLASS; }
        if (arrOldSeatProps.includes('e') || arrOldSeatProps.includes('p')) { oldSeatClass = SEAT_TYPES.EXTRA_COMFORT; }
        if (arrOldSeatProps.includes('f')) { oldSeatClass = SEAT_TYPES.FIRST_CLASS; }

        // check if upgrade
        let isUpgrade = false;
        if (newSeatClass.level > oldSeatClass.level) {
          isUpgrade = true;
        }

        // check if exit row
        const isExitRow = arrNewSeatProps.includes('x');

        return {
          paxId: this.paxId,
          flightId: this.flightId,
          seatId: arrNewSeatId[1],
          seatClass: newSeatClass.name,
          upgradeFrom: isUpgrade ? oldSeatClass : null,
          upgradeTo: isUpgrade ? newSeatClass : null,
          isExitRow: isExitRow
        };
      })
    );
  }

  public getAllSelectedSeatIds(): string[] {
    const theSeats = this.seatmap.querySelectorAll('g[class=selected]');
    return Array.from(theSeats).map(seat => seat.id);
  }

  private getAllAvailableSeats(): string[] {
    let toRet = [];
    const entry = this.catalog.entries.find(
      collection => collection.flightId === this.flightId
      && collection.passengerId === this.paxId
    );

    if (!entry) {
      return;
    }

    entry.products.filter(product =>
      product.priceQuotes[0].quantity > 0)
    .forEach(
        product => {
          toRet = [...toRet, ...((product.availability.split(',')))];
        }
    );

    // include originally selected seat to make it available for the pax
    const originalAssign = this.originalAssignments.find(assign => assign.paxId === this.paxId && assign.flightId === this.flightId);
    if (!!originalAssign && !!originalAssign.seatId) {
      toRet.push(originalAssign.seatId);
    }

    return toRet;
  }

  private setSeatSelected(seatEl: Element, assign: SeatAssignment, paxId: string) {
    if (!seatEl) {
      return;
    }
    // add class CLASS_SELECTED
    this.renderer.addClass(seatEl, CLASS_SELECTED);
    this.renderer.removeAttribute(seatEl, ATTR_TABINDEX);
    const iconX = seatEl.querySelector(SVG_ELEMENT);
    if (!!iconX) {
      this.renderer.removeChild(seatEl, iconX);
    }
    // set rect attributes
    const seatRect = seatEl.querySelector(SVG_ELEMENT_RECT);
    if (!seatRect) {
      console.error('SEATMAP SERVICE: something went wrong. seat doesn\'t have rect', seatEl);
      return;
    }
    // get x y coordinate of the rect
    let xPax = Number(seatRect.getAttribute('x'));
    let yPax = Number(seatRect.getAttribute('y'));
    // check if NaN
    if (!xPax || !yPax) {
      console.error('SEATMAP SERVICE: something went wrong. rect doesn\'t have x y coordinate', seatRect);
      return;
    }
    // get the seat property
    const seatIdSplits = seatEl.id.split('-');
    if (seatIdSplits.length > 2) {
      const seatProps = seatIdSplits[2].split('');
      // check if first class and not lieflat: property includes f, not i.
      if (seatProps.includes('f') && !seatProps.includes('i')) {
        xPax = xPax + PAX_SHIFT_FIRST_REGULAR;
        yPax = yPax + PAX_SHIFT_FIRST_REGULAR;
      } else {
        xPax = xPax + PAX_SHIFT;
        yPax = yPax + PAX_SHIFT;
      }
    } else {
      xPax = xPax + PAX_SHIFT;
      yPax = yPax + PAX_SHIFT;
    }
    // get the original seat color and set the seat color with it
    let originalSeatColor = COLOR_BASIC_SEAT;
    if (seatIdSplits.length > 2) {
      const seatProps = seatIdSplits[2].split('');
      if (seatProps.includes('f')) {
        originalSeatColor = COLOR_FIRST_CLASS;
      } else if (seatProps.includes('p')) {
        originalSeatColor = COLOR_PREFERED_SEAT;
      } else if (seatProps.includes('e')) {
        originalSeatColor = COLOR_EXTRA_COMFORT;
      }
    }
    this.renderer.setAttribute(seatRect, SVG_ATTR_FILL, originalSeatColor);
    // create svg wrapper element
    const paxWrapperEl = this.renderer.createElement('g', SVG_NS);
    // add class
    this.renderer.addClass(paxWrapperEl, CLASS_PAX_SVG);
    // add attributes 1. transform : translate, scale
    const transformStr = 'translate(' + xPax.toString() + ', ' + yPax.toString() + ') scale(' + PAX_SCALE + ', ' + PAX_SCALE + ')';
    this.renderer.setAttribute(paxWrapperEl, SVG_ATTR_TRANSFORM, transformStr);
    // add attributes 2. fill : pax color
    this.renderer.setAttribute(paxWrapperEl, SVG_ATTR_FILL, COLOR_PAX);
    // add attributes 3. fill opacity
    if (paxId !== assign.paxId)  {
      this.renderer.setAttribute(paxWrapperEl, SVG_ATTR_FILL_OPACITY, SELECTED_OTHER_PAX_OPACITY);
    }
    // create pax svg
    const paxSvgEl = this.renderer.createElement(SVG_ELEMENT_PATH, SVG_NS);
    this.renderer.setAttribute(paxSvgEl, SVG_ATTR_D, PAX_SVG_PATH);
    // all set. add those to parent
    this.renderer.appendChild(seatEl, paxWrapperEl);
    this.renderer.appendChild(paxWrapperEl, paxSvgEl);
  }
}
