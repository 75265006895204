import { Component, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-military-refer-out-modal',
  templateUrl: './military-refer-out-modal.component.html',
  styleUrls: ['./military-refer-out-modal.component.scss'],
})
@ContentSchema({
  description: '',
  name: 'MilitaryReferOutModal',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
  ]
})
export class MilitaryReferOutModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(private modalContentsSvc: ModalContentsService) { }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('military-refer-out-modal') || [{}];
  }
}
