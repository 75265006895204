import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { FlightState } from '~app/store/reducers/flight/flight.reducers';

export const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getFlightState = createSelector(
    getEntityState,
    (state: EntityState) => state.flight
);

export const getFlight = createSelector(
    getFlightState,
    (state: FlightState) => state.flight
);

export const getFlightLoading = createSelector(
    getFlightState,
    (state: FlightState) => state.loading
);

export const allFlights = createSelector(
    getFlightState,
    (state: FlightState) => state.allFlights
);

export const error = createSelector(
    getFlightState,
    (state: FlightState) => state.error
);

@Injectable()
export class FlightSelectors {
    constructor(private store: Store<EntityState>) {}

    flight$ = this.store.select(getFlight);
    flightState$ = this.store.select(getFlightState);
    flightLoading$ = this.store.select(getFlightLoading);
    allFlights$ = this.store.select(allFlights);
    error$ = this.store.select(error);
}
