<div *ngIf="loading$ | async; else resultPage">
  <app-checkin-header [hideHeaderInfo]="true"></app-checkin-header>
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #resultPage class="container">
    <div class="row no-gutters white">
        <div class="col">
            <div class="airport-information" *ngIf="(airports$ | async)">
                <p id="origin" class="origin call-out-bold white flight-info-spacing d-none d-md-block">{{(airports$ | async)[origin].airportCityAndCode}}</p>
                <p id="mobile-origin" class="origin call-out-bold white flight-info-spacing d-block d-md-none">{{(airports$ | async)[origin]._id}}</p>
                <i class="ha-icon icon-long-arrow-right"></i>
                <p id="destination" class="destination call-out-bold white flight-info-spacing d-none d-md-block">{{(airports$ | async)[destination].airportCityAndCode}}</p>
                <p id="mobile-destination" class="destination call-out-bold white flight-info-spacing d-block d-md-none">{{(airports$ | async)[destination]._id}}</p>
                <div *ngIf="flightStops >= 1" class="flight-stop flight-info-spacing">
                    <p id="stops" class="small white mb-0" *haText="rendering.props['stopCounter']"></p>
                </div>
                <div class="flight-info-spacing">
                    <span class="small white" *haText="rendering.props['boardingTime']"></span>
                    <div id="boardingTime">{{ boardingTime | date:'h:mma'| lowercase}}</div>
                </div>
                <div class="flight-info-spacing">
                    <span class="small white" *haText="rendering.props['gateNumber']"></span>
                    <div id="gateNumber">{{ originGate }}</div>
                </div>
            </div>
        </div>
    </div>
    <section id="get-boarding-pass">
        <div class="anotherflight" *ngIf="isMultiSegment$ | async">
            <button id="anotherFlightButton" class="btn btn-secondary" (click)="anotherFlight()" *haText="rendering.props['anotherSegmentButton']"></button>
        </div>
        <h6 id="get-boarding-pass-title" *haText="(isSinglePaxCheckin$ | async) ? rendering.props['getBoardingPassTitle'] : rendering.props['getBoardingPassPluralTitle']"></h6>
        <p id="get-boarding-pass-text" class="call-out d-none d-md-block" *haText="rendering.props['getBoardingPassText']"></p>
        <p id="get-mobile-boarding-pass-text" class="call-out d-sm-block d-md-none" *haText="rendering.props['getMobileBoardingPassText']"></p>
        <div class="row" id="get-boarding-pass-buttons">
            <div class="col get-boarding-pass-flex">
                <div class="button-container col-4 button-padding" id="result-print">
                    <button mat-button id="result-boarding-pass" class="btn btn-default col-12" [disabled]='loading$ | async'
                        (click)="routeToBoardingPass()">
                        <div *ngIf="loading$ | async; else boardingPassButton">
                            <div class="spinner-border" role="status">
                                <span class="sr-only" *haText="rendering.props['loading']"></span>
                            </div>
                        </div>
                        <ng-template #boardingPassButton>
                            <span class="d-none d-md-block"
                              *haText="((isSinglePaxCheckin$ | async) ? rendering.props['printBoardingPass'] :
                                rendering.props['printBoardingPasses']) | titlecase"></span>
                            <span class="d-block d-md-none"
                              *haText="((isSinglePaxCheckin$ | async) ? rendering.props['getBoardingPassTitle'] :
                                rendering.props['getBoardingPassPluralTitle']) | titlecase"></span>
                        </ng-template>
                    </button>
                </div>
                <!-- <div class="button-container col-4 button-padding">
                    <button mat-raised-button id="result-pdf" class="btn btn-default col-12"
                    [disabled]='true' (click)='onExit()' *haText="rendering.props['downloadPdf']"></button>
                </div>
                <div class="button-container col-4 button-padding">
                    <button mat-raised-button id="result-email" class="btn btn-default col-12"
                    [disabled]='true' (click)='onExit()' *haText="rendering.props['emailBoardingPasses']"></button>
                </div> -->
            </div>
        </div>
        <!-- <app-info-links [rendering]="infoLinksRendering" [marginTop]="'40px'" (infoLinkEvent)="clickInfoLink($event)"
            [linkArray]="['saveTripCalendar', 'tripDetailsStatus', 'emailReceipt']">
        </app-info-links> -->
    </section>
    <div *ngFor='let trip of fullTripData$ | async; let i = index'>
        <section
            *ngIf='resultData && resultData?.status && trip?.operatedBy !== haCode && trip?.isCheckedIn && trip?.passengerId === passengerID'
            class="OASection">
            <h5 id="results-oa-header" class="OAHeader">
                <i class="icon-help-font help-icon"></i>
                <span class="OAHeaderText" *haText="rendering.props['OAflightCheckedInTitle']"></span>
            </h5>
            <p id="oa-checked-in-message" class="call-out" *haRichText="oaCheckedInMessage[i]">
        </section>

        <section
            *ngIf='resultData && resultData?.status && trip?.operatedBy !== haCode && !trip?.isCheckedIn && trip?.passengerId === passengerID'
            class="OASection">
            <p id="oa-not-checked-in-message" class="call-out" *haRichText="oaNotCheckedInMessage[i]"></p>
        </section>
    </div>
    <section id="next-steps" class="bg-transparent">
        <div class="mx-0 d-none d-md-block">
            <div *ngIf="hasCheckedBags; else nextStepsMainText" class="row">
                <div class="col-9">
                    <h6 id="next-steps-title" *haText="rendering.props['nextStepsTitle']"></h6>
                    <p id="next-steps-text" class="call-out" *haText="rendering.props['nextStepsText']"></p>
                </div>
            </div>
            <ng-template #nextStepsMainText>
                <div class="row">
                    <div class="col-9">
                        <div *ngIf="isInternational$ | async; else domesticBagsDescription">
                            <h6 id="international-pax-without-check-in-items-heading">
                                {{ rendering.props['boardingPassWithoutCheckinItemsTitle'] }}
                            </h6>
                            <p class="call-out" *haRichText="rendering.props['boardingPassWithoutCheckinItemsInternational']"></p>
                            <br>
                            <h6 id="international-pax-with-check-in-items-heading">
                                {{ rendering.props['boardingPassWithCheckinItemsTitle'] }}
                            </h6>
                            <p class="call-out" *haRichText="rendering.props['boardingPassWithCheckinItemsInternational']">
                            <br>
                            <h6 id="baggage-drop-off-time-requirement-description">
                                {{ rendering.props['boardingPassBaggageInternational'] }}
                            </h6>
                        </div>
                        <ng-template #domesticBagsDescription>
                            <h6 id="next-steps-title" *haText="rendering.props['nextStepsTitle']"></h6>
                            <p id="next-steps-text" class="call-out"><strong class="dark-grey">{{rendering.props['checkedBagsBoldText']}}</strong>
                                {{rendering.props['checkedBagsProceed']}}</p>
                            <p id="next-steps-text" class="call-out" *haText="rendering.props['checkedBagsDescription']"></p>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </div>

        <app-info-links class="d-sm-block d-md-none" [rendering]="infoLinksRendering" [marginTop]="'40px'" (infoLinkEvent)="clickInfoLink($event)"
            [linkArray]="['nextSteps']">
        </app-info-links>

    </section>
    <footer>
        <get-our-app *ngIf="getOurAppRendering" [rendering]="getOurAppRendering"></get-our-app>
        <web-footer *ngIf="footerRendering" [rendering]="footerRendering"></web-footer>
    </footer>
</ng-template>
