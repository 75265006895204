import { Action, createReducer, on } from '@ngrx/store';
import * as StationCodeActions from '../../actions/station-code.actions';
import * as AirportActions from '../../actions/airport.actions';
import * as CountriesActions from '../../actions/countries.actions';
import { Airport, AirportMap } from '~app/models/airport.model';
import { AIRPORTS } from '~app/data/airport-data';
import { Response } from '~app/models/response.model';
import { StationCode } from '~app/models/stationCode.model';
import { PointOfSale } from '~app/models/pointofsale.model';
import { Country, CountryMap, CountryName, Country3CodeMap } from '~app/models/country.model';
import { CountryNamePipe } from '~app/pipes/country-name.pipe';
import { TitleCasePipe } from '@angular/common';

export interface ICountriesState {
  loading: boolean;
  error: boolean;
  countriesData?: Countries;
}

export interface Countries {
  countryList: Country[];
  countryMap: CountryMap;
  country3CodeMap: Country3CodeMap;
  countryDisplayName?: CountryName[];
}

export interface StationCodeDataState {
  loading: boolean;
  error: boolean;
  stationCodeData: StationCode;
  pointOfSale: PointOfSale;
  currencyCode: string;
}

export interface IAirportState {
  loading: boolean;
  error: boolean;
  airports?: AirportMap;
}

export interface ReferenceDataState {
  loading: boolean;
  error: boolean;
  stationCodeData: StationCodeDataState;
  airports: IAirportState;
  countries: ICountriesState;
}

export const initialReferenceDataState: ReferenceDataState = {
  loading: false,
  error: false,
  stationCodeData: {
    loading: false,
    error: false,
    stationCodeData: null,
    pointOfSale: null,
    currencyCode: ""
  },
  airports: {
    loading: false,
    error: false,
    airports: null
  },
  countries: {
    loading: false,
    error: false,
    countriesData: {
      countryList: null,
      countryMap: null,
      country3CodeMap: null,
      countryDisplayName: []
    }
  }
};

export const referenceDataReducer = createReducer(
  initialReferenceDataState,
  on(StationCodeActions.getStationCode, (state) => ({
    ...state,
    loading: true,
    error: false,
    stationCodeData: {
      ...state.stationCodeData,
      loading: true
    }
  })),
  on(StationCodeActions.getStationCodeError, (state) => ({
    ...state, 
    loading: false, 
    error: true,
    stationCodeData: {
      ...state.stationCodeData,
      loading: false,
      error: true
    }
  })),
  on(StationCodeActions.getStationCodeSuccess, (state, { stationCode }) => ({
    ...state,
    stationCodeData: {
      stationCodeData: stationCode.results[0],
      loading: false,
      error: false,
      currencyCode: stationCode.results[0].currencyCode,
      pointOfSale: {
        location: {
          cityCode: stationCode.results[0].pseudoCityCode,
          stationCode: stationCode.results[0].stationCode
        }
      }
    },
    loading: state.stationCodeData.loading || state.airports.loading,
    error: false,
  })),
  on(AirportActions.getAirports, (state) => ({
    ...state,
    loading: true,
    error: false,
    airports: {
      ...state.airports,
      loading: true
    }
  })),
  on(AirportActions.getAirportsError, state => ({
    ...state,
    loading: false,
    error: true,
    airports: { 
      ...state.airports, 
      loading: false, 
      error: true
    }
  })),
  on(AirportActions.getAirportsSuccess, (state, { airports, airportsList }) => ({
    ...state,
    airports: {
      airports: getAirportData(airports, airportsList),
      loading: false,
      error: false
    },
    loading: state.stationCodeData.loading || state.countries.loading,
    error: false,
  })),
  on(CountriesActions.getCountries, (state) => ({
    ...state,
    loading: true,
    error: false,
    countries: {
      ...state.countries,
      loading: true
    }
  })),
  on(CountriesActions.getCountriesError, (state) => ({
    ...state,
    loading: false,
    error: true,
    countries: {
      ...state.countries,
      loading: false,
      error: true
    }
  })),
  on(CountriesActions.getCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries: {
      countriesData: {
        countryList: countries.results,
        countryMap: mapCountries(countries.results, true),
        country3CodeMap: mapCountries(countries.results, false),
        countryDisplayName: generateDisplayNamesArray(countries.results)
      },
      loading: false,
      error: false
    },
    loading: state.airports.loading || state.stationCodeData.loading,
    error: false
  })),
);

export function getAirportData(airports: Response<Airport>, airportsList: string[]) {
  const airportData: AirportMap = {};
  airportsList.forEach(airportId => {
    const data = airports.results.find(airportResponse => airportResponse._id === airportId) || null;
    const appAirportData = AIRPORTS.find(location => location.airportCode === airportId);
    const airportCityAndCode = `${data.cityNm.split(',')[0]} (${data._id})`;
    airportData[airportId] = {...data, airportData: appAirportData, airportCityAndCode};
  });
  return airportData;
}

const mapCountries = (countries: Country[], isMappingName: boolean): CountryMap => {
  const countryPipe = new CountryNamePipe(new TitleCasePipe());
  const countryMap: CountryMap = {};
  for (const country of countries) {
    isMappingName ? countryMap[countryPipe.transform(country.countryNm)] = country.country2LetterCode
      : countryMap[country.country3LetterCode] = country.country2LetterCode;
  }
  return countryMap;
};

const generateDisplayNamesArray = (countries: Country[]): CountryName[] => {
  const countryPipe = new CountryNamePipe(new TitleCasePipe());
  return countries.map(country => countryPipe.transform(country.countryNm)).sort();
};

export function reducer(state: ReferenceDataState | undefined, action: Action) {
    return referenceDataReducer(state, action);
}
