import { routerNavigatedAction, routerRequestAction } from '@ngrx/router-store';
import * as RouterActions from '~app/store/actions/router.actions';
import * as SessionActions from '~app/store/actions/session.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { ActionPrecedence } from './checkin-route-serializer';

export interface ICheckinRouterState {
    dashboardActions: string[];
    previous: string;
    current: string;
    next: string;
    sortedActions: ActionPrecedence[];
}

export const initialCheckinRouterState: ICheckinRouterState = {
    dashboardActions: [],
    previous: '',
    current: '',
    next: '',
    sortedActions: [],
};

export const checkinRouterReducer = createReducer(
    initialCheckinRouterState,
    on(routerRequestAction, (state, { payload }) => ({
        ...state,
        previous: payload.routerState.url.substring(1)
    })),
    on(routerNavigatedAction, (state, { payload }) => ({
        ...state,
        current: payload.routerState.url
    })),
    on(RouterActions.actionRouting, (state, { actions }) => ({
        ...state,
        dashboardActions: !!actions ? [...state.dashboardActions, ...actions] : state.dashboardActions,
    })),
    on(RouterActions.actionRoutingSuccess, (state, { routerState, sortedActions }) => ({
        ...state,
        ...routerState,
        sortedActions
    })),
    on(SessionActions.clearSessionDataCheckinStart, state => ({
        ...state,
        dashboardActions: [],
        sortedActions: [],
    })),
);

export function reducer(state: ICheckinRouterState | undefined, action: Action) {
    return checkinRouterReducer(state, action);
}
