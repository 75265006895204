import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as TripAction from '../../actions/trip.actions';
import { EntityState } from '../../reducers';
import { PassengerDetail } from '~app/models/passengerdetail.model';
import { ComponentRendering } from '@hawaiianair/core';
import { Trip } from "~app/models/trip.model";
import { LapInfant } from "~app/models/lapinfant.model";
import { PassengerName } from "~app/models/passengername.model";
import { TripSearch } from "~app/models/trip-search.model";
import { AdultSearch } from "~app/models/adultsearch.model";

@Injectable()
export class TripDispatchers {
    constructor(private store: Store<EntityState>) {}

    getByPnr(tripSearch: TripSearch, alertMessage?: any) {
        this.store.dispatch(TripAction.getByPnr({ tripSearch, alertMessage }));
    }

    checkinError(error: string, requestData?: any) {
      this.store.dispatch(TripAction.checkinError({ error, requestData }));
    }

    resultGetTrip(tripSearch: TripSearch, alertMessage?: any) {
      this.store.dispatch(TripAction.resultGetTrip({ tripSearch, alertMessage }));
    }

    updatePassenger(tripId: string, passengerUpdates: PassengerDetail[], errorRendering: string, routeTo?: string) {
        this.store.dispatch(TripAction.updatePassenger({tripId, passengerUpdates, errorRendering, routeTo}));
    }

    addMilitaryInformation(passengerUpdates: PassengerDetail[]) {
        this.store.dispatch(TripAction.addMilitaryInformation({passengerUpdates: passengerUpdates}));
    }

    updateMilitaryInformation(passengerUpdates: PassengerDetail | PassengerDetail[]) {
        this.store.dispatch(TripAction.updateMilitaryInformation({passengerUpdates: passengerUpdates}));
    }

    addLapInfant(tripId: string, adultPassengerId: string, lapInfant: LapInfant) {
        this.store.dispatch(TripAction.addLapInfant({ tripId, adultPassengerId, lapInfant }));
    }

    addLapInfantError(error: any) {
        this.store.dispatch(TripAction.addLapInfantError({ error }));
    }

    removeLapInfant(tripId: string, confirmationCode: string, passengerId: string) {
        this.store.dispatch(TripAction.removeLapInfant({tripId, confirmationCode, passengerId}));
    }

    addMinorToAdult(tripId: string, adultSearchInfo: AdultSearch) {
        this.store.dispatch(TripAction.addMinorToAdult({ tripId, adultSearchInfo }));
    }

    checkInfantStatus(trip: Trip) {
        this.store.dispatch(TripAction.checkInfantStatus({ trip }));
    }

    updatePassengerMilitary(tripId: string, passengerUpdates: PassengerDetail[], errorRendering: ComponentRendering) {
        this.store.dispatch(
            TripAction.updatePassengerMilitary({tripId: tripId, passengerUpdates: passengerUpdates, errorRendering: errorRendering}));
    }

    updatePassengerMilitaryType(tripId: string, passengerUpdates: PassengerDetail[], routeTo: string) {
        this.store.dispatch(
            TripAction.militarySaveTypeModalUpdatePassenger({tripId: tripId, passengerUpdates: passengerUpdates, routeTo}));
    }

    savePassengerMilitaryType(tripId: string, passengerUpdates: PassengerDetail[], routeTo: string) {
        this.store.dispatch(TripAction.militaryConfirmMilitaryType({tripId, passengerUpdates, routeTo}));
    }

    militaryTypeSuccessModalClosed(routeTo: string) {
        this.store.dispatch(TripAction.militaryTypeSuccessModalClosed({routeTo}));
    }

    clearTripDataCheckinStart() {
        this.store.dispatch(TripAction.clearTripDataCheckinStart());
    }

    getGroupPassengerByName(tripId: string, confirmationCode: string, passengerName: PassengerName, dateOfBirth?: string) {
        this.store.dispatch(TripAction.getGroupPassengerByName({ tripId, confirmationCode, passengerName, dateOfBirth }));
    }

    getByPassengerId(tripSearch: TripSearch, alertMessage?: any, passengerIds?: string[]) {
        this.store.dispatch(TripAction.getByPassengerId({ tripSearch, alertMessage, passengerIds }));
    }

    addToEliteStandByListErrorModalClosed() {
      this.store.dispatch(TripAction.addToEliteStandByListErrorModalClosed());
    }
}
