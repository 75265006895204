import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, switchMap, catchError, map, withLatestFrom, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as StationCodeActions from '~app/store/actions/station-code.actions';
import * as CountriesActions from '~app/store/actions/countries.actions';
import * as ReferenceDataActions from '~app/store/actions/reference-data.actions';
import * as AirportActions from '~app/store/actions/airport.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import * as fromTrips from '~app/store/services/trip/trip.selectors';
import { ModalsService } from '~app/modals/modals.service';
import { ReferenceDataService } from "~app/store/services/reference-data/reference-data.service";
import { EntityState } from "~app/store";
import { Store, select } from "@ngrx/store";

@Injectable()
export class ReferenceDataEffects {
    constructor(
        private actions$: Actions,
        private store: Store<EntityState>,
        private referenceDataService: ReferenceDataService,
        private modalsService: ModalsService
    ) { }

    getStationCode$ = createEffect(() =>
        this.actions$.pipe(
        ofType(StationCodeActions.getStationCode, ReferenceDataActions.getStationCode),
        switchMap(action => {
            return this.referenceDataService.getStationCode(action.cityCode).pipe(
            tap(stationCode => {
                if (!stationCode || !stationCode.results || !stationCode.results.length) {
                throw { error: 'no data', requestData: stationCode };
                }
            }),
            map(stationCode => {
                return StationCodeActions.getStationCodeSuccess({stationCode});
            }),
            catchError(error => {
                return of(StationCodeActions.getStationCodeError(error));
            }
            ));
        })
        )
    );

    getCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.getCountries),
            switchMap(() => {
                return this.referenceDataService.getCountries().pipe(
                    tap(countries => {
                        if (!countries && !countries.results.length) {
                        throw { error: 'no data', requestData: countries };
                    }
                }),
                map(countries => CountriesActions.getCountriesSuccess({countries})),
                catchError(error => of(CountriesActions.getCountriesError(error))
                ));
            })
        )
    );

    getAirports$ = createEffect(() =>
        this.actions$.pipe(
        ofType(AirportActions.getAirports ),
        withLatestFrom(this.store.pipe(select(fromTrips.getTripFlightEntries))),
        switchMap(([_, tripFlights]) => {
            const airportsList = [];
            if (tripFlights) {
            tripFlights.forEach(flight => {
                if (!airportsList.includes(flight.origin)) {
                airportsList.push(flight.origin);
                }

                if (!airportsList.includes(flight.scheduledDestination)) {
                airportsList.push(flight.scheduledDestination);
                }
            });
            } else  {
            throw { error: 'no airports data' };
            }
            return this.referenceDataService.getAirportsData(airportsList).pipe(
            tap(airports => {

                if (!airports || !airports.results.length) {
                    console.log('in error block');
                throw { error: 'no data', requestData: airports };
                }
            }),
            concatMap(airports => {
                return [
                AirportActions.getAirportsSuccess({airports, airportsList}),
                RouterActions.getTripSuccessRouting(),
                ];
            }),
            catchError(error => {
                return of(AirportActions.getAirportsError(error));
                }
                ));
            })
        )
    );

    getStationCodeError$ = createEffect(() =>
        this.actions$.pipe(
        ofType(StationCodeActions.getStationCodeError),
        tap(_ => {
            this.modalsService.openGenericErrorMessage({ contentProps: 'getStationCodeError' });
        })
        ), { dispatch: false }
    );

    getCountriesError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.getCountriesError),
            tap(_ => {
            this.modalsService.openGenericErrorMessage({ contentProps: 'getCountriesError' });
            })
        ), { dispatch: false }
    );
    getAirportsError$ = createEffect(() =>
        this.actions$.pipe(
        ofType(AirportActions.getAirportsError),
        tap(_ => {
            this.modalsService.openGenericErrorMessage({ contentProps: 'getAirportsError' });
            }),
        ), { dispatch: false }
    );
}
