import { createAction, props } from '@ngrx/store';
import { Response } from '~app/models/response.model';
import { Trip } from '~app/models/trip.model';
import { PassengerDetail } from '~app/models/passengerdetail.model';
import { PassengerList } from '~app/models/passengerlist.model';
import { LapInfant } from '~app/models/lapinfant.model';
import { PassengerName } from '~app/models/passengername.model';
import { TripSearch } from '~app/models/trip-search.model';
import { AdultSearch } from '~app/models/adultsearch.model';
import { EliteStandByPassenger } from '~app/components/seat/seat-upgrade-passenger-list/seat-upgrade-passenger-list.component';

export const createTripAction = (actionType: string) =>
    createAction(actionType, props<{ trip: Response<Trip> }>());

export const createTripErrorAction = (actionType: string) =>
    createAction(actionType, props<{ error: string, requestData: Response<Trip> }>());

export const checkinNoCart = (actionType: string) =>
    createAction(actionType);

export const tripRouter = (actionType: string) =>
    createAction(actionType, props<{ routeTo: string, payload: any }>());

export const getTripAction = (actionType: string) =>
    createAction(actionType, props<{ tripSearch: TripSearch, alertMessage?: any, passengerIds?: string[]}>());

export const updatePassengerAction = (actionType: string) =>
    createAction(actionType, props<{tripId: string, passengerUpdates: PassengerDetail[], errorRendering?: any, routeTo?: string}>());

export const getByPnr = createAction(
    '[Passengers] Get By PNR',
    props<{ tripSearch: TripSearch, alertMessage: any, passengerIds?: string[] }>()
);

export const resultGetTrip = getTripAction(
  '[Result] Get By PNR',
);

export const getByPnrError = createAction(
    '[Trip API] Get By PNR Error',
    props<{ error: string, requestData: Response<Trip>, contentProps?: string }>()
);

export const getByPnrSuccess = createTripAction(
    '[Trip API] GET_BY_PNR_SUCCESS'
);

export const checkIn = createAction(
    '[Checkin Results] Checkin'
);

export const paymentCheckin = createAction(
    '[Payment Route] Checkin',
    props<{ passengerIds: string[] }>()
  );

export const guidelinesCheckin = createAction(
    '[Guidelines Component] Checkin'
);

export const checkinError = createTripErrorAction(
    '[Trip API] Checkin Error'
);

export const checkinSuccess = createTripAction(
    '[Trip API] Checkin Success'
);

export const paymentCheckinSuccess = createTripAction(
    '[Trip API] Payment Checkin Success'
);

export const checkinSuccessAlreadyCheckedIn = createTripAction(
  '[Trip API] Checkin Success All Passengers Already Checkedin'
);

export const updatePassenger = updatePassengerAction(
    '[Passengers] Update Passenger '
);

export const addLapInfant = createAction(
    '[Trip API] Add Lap Infant ',
    props<{ tripId: string, adultPassengerId: string, lapInfant: LapInfant }>()
);

export const addLapInfantSuccess = createAction(
    '[Trip API] Add Lap Infant Success',
    props<{ passengerList: PassengerList }>()
);

export const addLapInfantError = createAction(
    '[Trip API] Add Lap Infant Error',
    props<{ error: any }>()
);

export const removeLapInfant = createAction(
    '[Remove Infant Modal] Remove Lap Infant ',
    props<{ tripId: string, confirmationCode: string, passengerId: string }>()
);

export const addMinorToAdult = createAction(
    '[Trip API] Add Minor To Adult',
    props<{ tripId: string, adultSearchInfo: AdultSearch}>()
);

export const addMinorToAdultSuccess = createAction(
    '[Trip API] Add Minor To Adult Success'
);

export const addMinorToAdultError = createAction(
    '[Trip API] Add Minor To Adult Error',
    props<{ error: any }>()
);

export const checkInMinorStatus = createAction(
    '[Passengers Component] Check Minor Status',
    props<{ trip: Trip }>()
);

export const updatePassengerMilitary = updatePassengerAction(
    '[Military Form] Update Passenger'
);

export const militarySaveTypeModalUpdatePassenger = updatePassengerAction(
    '[Military Save Type Modal] Update Passenger Military Type'
);

export const militaryConfirmMilitaryType = updatePassengerAction(
    '[Military] Confirm Military Type'
  );

export const addMilitaryInformation = createAction(
    '[Military] Add Military Information',
    props<{ passengerUpdates: PassengerDetail[] }>()
);

export const updateMilitaryInformation = createAction(
    '[Military] Update Military Information',
    props<{ passengerUpdates: PassengerDetail | PassengerDetail[] }>()
);

export const updatePassengerMilitaryTypeSuccess = createAction(
    '[Trip API] Update Passenger Military Type Success',
    props<{ trip: Response<Trip>, routeTo?: string }>()
);

export const removeMilitaryTravelTypeSuccess = createAction(
    '[Military] Remove Military Travel Type Success',
    props<{routeTo?: string}>()
);

export const militarySaveTypeSuccessMessage = createAction(
    '[Military] Open Military Type Success Message Component'
);

export const militaryTypeSuccessModalClosed = createAction(
    '[Military] Military Type Success Message Closed',
    props<{routeTo?: string}>()
);

export const updatePassengerSuccess = createAction(
    '[Trip API] Update Passenger Success',
    props<{ passengerList: PassengerList, routeTo?: string, inputType?: string, isInternational?: boolean }>()
);

export const updatePassengerError = createAction(
    '[Trip API] Update Passenger Error',
    props<{ error: any, from: any, routeTo: any }>()
);

export const checkInfantStatus = createAction(
    '[Passengers Component] Check Infant Status',
    props<{ trip: Trip }>()
);

export const removeLapInfantSuccess = createAction(
    '[Trip API] Remove Lap Infant Success',
    props<{ trip: Response<Trip>, routeTo?: string }>()
);

export const removeLapInfantError = createAction(
    '[Trip API] Remove Lap Infant Error',
    props<{ error: any, from: any }>()
);

export const updatePassengerMilitaryTypeError = createAction(
    '[Trip API] Update Passenger Military Type Error',
    props<{ error: any, from: any, routeTo?: string }>()
);

export const setTripSelectedSegment = createAction(
    '[Passengers] Set Trip Selected Segment'
);

export const clearTripDataCheckinStart = createAction(
    '[Checkin Start] Clear Trip Data'
);

// Group Booking Actions
export const getGroupPassengerByName = createAction(
    '[Group Passenger Component] GET_GROUP_PASSENGER',
    props<{ tripId: string, confirmationCode: string, passengerName: PassengerName, dateOfBirth?: string}>()
);

export const updateGroupPassenger = createAction(
    '[Trip API] UPDATE_GROUP_PASSENGER',
    props<{passengerList: Response<PassengerList>}>()
);

export const groupPassengerNotFoundError = createAction(
    '[Trip API] PASSENGER_NOT_FOUND_ERROR',
    props<{ error: any, tripId: string, confirmationCode: string, passengerName: PassengerName }>()
);

export const getGroupPassengerError = createTripErrorAction(
    '[Trip API] GET_GROUP_PASSENGER_ERROR',
);

export const getByPassengerId = createAction(
    '[Group Passenger Component] GET_BY_PASSENGER_ID',
    props<{ tripSearch: TripSearch, alertMessage: any, passengerIds?: string[] }>()
);

export const getGroupBookingFlow = createTripAction(
    '[Trip API] GET_GROUP_BOOKING_FLOW',
);

export const getGroupBookingFlowSuccess = createAction(
    '[Trip API] GET_GROUP_BOOKING_FLOW_SUCCESS',
);

export const groupBookingCheckinError = createTripErrorAction(
    '[Trip API] Group Booking Checkin Error'
);

// Elite Standby Actions
export const addToEliteStandByList = createAction(
  '[Checkin Online] Add to Elite Standby List',
  props<{ selectedEliteStandByPassengers: EliteStandByPassenger[] }>()
);

export const addToEliteStandByListSuccess = createAction(
  '[Checkin Online] Add to Elite Standby List Success'
);

export const noEliteStandByListPassengers = createAction(
  '[Checkin Online] No Elite Standby List Passengers'
);

export const addToEliteStandByListError = createTripErrorAction(
  '[Checkin Online] Add to Elite Standby List Error'
);

export const addToEliteStandByListErrorModalClosed = createAction(
  '[Checkin Online] Add to Elite Standby List Error Modal Closed'
);
