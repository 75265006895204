<div class="info-container row justify-content-between">
  <div *ngIf=!hideFlightStatus id="flight-status-wrapper" class="order-md-2">
    <app-flight-status></app-flight-status>
  </div>
  <div class="col-md-6 col-lg-7 order-md-1 text-left my-auto">
    <h4 id="page-heading" class="text-left">{{heading}}</h4>
    <div id="page-description" *ngIf="!!description">
      <span *haRichText="description" class="call-out"></span>
    </div>
  </div>
</div>
