<h6 id="generic-error-title" class="bold medium" mat-dialog-title>
  <span>{{ title }}</span>
</h6>
<mat-dialog-content id="generic-error-content" class="body-copy">
    <span *haRichText="message"></span>
    <span>{{ tryAgainLater }}</span>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="generic-error-close" mat-button [mat-dialog-close]="true" class="btn btn-default">
    <span>{{ btnClose }}</span>
  </button>
</mat-dialog-actions>