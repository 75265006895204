import { createAction, props } from '@ngrx/store';
import { Response } from '../../models/response.model';
import { Flight } from '../../models/flight.model';
import { DataServiceError } from '../config';

export const createFlightAction = (actionType: string) =>
  createAction(actionType, props<{ flight: Response<Flight>, flightIdx: number }>());

export const createFlightErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: DataServiceError<Flight> }>());

export const getFlightByIdAction = (actionType: string) =>
  createAction(actionType, props<{ flightId: string, flightIdx: number }>());

export const createAllFlightsAction = (actionType: string) =>
  createAction(actionType, props<{ allFlights: Flight[], firstFlight: Response<Flight>}>());


export const getAllFLights = (actionType: string) =>
  createAction(actionType);

export const seatMapModalGetFlightById = createAction(
  '[Seatmap Component] Get Flight By Id',
  props<{ flightId: string, flightIdx: number }>()
);

export const itineraryDetailGetFlightById = createAction(
  '[Itinerary Detail Component] Get Flight By Id',
  props<{ flightId: string, flightIdx: number }>()
);

export const boardingPassGetAllFlights = getAllFLights(
  '[Boarding Pass Component] Get All Flights By Id',
);
export const passengerGetAllFlights = getAllFLights(
  '[Passenger Component] Get All Flights By Id'
);

export const seatsGetAllFlights = getAllFLights(
  '[Seats Component] Get All Flights By Id'
);

export const getFlightError = createFlightErrorAction(
  '[Flight API] Get Flight Error',
);

export const getFlightSuccess = createFlightAction(
  '[Flight API] Get Flight Success'
);

export const getFlightDelayedSuccess = createFlightAction(
  '[Flight API] Get Flight Delayed Success'
);

export const getAllFlightsSuccess = createAllFlightsAction(
  '[Flight API] Get All Flights Success'
);

export const getDelayedFlights = createAction(
  '[Flight API] Check for delayed flights',
  props<{ allFlightsAdditionalInformation: any }>()
);

export const resetFlightState = createAction(
  '[Seatmap Modal] Reset Flight State'
);
