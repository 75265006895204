import { Component, Input } from '@angular/core';
import { ContentSchema } from '@hawaiianair/core';
import { ModalsService } from '~app/modals/modals.service';

@Component({
  selector: 'app-checkin-header',
  templateUrl: './checkin-header.component.html',
  styleUrls: ['./checkin-header.component.scss'],
})
@ContentSchema({
  name: 'CheckinHeader',
  description: 'CheckinHeaderComponent'
})
export class CheckinHeaderComponent {
  @Input() heading: string;
  @Input() description: string;
  @Input() hideHeaderInfo: boolean;
  @Input() hideFlightStatus: boolean;

  constructor(
    private modalsService: ModalsService
  ) { }

  public quit() {
    this.modalsService.openQuitModal();
  }
}
