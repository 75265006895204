export class Airport {
  airportCode: string;
  displayLongName: string;
  displayShortName: string;
  region: string;
  market: string;
  destinationImgUrl?: string;
}

export const AIRPORTS: Airport[] = [
  {
    airportCode: "BNE",
    displayLongName: "Brisbane, Australia",
    displayShortName: "Brisbane",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/australia/aerial-brisbane.ashx"
  },
  {
    airportCode: "SYD",
    displayLongName: "Sydney, Australia",
    displayShortName: "Sydney",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/australia/aerial-sydney.ashx"
  },
  {
    airportCode: "PEK",
    displayLongName: "Beijing, China",
    displayShortName: "Beijing",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/china/aerial-great-wall.ashx"
  },
  {
    airportCode: "CAN",
    displayLongName: "Guangzhou, China",
    displayShortName: "Guangzhou",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SHE",
    displayLongName: "Shenyang, China",
    displayShortName: "Shenyang",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SHA",
    displayLongName: "Shanghai Hongqiao, China",
    displayShortName: "Shanghai Hongqiao",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "HGH",
    displayLongName: "Hangzhou, China",
    displayShortName: "Hangzhou",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "CTS",
    displayLongName: "Sapporo, Japan (New Chitose Airport)",
    displayShortName: "Sapporo",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/japan/sapporo.ashx"
  },
  {
    airportCode: "HND",
    displayLongName: "Tokyo Haneda, Japan",
    displayShortName: "Tokyo Haneda",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/japan/aerial-tokyo.ashx"
  },
  {
    airportCode: "NRT",
    displayLongName: "Tokyo Narita, Japan",
    displayShortName: "Tokyo Narita",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/japan/aerial-tokyo.ashx"
  },
  {
    airportCode: "ITM",
    displayLongName: "Osaka Itami, Japan",
    displayShortName: "Osaka Itami",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "KIX",
    displayLongName: "Osaka Kansai, Japan (KIX)",
    displayShortName: "Osaka Kansai",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/japan/aerial-osaka.ashx"
  },
  {
    airportCode: "HIJ",
    displayLongName: "Hiroshima, Japan",
    displayShortName: "Hiroshima",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "FUK",
    displayLongName: "Fukuoka, Japan",
    displayShortName: "Fukuoka",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "OIT",
    displayLongName: "Oita, Japan",
    displayShortName: "Oita",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "KOJ",
    displayLongName: "Kagoshima, Japan",
    displayShortName: "Kagoshima",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "OKA",
    displayLongName: "Okinawa, Japan",
    displayShortName: "Okinawa",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "KUL",
    displayLongName: "Kuala Lumpur, Malaysia",
    displayShortName: "Kuala Lumpur",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "AKL",
    displayLongName: "Auckland, New Zealand",
    displayShortName: "Auckland",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/new-zealand/aerial-auckland.ashx"
  },
  {
    airportCode: "PPG",
    displayLongName: "Pago Pago, American Samoa",
    displayShortName: "Pago Pago",
    region: null,
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/samoa/aerial-pago-pago.ashx"
  },
  {
    airportCode: "SIN",
    displayLongName: "Changi, Singapore",
    displayShortName: "Changi",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "ICN",
    displayLongName: "Seoul Incheon, South Korea",
    displayShortName: "Seoul Incheon",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/korea/aerial-seoul.ashx"
  },
  {
    airportCode: "PUS",
    displayLongName: "Busan, South Korea",
    displayShortName: "Busan",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PPT",
    displayLongName: "Papeete, Tahiti",
    displayShortName: "Papeete",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/tahiti/aerial-papeete.ashx"
  },
  {
    airportCode: "TPE",
    displayLongName: "Taipei, Taiwan",
    displayShortName: "Taipei",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "BKK",
    displayLongName: "Bangkok, Thailand",
    displayShortName: "Bangkok",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PHX",
    displayLongName: "Phoenix, Arizona",
    displayShortName: "Phoenix",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/phoenix/phx-sunset.ashx"
  },
  {
    airportCode: "LGB",
    displayLongName: "Long Beach, California",
    displayShortName: "Long Beach",
    region: "North America",
    market: "TransPacific",
    // eslint-disable-next-line max-len
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/los-angeles/aerial-los-angeles.ashx"
  },
  {
    airportCode: "LAX",
    displayLongName: "Los Angeles, California",
    displayShortName: "Los Angeles",
    region: "North America",
    market: "TransPacific",
    // eslint-disable-next-line max-len
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/los-angeles/aerial-los-angeles.ashx"
  },
  {
    airportCode: "OAK",
    displayLongName: "Oakland, California",
    displayShortName: "Oakland",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/oakland/oakland.ashx"
  },
  {
    airportCode: "SMF",
    displayLongName: "Sacramento, California",
    displayShortName: "Sacramento",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/sacramento/sacramento.ashx"
  },
  {
    airportCode: "SAN",
    displayLongName: "San Diego, California",
    displayShortName: "San Diego",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/san-diego/aerial-san-diego.ashx"
  },
  {
    airportCode: "SFO",
    displayLongName: "San Francisco, California",
    displayShortName: "San Francisco",
    region: "North America",
    market: "TransPacific",
    // eslint-disable-next-line max-len
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/san-francisco/aerial-san-francisco.ashx"
  },
  {
    airportCode: "SJC",
    displayLongName: "San Jose, California",
    displayShortName: "San Jose",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/san-jose/san-jose.ashx"
  },
  {
    airportCode: "FLL",
    displayLongName: "Fort Lauderdale, Florida",
    displayShortName: "Fort Lauderdale",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "JAX",
    displayLongName: "Jacksonville, Florida",
    displayShortName: "Jacksonville",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "MCO",
    displayLongName: "Orlando, Florida",
    displayShortName: "Orlando",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PBI",
    displayLongName: "Palm Beach, Florida",
    displayShortName: "Palm Beach",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "TPA",
    displayLongName: "Tampa Bay, Florida",
    displayShortName: "Tampa Bay",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SAV",
    displayLongName: "Savannah, Georgia",
    displayShortName: "Savannah",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "ITO",
    displayLongName: "Hilo, Hawaii",
    displayShortName: "Hilo",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/big-island/aerial-hilo.ashx"
  },
  {
    airportCode: "KOA",
    displayLongName: "Kona, Hawaii",
    displayShortName: "Kona",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/big-island/aerial-kona.ashx"
  },
  {
    airportCode: "LIH",
    displayLongName: "Lihue, Kauai",
    displayShortName: "Lihue",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/kauai/aerial-kauai.ashx"
  },
  {
    airportCode: "LNY",
    displayLongName: "Lanai City, Lanai",
    displayShortName: "Lanai City",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/lanai/lny.ashx"
  },
  {
    airportCode: "OGG",
    displayLongName: "Kahului, Maui",
    displayShortName: "Kahului",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/maui/aerial-maui.ashx"
  },
  {
    airportCode: "JHM",
    displayLongName: "Kapalua, Maui",
    displayShortName: "Kapalua",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/maui/kapalua.ashx"
  },
  {
    airportCode: "MKK",
    displayLongName: "Hoolehua, Molokai",
    displayShortName: "Hoolehua",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/molokai/aerial-molokai.ashx"
  },
  {
    airportCode: "HNL",
    displayLongName: "Honolulu, Oahu",
    displayShortName: "Honolulu",
    region: "North America",
    market: "Interisland",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/hawaii/oahu/rainbow-over-waikiki.ashx"
  },
  {
    airportCode: "ORD",
    displayLongName: "Chicago, Illinois",
    displayShortName: "Chicago",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PWM",
    displayLongName: "Portland, Maine",
    displayShortName: "Portland",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "BOS",
    displayLongName: "Boston, Massachusetts",
    displayShortName: "Boston",
    region: "North America",
    market: "TransPacific",
    // eslint-disable-next-line max-len
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/brand/airplanes/airbus-a330/a330-plane-hero.ashx"
  },
  {
    airportCode: "ACK",
    displayLongName: "Nantucket, Massachusetts",
    displayShortName: "Nantucket",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "LAS",
    displayLongName: "Las Vegas, Nevada",
    displayShortName: "Las Vegas",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/las-vegas/aerial-las-vegas.ashx"
  },
  {
    airportCode: "EWR",
    displayLongName: "Newark, New Jersey",
    displayShortName: "Newark",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "BUF",
    displayLongName: "Buffalo, New York",
    displayShortName: "Buffalo",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "JFK",
    displayLongName: "New York, New York",
    displayShortName: "New York",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/new-york/aerial-new-york.ashx"
  },
  {
    airportCode: "ROC",
    displayLongName: "Rochester, New York ",
    displayShortName: "Rochester",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SYR",
    displayLongName: "Syracuse, New York",
    displayShortName: "Syracuse",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "CLT",
    displayLongName: "Charlotte, North Carolina",
    displayShortName: "Charlotte",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "RDU",
    displayLongName: "Raleigh-Durham, North Carolina",
    displayShortName: "Raleigh-Durham",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PDX",
    displayLongName: "Portland, Oregon",
    displayShortName: "Portland",
    region: "North America",
    market: "TransPacific",
    // eslint-disable-next-line max-len
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/portland/aerial-portland-night.ashx"
  },
  {
    airportCode: "DAL",
    displayLongName: "Dallas Love Field, Texas",
    displayShortName: "Dallas Love Field",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "BTV",
    displayLongName: "Burlington, Vermont",
    displayShortName: "Burlington",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SEA",
    displayLongName: "Seattle, Washington",
    displayShortName: "Seattle",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/seattle/aerial-seattle-night.ashx"
  },
  {
    airportCode: "IAD",
    displayLongName: "Washington DC, Washington DC",
    displayShortName: "Washington DC",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "QSF",
    displayLongName: "San Francisco Bay Area",
    displayShortName: "San Francisco Bay Area",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/san-francisco/san-francisco.ashx"
  },
  {
    airportCode: "NYC",
    displayLongName: "New York and New Jersey",
    displayShortName: "New York and New Jersey",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/north-america/new-york/new_york_1.ashx"
  },
  {
    airportCode: "TYO",
    displayLongName: "Tokyo City",
    displayShortName: "Tokyo City",
    region: "Asia and South Pacific",
    market: "SouthPacific",
    destinationImgUrl: "https://img.s-hawaiianairlines.com/static/images/destinations/international/japan/tokyo.ashx"
  },
  {
    airportCode: "DEN",
    displayLongName: "Denver, Colorado",
    displayShortName: "Denver",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "ATL",
    displayLongName: "Atlanta, Georgia",
    displayShortName: "Atlanta",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "BNA",
    displayLongName: "Nashville, Tennessee",
    displayShortName: "Nashville",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PHL",
    displayLongName: "Philadelphia, Pennsylvania",
    displayShortName: "Philadelphia",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "CLE",
    displayLongName: "Cleveland, Ohio",
    displayShortName: "Cleveland",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "DCA",
    displayLongName: "Arlington, Virginia",
    displayShortName: "Arlington",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "CHS",
    displayLongName: "North Charleston, South Carolina",
    displayShortName: "North Charleston",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "DTW",
    displayLongName: "Detroit, Michigan",
    displayShortName: "Detroit",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "DFW",
    displayLongName: "Dallas, Texas",
    displayShortName: "Dallas",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "RIC",
    displayLongName: "Richmond, Virginia",
    displayShortName: "Richmond",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "MIA",
    displayLongName: "Miami, Florida",
    displayShortName: "Miami",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "SLC",
    displayLongName: "Salt Lake City, Utah",
    displayShortName: "Salt Lake City",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "ANC",
    displayLongName: "Anchorage, Alaska",
    displayShortName: "Anchorage",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
  {
    airportCode: "PAE",
    displayLongName: "Everett, Washington",
    displayShortName: "Everett",
    region: "North America",
    market: "TransPacific",
    destinationImgUrl: null
  },
];
