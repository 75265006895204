import { Component, Input } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: [ './content-block.component.scss' ]
})
@ContentSchema({
  description: 'RichText Block (limited HTML)',
  name: 'ContentBlock',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'content', type: PropertyType.RichText },
  ]
})
export class ContentBlockComponent {
  @Input() rendering: ComponentRendering;
}
