import { Component, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from './../modal-contents.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-seat-upgrade-error-modal',
    templateUrl: './seat-upgrade-error-modal.component.html',
    styleUrls: [ './seat-upgrade-error-modal.component.scss' ],
})

@ContentSchema({
    name: 'SeatUpgradeErrorModal',
    description: 'Seat Upgrade Error Modal',
    props: [
        { name: 'title', type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text }
    ]
})

export class SeatUpgradeErrorModalComponent implements OnInit {
    rendering: ComponentRendering;

    constructor(
        public dialogRef: MatDialogRef<SeatUpgradeErrorModalComponent>,
        private modalContentsSvc: ModalContentsService,
    ) { }

    ngOnInit() {
        this.rendering.props = this.modalContentsSvc.getRendering('seat-upgrade-error-modal') || [{}];
    }
}
