import { Action, createReducer, on } from '@ngrx/store';
import { Flight } from '~app/models/flight.model';
import { Response } from '~app/models/response.model';
import * as FlightActions from '~app/store/actions/flight.actions';

export interface FlightState {
    flight: Response<Flight>;
    loading: boolean;
    flightIdx: number;
    allFlights: Flight[];
    correlationId?: string;
    error: boolean;
}

export const initialState: FlightState = {
    flight: null,
    loading: false,
    flightIdx: -1,
    allFlights: null,
    correlationId: '',
    error: false,
};

export const flightReducer = createReducer(
    initialState,
    on(FlightActions.seatMapModalGetFlightById, state => ({ ...state, flight: null, loading: true, flightIdx: -1, error: false })),
    on(FlightActions.itineraryDetailGetFlightById, state => ({ ...state, flight: null, loading: true, flightIdx: -1, error: false })),
    on(FlightActions.boardingPassGetAllFlights, state => ({ ...state, flight: null, loading: true, flightIdx: -1, error: false })),
    on(FlightActions.getFlightError, state => ({ ...state, loading: false, flightIdx: -1, error: true })),
    on(FlightActions.getFlightSuccess, (state, { flight, flightIdx }) => ({
        ...state,
        loading: false,
        flight,
        flightIdx,
        correlationId: flight.correlationId,
        error: false,
    })),
    on(FlightActions.getFlightDelayedSuccess, (state, { flight, flightIdx }) => ({
        ...state,
        loading: false,
        flight,
        flightIdx,
        error: false,
    })),
    on(FlightActions.getAllFlightsSuccess, (state, { allFlights, firstFlight }) => ({
        ...state,
        flight: firstFlight,
        loading: false,
        allFlights,
        error: false,
    })),
    on(FlightActions.passengerGetAllFlights, (state => ({
        ...state,
    loading: true,
    error: false,
    }))),
    on(FlightActions.resetFlightState, (state => ({
        ...state,
    flight: null,
    loading: false,
    flightIdx: -1,
    allFlights: null,
    error: false,
    }))),
);

export function reducer(state: FlightState | undefined, action: Action) {
    return flightReducer(state, action);
}
