import { BoardingTime } from './boardingtime.model';
import { AirportDate } from './airportdate.model';

export const FLIGHT_STATUS_DELAYED = 'Delayed';

export class Status {
    id: string;
    flightId: string;
    originGate: string;
    originTerminal: string;
    destination: string;
    destinationGate: string;
    destinationTerminal: string;
    departure: AirportDate;
    arrival: AirportDate;
    flightStatus: string;
    flightSubStatus: string;
    baggageClaim: string;
    boardingStatus?: string;
    boardingStartTime?: BoardingTime;
    boardingEndTime?: BoardingTime;
}
