import { Component, OnInit } from "@angular/core";
import { ComponentRendering, ContentSchema, PropertyType } from "@hawaiianair/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { ModalContentsService } from "~app/modals/modal-contents.service";
import { AdultSearch } from "~app/models/adultsearch.model";
import { AnalyticsDispatchers, SessionSelectors, TripDispatchers, TripSelectors } from "~app/store";
@Component({
    selector: 'app-link-minor-to-adult',
    templateUrl: './link-minor-to-adult.component.html',
    styleUrls: ['./link-minor-to-adult.component.scss']
})

@ContentSchema({
    description: 'Link Minor to Adult',
    name: 'LinkMinorToAdult',
    props: [
        { name: 'heading', type: PropertyType.Text },
        { name: 'request', type: PropertyType.Text },
        { name: 'navButtons', type: PropertyType.Object }
    ]
})

export class LinkMinorToAdultComponent implements OnInit {
    rendering: ComponentRendering;
    adultSearchCardRendering: ComponentRendering;
    adultSearchInfo: AdultSearch;
    savingAdultToMinorInfo$: Observable<boolean>;
    sessionTripId$: Observable<string>;

    constructor(
        private tripSelectors: TripSelectors,
        private modalContentsService: ModalContentsService,
        private tripDispatchers: TripDispatchers,
        private sessionSelectors: SessionSelectors,
        private analyticsDispatchers: AnalyticsDispatchers,
    ) {
        this.savingAdultToMinorInfo$ = this.tripSelectors.savingAdultToMinorInfo$;
        this.sessionTripId$ = this.sessionSelectors.sessionTripId$;

    }

    ngOnInit() {
        this.adultSearchCardRendering = (this.rendering.components['adult-search-card'] || [{}])[0];

        this.setErrorModalContents();
        this.analyticsDispatchers.minorPageLoaded();
    }

    private setErrorModalContents(): void {
        this.modalContentsService.addRendering('minor-checkin-error-modal', this.rendering.components['minor-checkin-error-modal']);
    }

    submitAdultInformation() {
        let tripId: string;
        this.sessionTripId$.pipe(take(1)).subscribe(id => tripId = id);
        this.tripDispatchers.addMinorToAdult(tripId, this.adultSearchInfo);
    }
}
