<h6 id="generic-error-title" class="bold medium" mat-dialog-title *haText="rendering?.props['header']"></h6>
<mat-dialog-content id="generic-error-content">
  <div class="body-copy" *haText="rendering?.props['description']"></div>
  <form [formGroup]="dobForm" class="container py-4 px-md-3 px-0">
    <div class="text-left foot-note" *haText="rendering?.props['dob']"></div>
    <ha-date class="dob-date" [content]="dateContent" [control]="dobForm.get('dateOfBirth')"></ha-date>
  </form>
</mat-dialog-content>
<mat-dialog-actions id="group-dob-close" align="center">
  <button mat-button mat-dialog-close class="btn btn-secondary" id="group-dob-modal-cancel-button">{{ 'Cancel' | translate }}</button>
  <button mat-button (click)='onSubmit()'[mat-dialog-close]="dobForm.value" class="btn btn-default" id="group-dob-modal-ok-button" [disabled]="dobForm.invalid">{{ 'Ok' | translate }}</button>
</mat-dialog-actions>
