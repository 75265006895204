import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { ClassicWebLoginComponent } from '~app/shared/classic-web-login/classic-web-login.component';
import { WebFooterComponent } from '~app/shared/web-footer/web-footer.component';
import { LanguageButtonsComponent } from './language-buttons/language-buttons.component';
import { FindTripSearchComponent } from './find-trip-search/find-trip-search.component';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { CheckinStartComponent } from './checkin-start/checkin-start.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        CoreModule.forChild({
            components: [
                CheckinStartComponent,
            FindTripSearchComponent,
            LanguageButtonsComponent,
            ClassicWebLoginComponent,
            WebFooterComponent
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        ClassicWebLoginComponent,
        WebFooterComponent,
        RouterModule
    ],
    declarations: [
        CheckinStartComponent,
        FindTripSearchComponent,
        LanguageButtonsComponent,
        ClassicWebLoginComponent,
        WebFooterComponent
    ],
})
export class CheckinModule { }