import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CurrentLocaleService } from '@hawaiianair/core';

@Pipe({
  name: 'customCurrency'
})

export class CustomCurrencyPipe implements PipeTransform {
  private display = 'symbol';

  constructor(
    private localeService: CurrentLocaleService,
    private currencyPipe: CurrencyPipe
  ) {
  }
  transform(value: any): string {
    let currency = this.localeService.currency;
    if (this.localeService.language.toLocaleLowerCase() === "zh") {
      currency = 'USD';
    }
    if (value !== null) {
      return this.currencyPipe.transform(value, currency, this.display);
    }
    return this.currencyPipe.transform(0, currency, this.display).split('0.00')[0];
  }
}


