import { trigger, state, style, transition, animate, query, animateChild, stagger } from '@angular/animations';

export const routingAnimation = [
  trigger('routingTrigger', [
    state('in', style({ opacity: 1})),
    state('out', style({ opacity: 0})),
    transition('* => in', [
      style({ opacity: 0}),
      animate('0.5s', style({
        opacity: '1'
      })),
      query('@airplaneTrigger, @fadeTrigger, @slideTrigger', [
        animateChild()
      ], { optional: true }),
    ]),
    transition('* => out', [
      animate('0.5s', style({
        opacity: '0'
      })),
      query('@airplaneTrigger, @fadeTrigger, @slideTrigger', [
        animateChild()
      ], { optional: true }),
    ]),
  ]),
];

export const airplaneAnimation = [
  trigger('airplaneTrigger', [
    state('stepOne', style({ left: '0%' })),
    state('stepTwo', style({ left: '20%' })),
    state('stepThree', style({ left: '40%' })),
    state('stepFour', style({ left: '60%' })),
    state('stepFive', style({ left: '80%' })),
    transition('* => stepOne', animate('0.5s ease-in-out')),
    transition('* => stepTwo', animate('0.5s ease-in-out')),
    transition('* => stepThree', animate('0.5s ease-in-out')),
    transition('* => stepFour', animate('0.5s ease-in-out')),
    transition('* => stepFive', animate('0.5s ease-in-out')),
  ]),
];

export const fadeAnimation = [
  trigger('fadeTrigger', [
    transition(':leave', [
      query(':leave', [
        style({ opacity: 1 }),
        stagger('30ms', [
          animate('100ms ease-in', style({ 
            opacity: 0,
          }))
        ])
      ], {optional: true })
    ]),
    transition(':enter', [
      query(':enter', [
        style({ opacity: 0, transform: 'translateY(50px)' }),
        stagger('60ms', [
          animate('400ms ease-out', style({
            opacity: 1,
            transform: 'translateY(0px)'
          }))
        ])
      ], { optional: true })
    ]),
  ])
];

export const slideAnimation = [
  trigger('slideTrigger', [
    transition(':leave', [
      style({transform: 'translateX(0)'}),
      animate('0.5s ease-in', style({
        transform: 'translateX(-100%)'
      })),
    ]),
    transition(':enter', [
      style({transform: 'translateX(-100%)'}),
      animate('0.5s ease-out', style({
        transform: 'translateX(0)'
      })),
    ]),
  ])
];
