import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, Subscription } from 'rxjs';
import { SessionSelectors } from './session/session.selectors';
import { TripSearch } from '~app/models/trip-search.model';
import { take } from 'rxjs/operators';

// polyfill function for Object.entries() - IE compatibility
const entriesPolyFill = (obj: object) => Object.keys(obj).map(key => [key, obj[key]]);

@Injectable({
  providedIn: 'root'
})

// Base Service
export abstract class BaseService {
  protected http: HttpClient;
  protected subscription: Subscription;
  sessionConfirmationCode$: Observable<string>;

  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors
  ) {
    this.http = http;
    this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
  }

  protected handleError<T>(requestData?: T, alertMessage?: object) {
    return (res: HttpErrorResponse) => {
      if (res.status === 500) {
        throwError('Something bad happened; please try again later.');
        return throwError(res.error);
      }

      if (res.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.log('An error occurred:', res.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(
          `Backend returned code ${res.status}, ` +
          `body was: ${res.error}`);
      }
      // return an observable with a user-facing error message
      throwError('Something bad happened; please try again later.');
      return throwError(res.error);
    };
  }

  protected getAuthCredentials(sessionConfirmationCode$: Observable<string>): string {
    let authenticationString: string;
    sessionConfirmationCode$.pipe(take(1)).subscribe(code => {
      if (code) {
        authenticationString = `?confirmationCode=${code}`;
      }
    });
    return authenticationString;
  }

  protected getSearchCredentials(tripSearch: TripSearch): string {
    const tripSearchEntries = (Object.entries ? Object.entries(tripSearch) : entriesPolyFill(tripSearch));
    let authenticationString: string;
    if (!!tripSearchEntries) {
      authenticationString = `?`;
      tripSearchEntries.forEach(([key, value], index) => {
        authenticationString += `${key}=${value.toUpperCase()}`;
        if (index < tripSearchEntries.length - 1) {
          authenticationString += `&`;
        }
      });
    }
    return authenticationString;
  }
}
