import { Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { RegexPatterns } from '~app/constants/regex-constants';

export class CustomValidators extends Validators {
  static noMatch(options: string[]): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (!options || options.length < 1) {
        return null;
      }
      const hasMatch = options.some((option) => {
        return option === c.value;
      });

      if (!hasMatch) {
        return { noMatch: true };
      }
      return null;
    };
  }

  static characterCountError(codeRegex: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      const userInput = new RegExp(codeRegex);
      const testRegEx = userInput.test(control.value);
      return testRegEx ? null : { characterCountError: Object.keys(RegexPatterns).find(key => RegexPatterns[key] === codeRegex) };
    };
  }
}
