<mat-accordion class="d-sm-block d-md-none">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'80px'" class="pl-0 military-card-mat-header">
      <mat-panel-title>
        <div class="container" *ngIf="!!paxDetail && !!militaryTravelTypeFormGroup">
          <div class="row">
            <div class="col-md-7">
              <span id="military-passenger-name-{{paxIndex}}" class="subtitle-bold">
                {{ paxDetail?.passengerName.firstName | titlecase }} {{ paxDetail?.passengerName.lastName | titlecase }}
              </span>
            </div>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="col-md-5" [formGroup]="militaryTravelTypeFormGroup" *ngIf="militaryTravelTypeFormGroup">
      <mat-radio-group formControlName="militaryTravelType" class="military-radio-group">
        <div class="row">
          <div class="mr-auto full-width">
            <mat-radio-button #button1 id="military-orders-radio-mobile-{{paxIndex}}" (click)='clickRadio($event, button1)'
              class="row body-copy mat-radio-button-margin mat-radio-button-official-orders-mobile orders-button"
              [value]="militaryTypeOrders" disableRipple>
              {{rendering.props['ordersOption']}}
            </mat-radio-button>
            <hr>
          </div>
          <div class="pr-5 leisure-button-margin">
            <mat-radio-button #button2 id="military-leisure-radio-mobile-{{paxIndex}}" (click)='clickRadio($event, button2)'
              class="row body-copy mat-radio-button-margin leisure-button"
              [value]="militaryTypeLeisure" disableRipple>
              {{rendering.props['leisureOption']}}
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<mat-card class="mx-0 d-none d-md-block">
  <div class="container" *ngIf="!!paxDetail && !!militaryTravelTypeFormGroup">
    <div class="row">
      <div class="col-md-7 align-self-center">
        <span id="military-passenger-name-{{paxIndex}}" class="subtitle-bold">
          {{ paxDetail?.passengerName.firstName | titlecase }} {{ paxDetail?.passengerName.lastName | titlecase }}
        </span>
      </div>
      <div class="col-md-5" [formGroup]="militaryTravelTypeFormGroup">
        <mat-radio-group formControlName="militaryTravelType" class="military-radio-group">
          <div class="row">
            <div class="mr-auto">
              <mat-radio-button #button1 id="military-orders-radio-{{paxIndex}}" (click)='clickRadio($event, button1)'
                class="row body-copy" [value]="militaryTypeOrders" disableRipple>
                {{rendering.props['ordersOption']}}
              </mat-radio-button>
            </div>
            <div class="mr-5">
              <mat-radio-button #button2 id="military-leisure-radio-{{paxIndex}}" (click)='clickRadio($event, button2)'
                class="row body-copy" [value]="militaryTypeLeisure" disableRipple>
                {{rendering.props['leisureOption']}}
              </mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</mat-card>
