import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { CoreModule } from "@hawaiianair/core";
import { OffersErrorModalComponent } from "~app/modals/offers-error-modal/offers-error-modal.component";
import { AppSharedModule } from "~app/shared/app.shared.module";
import { AddGuestErrorModalComponent } from "./add-guest-error-modal/add-guest-error-modal.component";
import { AuthTokenExpirationModalComponent } from "./auth-token-expiration-modal/auth-token-expiration-modal.component";
import { BagsPriceConfirmationModalComponent } from "./bags-price-confirmation-modal/bags-price-confirmation-modal.component";
import { BoardingPassNextStepsModalComponent } from "./boarding-pass-next-steps-modal/boarding-pass-next-steps-modal.component";
import { ConcurrencyModalComponent } from "./concurrency-modal/concurrency-modal.component";
import { FlightDelayedModalComponent } from "./flight-delayed-modal/flight-delayed-modal.component";
import { GenericErrorComponent } from "./generic-error/generic-error.component";
import { GroupDOBModalComponent } from "./group-dob-modal/group-dob-modal.component";
import { InfantAgedOutModalComponent } from "./infant-aged-out-modal/infant-aged-out-modal.component";
import { InfantExitSeatUnavailableModalComponent } from "./infant-exit-seat-unavailable-modal/infant-exit-seat-unavailable-modal.component";
import { InfantInternationalAlertModalComponent } from "./infant-international-alert-modal/infant-international-alert-modal/infant-international-alert-modal.component";
import { LinkMinorModalComponent } from "./link-minor-modal/link-minor-modal.component";
import { MilitaryReferOutModalComponent } from "./military-refer-out-modal/military-refer-out-modal.component";
import { MilitarySaveTypeComponent } from "./military-save-type/military-save-type.component";
import { MilitaryTypeEmptyModalComponent } from "./military-type-empty-modal/military-type-empty-modal.component";
import { MilitaryTypeSuccessModalComponent } from "./military-type-success-modal/military-type-success-modal.component";
import { MinorCheckinErrorModalComponent } from "./minor-checkin-error-modal/minor-checkin-error-modal.component";
import { MinorTravelingAloneModalComponent } from "./minor-traveling-alone-modal/minor-traveling-alone-modal.component";
import { PartialCartsModalComponent } from "./partial-carts-modal/partial-carts-modal.component";
import { PassengerFaqComponent } from "./passenger-faq/passenger-faq.component";
import { PassengerUpdateErrorModalComponent } from "./passenger-update-error-modal/passenger-update-error-modal.component";
import { PassportMatchErrorComponent } from "./passport-match-error/passport-match-error.component";
import { RedressKnownTravelerInfoComponent } from "./redress-known-traveler/redress-known-traveler.component";
import { RemoveInfantConfirmationComponent } from "./remove-infant-confirmation-modal/remove-infant-confirmation-modal.component";
import { RemoveInfantComponent } from "./remove-infant/remove-infant.component";
import { SeatUnavailableModalComponent } from "./seat-unavailable-modal/seat-unavailable-modal.component";
import { SeatUpgradeErrorModalComponent } from "./seat-upgrade-error-modal/seat-upgrade-error-modal.component";
import { TargetMessageModalComponent } from "./target-message-modal/target-message-modal.component";
import { HaDateModule } from '@hawaiianair/date';

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        MatDialogModule,
        AppSharedModule,
        HaDateModule,
    ],
    declarations: [
        GenericErrorComponent,
        ConcurrencyModalComponent,
        SeatUnavailableModalComponent,
        SeatUpgradeErrorModalComponent,
        FlightDelayedModalComponent,
        PartialCartsModalComponent,
        MilitaryTypeSuccessModalComponent,
        MilitarySaveTypeComponent,
        MilitaryTypeEmptyModalComponent,
        PassengerUpdateErrorModalComponent,
        AuthTokenExpirationModalComponent,
        OffersErrorModalComponent,
        BagsPriceConfirmationModalComponent,
        RedressKnownTravelerInfoComponent,
        RemoveInfantComponent,
        BoardingPassNextStepsModalComponent,
        InfantInternationalAlertModalComponent,
        PassportMatchErrorComponent,
        PassengerFaqComponent,
        AddGuestErrorModalComponent,
        GroupDOBModalComponent,
        InfantAgedOutModalComponent,
        InfantExitSeatUnavailableModalComponent,
        RemoveInfantConfirmationComponent,
        TargetMessageModalComponent,
        LinkMinorModalComponent,
        MinorCheckinErrorModalComponent,
        MinorTravelingAloneModalComponent,
        MilitaryReferOutModalComponent
    ],
    exports: [
        GenericErrorComponent,
        ConcurrencyModalComponent,
        SeatUnavailableModalComponent,
        SeatUpgradeErrorModalComponent,
        FlightDelayedModalComponent,
        PartialCartsModalComponent,
        MilitaryTypeSuccessModalComponent,
        MilitarySaveTypeComponent,
        MilitaryTypeEmptyModalComponent,
        PassengerUpdateErrorModalComponent,
        AuthTokenExpirationModalComponent,
        OffersErrorModalComponent,
        BagsPriceConfirmationModalComponent,
        RedressKnownTravelerInfoComponent,
        RemoveInfantComponent,
        BoardingPassNextStepsModalComponent,
        InfantInternationalAlertModalComponent,
        PassportMatchErrorComponent,
        PassengerFaqComponent,
        AddGuestErrorModalComponent,
        GroupDOBModalComponent,
        InfantAgedOutModalComponent,
        InfantExitSeatUnavailableModalComponent,
        RemoveInfantConfirmationComponent,
        TargetMessageModalComponent,
        LinkMinorModalComponent,
        MinorCheckinErrorModalComponent,
        MinorTravelingAloneModalComponent,
        MilitaryReferOutModalComponent
    ]
})

export class ModalsModule {}
