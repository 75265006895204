import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { AppContextService } from '@hawaiianair/core';
import { CheckInAnalyticsService } from './services/logging/checkin-analytics.service';
import { Observable } from 'rxjs';
import { HeaderContentService } from './services/header-content.service';
import { Subscription } from 'rxjs';
import { RouterSelectors, AuthTokenSelectors, TripSelectors, SessionSelectors } from './store';
import { ReferenceDataSelectors } from './store/services/reference-data/reference-data.selectors';
import { ISessionState } from './store/reducers/session/session.reducers';
import { Constants } from './constants/ha-constants';
import { isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from '@hawaiianair/analytics';
import { TaggingService } from '@hawaiianair/tagging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  isCheckInView$: Observable<boolean>;
  tripLoading$: Observable<boolean>;
  authLoading$: Observable<boolean>;
  sessionState$: Observable<ISessionState>;
  isCheckInView: boolean;
  tripLoading: boolean;
  authLoading: boolean;
  referenceDataLoading$: Observable<boolean>;
  referenceDataLoading: boolean;
  isCheckinFlow = false;
  destinationCode: string;
  resetHeader$: Observable<boolean>;

  constructor(
    public appContext: AppContextService,
    private checkInAnalyticsService: CheckInAnalyticsService,
    private routerSelectors: RouterSelectors,
    private headerContentService: HeaderContentService,
    private authTokenSelector: AuthTokenSelectors,
    private tripSelector: TripSelectors,
    private renderer: Renderer2,
    private referenceDataSelectors: ReferenceDataSelectors,
    private sessionSelectors: SessionSelectors,
    private analyticsService: AnalyticsService,
    private taggingService: TaggingService,
    @Inject(PLATFORM_ID) private platformId: any
    ) {
    this.isCheckInView$ = this.routerSelectors.isCheckInView$;
    this.tripLoading$ = this.tripSelector.tripLoading$;
    this.authLoading$ = this.authTokenSelector.authTokenLoading$;
    this.sessionState$ = this.sessionSelectors.session$;
    this.referenceDataLoading$ = this.referenceDataSelectors.referenceDataLoading$;
    this.resetHeader$ = this.sessionSelectors.resetHeader$;
    this.checkInAnalyticsService.applicationInit();
    // IMPORTANT: do not remove the following app configuration lines
    this.appContext.init();
  }

  ngOnInit() {
    this.analyticsService.start();
    this.tripLoading$.subscribe(tripLoading => {
      this.tripLoading = tripLoading;
      this.updateIsCheckinFlow();
    });
    this.authLoading$.subscribe(authLoading => {
      this.authLoading = authLoading;
      this.updateIsCheckinFlow();
    });
    this.isCheckInView$.subscribe(isCheckInView => {
      this.isCheckInView = isCheckInView;
      this.updateIsCheckinFlow();
    });
    this.referenceDataLoading$.subscribe(referenceDataLoading => {
      this.referenceDataLoading = referenceDataLoading;
      this.updateIsCheckinFlow();
    });

    this.subscription = new Subscription();
    this.resetHeader$.subscribe(reset => {
      if (reset && isPlatformBrowser(this.platformId)) {
        const header =  this.renderer.selectRootElement("#main-top-header");
        this.renderer.setProperty(header, "innerHTML", Constants.mainHeaderText);
        this.renderer.removeStyle(header.parentNode, "background-image");
      }
    });

    const headerContent  = this.headerContentService.headerContentSub;
    if (!!headerContent && isPlatformBrowser(this.platformId)) {
      this.subscription.add(
        headerContent.subscribe((content) => {
          const header =  this.renderer.selectRootElement("#main-top-header");
          this.renderer.setProperty(header, "innerHTML", content.headerText);
          this.renderer.setStyle(header.parentNode, "background-image", `${content.destinationImgUrl}`);
        }
      ));
    }
    this.taggingService.load();
  }

  ngOnDestroy() {
    if (!!this.subscription) {
      this.subscription.unsubscribe();
    }
    // IMPORTANT: do not remove the following line to tear down the app context
    this.appContext.destroy();
    this.analyticsService.stop();
  }

  updateIsCheckinFlow() {
    this.isCheckinFlow = this.isCheckInView || this.authLoading || this.referenceDataLoading || this.tripLoading;
  }
}
