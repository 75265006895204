import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { PassengerCardComponent } from '~app/shared/passenger-card/passenger-card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { PassengersComponent } from './passengers.component';


@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatBadgeModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule.forChild({
            components: [
                PassengersComponent,
                PassengerCardComponent,
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatBadgeModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        RouterModule
    ],
    declarations: [
        PassengersComponent,
        PassengerCardComponent,
    ],
})
export class PassengerModule { }
