import { createAction, props } from '@ngrx/store';
import { ContactInfo } from '~app/models/contactinfo.model';
import { Response } from '~app/models/response.model';
import { PassengerSecurityInfo } from '~app/models/passengersecurityinfo.model';
import { SecurityInfoResponse } from '~app/models/security-info-model';
import { AddressInfo } from '~app/models/addressinfo.model';

export enum SECURITY_INFO_ACTIONS {
    PostSecurityInfo = 'Post Security Info',
    PostSecurityInfoSuccess = 'Post Security Info Success',
    PostSecurityInfoError = 'Security Info Error',
    GetTripPassengers = 'Get Trip Passengers',
    GetTripPassengersSuccess = 'Get Trip Passengers Success',
    GetTripPassengersError = 'Get Trip Passengers Error'
}

export const postSecurityInfo = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.PostSecurityInfo}`);

export const postSecurityInfoSuccess = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.PostSecurityInfoSuccess}`,
        props<{ securityInfoResponse: Response<SecurityInfoResponse> }>()
    );

export const postSecurityInfoError = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.PostSecurityInfoError}`, props<{ error: any }>());

export const getTripPassengers = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.GetTripPassengers}`);

export const getTripPassengersSuccess = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.GetTripPassengersSuccess}`,
      props<{ passengerInfo: PassengerSecurityInfo[], correlationId: string }>());

export const getTripPassengersError = (actionType: string) =>
    createAction(`${actionType} ${SECURITY_INFO_ACTIONS.GetTripPassengersError}`, props<{error: any}>());

export const addTravelerInformation = (actionType: string) =>
    createAction(actionType, props<{ travelerId: string }>());

// Passengers Page
export const passengersGetTripPassengers = getTripPassengers(
    '[Passengers Component]'
);

export const passengersGetTripPassengersSuccess = getTripPassengersSuccess(
    '[Passengers Component]'
);

export const passengersGetTripPassengersError = getTripPassengersError(
    '[Passengers Component]'
);

// International Traveler Selection
export const travelerSelectionAddInfo = addTravelerInformation(
    '[Intl Traveler Selection Component] Add Traveler Information'
);

// International Traveler Information
export const addResidentInfo = createAction(
    '[International Traveler Information Component] Add Resident Address Info',
    props<{ addressInfo: AddressInfo }>()
);

export const addDestinationInfo = createAction(
    '[Intl Travel Info Component] Add Destination Info',
    props<{ addressInfo: AddressInfo }>()
);

export const addAddressInfoSuccess = createAction(
    '[Intl Traveler Info Component] Add Address Info Success',
    props<{ addressInfo: AddressInfo[] }>()
);

export const addEmergencyContactInfo = createAction(
    '[Emergency Contact Info Component] Add Emergency Contact Info',
    props<{ contactInfo: ContactInfo }>()
);

export const travelerInfoPostSecurity = postSecurityInfo(
    '[Intl Traveler Info Component]'
);

export const travelerInfoPostSecuritySuccess = postSecurityInfoSuccess(
    '[Intl Traveler Info Component]'
);

export const travelerInfoPostSecurityError = postSecurityInfoError(
    '[Intl Traveler Info Component]'
);

export const travelerInfoGetPassenger = getTripPassengers(
    '[Intl Traveler Info Component]'
);

export const travelerInfoGetPassengerSuccess = getTripPassengersSuccess(
    '[Intl Traveler Info Component]'
);

export const travelerInfoGetPassengerError = getTripPassengersError(
    '[Intl Traveler Info Component]'
);

// Guidelines Page
export const guidelinesPostSecurityInfo = postSecurityInfo(
    '[Guidelines Component]'
);

export const guidelinesPostSecurityInfoSuccess = postSecurityInfoSuccess(
    '[Guidelines Component]'
);

export const guidelinesPostSecurityInfoError = postSecurityInfoError(
    '[Guidelines Component]'
);

export const guidelinesGetTripPassengers = getTripPassengers(
    '[Guidelines Component]'
);

export const guidelinesGetTripPassengersSuccess = getTripPassengersSuccess(
    '[Guidelines Component]'
);

export const guidelinesGetTripPassengersError = getTripPassengersError(
    '[Guidelines Component]'
);

// Dashboard Page for Recheckin
export const dashboardPostSecurityInfo = postSecurityInfo(
    '[Dashboard]'
);

export const dashboardPostSecurityInfoSuccess = postSecurityInfoSuccess(
    '[Dashboard]'
);

export const dashboardPostSecurityInfoError = postSecurityInfoError(
    '[Dashboard]'
);

export const dashboardGetTripPassengers = getTripPassengers(
    '[Dashboard]'
);

export const dashboardGetTripPassengersSuccess = getTripPassengersSuccess(
    '[Dashboard]'
);

export const dashboardGetTripPassengersError = getTripPassengersError(
    '[Dashboard]'
);
