import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TripDataService } from '~app/store/services/trip/trip-data.service';
import * as TripActions from '../../actions/trip.actions';
import * as PassportScanActions from '../../actions/passport-scan.actions';
import * as CatalogActions from '../../actions/catalog.actions';
import * as SessionActions from '../../actions/session.actions';
import * as fromSession from '../../services/session/session.selectors';
import * as fromRouter from '../../services/router/router.selectors';
import * as fromFlight from '../../services/flight/flight.selectors';
import * as CartActions from '~app/store/actions/cart.actions';
import * as AirportActions from '~app/store/actions/airport.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import * as fromTrip from '~app/store/services/trip/trip.selectors';
import * as ReferenceDataActions from '~app/store/actions/reference-data.actions';
import * as CountriesActions from '~app/store/actions/countries.actions';
import { Action, select, Store } from '@ngrx/store';
import { SessionState } from '../../reducers';
import { LogService } from '~app/services/log-service';
import { actionPrecedenceList, CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { AlertReasonCodeService } from '~app/services/alert-reason-code.service';
import {
    ALERTS,
    Constants,
    linkMinorErrorInfo,
    MilitaryTravelType,
    standByPriorityCodes,
} from '~app/constants/ha-constants';
import { localeStationCodesMap } from '~app/constants/international-constants';
import { CurrentLocaleService } from '@hawaiianair/core';
import { ModalsService } from '~app/modals/modals.service';
import { HelperService } from '~app/services/helper.service';
import { PassengerList } from '~app/models/passengerlist.model';
import { LaunchDarklyClientService } from '~app/services/launch-darkly-client.service';
import { CheckInPayload } from '~app/models/checkinpayload.model';

@Injectable()
export class TripEffects {
    constructor(
        private actions$: Actions,
        private tripDataService: TripDataService,
        private logService: LogService,
        private store: Store<SessionState>,
        private modalsService: ModalsService,
        private alertReasonCodeService: AlertReasonCodeService,
        private localeService: CurrentLocaleService,
        private helperService: HelperService,
        private ldClientService: LaunchDarklyClientService,
    ) { }

    getByPnr$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.getByPnr, TripActions.resultGetTrip, TripActions.getByPassengerId),
            withLatestFrom(
                this.store.pipe(select(fromSession.getSessionState)),
                this.store.pipe(select(fromRouter.getSortedActions)),
                this.store.pipe(select(fromRouter.getRouterParams)),
                this.store.pipe(select(fromTrip.isGroupBooking)),
                this.store.pipe(select(fromSession.getSessionPassengerIds)),
            ),
            switchMap(([action, state, sortedActions, routerURL, isGroupBooking, passengerIds]) => {
                const paxIds = isGroupBooking && !action.passengerIds ? passengerIds : action.passengerIds;
                return this.tripDataService.getByPnr(action.tripSearch, action.type, action.alertMessage, paxIds).pipe(
                    tap(trip => {
                        if (!trip.results[0]) {
                            this.logService.error("Trip has no data");
                            throw { error: 'no data', requestData: trip };
                        }

                        // if group booking is disabled
                        if (trip?.results[0]?.isGroupBooking && !this.ldClientService.getFlag('web-check-in-spa-group-booking-enabled')) {
                            throw { error: 'CI10', requestData: trip };
                        }

                        // check if given alertMessage in tripDataService getByPnr
                        if (action.alertMessage) {
                            this.logService.error(action.alertMessage);
                            throw { error: action.alertMessage, requestData: trip };
                        }

                        const tripToCheckAlerts = trip;
                        // filter trip response if getting trip for result page to check alertReasonCodes
                        if (action.type === TripActions.resultGetTrip.type) {
                            // filter out unselected paxes
                            tripToCheckAlerts.results[0].segments.entries.forEach(entry => {
                                entry.details = entry.details.filter(detail => state.passengerIds.includes(detail.passengerId));
                            });

                            // filter out unselected segments
                            tripToCheckAlerts.results[0].segments.entries =
                                trip.results[0].segments.entries.filter((entry) => this.helperService.hasSameFlights(entry, state));
                        }

                        // checks for alert reason codes in trip
                        if (trip.results[0].segments.entries.length === 1) {
                            const isInternational = trip.results[0].segments.entries[0].marketType === Constants.marketTypeInternational;
                            const isCheckedIn = trip.results[0].segments.entries[0].areAllPassengersCheckedIn;
                            const alert = this.alertReasonCodeService.checkAllAlert(tripToCheckAlerts);
                            if ((!isInternational || isCheckedIn || (isInternational && alert?.error !== ALERTS.regulatoryDetailsInhibition)) && !!alert) {
                                throw alert;
                            }
                        }
                        else {
                            const isInternational = trip.results[0].segments.entries.some(entry => entry.marketType === Constants.marketTypeInternational);
                            if (!isInternational) {
                                tripToCheckAlerts.results[0].segments.entries = tripToCheckAlerts.results[0].segments.entries?.filter((entry) => entry.canAllPassengersCheckIn);
                            }

                            const alert = this.alertReasonCodeService.checkAllAlert(tripToCheckAlerts);
                            if (!!alert) {
                                throw alert;
                            }
                            else if (tripToCheckAlerts.results[0].segments.entries.length === 0) {
                                throw { error: 'no data', requestData: trip };
                            }
                        }
                    }),
                    concatMap(trip => {
                        if (!!trip.results[0].isGroupBooking &&
                            action.type !== TripActions.getByPassengerId.type
                            && routerURL.serverRoute === '/') {
                            return [TripActions.getGroupBookingFlow({ trip: trip })];
                        }
                        else if (action.type === TripActions.resultGetTrip.type) {
                            const segment = !!trip && !!trip.results.length
                                && trip.results[0].segments.entries.find(entry => this.helperService.hasSameFlights(entry, state));
                            const actions: Action[] = [
                                TripActions.getByPnrSuccess({ trip }),
                                SessionActions.setSessionResultData({ name: 'resultData', value: trip }),
                                SessionActions.updateSessionSelectedSegment({ segment })
                            ];
                            if (!!sortedActions && sortedActions[0] && sortedActions[0].route === actionPrecedenceList.BoardingPass.route) {
                                actions.unshift(RouterActions.routeToBoardingPass({ componentName: "resultGetTrip" }));
                            }
                            return actions;
                        } else {
                            const actions: Action[] = [
                                TripActions.getByPnrSuccess({ trip }),
                                SessionActions.setSessionTripParams({
                                    tripSearch: action.tripSearch,
                                    confirmationCode: trip.results[0].confirmationCode
                                }),
                                SessionActions.setSessionTrip2Checkin({ name: 'trip2Checkin', value: trip.results[0] }),
                                AirportActions.getAirports(),
                                ReferenceDataActions.getStationCode({ cityCode: localeStationCodesMap[this.localeService.locale] }),
                                CountriesActions.getCountries(),
                                SessionActions.setSessionResultData({ name: 'resultData', value: trip })
                            ];
                            if (trip.results[0].segments.entries.some(s => s.marketType === Constants.marketTypeInternational)) {
                                actions.push(PassportScanActions.getPassportScanAttempts());
                            }
                            return actions;
                        }

                    }),
                    catchError(error => {
                        if (error.error === Constants.unaccompaniedMinorCodeSSR) {
                            this.modalsService.openMinorTravelingAloneModal();
                        } else if (error.error === ALERTS.unaccompaniedMinorReasonCode) {
                            this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToAgentForUnaccompaniedMinor });
                        }
                        return of(TripActions.getByPnrError(error));
                    })
                );
            })
        )
    );

    setSessionPaxIdIfSingle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SessionActions.setSessionSelectedSegmentSuccess),
            switchMap(action => of(action).pipe(
                withLatestFrom(
                    this.store.pipe(select(fromTrip.getTripPassengersList)),
                    this.store.pipe(select(fromTrip.isSinglePax)),
                ),
            ).pipe(
                map(([_, passengerList, isSinglePax]) => {
                    const passenger = passengerList.entries[0].id;
                    if (isSinglePax) {
                        return SessionActions.setSessionPassengerIds({ name: 'passengerIds', value: [passenger] });
                    } else {
                        return SessionActions.createNoopAction('[Add Passengers IDs] Noop Action')();
                    }
                }),
                catchError(error => of(error))
            ))
        )
    );

    getByPnrError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.getByPnrError),
            tap(error => {
                // set default error message for errors if not defined
                if (!error.contentProps) {
                    error = { ...error, contentProps: 'messageGetByPnrError' };
                }
                this.modalsService.openGenericErrorMessage(error);
            })
        ), { dispatch: false }
    );

    checkIn$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.checkIn, TripActions.guidelinesCheckin, TripActions.paymentCheckin),
            withLatestFrom(
                this.store.pipe(select(fromSession.getSessionState)),
                this.store.pipe(select(fromTrip.getTrip)),
                this.store.pipe(select(fromTrip.isGroupBooking)),
                this.store.pipe(select(fromTrip.getGroupPassengerList))),
            switchMap(([action, state, tripData, isGroupBooking, groupPassengerList]) => {
                const passengerIds = (action.type === TripActions.paymentCheckin.type) ?
                    action.passengerIds : state && state.passengerIds && state.passengerIds.filter(id => {
                        const segmentDetails = state.selectedSegment && state.selectedSegment.details.find(detail => detail.passengerId === id);
                        return segmentDetails.flightDetails.some(flight => flight.isCheckedIn === false);
                    });
                if (!passengerIds || !passengerIds.length) {
                    return [TripActions.checkinSuccessAlreadyCheckedIn({ trip: tripData }),
                    SessionActions.setSessionCheckinComplete({ name: 'checkinComplete', value: true }),
                    SessionActions.setSessionResultData({ name: 'resultData', value: tripData })];
                }

                const checkInPayload: CheckInPayload = {
                    checkinDetails: passengerIds.map(paxId => ({
                        passengerId: paxId,
                        passengerSegmentIds: state?.selectedSegment?.details?.find(segDetail => paxId === segDetail.passengerId)?.flightDetails.flatMap(flight => flight.passengerSegmentId)
                    })),
                    origin: state?.selectedSegment?.origin,
                    destination: state?.selectedSegment?.destination
                };

                const lapInfantAssociationId = groupPassengerList?.entries.filter(entry => entry.hasInfant).map(entry => entry.lapInfant.passengerAssociationId);

                const paxIds = isGroupBooking ? [...passengerIds, ...lapInfantAssociationId] : null;

                return this.tripDataService.checkIn(state.tripId, checkInPayload, paxIds).pipe(
                    tap(trip => {
                        const tripToCheckAlerts = JSON.parse(JSON.stringify(trip));
                        if (!trip.results[0]) {
                            this.logService.error('Trip has no data to checkin');
                            throw { error: 'no data', requestData: trip };
                        }

                        // filter out unselected segments
                        tripToCheckAlerts.results[0].segments.entries = tripToCheckAlerts.results[0].segments.entries.filter((entry) => this.helperService.hasSameFlights(entry, state));
                        tripToCheckAlerts.results[0].segments.entries.forEach(entry => {
                            entry.details = entry.details.filter(detail => state.passengerIds.includes(detail.passengerId));
                        });

                        // checks for alert reason codes in trip
                        const alert = this.alertReasonCodeService.checkAllAlert(tripToCheckAlerts);
                        if (!!alert) { throw alert; }
                    }),
                    concatMap(trip => {
                        const segment = !!trip && !!trip.results.length
                            && trip.results[0].segments.entries.find(entry => entry.origin === state.selectedSegment.origin
                                && entry.destination === state.selectedSegment.destination
                                && entry.departure.utcDateTime === state.selectedSegment.departure.utcDateTime);

                        const checkin = (action.type === TripActions.paymentCheckin.type) ?
                            TripActions.paymentCheckinSuccess({ trip }) : TripActions.checkinSuccess({ trip });
                        const actions: Action[] = [
                            checkin,
                            SessionActions.setSessionCheckinComplete({ name: 'checkinComplete', value: true }),
                            SessionActions.setSessionResultData({ name: 'resultData', value: trip }),
                            SessionActions.updateSessionSelectedSegment({ segment })
                        ];
                        return actions;
                    }),
                    catchError(error => {
                        return of(TripActions.checkinError(error));
                    })
                );
            })
        )
    );

    checkinSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.checkinSuccess, TripActions.checkinSuccessAlreadyCheckedIn, TripActions.paymentCheckinSuccess),
            withLatestFrom(
                this.store.pipe(select(fromSession.getSessionState))
            ),
            switchMap(action => of(action).pipe(
                concatMap(([action, state]) => {
                    const isPaymentCheckin = action.type === TripActions.paymentCheckinSuccess.type;
                    if (state.finishCheckin || isPaymentCheckin) {
                        return [RouterActions.routeToResult({
                            componentName: isPaymentCheckin ? CHECKIN_ROUTES.ROUTE_PAYMENT.route : CHECKIN_ROUTES.ROUTE_GUIDELINES.route
                        })];
                    }
                    const payload = {
                        confirmationCode: state.trip2Checkin.confirmationCode,
                        segmentId: state.selectedSegment.id,
                        passengerIds: state.passengerIds.join()
                    };
                    const actions: Action[] = [
                        CartActions.createCartPassengers(payload),
                        RouterActions.routeToSeats({ componentName: CHECKIN_ROUTES.ROUTE_GUIDELINES.route })
                    ];
                    return actions;
                })
            ))
        )
    );

    checkinError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.checkinError, TripActions.groupBookingCheckinError),
            tap(_ => this.modalsService.openGenericErrorMessage({ contentProps: 'messageCheckinError' }))
        ), { dispatch: false }
    );


    updatePassenger$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.updatePassenger, TripActions.updatePassengerMilitary),
            withLatestFrom(
                this.store.pipe(select(fromTrip.isInternational)),
                this.store.pipe(select(fromTrip.getTripPassengersList))
            ),
            switchMap(([action, isInternational, paxList]) =>
                this.tripDataService.updatePassenger(action.tripId, action.passengerUpdates, action.errorRendering).pipe(
                    switchMap(res => {
                        let updatedPaxList: PassengerList;
                        // create deep copy to allow reassignment of values
                        updatedPaxList = Object.assign({}, paxList);
                        updatedPaxList.entries = paxList.entries.map(pax => {
                            const updatedPax = res.results[0].entries.find(patchPax => pax.id === patchPax.id);
                            return updatedPax ? updatedPax : pax;
                        });
                        const actions: Action[] = [
                            TripActions.updatePassengerSuccess({
                                passengerList: updatedPaxList ? updatedPaxList : res.results[0],
                                routeTo: action.routeTo,
                                inputType: action.errorRendering,
                                isInternational: isInternational
                            }),
                        ];
                        return actions;
                    }),
                    catchError(error => {
                        this.logService.error(JSON.stringify(error));
                        this.modalsService.openUpdatePassengerErrorMessage(error.error, action.errorRendering, 'passenger');
                        return of(TripActions.updatePassengerError({ error: error.error, from: action.type, routeTo: action.routeTo }));
                    })
                )
            )
        )
    );

    updatePassengersSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.updatePassengerSuccess),
            withLatestFrom(
                this.store.pipe(select(fromRouter.getSortedActions)),
                this.store.pipe(select(fromSession.getSessionTripSearch)),
                this.store.pipe(select(fromSession.getSessionState)),
                this.store.pipe(select(fromTrip.getTrip)),
            ),
            switchMap(([action, sortedActions, tripSearch, state, trip]) => {
                const actions: Action[] = [
                    SessionActions.setSessionTrip2CheckinPassengers({ name: 'passengers', passengers: action.passengerList }),
                ];
                if (!!sortedActions && !!sortedActions.length
                    && action.inputType === 'hawaiianMilesNumber'
                    && (action.passengerList.entries[0].customerLevel === Constants.customerLevelGold
                        || action.passengerList.entries[0].customerLevel === Constants.customerLevelPlatinum)) {
                    actions.push(
                        TripActions.resultGetTrip({ tripSearch: tripSearch, alertMessage: '' }),
                        CatalogActions.itineraryGetCatalog()
                    );
                }
                return actions;
            }),
        )
    );

    openInfantRemovalModal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.checkInfantStatus),
            tap(action => {
                if (action.trip.passengers.entries.some(passenger => passenger.hasInfant)) {
                    this.modalsService.openInfantRemoval(action.trip);
                }
            })
        ), { dispatch: false }
    );

    addLapInfant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.addLapInfant),
            switchMap(action =>
                this.tripDataService.addLapInfant(action.tripId, action.adultPassengerId, action.lapInfant).pipe(
                    switchMap(response => {
                        return [
                            SessionActions.setSessionTrip2CheckinPassengers({ name: 'passengers', passengers: response.results[0] }),
                            RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_INFANT_ADDITIONAL_INFO.component }),
                            TripActions.addLapInfantSuccess({ passengerList: response.results[0] }),
                        ];
                    }),
                    catchError(error => of(TripActions.addLapInfantError(error)))
                )
            )
        )
    );

    addLapInfantError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.addLapInfantError),
            tap(_ => this.modalsService.openInfantAgedOutModal())
        ), { dispatch: false }
    );

    removeLapInfant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.removeLapInfant),
            switchMap(action =>
                this.tripDataService.removeLapInfant(action.tripId, action.confirmationCode, action.passengerId).pipe(
                    switchMap(trip => {
                        const actions: Action[] = [
                            TripActions.removeLapInfantSuccess({ trip }),
                        ];
                        return actions;
                    }),
                    catchError(error => {
                        this.logService.error(JSON.stringify(error));
                        return of(TripActions.removeLapInfantError({ error: JSON.stringify(error), from: 'removeLapInfant' }));
                    })
                )
            )
        )
    );

    removeLapInfantError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.removeLapInfantError),
            tap(_ => this.modalsService.openRemoveInfantErrorModal(_.error))
        ), { dispatch: false }
    );

    addLinkMinor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.addMinorToAdult),
            withLatestFrom(this.store.pipe(select(fromSession.sessionSelectedSegment))),
            switchMap(([action, segment]) => {
                let flightInfo = [];
                segment.details.forEach(segmentDetails => flightInfo = segmentDetails.flightDetails.map(
                    details => ({ flightId: details.flightId, cabin: details.cabinType })));
                return this.tripDataService.addMinorToAdult(action.tripId,
                    action.adultSearchInfo, flightInfo).pipe(
                        concatMap(() => {
                            return [
                                RouterActions.routeToPassengers({ componentName: CHECKIN_ROUTES.ROUTE_MINOR.component }),
                                TripActions.addMinorToAdultSuccess()
                            ];
                        }),
                        catchError(error => {
                            return of(TripActions.addMinorToAdultError(error));
                        })
                    );
            })
        )
    );

    addMinorToAdultError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.addMinorToAdultError),
            tap(error => {
                const linkMinorError = linkMinorErrorInfo.find(errorSentence => errorSentence.errorSentence === error.error);
                if (linkMinorError) {
                    this.modalsService.openMinorCheckinErrorModal(linkMinorError.errorType);
                } else {
                    this.modalsService.openMinorCheckinErrorModal("reservationNotFoundError");
                }
            })
        ), { dispatch: false }
    );

    openGuestMinorModal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.checkInMinorStatus),
            tap(action => {
                if (!action.trip.passengers.entries.some(passenger => !passenger.isMinor)) {
                    this.modalsService.openLinkMinorModal();
                }
            })
        ), { dispatch: false }
    );

    updatePassengerMilitaryType$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.militarySaveTypeModalUpdatePassenger),
            withLatestFrom(this.store.pipe(select(fromSession.getSessionState))),
            switchMap(([action, sessionState]) =>
                this.tripDataService.setMilitaryTravelType(action.tripId, action.passengerUpdates).pipe(
                    switchMap(res => {
                        const actions: Action[] = [
                            TripActions.updatePassengerMilitaryTypeSuccess({ trip: res, routeTo: action.routeTo })
                        ];
                        if (!!sessionState.selectedSegment
                            && !!sessionState.passengerIds
                            && !!sessionState.passengerIds.length
                            && !!sessionState.trip2Checkin) {
                            actions.push(
                                CatalogActions.bagsGetCatalog()
                            );
                            actions.push(
                                CartActions.militaryUpdateCart()
                            );
                        }

                        return actions;
                    }),
                    catchError(error => {
                        this.logService.error(JSON.stringify(error));
                        this.modalsService.openUpdatePassengerErrorMessage(error.error, 'knownTravelerNumber', action.routeTo);
                        return of(TripActions.updatePassengerMilitaryTypeError({
                            error: JSON.stringify(error), from: 'updatePassengerMilitaryType', routeTo: action.routeTo
                        }));
                    })
                )
            )
        )
    );

    updatePassengerMilitaryTravelTypeSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.updatePassengerMilitaryTypeSuccess),
            switchMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromTrip.militaryPaxInformation))),
                map(([_, militaryPaxInformation]) => {
                    const noMilitaryTravelType = militaryPaxInformation.every(pax => pax.militaryTravelType === MilitaryTravelType.NONE);
                    if (noMilitaryTravelType) {
                        return TripActions.removeMilitaryTravelTypeSuccess({ routeTo: action.routeTo });
                    } else {
                        this.modalsService.openMilitaryTypeSuccessMessage(action.routeTo);
                        return TripActions.militarySaveTypeSuccessMessage();
                    }
                })
            ))
        )
    );

    getGroupBookingPassengerList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.getGroupPassengerByName),
            switchMap(action => {
                return this.tripDataService.getGroupBookingPassengerList
                    (action.tripId, action.confirmationCode, action.passengerName, action.dateOfBirth).pipe(
                        map(passengerListResponse => {
                            return TripActions.updateGroupPassenger({ passengerList: passengerListResponse });
                        }),
                        catchError(error => {
                            if (error.status === 404) {
                                return of(
                                    TripActions.groupPassengerNotFoundError({
                                        error: error,
                                        tripId: action.tripId,
                                        confirmationCode: action.confirmationCode,
                                        passengerName: action.passengerName
                                    })
                                );
                            } else {
                                return of(TripActions.groupBookingCheckinError({ error: error, requestData: error }));
                            }
                        }),
                    );
            })
        )
    );

    getGroupBookingFlow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.getGroupBookingFlow),
            concatMap(action => {
                return [
                    TripActions.getByPnrSuccess({ trip: action.trip }),
                    RouterActions.routeToGroupBookingPage({ componentName: CHECKIN_ROUTES.ROUTE_CHECKIN.component }),
                    TripActions.getGroupBookingFlowSuccess(),
                ];
            }),
            catchError(error => of(error))
        )
    );

    groupPassengerNotFoundError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.groupPassengerNotFoundError),
            tap(action => {
                const error = action.error.error.errors;
                if (error.message.includes('There are multiple passengers with same name. Please include dateOfBirth')) {
                    this.modalsService.openGroupDOBModal(action.tripId, action.confirmationCode, action.passengerName);
                }

                if (error.message.includes('There are no matching passengers in this trip')) {
                    this.modalsService.openAddGuestErrorModal();
                }
            })
        ), { dispatch: false }
    );

    addToEliteStandByList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TripActions.addToEliteStandByList),
            withLatestFrom(this.store.pipe(select(fromFlight.allFlights))),
            switchMap(([action, flights]) => {
                const routeToSeats = !action.selectedEliteStandByPassengers.some(pax => !pax.isCheckedIn);
                const payload = [];
                action.selectedEliteStandByPassengers.forEach(pax => {
                    pax.flightDetails.forEach(detail => {
                        const flight = flights.find(leg => leg.id === detail.flightId);
                        if (!flight) {
                            throw { error: 'no flights' };
                        }

                        const payloadEntry = payload.find(entry =>
                            entry.airline === flight.airlineCode && entry.flightNumber === flight.flightNumber);
                        if (!!payloadEntry) {
                            payloadEntry.passengers.push({
                                lastName: pax.passengerName.lastName,
                                externalId: detail.externalId,
                                priorityCode: standByPriorityCodes[pax.customerLevel]
                            });
                        } else {
                            payload.push({
                                origin: flight.origin,
                                airline: flight.operatedBy,
                                flightNumber: flight.flightNumber,
                                departureDate: flight.scheduledDeparture.airportDateTimeString.split("T")[0],
                                bookingClass: detail.bookingClass,
                                passengers: [{
                                    lastName: pax.passengerName.lastName,
                                    externalId: detail.externalId,
                                    priorityCode: standByPriorityCodes[pax.customerLevel]
                                }]
                            });
                        }
                    });
                });
                return this.tripDataService.addToEliteStandByList(payload).pipe(
                    map(_ => {
                        return TripActions.addToEliteStandByListSuccess();
                    }),
                    catchError((error) => {
                        this.modalsService.openSeatUpgradeErrorModal(routeToSeats);
                        return of(TripActions.addToEliteStandByListError({ error: error, requestData: error }));
                    }),
                );
            })
        )
    );
}
