import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { CurrentLocaleService, PatternRouteMatcher } from '@hawaiianair/core';
import { Injectable } from '@angular/core';
interface CheckinRoutes {
  [key: string]: {
    route: string;
    serverRoute?: string;
    component: string;
    isChild: boolean;
  };
}
export interface ActionPrecedence {
  route: string;
  priority: number;
}

export const CHECKIN_ROUTES: CheckinRoutes = {
  ROUTE_DASHBOARD : {
    route: 'dashboard',
    component: 'Dashboard',
    isChild: false
  },
  ROUTE_CHECKIN : {
    route: '',
    component: 'Checkin',
    isChild: false
  },
  ROUTE_HEALTH_ATTESTATION : {
    route: 'health-attestation',
    component: 'Health Attestation',
    isChild: false
  },
  ROUTE_PASSENGER : {
    route: 'passenger',
    component: 'Passenger',
    isChild: false
  },
  ROUTE_GROUP_CHECKIN: {
    route: 'groups',
    component: 'GroupPassengersComponent',
    isChild: false
  },
  ROUTE_INTERNATIONAL_TRAVELER_SELECTION: {
    route: 'international-traveler-selection',
    component: 'International Traveler Selection',
    isChild: false
  },
  ROUTE_SEAT : {
    route: 'seat',
    component: 'Seat',
    isChild: false
  },
  ROUTE_BAGGAGE : {
    route: 'baggage',
    component: 'Baggage',
    isChild: false
  },
  ROUTE_MILITARY : {
    route: 'military',
    component: 'Military',
    isChild: false
  },
  ROUTE_INFANT : {
    route: 'infant',
    component: 'Infant',
    isChild: false
  },
  ROUTE_INFANT_ADDITIONAL_INFO : {
    route: 'infant-additional-info',
    component: 'Infant Additional Info',
    isChild: false
  },
  ROUTE_PAYMENT : {
    route: 'payment',
    component: 'Payment',
    isChild: false
  },
  ROUTE_RESULT : {
    route: 'result',
    serverRoute: 'result',
    component: 'Result',
    isChild: false
  },
  ROUTE_BAG_SPECIAL_ITEMS : {
    route: 'bag-special-items',
    component: 'Bag Special Items',
    isChild: true
  },
  ROUTE_BAG_ADVISORY_ITEMS : {
    route: 'bag-advisory-items',
    component: 'Bag Advisory Items',
    isChild: true
  },
  ROUTE_SEGMENT_SELECTOR : {
    route: 'segment-selector',
    component: 'Segment Selector',
    isChild: false
  },
  ROUTE_GUIDELINES : {
    route: 'guidelines',
    component: 'GuidelinesPageComponent',
    isChild: false
  },
  ROUTE_SEAT_UPGRADE_LIST : {
    route: 'seat-upgrade-passenger-list',
    component: 'SeatUpgradePassengerListComponent',
    isChild: true
  },
  ROUTE_SEAT_UPGRADE : {
    route: 'seat-upgrade',
    component: 'SeatUpgradeComponent',
    isChild: true
  },
  ROUTE_SEATMAP : {
    route: 'seatmap',
    component: 'Seatmap',
    isChild: true
  },
  ROUTE_BOARDING_PASS_PAGE : {
    route: 'boarding-pass-page',
    component: 'BoardingPassComponent',
    isChild: true
  },
  ROUTE_INTERNATIONAL_TRAVELER_INFO: {
    route: 'international-traveler-information',
    component: 'InternationalTravelerInformationComponent',
    isChild: false
  },
  ROUTE_MINOR: {
    route: 'minor',
    component: 'LinkMinorToAdultComponent',
    isChild: false
  },
  ROUTE_PASSPORT_INFORMATION: {
    route: 'passport-information',
    component: 'Passport Information',
    isChild: false
  },
  ROUTE_RESIDENCE_INFORMATION: {
    route: 'residence-information',
    component: 'Residence Information',
    isChild: false
  },
  ROUTE_TRAVEL_INFORMATION: {
    route: 'travel-information',
    component: 'Travel Information',
    isChild: false,
  },
  ROUTE_EMERGENCY_CONTACT: {
    route: 'emergency-contact',
    component: 'Emergency Contact',
    isChild: false,
  },
  ROUTE_CONTACT_TRACING: {
    route: 'contact-tracing',
    component: 'Contact Tracing',
    isChild: false,
  }
};

export const actionPrecedenceList = {
  BoardingPass: {
    route: `result/boarding-pass-page`,
    priority: 4
  } as ActionPrecedence,
  Bags: {
    route: 'baggage',
    priority: 3
  } as ActionPrecedence,
  PassengerInformation: {
    route: 'passenger',
    priority: 1
  } as ActionPrecedence,
  Seats: {
    route: 'seat',
    priority: 2
  } as ActionPrecedence,
  Extras: {
    route: 'extras',
    priority: 5
  } as ActionPrecedence
};

export interface ICheckinRouteSerializerState {
  url: string;
  params: Params;
  queryParams: Params;
  isCheckInView: boolean;
}

@Injectable({
  providedIn: 'any'
})

export class CheckinRouteSerializer implements RouterStateSerializer<ICheckinRouteSerializerState> {
  constructor(
    private patternRouteMatcher: PatternRouteMatcher,
    private localeService: CurrentLocaleService
  ) {}

  serialize(routerState: RouterStateSnapshot): ICheckinRouteSerializerState {
    let route = routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    if (!!route && !!route.params && route.params.locale !== this.localeService.language) {

      // TO DO - Consolidate these two lists into one.

      this.localeService.use(route.params.locale);

      let locale = this.localeService.locale;
      switch (this.localeService.locale) {
        case 'en-us':
          locale = 'en';
          break;
        case 'ja-jp':
          locale = 'ja';
          break;
        case 'ko-kr':
          locale = 'ko';
          break;
        case 'zh-cn':
          locale = 'zh';
          break;
        default:
          break;
      }

      for (const checkinRoute in CHECKIN_ROUTES) {
        if (!CHECKIN_ROUTES[checkinRoute].isChild) {
          CHECKIN_ROUTES[checkinRoute].route = this.patternRouteMatcher.replacePathLanguage(
            CHECKIN_ROUTES[checkinRoute].route, locale);
        }
      }

      for (const action in actionPrecedenceList) {
          actionPrecedenceList[action] = {
            ...actionPrecedenceList[action],
            route: this.patternRouteMatcher.replacePathLanguage(actionPrecedenceList[action].route, locale)
          };
      }
    }
    const {
      url,
      root: { queryParams },
    } = routerState;

    const { params } = route;
    const isCheckInView =
      (params.serverRoute !== '/'
      && !url.includes(CHECKIN_ROUTES.ROUTE_RESULT.serverRoute))
      || (params.serverRoute === '/' && !!queryParams?.['name'] && !!queryParams?.['code']);

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams, isCheckInView };
  }
}
