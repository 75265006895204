<form *ngIf="findTripSearchForm" [formGroup]="findTripSearchForm">
    <div class="row">
        <div class="col-12 call-out-bold" *haText="rendering?.props['findYourTrip']"></div>
    </div>
    <div class="row">
        <mat-form-field appearance="standard" class="col-md-4">
            <mat-select id="select-search-type" name="searchSelected" class="mat-select-text" [(value)]="selectedSearch"
                (selectionChange)="updateFindTripForm(selectedSearch)" disableRipple accesskey="1">
                <ng-container *ngFor="let search of rendering?.props['findTripSearch']; let HMOption = last">
                    <mat-option *ngIf="!HMOption" id="select-search-{{search.type}}"
                        [value]="search" class="mat-select-text secondary-text">
                        <span *haText="search.title"></span>
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="!!selectedSearch">
            <div class="col-md-4 pb-4 pb-sm-0" *ngFor="let field of selectedSearch?.fields; index as fieldIndex">
                <ng-container [ngSwitch]="field.type">
                    <app-ha-input *ngSwitchCase="'input'" type="{{field.type}}" formControlName="{{field.id}}" inputId="input-{{field.id}}" label="{{field.text}}"
                        placeholder="{{field.text}}" maxlength="{{field.maxLength}}" [control]="findTripSearchForm.get(field.id)" ariaLabel="{{field.ariaLabel}}" autoFocus="{{field.autoFocus}}" accessKey="{{fieldIndex + 2}}">
                    </app-ha-input>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <div class="col-md-8 text-left">
            <button type="submit" id="next-button" (click)="onSubmit(); $event.preventDefault()"
                [disabled]="!findTripSearchForm?.valid" class="btn btn-default trip-btn">
                <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
                <span *ngIf="!(isMobile$ | async); else mobileButton">{{ rendering.props['viewMyTrip'] }}</span>
                <ng-template #mobileButton>
                    <span *haText="rendering.props['checkInNow']"></span>
                </ng-template>
            </button>
        </div>
    </div>
</form>