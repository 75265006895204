<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(savingLapInfantInfo$ | async)">
    <div id="remove-infant-confirmation-modal-spinner" class="spinner-border" role="status"></div>
</div>
<div id="remove-infant-confirmation-modal">
    <h6 id="remove-infant-confirmation-heading" class="bold medium" mat-dialog-title *haText="rendering?.props['removeInfantHeading']">
    </h6>
        <mat-dialog-actions class="infant-removal-confirmation-buttons">
            <button id="go-back-button" mat-dialog-close mat-button class="btn btn-secondary">
                <span *haText="rendering?.props['backButton']"></span>
            </button>
            <button id="remove-infant-button" (click)="removeInfant()" mat-button class="btn btn-default" cdkFocusInitial>
                <span *haText="rendering?.props['removeInfantButton']"></span>
            </button>
        </mat-dialog-actions>
</div>