import { InnerError } from './innererror.model';

export const RANGE_REF_BAG_FULFILLMENT = 'bagFulfillment';
export const RANGE_REF_BAG_PAYMENT = 'bagPayment';
export const RANGE_REF_SEAT_ASSIGNMENT = 'seatAssignment';
export const RANGE_REF_SEAT_PAYMENT = 'seatPayment';
export const RANGE_REF_PAYMENT_INFO = 'paymentInfo';

export interface PartialSuccess {
    message?: string;
    rangeReference?: string;
    status?: number;
    errors?: InnerError;
}
