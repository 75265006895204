<app-checkin-header [heading]="rendering.props['heading']" 
[description]="rendering.props['request']" [hideHeaderInfo]="(savingAdultToMinorInfo$ | async)"></app-checkin-header>
<div *ngIf="savingAdultToMinorInfo$ | async ; else minorTemplate">
    <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #minorTemplate >
    <div class="container text-left px-0 px-md-4">
        <app-adult-search-card [rendering]="adultSearchCardRendering"
        (adultSearchFormIsValid)="adultSearchInfo = $event"></app-adult-search-card>
    </div>
    <app-checkin-footer [navButtons]="rendering.props['navButtons']"
    [disableButtons]="!adultSearchInfo" (defaultNavButtonClicked)="submitAdultInformation()"></app-checkin-footer>
</ng-template>
