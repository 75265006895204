import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Cart } from '~app/models/cart.model';
import { Response } from '~app/models/response.model';
import { CartSelectors } from '~app/store/services/cart/cart.selectors';
import { CartItem } from '~app/models/cartitem.model';
import { SessionSelectors, TripSelectors } from '~app/store';
import { PassengerList } from '~app/models/passengerlist.model';

@Component({
  selector: 'app-checkin-cart',
  templateUrl: './checkin-cart.component.html',
  styleUrls: ['./checkin-cart.component.scss'],
})
export class CheckinCartComponent implements OnInit, OnDestroy {
  cart$: Observable<Response<Cart>>;
  grandTotal$: Observable<number>;
  bagsTotal$: Observable<number>;
  seatsTotal$: Observable<number>;
  cartItems$: Observable<CartItem[]>;
  sessionPassengerIds$: Observable<string[]>;
  tripPassengerList$: Observable<PassengerList>;
  routeState$: Observable<any>;
  subscriptions: Subscription;
  grandTotal: number;
  bagsTotal: number;
  seatsTotal: number;
  cartItems: CartItem[];
  sessionPassengerIds: string[];
  tripPassengerList: PassengerList;
  paymentPage: any;
  constructor(
    private cartSelectors: CartSelectors,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
  ) {
    this.cart$ = this.cartSelectors.cart$;
    this.grandTotal$ = this.cartSelectors.grandTotal$;
    this.bagsTotal$ = this.cartSelectors.bagsTotalCost$;
    this.seatsTotal$ = this.cartSelectors.seatsTotalCost$;
    this.cartItems$ = this.cartSelectors.cartItems$;
    this.sessionPassengerIds$ = this.sessionSelectors.sessionPassengerIds$;
    this.tripPassengerList$ = this.tripSelectors.tripPassengersList$;
  }

  ngOnInit() {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.grandTotal$.subscribe(total => this.grandTotal = total));
    this.subscriptions.add(this.seatsTotal$.subscribe(total => this.seatsTotal = total));
    this.subscriptions.add(this.bagsTotal$.subscribe(total => this.bagsTotal = total));
    this.subscriptions.add(this.cartItems$.subscribe(items => this.cartItems = items));
    this.subscriptions.add(this.sessionPassengerIds$.subscribe(ids => this.sessionPassengerIds = ids));
    this.subscriptions.add(this.tripPassengerList$.subscribe(list => this.tripPassengerList = list));
  }

  ngOnDestroy() {
    if (!!this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
