<div class="tout-image"></div>
<div class="tout-section">
  <div class="tout-body">
    <div class="tout-headline">
      <div class="tout-subhead" *haText="rendering.props?.heading"></div>
      <div class="tout-head" *haText="rendering.props?.extraComfort?.imgTitle"></div>
    </div>
    <div class="tout-features">
      <div *ngFor="let description of rendering.props?.extraComfort?.descriptions">
        <i class="check-circle"></i>
        <div class="description" *haText="description"></div>
      </div>
    </div>
  </div>
  <button ha-button
    *haText="rendering.props?.seeAvailableSeats"
    (click)="routeToSeatmap()"
    buttonSize="small"
    buttonType="secondary"
    buttonWidth="fill"
  ></button>
</div>