import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { RouterDispatchers, SessionDispatchers, SessionSelectors } from "~app/store";
import { SessionData } from "~app/models/sessiondata.model";
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  sessionData: SessionData;
  sessionSub: Subscription;

  constructor(
    public router: Router,
    public sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private routerDispatchers: RouterDispatchers
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.sessionSelectors.session$.pipe(take(1)).subscribe(sessionData => {
      sessionData ? this.sessionData = sessionData : this.sessionDispatchers.getState();
      if (!this.sessionData.authToken) {
        this.routerDispatchers.routeToCheckin('Auth Guard Component');
      }
      return this.sessionData.authToken;
    });
    return !!this.sessionData.authToken;
  }
}
