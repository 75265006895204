import { createAction, props } from '@ngrx/store';
import { Response } from '~app/models/response.model';
import { Country } from "~app/models/country.model";

export const getCountries = createAction(
  '[Checkin Start] Get Countries'
);

export const getCountriesSuccess = createAction(
  '[Checkin Start] Get Countries Success',
  props<{ countries: Response<Country>}>()
);

export const getCountriesError = createAction(
  '[Checkin Start] Get Countries Error', props<{ error: any }>()
);
