<h6 id="target-message-title" class="bold medium" mat-dialog-title *haText="rendering?.props[data.propName]?.title"></h6>
<mat-dialog-content id="target-message-content" class="body-copy">
  <div *haRichText="rendering?.props[data.propName]?.body"></div>
  <br />
  <div *haRichText="rendering?.props['welcomeMessage']"></div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="target-message-close" mat-button [mat-dialog-close]="true" class="btn btn-default">
    {{ 'Ok' | translate }}
  </button>
</mat-dialog-actions>