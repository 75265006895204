import { SessionEffects } from './session/session.effects';
import { TripEffects } from './trip/trip.effects';
import { AircraftImageEffects } from './aircraft-image/aircraft-image.effects';
import { CatalogEffects } from './catalog/catalog.effects';
import { CartEffects } from './cart/cart.effects';
import { FlightEffects } from './flight/flight.effects';
import { AnalyticsEffects } from './analytics/analytics.effects';
import { AuthTokenEffects } from './auth-token/auth-token.effects';
import { RouterEffects } from './router/router.effects';
import { SecurityInfoEffects } from './security-info/security-info.effects';
import { ReferenceDataEffects } from './reference-data/reference-data.effects';
import { PassportScanEffects } from './passport-scan/passport-scan.effects';
import { OrderFulfillEffects } from './orderFulfill/orderFulfill.effects';
import { OffersEffects } from './offers/offers.effects';
import { InternationalDetailsEffects } from './international-details/international-details.effects';

export const effects: any[] = [
  TripEffects,
  SessionEffects,
  AircraftImageEffects,
  CatalogEffects,
  CartEffects,
  FlightEffects,
  AnalyticsEffects,
  AuthTokenEffects,
  RouterEffects,
  SecurityInfoEffects,
  ReferenceDataEffects,
  PassportScanEffects,
  OrderFulfillEffects,
  OffersEffects,
  InternationalDetailsEffects
];

export * from './session/session.effects';
export * from './trip/trip.effects';
export * from './aircraft-image/aircraft-image.effects';
export * from './catalog/catalog.effects';
export * from './flight/flight.effects';
export * from './cart/cart.effects';
export * from './auth-token/auth-token.effects';
export * from './router/router.effects';
export * from './security-info/security-info.effects';
export * from './reference-data/reference-data.effects';
export * from './passport-scan/passport-scan.effects';
export * from './orderFulfill/orderFulfill.effects';
export * from './offers/offers.effects';
export * from './international-details/international-details.effects'
