<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(authLoading$ | async)">
  <div id="auth-token-expiration-modal-spinner" class="spinner-border" role="status"></div>
</div>
<div id="auth-token-expiration-modal">
  <h6 class="bold medium" mat-dialog-title>{{ title }}</h6>
  <mat-dialog-content class="dlg-content mat-typography body-copy">
    <span>{{header}}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="row justify-content-center full-width">
      <button id="auth-token-modal-ok" (click)="endSession()" mat-button [mat-dialog-close]="true"
      class="btn btn-secondary col-10 col-sm-4 mr-sm-3" accesskey="n">{{ btnNo }}</button>
      <button id="auth-token-modal-continue" (click)="getNewAuthToken()" mat-button
      cdkFocusInitial class="btn btn-default col-10 col-sm-4" accesskey="y">{{ btnYes }}</button>
    </div>
  </mat-dialog-actions>
</div>
