<!-- eslint-disable @angular-eslint/template/no-negated-async  -->
<h6 id="header" class="bold medium" mat-dialog-title *haText="rendering?.props['header']"></h6>
<mat-dialog-content class="dlg-content mat-typography">
  <p class="body-copy" id="content" *haText="rendering?.props['content']"></p>
  <p *ngIf="!(hasAlreadyCheckedIn$ | async)" class="body-copy">
    <span *haText="rendering?.props['subContent']"></span>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
  id="cancel-button"
  (click)="onCloseClick()"
  [class]="!(hasAlreadyCheckedIn$ | async) ? 'btn btn-secondary' : 'btn btn-default'"
  mat-button
  mat-dialog-close
  *haText="rendering?.props['cancelBtn']">
  </button>
  <button
  *ngIf="!(hasAlreadyCheckedIn$ | async)"
  (click)="onCheckInClick()"
  id="check-in-button"
  class="btn btn-default"
  mat-button
  mat-dialog-close cdkFocusInitial>
    <span *haText="rendering?.props['checkInBtn']"></span>
    <span class="small" *haText="rendering?.props['checkInBtnSubText']"></span>
  </button>
</mat-dialog-actions>
