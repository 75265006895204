import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Subscription } from 'rxjs';
import { InternationalDetailsDispatchers, InternationalDetailsSelectors } from '~app/store';
import { Observable } from 'rxjs/internal/Observable';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { TitleCasePipe } from '@angular/common';
import { PassengerName } from '~app/models/passengername.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-travel-information',
  templateUrl: './travel-information.component.html',
  styleUrls: ['./travel-information.component.scss'],
})

@ContentSchema({
  name: 'TravelInformation',
  description: 'Travel Information Component',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'firstDayDescription', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
    { name: 'sameTravelInformationCheckboxContent', type: PropertyType.Text },
  ],
  components: [
    'destination-information',
  ],
})

export class TravelInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  isDestinationInformationFormValid = false;
  internationalPassengers: {
    id: string;
    passengerName: PassengerName;
    isSameTravelInfo: boolean;
    destinationInfoForm: FormGroup;
  }[];
  loading$: Observable<boolean>;
  subs = new Subscription();

  constructor(
    private dynamicContentService: DynamicContentService,
    private internationalDetailsDispatchers: InternationalDetailsDispatchers,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private titleCasePipe: TitleCasePipe,
  ) {
    this.loading$ = this.internationalDetailsSelectors.internationalDetailsLoading$;
  }

  ngOnInit() {
    this.subs.add(this.internationalDetailsSelectors.internationalPassengers$.subscribe(internationalPassengers => {
      this.internationalPassengers = internationalPassengers?.map((pax, index) => {
        if (index === 0) {
          const paxName = this.titleCasePipe.transform(`${pax?.passengerName?.firstName} ${pax?.passengerName?.lastName}`);
          this.rendering = this.dynamicContentService.fillContentProps(this.rendering, {
            defaultPaxName: paxName,
          });
        }

        return {
          ...pax,
          isSameTravelInfo: false,
          destinationInfoForm: new FormGroup({
            location: new FormControl('', [Validators.required]),
          }),
        }
      });
    }));

    this.internationalPassengers.forEach(passenger =>
      this.subs.add(passenger.destinationInfoForm.statusChanges.subscribe(_ =>
          this.isDestinationInformationFormValid = this.internationalPassengers.every(internationalPassenger =>
            internationalPassenger.destinationInfoForm.valid || internationalPassenger.isSameTravelInfo
          )
        )
      )
    );
  }

  submitTravelInformation() {
    const destinationAddresses = this.internationalPassengers.map((passenger, index) => {
      return {
        passengerId: passenger.id,
        destinationAddress: (passenger.isSameTravelInfo ? this.internationalPassengers[0].destinationInfoForm :
          passenger.destinationInfoForm)?.get('destinationForm').value,
      }
    });

    this.internationalDetailsDispatchers.postAddressInformation(destinationAddresses);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
