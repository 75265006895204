import { RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { MilitaryComponent } from './military/military.component';
import { MilitaryPaxCardComponent } from './military-pax-card/military-pax-card.component';


@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatBadgeModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatRadioModule,
        CoreModule.forChild({
            components: [
                MilitaryComponent,
                MilitaryPaxCardComponent,
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatCardModule,
        MatDividerModule,
        MatBadgeModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatGridListModule,
        MatRadioModule,
        MatIconModule,
        RouterModule
    ],
    declarations: [
        MilitaryComponent,
        MilitaryPaxCardComponent,
    ],
})
export class MilitaryModule { }
