import { Component, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ContentSchema, ComponentRendering } from '@hawaiianair/core';
import { SessionSelectors, FlightSelectors } from '~app/store';
import { Subscription, Observable } from 'rxjs';
import { FlightInformationComponent } from './flight-information/flight-information.component';
import { MatDialog } from '@angular/material/dialog';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { Flight } from '~app/models/flight.model';
import { Response } from '~app/models/response.model';
import { AirportMap } from '~app/models/airport.model';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { DynamicContentService } from '~app/services/dynamic-content.service';

@Component({
  selector: 'app-flight-status',
  templateUrl: './flight-status.component.html',
  styleUrls: [ './flight-status.component.scss' ],
})
@ContentSchema({
  name: 'FlightStatus',
  description: 'FlightStatusComponent',
})
export class FlightStatusComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  sessionState$: Observable<ISessionState>;
  flight$: Observable<Response<Flight>>;
  flightLoading$: Observable<boolean>;
  flightError$: Observable<boolean>;
  origin: string;
  originShortName: string;
  destination: string;
  destinationShortName: string;
  departure: string;
  flightStops: number;
  flightCounter: Array<string>;
  subscriptions: Subscription = new Subscription();
  allFlights$: Observable<Flight[]>;
  airports$: Observable<AirportMap>;

  constructor(
    private sessionSelectors: SessionSelectors,
    private flightSelectors: FlightSelectors,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private airportSelectors: ReferenceDataSelectors,
    private modalContentsSvc: ModalContentsService,
    private dynamicContentService: DynamicContentService
    ) {
      this.sessionState$ = this.sessionSelectors.session$;
      this.flight$ = this.flightSelectors.flight$;
      this.flightLoading$ = this.flightSelectors.flightLoading$;
      this.flightError$ = this.flightSelectors.error$;
      this.allFlights$ = this.flightSelectors.allFlights$;
      this.airports$ = this.airportSelectors.airports$;
   }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('flight-status') || [{}];
    this.subscriptions.add(this.sessionState$.subscribe(state => {
      this.originShortName = state.origin;
      this.destinationShortName = state.destination;
      this.origin = state.airportOriginLongName;
      this.destination = state.aiportDestinationLongName;
      this.flightStops = state.flightCount - 1;
      if (!!this.rendering.props) {
        this.rendering.props['stopCounter'] = this.dynamicContentService.getContentString(
          this.rendering.props['stopCounter'], { numberOfStops: state.flightCount - 1 }
        );
      }
    }));

    this.subscriptions.add(this.flight$.subscribe(flight => {
      if (flight) {
        this.departure = flight.results[0].scheduledDeparture.airportDateTimeString;
      }
    }));

    this.subscriptions.add(this.allFlights$.subscribe(allFlights => {
      if (allFlights) {
        this.flightCounter = allFlights.map((_, index) =>
          this.dynamicContentService.getContentString(
            this.rendering.props['flightCounter'],
            { flightIndex: index + 1, totalFlights: allFlights.length }
          )
        );
      }
    }));
  }

  openFlightInformation(): void {
    this.dialog.open(FlightInformationComponent, {
      data: {
      },
    });
  }

  openPanelEvent(): void {
    const bodyElement = document.querySelector('body');
    this.renderer.addClass(bodyElement, 'expansion-open');
  }

  closedPanelEvent(): void {
    const bodyElement = document.querySelector('body');
    this.renderer.removeClass(bodyElement, 'expansion-open');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.closedPanelEvent();
  }
}
