<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
    [hideHeaderInfo]="(tripLoading$ | async)">
</app-checkin-header>
<div *ngIf="(tripLoading$ | async); else dashboard">
    <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #dashboard>
    <div class="container">
        <div class="row">
            <div *ngFor="let action of rendering.props.actions; let idx = index" class="col-md-6">
                <mat-card
                id="dashboard-action-{{action.action}}"
                (click)="selectDashboardAction(action.action, idx)"
                (keydown.enter)="$event.preventDefault(); selectDashboardAction(action.action, idx); $event.stopPropagation();"
                >
                    <mat-checkbox
                    id="dashboard-select-checkbox-{{action.action}}"
                    (change)="selectDashboardAction(action.action, idx)"
                    (click)="$event.stopPropagation()"
                    [checked]="isActionChecked[idx]"
                    value="{{action.action}}"
                    [aria-label]="action.action + ' checkbox'"
                    >
                    </mat-checkbox>
                    <div>
                        <p class="subtitle-bold" *haText="action.title"></p>
                        <ng-container *ngIf="action.description">
                            <p id="action-{{action.action}}-description" class="small" *haText="action.description"></p>
                        </ng-container>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <app-checkin-footer [navButtons]="rendering?.props['navButtons']" [disableButtons]="!actions?.length"
        [dashboardActions]="actions"></app-checkin-footer>
</ng-template>