import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { RouterSelectors, RouterDispatchers, CartSelectors } from '~app/store';
import { Observable, Subscription } from 'rxjs';
import { ActionPrecedence, CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';

export interface FooterButtons {
  default: FooterButton;
  secondary: FooterButton;
  fees?: FooterFees;
  flights?: FooterFlights;
}

export interface FooterButton {
  text: string;
  subText?: string;
  ignoreEsc?: boolean;
}

export interface FooterFees {
  text: string;
  type: string;
}

export interface FooterFlights {
  btnPrevious: string;
  btnNext: string;
  totalFlights?: string;
  flightCounterString?: string;
}

@Component({
  selector: 'app-checkin-footer',
  templateUrl: './checkin-footer.component.html',
  styleUrls: ['./checkin-footer.component.scss'],
})
@ContentSchema({
  name: 'CheckinFooter',
  description: 'CheckinFooterComponent',
  props: [
    // TODO: replace this example schema property with real definitions
    { name: 'example', type: PropertyType.Text },
  ]
})
export class CheckinFooterComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  @Input() dashboardActions: any;
  @Input() navButtons: FooterButtons;
  @Input() disableButtons: boolean;
  @Input() disableDefaultNavButton: boolean;
  @Input() disableSecondaryNavButton: boolean;
  @Output() defaultNavButtonClicked: EventEmitter<boolean> = new EventEmitter();

  routerUrl$: Observable<string>;
  routerUrl: string;
  subs: Subscription = new Subscription();
  route: string;

  bagsTotalCost$: Observable<number>;
  seatsTotalCost$: Observable<number>;
  sortedActions$: Observable<ActionPrecedence[]>;
  sortedActions: ActionPrecedence[];
  totalFees = 0;

  constructor(
    private routerSelectors: RouterSelectors,
    private routerDispatchers: RouterDispatchers,
    private cartSelectors: CartSelectors,
  ) {
    this.bagsTotalCost$ = this.cartSelectors.bagsTotalCost$;
    this.seatsTotalCost$ = this.cartSelectors.seatsTotalCost$;
    this.routerUrl$ = this.routerSelectors.routerUrl$;
    this.sortedActions$ = this.routerSelectors.sortedActions$;
  }

  ngOnInit() {
    if (this.navButtons && this.navButtons.fees && this.navButtons.fees.type) {
      if (CHECKIN_ROUTES.ROUTE_SEAT.route.includes(this.navButtons.fees.type)) {
        this.subs.add(this.seatsTotalCost$.subscribe(cost => this.totalFees = cost));
      } else if (CHECKIN_ROUTES.ROUTE_BAGGAGE.route.includes(this.navButtons.fees.type)) {
        this.subs.add(this.bagsTotalCost$.subscribe(cost => this.totalFees = cost));
      }
    }
    this.subs.add(
      this.routerUrl$.subscribe(url => {
        if (url) {
          this.routerUrl = url;
          const urlArr = url.split('/');
          this.route = urlArr[urlArr.length - 1].split('?')[0];
        }
      })
    );
    this.subs.add(this.sortedActions$.subscribe(sortedActions => this.sortedActions = sortedActions));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  defaultNavButtonClick(): void {
    this.defaultNavButtonClicked.emit(true);
    if (!!this.dashboardActions || (this.sortedActions &&
      !!this.sortedActions.length && this.sortedActions[0].route.includes(this.route))) {
      this.routerDispatchers.actionRouting(this.dashboardActions);
    } else {
      this.routerDispatchers.defaultNavButtonClick(this.route);
    }
  }

  secondaryNavButtonClick(): void {
    this.routerDispatchers.secondaryNavButtonClick(this.route);
  }
}
