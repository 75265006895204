import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { CHECKIN_ROUTES, ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import { Flight } from '~app/models/flight.model';
import { HelperService } from '~app/services/helper.service';
import { RouterDispatchers, RouterSelectors, SessionSelectors, TripSelectors } from '~app/store';
import { SeatAssignment } from '~app/models/seatassignment';

@Component({
  selector: 'app-seatmap-legend',
  templateUrl: './seatmap-legend.component.html',
  styleUrls: ['./seatmap-legend.component.scss'],
})
@ContentSchema({
  description: 'SeatmapLegend',
  name: 'SeatmapLegend',
  props: [
    { name: 'legend', type: PropertyType.Text },
    { name: 'saveSeats', type: PropertyType.Text },
    { name: 'firstClass', type: PropertyType.Text },
    { name: 'businessClass', type: PropertyType.Text },
    { name: 'extraComfort', type: PropertyType.Text },
    { name: 'standard', type: PropertyType.Text },
    { name: 'unavailable', type: PropertyType.Text },
    { name: 'selected', type: PropertyType.Text },
    { name: 'preferred', type: PropertyType.Text },
    { name: 'exit', type: PropertyType.Text }
  ]
})
export class SeatmapLegendComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;

  subs = new Subscription();
  routerState$: Observable<ICheckinRouteSerializerState>;
  segmentFlights$: Observable<Flight[]>;
  assignedSeats$: Observable<SeatAssignment[]>;
  isInternational$: Observable<boolean>;

  segmentFlight: Flight;
  isHAFlight = true;
  areAllSeatsSelected: boolean;
  isInternational: boolean;
  isPreferredAvailable: boolean;
  isLieFlat: boolean;

  constructor(
    private routerSelectors: RouterSelectors,
    private routerDispatchers: RouterDispatchers,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
    private helperService: HelperService,
  ) {
    this.routerState$ = this.routerSelectors.routerState$;
    this.segmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.assignedSeats$ = this.sessionSelectors.recentlySavedSeats$;
    this.isInternational$ = this.tripSelectors.isInternational$;
  }

  ngOnInit() {
    this.subs.add(this.isInternational$.subscribe(isInternationalFlag => this.isInternational = isInternationalFlag));
    this.subs.add(this.routerState$.subscribe(
      state => {
        if (!!state) {
          this.segmentFlights$.subscribe(flights => {
            if (!!flights) {
              this.segmentFlight = flights.find(flight => flight.id === state.queryParams.flightId);
              this.isLieFlat = ['789', '330'].includes(this.segmentFlight?.aircraftType);
              this.isPreferredAvailable = this.segmentFlight?.aircraftType !== '717';
              this.isHAFlight = this.helperService.isHAFlight(state.queryParams.flightId, flights);
            }
          });

          this.subs.add(this.assignedSeats$.subscribe(seats => {
            if (!!seats && this.segmentFlight) {
              this.areAllSeatsSelected = seats.filter(seat =>
                seat.flightId === this.segmentFlight.id).every(seat => !!seat.seatId);
            }
          }));
        }
      }
    ));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  clickSeatmapLegendButton(element: HTMLElement) {
    if (this.areAllSeatsSelected) {
      this.routerDispatchers.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route);
    } else {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
