<h6 class="bold medium" mat-dialog-title *haText="rendering?.props['heading']"></h6>
<mat-dialog-content class="mat-typography body-copy">
  <p *haRichText="rendering?.props['delayMessage']"></p>
  <p *haText="rendering?.props['cancelText']"></p>
  <p *ngIf="!!data.estimatedDepartureTime">
    <span *haRichText="rendering?.props['delayedTime']"></span>
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="flight-delayed-ok" mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-default">
    {{ 'Ok' | translate }}
  </button>
</mat-dialog-actions>
