import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { CurrentLocaleService, PatternRouteMatcher, ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';

@Component({
  selector: 'app-language-buttons',
  templateUrl: './language-buttons.component.html',
  styleUrls: [ './language-buttons.component.scss' ],
})
@ContentSchema({
  name: 'LanguageButtons',
  description: 'Language Buttons',
  props: [
    { name: 'heading', type: PropertyType.Text },
  ],
})
export class LanguageButtonsComponent {
  @Input() rendering: ComponentRendering;
  languages: string[] = [];

  constructor(
    private location: Location,
    private currentLocale: CurrentLocaleService,
    private patternRouteMatcher: PatternRouteMatcher,
  ) {
    this.languages = currentLocale.supportedLanguages;
  }

  href(lang: string): string {
    return this.patternRouteMatcher.replacePathLanguage(this.location.path(), lang);
  }

  nativeName(lang: string): string {
    return this.currentLocale.languageInfo[lang].nativeName;
  }
}
