import { Component, Output, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TripSelectors } from '../../store/services/trip/trip.selectors';
import { TripDispatchers } from '../../store/services/trip/trip.dispatchers';
import { SessionDispatchers } from '../../store/services/session/session.dispatchers';
import { Observable, Subscription } from 'rxjs';
import { ModalContentsService } from '../modal-contents.service';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';

@Component({
  selector: 'app-remove-infant',
  templateUrl: './remove-infant.component.html',
  styleUrls: ['./remove-infant.component.scss']
})
@ContentSchema({
  description: 'Remove Infant Component',
  name: 'RemoveInfant',
  props: [
    { name: 'removeInfantHeading', type: PropertyType.Text },
    { name: 'removeInfantDescription', type: PropertyType.Text },
    { name: 'removeInfant', type: PropertyType.Text },
    { name: 'removedInfant', type: PropertyType.Text },
    { name: 'removeInfantTitle', type: PropertyType.Text },
    { name: 'removeInfantConfirmation', type: PropertyType.Text },
    { name: 'done', type: PropertyType.Text },
    { name: 'yes', type: PropertyType.Text },
    { name: 'no', type: PropertyType.Text }
  ]
})
export class RemoveInfantComponent implements OnInit {
  rendering: ComponentRendering;
  @Output() valueChange = new EventEmitter();

  objectKeys = Object.keys;
  selectedSegment = {};
  disableConfirmButton = true;
  savingLapInfantInfo$: Observable<boolean>;
  tripError$: any;
  subscriptions: Subscription;

  removedPNRInfants = [];
  tripError = false;
  removeInfantConfirmationOption = false;

  infantToRemovePassengerID: string;
  infantToRemoveIndex: number;

  constructor(
    public dialogRef: MatDialogRef<RemoveInfantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tripDispatchers: TripDispatchers,
    private sessionDispatchers: SessionDispatchers,
    private tripSelectors: TripSelectors,
    private modalContentsSvc: ModalContentsService
  ) {
    this.savingLapInfantInfo$ = this.tripSelectors.savingLapInfantInfo$;
    this.tripError$ = this.tripSelectors.tripError$;
  }

  openInfantRemovalConfirmation(passengerID: string, infantIndex: number) {
    this.removeInfantConfirmationOption = true;
    this.infantToRemovePassengerID = passengerID;
    this.infantToRemoveIndex = infantIndex;
  }

  closeInfantRemovalConfirmation() {
    this.removeInfantConfirmationOption = false;
  }

  onCancel() {
    this.sessionDispatchers.setSessionInfantConfirmation(true);
    this.dialogRef.close(true);
  }

  removeInfant() {
    this.sessionDispatchers.setSessionInfantConfirmation(true);
    this.tripDispatchers.removeLapInfant(this.data.results.id, this.data.results.confirmationCode, this.infantToRemovePassengerID);
    this.subscriptions.add(this.tripError$.subscribe(error => this.tripError = !!error));
    this.subscriptions.add(this.savingLapInfantInfo$.subscribe(loading => {
      if (!loading && !this.tripError) {
        this.removedPNRInfants[this.infantToRemoveIndex] = true;
      }
    }));
    this.removeInfantConfirmationOption = false;
  }

  ngOnInit() {
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('remove-infant-modal');
    this.subscriptions = new Subscription();

    for (let i = 0; i < this.data.results.passengers.entries.length; i++) {
      this.removedPNRInfants.push(false);
    }
  }
}
