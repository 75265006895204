import { PaymentInformation } from '@hawaiianair/payment/lib/models/amadeus-payment.model'
import { createAction, props } from "@ngrx/store";

export const orderFulfill = createAction(
  "[Payment Route] Order Fulfill Cart",
  props<{ paymentInformation?: PaymentInformation }>()
);
export const getOrderStatus = createAction(
  "[Order Fulfill API] Get Order Status",
  props<{ orderId: string }>()
);
export const getOrderStatusError = createAction(
  "[Order Fulfill API] Get Order Status Error",
  props<{ error: any }>()
);
export const getOrderStatusSuccess = createAction(
  "[Order Fulfill API] Get Order Status Success",
);
export const orderFulfillSuccess = createAction(
  "[Payment Route] Order Fulfill Cart Success"
);
export const orderFulfillFailure = createAction(
  "[Payment Route] Order Fulfill Cart Failure",
  props<{ error: any }>()
);
