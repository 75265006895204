<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="(loading$ | async) || (savingAdultToMinorInfo$ | async) || (internationalDetailsLoading$ | async)"></app-checkin-header>
  <!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(savingPassengersInformation$ | async)">
  <div id="passenger-update-spinner" class="spinner-border" role="status"></div>
</div>
<div *ngIf='(loading$ | async) || (savingAdultToMinorInfo$ | async) || (internationalDetailsLoading$ | async); else itineraryTemplate'>
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #itineraryTemplate>
  <div class="container background">
    <div class="main-passenger-card info-links-margin">
      <div *ngFor='let result of tripData?.results'>
        <div *ngFor='let entry of result?.segments.entries'>
          <div *ngIf="sessionSelectedSegment?.id === entry?.id" class="row">
            <div id="passenger-card" *ngFor='let detail of entry.details; let listIndex = index' class="col-md-12 text-left">
              <app-passenger-card (disableDefaultNavButton)="handleDisableDefaultNavButtonChange(listIndex, $event)" [rendering]="passengerCardRendering" [detail]="detail" [listIndex]=listIndex></app-passenger-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-info-links [rendering]="infoLinksRendering" (infoLinkEvent)="clickInfoLink($event)" [marginTop]="'0px'" 
      [linkArray]="showInfantInfoLink ? ['military', 'addInfant', 'frequentFlyerInfo'] : ['military', 'frequentFlyerInfo']">
    </app-info-links>
  </div>
  <app-checkin-footer
    [navButtons]="(isInternational$ | async) ? rendering?.props['internationalNavButtons'] : rendering?.props['defaultNavButtons']"
    [disableButtons]="!sessionData.passengerIds.length" [disableDefaultNavButton]="isDefaultNavButtonDisabled">
  </app-checkin-footer>
</ng-template>
