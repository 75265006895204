import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable } from 'rxjs';
import { TripSelectors, TripDispatchers, SessionSelectors, AnalyticsDispatchers } from '~app/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegexPatterns } from "~app/constants/regex-constants";
import { LapInfant } from '~app/models/lapinfant.model';
import { take } from 'rxjs/operators';
import { Trip } from '~app/models/trip.model';
import { ModalContentsService } from '~app/modals/modal-contents.service';

@Component({
  selector: 'app-infant-additional-info',
  templateUrl: './infant-additional-info.component.html',
  styleUrls: ['./infant-additional-info.component.scss']
})
@ContentSchema({
  description: 'Infant Additional Info',
  name: 'InfantAdditionalInfo',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'information', type: PropertyType.Text },
    { name: 'infantFirstName', type: PropertyType.Text },
    { name: 'infantMiddleName', type: PropertyType.Text },
    { name: 'infantLastName', type: PropertyType.Text },
    { name: 'gender', type: PropertyType.Text },
    { name: 'genders', type: PropertyType.Object },
    { name: 'dateOfBirth', type: PropertyType.Text },
    { name: 'privacyNotice', type: PropertyType.Object },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})

export class InfantAdditionalInfoComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  sessionTripId$: Observable<string>;
  addInfantAdultId$: Observable<string>;
  savingLapInfantInfo$: Observable<boolean>;
  sessionTrip2CheckIn$: Observable<Trip>;
  infantFormGroup: FormGroup;

  constructor(
    private tripSelectors: TripSelectors,
    private sessionSelectors: SessionSelectors,
    private tripDispatchers: TripDispatchers,
    private modalContentsService: ModalContentsService,
    private formBuilder: FormBuilder,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.sessionTripId$ = this.sessionSelectors.sessionTripId$;
    this.addInfantAdultId$ = this.sessionSelectors.addInfantAdultId$;
    this.savingLapInfantInfo$ = this.tripSelectors.savingLapInfantInfo$;
    this.sessionTrip2CheckIn$ = this.sessionSelectors.sessionTrip2Checkin$;
  }

  ngOnInit(): void {
    this.infantFormGroup = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern(RegexPatterns.alphaValidation)]],
      middleName: ['', Validators.pattern(RegexPatterns.alphaValidation)],
      lastName: ['', [Validators.required, Validators.pattern(RegexPatterns.alphaValidation)]],
      gender: ['', Validators.required],
      dateOfBirth: ['', Validators.required]
    });
    this.setErrorModalContents();
    this.analyticsDispatchers.infantInfoPageLoaded();
  }

  private setErrorModalContents() {
    this.modalContentsService.addRendering('infant-aged-out-modal', this.rendering.components['infant-aged-out-modal']);
    this.modalContentsService.addRendering('infant-exit-seat-unavailable-modal', this.rendering.components['infant-exit-seat-unavailable-modal']);
  }

  addLapInfant(): void {
    if (!this.isDoBValid(this.infantFormGroup.controls.dateOfBirth.value.format('YYYY-MM-DD'))) {
      this.tripDispatchers.addLapInfantError('Infant Aged Out');
    } else {
      let tripId: string;
      let adultPaxId: string;
      const lapInfant: LapInfant = {
        infantName: {
          firstName: this.infantFormGroup.controls.firstName.value,
          lastName: this.infantFormGroup.controls.lastName.value,
          middleName: !!this.infantFormGroup.controls.middleName.value ? this.infantFormGroup.controls.middleName.value : '',
        },
        dateOfBirth: this.infantFormGroup.controls.dateOfBirth.value.format('DD-MM-YYYY'),
        gender: this.infantFormGroup.controls.gender.value,
      };
      this.sessionTripId$.pipe(take(1)).subscribe(id => tripId = id);
      this.addInfantAdultId$.pipe(take(1)).subscribe(id => adultPaxId = id);
      this.tripDispatchers.addLapInfant(tripId, adultPaxId, lapInfant);
    }
  }

  private isDoBValid(birthDate: string): boolean {
    let arrivalDate: Date;
    this.sessionTrip2CheckIn$.pipe(take(1)).subscribe(trip => {
      arrivalDate = new Date(trip.segments.entries[trip.segments.entries.length - 1].arrival.airportDateTimeString.split('T').shift());
      arrivalDate.setFullYear(arrivalDate.getFullYear() - 2);
    });
    return new Date(birthDate) > arrivalDate;
  }
}
