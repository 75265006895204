import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { InternationalDetailsState } from '~app/store/reducers/international details/international-details.reducers';

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getInternationalDetailsState = createSelector(
  getEntityState,
  (state: EntityState) => state.internationalDetails,
);

export const getInternationalDetailsLoading = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.loading,
);

export const getInternationalDetailsError = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.error,
);

export const getInternationalPassportScanSetup = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.passportScan,
);

export const getPassportPaxId = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.passportPaxId,
);

export const getPassportInformation = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.passportInformation,
);

export const getInternationalPassengers = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.internationalPassengers,
);

export const getSubmittedContactTracingPaxIds = createSelector(
  getInternationalDetailsState,
  (state: InternationalDetailsState) => state.submittedContactTracingPaxIds,
);

@Injectable()
export class InternationalDetailsSelectors {
  constructor(private store: Store<EntityState>) { }

  internationalDetailsState$ = this.store.select(getInternationalDetailsState);
  internationalDetailsLoading$ = this.store.select(getInternationalDetailsLoading);
  internationalDetailsError$ = this.store.select(getInternationalDetailsError);
  passportScanSetup$ = this.store.select(getInternationalPassportScanSetup);
  passportPaxId$ = this.store.select(getPassportPaxId);
  passportInformation$ = this.store.select(getPassportInformation);
  internationalPassengers$ = this.store.select(getInternationalPassengers);
  submittedContactTracingPaxIds$ = this.store.select(getSubmittedContactTracingPaxIds);
}
