import { Injectable } from '@angular/core';
import { BaseService } from '~app/store/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Flight } from '~app/models/flight.model';
import { Response } from '~app/models/response.model';
import { environment } from '~environments/environment';
import { formatDate } from '@angular/common';
import { forkJoin } from 'rxjs';
import { SessionSelectors } from '../session/session.selectors';

export const AIRLINE_CODE_HA = "HA";

export interface IFlightService {
  getFlightsByRoute(origin: string, destination: string, date: Date): Observable<Response<Flight>>;
  getFlightsByNumber(flightNumber: string, date: Date): Observable<Response<Flight>>;
  getFlightsById(id: string): Observable<Response<Flight>>;
  getAllFlights(flights: Flight[]): Observable<Response<Flight>[]>;
}

@Injectable({
  providedIn: 'root'
})

export class FlightService extends BaseService implements IFlightService {
  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors,
  ) {
    super(http, sessionSelectors);
  }

  getFlightsByRoute(origin: string, destination: string, date: Date): Observable<Response<Flight>> {
    const param = `start-date=${formatDate(date, 'yyyy-MM-dd', "en-US")}&origin=${origin}&destination=${destination}`;
    const url = `${environment["exp-web-checkin-v2-api"]}/flights?${param}&include=status`;
    return this.http.get<Response<Flight>>(url);
  }

  getFlightsByNumber(flightNumber: string, date: Date): Observable<Response<Flight>> {
    const param = `flight-number=${flightNumber}&start-date=${formatDate(date, 'yyyy-MM-dd', "en-US")}`;
    const url = `${environment["exp-web-checkin-v2-api"]}/flights?${param}&include=status`;
    return this.http.get<Response<Flight>>(url);
  }

  getFlightsById(id: string): Observable<Response<Flight>> {
    const url = `${environment["exp-web-checkin-v2-api"]}/flights/${id}?include=status`;
    return this.http.get<Response<Flight>>(url);
  }

  getAllFlights(flights: Flight[]): Observable<Response<Flight>[]> {
    const flightSubs = flights.map(flight => {
      const url = `${environment["exp-web-checkin-v2-api"]}/flights/${flight.id}?include=status`;
      return this.http.get<Response<Flight>>(url);
    });
    return forkJoin(flightSubs) as Observable<Response<Flight>[]>;
  }
}
