import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Subscription, Observable } from 'rxjs';
import { fadeAnimation } from '~app/animations/animations';
import { Trip } from '~app/models/trip.model';
import { Segment } from '~app/models/segment.model';
import { Response } from '~app/models/response.model';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { ModalsService } from '~app/modals/modals.service';
import { TripSelectors, SessionDispatchers, SessionSelectors, RouterDispatchers, AnalyticsDispatchers, InternationalDetailsSelectors } from '~app/store';
import { Passenger } from '~app/models/passenger.model';
import { HelperService } from '~app/services/helper.service';
import { HeaderContentService } from '~app/services/header-content.service';
import { ISessionState } from '~app/store/reducers/session/session.reducers';

@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
  animations: [
    fadeAnimation,
  ],
})

@ContentSchema({
  description: 'Passengers',
  name: 'Passengers',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'defaultNavButtons', type: PropertyType.Object },
    { name: 'internationalNavButtons', type: PropertyType.Object },
    { name: 'resultPageHeaderText', type: PropertyType.Text },
  ],
})

export class PassengersComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  passengerCardRendering: ComponentRendering;
  infoLinksRendering: ComponentRendering;

  subs: Subscription = new Subscription();
  tripData$: Observable<Response<Trip>>;
  loading$: Observable<boolean>;
  entries$: Observable<Array<{}>>;
  sessionSelectedSegment$: Observable<Segment>;
  selectedPassengers$: Observable<Passenger[]>;
  sessionData$: Observable<ISessionState>;
  savingPassengersInformation$: Observable<boolean>;
  isInternational$: Observable<boolean>;
  sessionSelectedSegment: Segment;
  tripData: Response<Trip>;
  sessionData: ISessionState;
  showInfantInfoLink = false;
  isInternational: boolean;
  isGroupBooking$: Observable<boolean>;
  isGroupBooking: boolean;
  savingAdultToMinorInfo$: Observable<boolean>;
  internationalDetailsLoading$: Observable<boolean>;
  isDefaultNavButtonDisabled: boolean;
  isFrequentFlyerFormInvalid: boolean[];

  constructor(
    private tripSelectors: TripSelectors,
    private sessionDispatchers: SessionDispatchers,
    private sessionSelectors: SessionSelectors,
    private modalContentsService: ModalContentsService,
    private modalsService: ModalsService,
    private routerDispatchers: RouterDispatchers,
    private helperService: HelperService,
    private headerContentService: HeaderContentService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
  ) {
    this.tripData$ = this.tripSelectors.trip$;
    this.loading$ = this.tripSelectors.tripLoading$;
    this.entries$ = this.tripSelectors.tripEntries$;
    this.sessionSelectedSegment$ = this.sessionSelectors.sessionSelectedSegment$;
    this.selectedPassengers$ = this.sessionSelectors.selectedPassengers$;
    this.sessionData$ = this.sessionSelectors.session$;
    this.savingPassengersInformation$ = this.tripSelectors.tripSavingPassengerInformation$;
    this.isInternational$ = this.tripSelectors.isInternational$;
    this.isGroupBooking$ = this.tripSelectors.isGroupBooking$;
    this.savingAdultToMinorInfo$ = this.tripSelectors.savingAdultToMinorInfo$;
    this.internationalDetailsLoading$ = this.internationalDetailsSelectors.internationalDetailsLoading$;
  }

  ngOnInit() {
    this.passengerCardRendering = (this.rendering.components['passenger-card'] || [{}])[0];
    this.infoLinksRendering = (this.rendering.components['info-links'] || [{}])[0];
    this.setErrorModalContents();

    this.subs.add(this.isGroupBooking$.subscribe(isGroupBooking => this.isGroupBooking = isGroupBooking));
    this.subs.add(this.isInternational$.subscribe(bool => this.isInternational = bool));
    this.subs.add(this.sessionData$.subscribe(sessionData => {
      this.sessionData = sessionData;

      // TODO: Add back when functionality available
      // this.showInfantInfoLink = !this.isInternational && sessionData?.selectedPassengers?.some(pax =>
      //   !pax?.hasInfant && this.isPaxAdult(pax?.dateOfBirth, sessionData?.trip2Checkin?.segments?.entries?.[0]?.departure?.airportDateTimeString)
      // );
      this.showInfantInfoLink = false;
    }));
    this.setTripData();

    const contentItems = this.rendering.components['cart-item-content'];
    if (!!contentItems) {
      const items = contentItems[0].props["cartItems"].map(contentItem => {
        return { code: contentItem.code, title: contentItem.title, icon: contentItem.icon, contentExists: true };
      });
      this.sessionDispatchers.addCartContent(items);
    }
    this.setHeaderContentForGroupBooking();
    this.analyticsDispatchers.passengerPageLoaded();
  }
  
  handleDisableDefaultNavButtonChange(index: number, status: boolean) {
    this.isFrequentFlyerFormInvalid[index] = status;
    this.isDefaultNavButtonDisabled = this.isFrequentFlyerFormInvalid.some(bool => bool === true);
  }

  setTripData(): void {
    this.subs.add(this.tripData$.subscribe(trip => {
      if (trip) {
        this.tripData = trip;
      }
    }));
    this.subs.add(this.entries$.subscribe(entry => {
      if (entry) { this.initForm(); }
    }));
  }

  initForm(): void {
    this.subs.add(this.sessionSelectedSegment$.subscribe(segment => {
      if (segment) { this.sessionSelectedSegment = segment; }
      this.isFrequentFlyerFormInvalid = this.sessionData.passengerIds.map(_ => false);
    }));
  }

  clickInfoLink(link: string): void {
    if (link === 'military') {
      this.modalsService.openMilitaryReferOutModal();
    } else if (link === 'addInfant') {
      this.routerDispatchers.routeToInfant(CHECKIN_ROUTES.ROUTE_PASSENGER.component);
    } else if (link === 'frequentFlyerInfo') {
      this.modalsService.openFAQ(true);
    }
  }

  setHeaderContentForGroupBooking() {
    if (!!this.sessionData && this.isGroupBooking) {
      const destinationCode = this.sessionData.destination;
      if (!!destinationCode) {
        const airportDetails = this.helperService.getAirportDetails(destinationCode);
        if (!!airportDetails && airportDetails.destinationImgUrl !== "") {
          const resultHeaderContent = {
            destinationImgUrl: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)), url('${airportDetails.destinationImgUrl}')`,
            headerText: this.rendering.props['resultPageHeaderText']
          };
          this.headerContentService.setHeaderContent(resultHeaderContent);
        }
      }
    }
  }

  private setErrorModalContents(): void {
    this.modalContentsService.addRendering('concurrency-modal', this.rendering.components['concurrency-modal']);
    this.modalContentsService.addRendering('seat-unavailable-modal', this.rendering.components['seat-unavailable-modal']);
    this.modalContentsService.addRendering('checkin-confirm-modal', this.rendering.components['checkin-confirm-modal']);
    this.modalContentsService.addRendering('bags-price-confirmation-modal', this.rendering.components['bags-price-confirmation-modal']);
    this.modalContentsService.addRendering('passenger-faq', this.rendering.components['passenger-faq']);
    this.modalContentsService.addRendering('remove-infant-modal', this.rendering.components['remove-infant-modal']);
    this.modalContentsService.addRendering(
      'remove-infant-confirmation-modal', this.rendering.components['remove-infant-confirmation-modal']
    );
  }

  // private isPaxAdult(birthDate: string, departureDate: string) {
  //   const departDate = new Date(departureDate);
  //   departDate.setFullYear(departDate.getFullYear() - 15);
  //   return new Date(birthDate) < departDate;
  // }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
