<header *ngIf="!isCheckinFlow">
  <ha-global-header *ngIf="appContext.header" [rendering]="appContext.header"></ha-global-header>
  <ha-member-badge></ha-member-badge>
</header>
<div>
  <header id="mainContentHeader" [style.display]="isCheckinFlow ? 'none' : 'block'" class="mainContentHeader taro-leaf">
    <h1 id="main-top-header"></h1>
  </header>
  <div id="checkinRoute" [ngClass]='isCheckinFlow ? "checkin-flow-margin" : "non-checkin-flow-margin"'>
    <router-outlet #o="outlet"></router-outlet>
  </div>
</div>
<loading-anim></loading-anim>
