<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['information']"></app-checkin-header>
<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(savingLapInfantInfo$ | async)">
    <div id="infant-additional-info-spinner" class="spinner-border" role="status"></div>
</div>
<section class="container background">
    <form [formGroup]="infantFormGroup">
        <div class="container">
            <div class="row">
                <app-ha-input class="col-sm-4 pl-0 pr-0 pr-md-4" inputId="infant-first-name" formControlName="firstName"
                    [label]="rendering.props['infantFirstName']" [placeholder]="rendering.props['infantFirstName']"
                    [control]="infantFormGroup.controls.firstName" autoFocus="true">
                </app-ha-input>
                <app-ha-input class="col-sm-4 pl-0 pr-0 pr-md-4" inputId="infant-middle-name" formControlName="middleName"
                    [label]="rendering.props['infantMiddleName']" [placeholder]="rendering.props['infantMiddleName']"
                    [control]="infantFormGroup.controls.middleName">
                </app-ha-input>
                <app-ha-input class="col-sm-4 pl-0 pr-0 pr-md-4" inputId="infant-last-name" formControlName="lastName"
                    [label]="rendering.props['infantLastName']" [placeholder]="rendering.props['infantLastName']"
                    [control]="infantFormGroup.controls.lastName">
                </app-ha-input>
            </div>
            <div class="row mt-2 mt-md-5">
                <mat-form-field class="col-sm-4 pl-0 pr-0 pr-md-4" floatLabel="never" appearance="standard">
                    <mat-label *haText="rendering.props['gender']"></mat-label>
                    <mat-select id="gender-field" formControlName="gender" disableRipple>
                        <mat-option [value]="gender.value" *ngFor="let gender of rendering?.props['genders']">{{gender.text}}</mat-option>
                    </mat-select>
                    <mat-error>{{'required' | translate}} {{'noMatch' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field class="col-sm-4 pl-0 pr-0 pr-md-4" appearance="standard">
                    <mat-label *haText="rendering.props['dateOfBirth']"></mat-label>
                    <input id="date-of-birth-field" matInput [matDatepicker]="dobDatePicker"
                        formControlName="dateOfBirth" placeholder="{{ rendering.props['dateOfBirth'] }}">
                    <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dobDatePicker startView="multi-year"></mat-datepicker>
                    <mat-error>{{'required' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col pl-0 pt-4">
                    <a *haLink="rendering?.props['privacyNotice']" target="_blank"></a>
                </div>
            </div>
        </div>
    </form>
</section>
<app-checkin-footer [navButtons]="rendering?.props['navButtons']" [disableDefaultNavButton]="!infantFormGroup.valid" (defaultNavButtonClicked)="addLapInfant()"></app-checkin-footer>
