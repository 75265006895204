<app-checkin-header id="seatmap-desktop-header" class="d-none d-md-block" [hideHeaderInfo]="true"></app-checkin-header>
<app-seatmap-header [navButtons]="rendering?.props['navButtons']" [selectedPaxId]="selectedPax?.id"
                    (selectPax)="onPaxChipClick($event, 0)"></app-seatmap-header>
<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div id="seatmap-spinner" class="spinner-wrapper" [hidden]="!(cartLoading$ | async)">
  <div class="spinner-border" role="status">
  </div>
</div>
<section class="px-0 container">
  <div id="seatmap-ghost" *ngIf="!svg && isHAFlight && !seatMapUnavailable; else seatMap" class="container">
    <div class="seatmapGhost ghostAnimate"></div>
  </div>
  <div class="seatmap-loading spinner-wrapper" [hidden]="!(!svg && isHAFlight)">
    <div id="seatmap-spinner" class="spinner-border" role="status"></div>
    <h6 *haText="rendering.props['loadingSeatmap']"></h6>
  </div>
  <ng-template #seatMap>
    <div *ngIf="seatMapUnavailable; else seatmapAvailable" class="col-md-12 call-out text-center see-agent-description">
      <span *haText="rendering?.props['seatMapErrorDescription']"></span>
    </div>
    <ng-template #seatmapAvailable>
      <div class="container seatmap">
        <div class="row">
          <div class="col-6 d-md-block d-none">
            <div id="seatmap-flight-info" *ngIf="(airports$ | async)">
              <div id="seatmap-flight-number" class="call-out">
                <span *ngIf="!isHAFlight; else operatingAirlineHeader">{{ segmentFlight?.marketedBy }} {{ segmentFlight?.marketingFlightNumber ?? segmentFlight?.flightNumber }}</span>
                <ng-template #operatingAirlineHeader>{{ segmentFlight?.operatedBy }} {{ segmentFlight?.operatingFlightNumber }}</ng-template>
              </div>
              <div class="container p-0">
                <div class="row m-0">
                  <div class="col-auto p-0">
                    <h4 id="seatmap-flight-origin" class="bold medium">
                      {{(airports$ | async)[segmentFlight?.origin]?.airportCityAndCode}}<i class="ha-icon
                      icon-long-arrow-right p-4"></i>
                    </h4>
                  </div>
                  <div class="col-*">
                    <h4 id="seatmap-flight-destination" class="bold medium mb-5">
                      {{(airports$ | async)[segmentFlight?.scheduledDestination]?.airportCityAndCode}}
                    </h4>
                  </div>
                </div>
              </div>
              <div id="seatmap-description" *ngIf="isHAFlight" class="call-out">
                <span *haText="rendering?.props['description']"></span>
              </div>
            </div>
            <div class="chips-container">
              <mat-chip-list class="container mat-chip-list-stacked" [selectable]="isHAFlight"
                             aria-orientation="vertical"
                             aria-label="passenger-chip selection">
                <mat-chip id="seatmap-passenger-{{paxIndex}}" *ngFor="let pax of seatAssignments; index as paxIndex"
                          class="pax-chip" [attr.accesskey]="paxIndex + 1"
                          (click)="onPaxChipClick(pax, paxIndex)"
                          (keydown.enter)="onPaxChipClick(pax, paxIndex)"
                          (keydown.space)="onPaxChipClick(pax, paxIndex)"
                          [ngClass]="{'selected': pax.paxId === selectedPax?.id}" defaultTabIndex="0">
                  <div class="col body-copy-bold medium">
                    {{pax?.paxName?.firstName}}
                    {{pax?.paxName?.lastName.substring(0, 1) + '.' | uppercase}}
                  </div>
                  <div
                    class="col-auto text-right body-copy-bold medium">{{ !!pax?.seatId && !!isHAFlight ? pax.seatId : '--' }}</div>
                </mat-chip>
              </mat-chip-list>
            </div>
            <app-seatmap-legend *ngIf="isHAFlight" [rendering]="seatmapLegendRendering"></app-seatmap-legend>
          </div>
          <div class="col d-flex justify-content-md-end justify-content-center align-items-center">
            <div *ngIf="isHAFlight; else OAFlight" class="scrollable-seatmap w-100">
              <basic-seat-map [svg]="svg" [passengerId]="selectedPax.id"
                              [flightPassengers]="flightPassengers" (emitSeat)="emitSeat($event)"></basic-seat-map>
            </div>
            <ng-template #OAFlight>
              <div class="col-md-12 call-out text-center see-agent-description"
                   *haText="rendering?.props['operatedByDescription']"></div>
            </ng-template>
          </div>
        </div>
        <app-seatmap-legend *ngIf="isHAFlight" [rendering]="seatmapLegendRendering"
                            class="d-block d-md-none"></app-seatmap-legend>
      </div>
    </ng-template>
  </ng-template>
</section>
<app-checkin-footer class="d-none d-md-block" [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
