import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from '../../reducers';
import { Store } from '@ngrx/store';
import { ISessionState } from '../../reducers/session/session.reducers';

const getSessionStateFeature = createFeatureSelector<SessionState>('sessionCache');

export const getSessionState = createSelector(
    getSessionStateFeature,
    (state: SessionState) => state.session
);

export const getSessionCartId = createSelector(
    getSessionState,
    (state: ISessionState) => state.cartId
);

export const getResetHeader = createSelector(
  getSessionState,
  (state: ISessionState) => state.resetHeader
);

export const sessionSelectedSegment = createSelector(
  getSessionState,
  (state: ISessionState) => state.selectedSegment
);

export const sessionSelectedSegmentId = createSelector(
  getSessionState,
  (state: ISessionState) => state.selectedSegmentId
);

export const sessionSelectedSegmentFlights = createSelector(
  getSessionState,
  (state: ISessionState) => state.segmentFlights
);

export const getSessionPassengerIds = createSelector(
    getSessionState,
    (state: ISessionState) => state.passengerIds
);

export const getSessionErrorCode = createSelector(
    getSessionState,
    (state: ISessionState) => state.errorCode
);

export const originallyAssignedSeats = createSelector(
  getSessionState,
  (state: ISessionState) => state.originallyAssignedSeats
);

export const recentlySavedSeats = createSelector(
  getSessionState,
  (state: ISessionState) => state.recentlySavedSeats
);

export const selectedPassengers = createSelector(
  getSessionState,
  (state: ISessionState) => state.selectedPassengers
);

export const getSessionCheckinComplete = createSelector(
    getSessionState,
    (state: ISessionState) => state.checkinComplete
);


export const sessionFullTripData = createSelector(
    getSessionState,
    (state: ISessionState) => state.fullTripData
);

export const sessionResultData = createSelector(
    getSessionState,
    (state: ISessionState) => state.resultData
);

export const sessionCorrelationId = createSelector(
  getSessionState,
  (state: ISessionState) => state.correlationId
);

export const getSessionFirstFlightId = createSelector(
    getSessionState,
    (state: ISessionState) => state.firstFlightId
);

export const getSessionConfirmatioCode = createSelector(
    getSessionState,
    (state: ISessionState) => state.code
);

export const getSessionTripSearch = createSelector(
  getSessionState,
  (state: ISessionState) => state.tripSearch
);

export const getSessionAlreadyCheckedIn = createSelector(
    getSessionState,
    (state: ISessionState) => state.hasAlreadyCheckedIn
);

export const getMobileDevice = createSelector(
    getSessionState,
    (state: ISessionState) => state.isMobile
);

export const getOSDevice = createSelector(
    getSessionState,
    (state: ISessionState) => state.os
);

export const getBrowserDevice = createSelector(
    getSessionState,
    (state: ISessionState) => state.browser
);

export const cartContentItems = createSelector(
  getSessionState,
  (state: ISessionState) => state.cartContent
);

export const getTrip2Checkin = createSelector(
  getSessionState,
  (state: ISessionState) => state.trip2Checkin
);

export const getEliteStandByEligiblePassengers = createSelector(
  getSessionState,
  (state: ISessionState) => state.eliteStandByEligiblePassengers
);

export const getSelectedEliteStandByPassengers = createSelector(
  getSessionState,
  (state: ISessionState) => state.selectedEliteStandByPassengers
);

export const getSessionTripId = createSelector(
  getSessionState,
  (state: ISessionState) => state.tripId
);

export const getAddInfantAdultId = createSelector(
  getSessionState,
  (state: ISessionState) => state.addInfantAdultId
);

@Injectable()
export class SessionSelectors {
  constructor(private store: Store<ISessionState>) { }
  session$ = this.store.select(getSessionState);
  sessionSelectedSegment$ = this.store.select(sessionSelectedSegment);
  sessionSelectedSegmentId$ = this.store.select(sessionSelectedSegmentId);
  sessionSelectedSegmentFlights$ = this.store.select(sessionSelectedSegmentFlights);
  sessionCartId$ = this.store.select(getSessionCartId);
  sessionPassengerIds$ = this.store.select(getSessionPassengerIds);
  sessionErrorCode$ = this.store.select(getSessionErrorCode);
  sessionCheckinComplete$ = this.store.select(getSessionCheckinComplete);
  sessionFullTripData$ = this.store.select(sessionFullTripData);
  sessionResultData$ = this.store.select(sessionResultData);
  sessionCorrelationId$ = this.store.select(sessionCorrelationId);
  sessionFirstFlightId$ = this.store.select(getSessionFirstFlightId);
  sessionConfirmationCode$ = this.store.select(getSessionConfirmatioCode);
  sessionTripSearch$ = this.store.select(getSessionTripSearch);
  originallyAssignedSeats$ = this.store.select(originallyAssignedSeats);
  recentlySavedSeats$ = this.store.select(recentlySavedSeats);
  selectedPassengers$ = this.store.select(selectedPassengers);
  sessionHasAlreadyCheckedIn$ = this.store.select(getSessionAlreadyCheckedIn);
  isMobile$ = this.store.select(getMobileDevice);
  osDevice$ = this.store.select(getOSDevice);
  browserDevice$ = this.store.select(getBrowserDevice);
  cartContent$ = this.store.select(cartContentItems);
  sessionTrip2Checkin$ = this.store.select(getTrip2Checkin);
  resetHeader$ = this.store.select(getResetHeader);
  sessionTripId$ = this.store.select(getSessionTripId);
  addInfantAdultId$ = this.store.select(getAddInfantAdultId);
  eliteStandByEligiblePassengers$ = this.store.select(getEliteStandByEligiblePassengers);
  selectedEliteStandByPassengers$ = this.store.select(getSelectedEliteStandByPassengers);
}
