import { ActionReducerMap } from '@ngrx/store';
import * as fromTrip from './trip/trip.reducers';
import * as fromSession from './session/session.reducers';
import * as fromAircraft from './aircraft-image/aircraft-image.reducers';
import * as fromCart from './cart/cart.reducers';
import * as fromCatalog from './catalog/catalog.reducers';
import * as fromSecurityInfo from './security-info/security-info.reducers';
import * as fromFlight from './flight/flight.reducers';
import * as fromAuthToken from './auth-token/auth-token.reducers';
import * as fromCheckinRouter from './router/checkin-router.reducer';
import * as fromReferenceData from './reference-data/reference-data.reducer';
import * as fromOffersReducer from './offers/offers.reducers';
import * as fromInternationalDetails from './international details/international-details.reducers';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ICheckinRouteSerializerState } from './router/checkin-route-serializer';

export interface EntityState {
  trip: fromTrip.TripState;
  aircraftImage: fromAircraft.AircraftImageState;
  cart: fromCart.ICartState;
  catalog: fromCatalog.CatalogState;
  flight: fromFlight.FlightState;
  securityInfo: fromSecurityInfo.ISecurityInfoState;
  referenceData: fromReferenceData.ReferenceDataState;
  offers: fromOffersReducer.BagOffersState;
  internationalDetails: fromInternationalDetails.InternationalDetailsState;
}

export interface SessionState {
  session: fromSession.ISessionState;
  authToken: fromAuthToken.IAuthTokenState;
}

export interface UiState {
  checkinRouter: fromCheckinRouter.ICheckinRouterState;
  router: RouterReducerState<ICheckinRouteSerializerState>;
}

export const entityReducers: ActionReducerMap<EntityState> = {
  trip: fromTrip.reducer,
  aircraftImage: fromAircraft.reducer,
  flight: fromFlight.reducer,
  cart: fromCart.reducer,
  catalog: fromCatalog.reducer,
  referenceData: fromReferenceData.reducer,
  securityInfo: fromSecurityInfo.reducer,
  offers: fromOffersReducer.reducer,
  internationalDetails: fromInternationalDetails.reducer
};

export const sessionReducers: ActionReducerMap<SessionState> = {
  session: fromSession.reducer,
  authToken: fromAuthToken.reducer
};

export const uiReducers: ActionReducerMap<UiState> = {
  checkinRouter: fromCheckinRouter.reducer,
  router: routerReducer
};
