<div class="mb-2">
    <mat-card style="max-width: fit-content;">
        <div class="container">
            <div class="row mb-5">
                <div class="col-sm">
                    <img id="iphone" src="/media/images/seat-upgrade-app-banner/iphone.png" alt="iPhone">
                </div>
                <div class="col-md-6 text-sm-left text-xs-center my-auto">
                    <h5 *haText="rendering.props['heading']" style="font-weight: bold;"></h5>
                    <p *haText="rendering.props['description']" class="secondary-text call-out"></p>
                    <a *haLink="getOurAppRendering?.props?.appleStoreUrl">
                        <img src="/media/images/logos/apple-store.png" id="apple-store" alt="Apple App Store" style="margin-right: 25px;">
                    </a>
                    <a *haLink="getOurAppRendering?.props?.googlePlayStoreUrl">
                        <img src="/media/images/logos/google-play.png" id="google-play-store" alt="Google Play Store">
                    </a>
                </div>
                <div class="col-sm col-md-2 align-self-center d-none d-sm-block">
                    <img id="qrcode" src="/media/images/seat-upgrade-app-banner/QRCode.png" alt="qr-code">
                    <p *haText="rendering.props['qrDescription']" class="small"></p>
                </div>
            </div>
        </div>
    </mat-card>
</div>
