import { Component, Input } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { AnalyticsDispatchers } from '~app/store';
@Component({
  selector: 'app-bag-advisory-items',
  templateUrl: './bag-advisory-items.component.html',
  styleUrls: ['./bag-advisory-items.component.scss']
})
@ContentSchema({
  name: 'BagAdvisoryItems',
  description: '',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'ok', type: PropertyType.Text },
    { name: 'carryOnAndPersonalItems', type: PropertyType.Object },
    { name: 'checkinItems', type: PropertyType.Object },
    { name: 'specialItems', type: PropertyType.Object },
    { name: 'navButtons', type: PropertyType.Object },
  ]
})
export class BagAdvisoryItemsComponent {
  @Input() rendering: ComponentRendering;
  constructor(
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.analyticsDispatchers.baggageAdvisoryItemsPageLoaded();
  }
}
