import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { CatalogState } from '../../reducers/catalog/catalog.reducers';
import { cartContentItems } from '../session/session.selectors';
import { Collection } from "~app/models/collection.model";

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getCatalogState = createSelector(
    getEntityState,
    (state: EntityState) => state.catalog
);

export const getCatalog = createSelector(
    getCatalogState,
    (state: CatalogState) => state.catalog
);

export const getCatalogLoading = createSelector(
    getCatalogState,
    (state: CatalogState) => state.loading
);

export const getCatalogBags = createSelector(
  getCatalogState,
  (state: CatalogState) => state.bags
);

export const getCatalogSeats = createSelector(
  getCatalogState,
  (state: CatalogState) => state.seats
);

export const catalogError = createSelector(
  getCatalogState,
  (state: CatalogState) => state.error
);

export const getCatalogSortedByProducts = createSelector(
  getCatalogBags,
  cartContentItems,
  (catalog, cartContent) => catalog && catalog.entries.map(entry => {
      return <Collection> {
       id: entry.id,
       passengerId: entry.passengerId,
       segmentId: entry.segmentId,
       flightId: entry.flightId,
       products: [...entry.products].sort(item => !!cartContent.
              find(contentItem => contentItem.code === item.productId && contentItem.contentExists) ? -1 : 1)        
      };
  })
);

@Injectable()
export class CatalogSelectors {
    constructor(private store: Store<EntityState>) {}

    catalog$ = this.store.select(getCatalog);
    catalogState$ = this.store.select(getCatalogState);
    catalogLoading$ = this.store.select(getCatalogLoading);
    catalogBags$ = this.store.select(getCatalogBags);
    catalogSeats$ = this.store.select(getCatalogSeats);
    catalogError$ = this.store.select(catalogError);
    getCatalogSortedByProducts$ = this.store.select(getCatalogSortedByProducts);
}
