<section>
    <h2 class="language-buttons">{{ 'Change Language' | translate }}</h2>
    <ul>
        <li *ngFor="let lang of languages">
            <button class="mat-raised-button btn btn-default"
                id="step1-btn-{{lang}}"
                [routerLink]="href(lang)">
                <span>{{ nativeName(lang)}}</span>
            </button></li>
    </ul>
</section>