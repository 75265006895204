<h6 id="military-success-title" class="bold medium" mat-dialog-title>
    <span>{{ title }}</span>
</h6>
<mat-dialog-content id="military-success-content">
    <div class="body-copy">
        <p id="bags-description">{{ description }}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
    <button id="okay-button" mat-button class="btn btn-default" (click)="goToPreviousPage()">
        <span>{{ okay }}</span>
    </button>
</mat-dialog-actions>