<h6 class="bold medium" mat-dialog-title>{{ title }}</h6>
<mat-dialog-content class="dlg-content mat-typography concurrency-modal">
  <div class="body-copy">{{header}}</div>
  <ul class="body-copy-bold medium" *ngIf="!!data.passengerNames && !!data.passengerNames.length">
    <li *ngFor="let passengerName of data.passengerNames">
      {{ passengerName }}
    </li>
  </ul>
  <div class="body-copy">{{ content }}</div>
  <div class="body-copy-bold">{{ secondaryContent }}</div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data.isPaymentInProgress">
  <button id="concurrency-cancel" mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-default">{{btnCancel}}</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="data.isCheckInInProgress">
  <button id="concurrency-cancel" mat-button mat-dialog-close class="btn btn-secondary">{{btnCancel}}</button>
  <button id="concurrency-continue" mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-default">{{btnContinue}}</button>
</mat-dialog-actions>
<mat-dialog-actions align="center" *ngIf="data.isSessionEnded">
  <button id="concurrency-ok" mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-default">{{btnOk}}</button>
</mat-dialog-actions>