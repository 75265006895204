import { Injectable } from "@angular/core";
import { Subscription, Observable } from 'rxjs';
import { TripSelectors } from '~app/store/services/trip/trip.selectors';
import { TripState } from '~app/store/reducers/trip/trip.reducers';
import { Response } from '~app/models/response.model';
import { Trip } from '~app/models/trip.model';
import { SessionSelectors } from '~app/store';
import { ISessionState } from '~app/store/reducers/session/session.reducers';

@Injectable({ providedIn: 'root' })
export class TripDataSource {
  subscriptions = new Subscription();
  tripState$: Observable<TripState>;
  sessionState$: Observable<ISessionState>;

  // data
  tripState: TripState;
  sessionState: ISessionState;
  constructor(
    private tripSelectors: TripSelectors,
    private sessionSelectors: SessionSelectors,
    ) {
    this.tripState$ = this.tripSelectors.tripState$;
    this.sessionState$ = this.sessionSelectors.session$;
    this.subscriptions.add(this.tripState$.subscribe(state => this.tripState = state));
    this.subscriptions.add(this.sessionState$.subscribe(state => this.sessionState = state));
  }

  isOA(): boolean {
    try {
      return this.sessionState?.isOAFlight;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  isInternational(): boolean {
    try {
      return this.tripState.isInternational;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  isGroupBooking(): boolean {
    try {
      return this.tripState.isGroupBooking;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  trip(): Response<Trip> {
    try {
      return this.tripState.trip;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  correlationId(): string {
    try {
      return this.tripState.correlationId;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  numberOfPax(): number {
    try {
      return this.tripState.passengerList.entries.length;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  confirmationCode(): string {
    try {
      return this.tripState.confirmationCode;
    } catch (e) {
      console.error('Logger >> ', e);
      return null;
    }
  }

  channel(): string {
    return 'WEB';
  }

  isPassTraveler(): boolean {
    try {
      return this.tripState.isPassTravel;
    } catch (e) {
      return null;
    }
  }

  isPositiveSpace(): boolean {
    try {
      return this.tripState.isPositiveSpace;
    } catch (e) {
      return null;
    }
  }

  entries(): Array<{}> {
    try {
      return this.tripState.entries;
    } catch (e) {
      return null;
    }
  }
}
