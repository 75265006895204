import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { ContactTracingComponent } from './contact-tracing.component';
import { ContactTracingPassengerCardComponent } from './contact-tracing-passenger-card/contact-tracing-passenger-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsModule } from '~app/modals/modals.module';


@NgModule({
  imports: [
    AppSharedModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatRadioModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule.forChild({
      components: [
        ContactTracingComponent,
        ContactTracingPassengerCardComponent,
      ],
    }),
    ModalsModule,
  ],
    exports: [
      // export Material Design components
      MatButtonModule,
      MatDialogModule,
      MatCardModule,
      MatCheckboxModule,
      MatFormFieldModule,
      MatRadioModule,
      RouterModule,
      TranslateModule,
    ],
    declarations: [
        ContactTracingComponent,
        ContactTracingPassengerCardComponent,
    ],
  })
  export class ContactTracingModule { }
