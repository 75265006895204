<app-checkin-header [heading]="rendering.props?.heading" [description]="rendering.props?.description" [hideHeaderInfo]="loading$ | async"></app-checkin-header>
<div *ngIf="loading$ | async; else residenceInfoPage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #residenceInfoPage>
  <div class="container px-0 px-md-4">
    <mat-card *ngFor="let pax of passengers; let i = index">
      <div class="py-4 py-md-0">
        <div class="col-12 col-md-4 text-left">
          <h6 class="medium mb-0">
            {{ pax?.passengerName?.firstName | titlecase }} {{ pax?.passengerName?.lastName | titlecase }}
          </h6>
        </div>
          <div class="col-12 col-md-4" id="traveler-country-main">
            <app-ha-datalist inputId="resident-country-datalist" 
              list="residence-country"
              [control]="pax.countryOfResidence"
              [label]="rendering.props['countryOfResidenceLabel']" 
              [options]="countriesList"
              [placeholder]="rendering.props['countryOfResidenceLabel']">
            </app-ha-datalist>
          </div>
      </div>
    </mat-card>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props?.navButtons" 
                      (defaultNavButtonClicked)="sendResidenceInformation()"
                      [disableDefaultNavButton]="!isContinueButtonEnabled">
  </app-checkin-footer>    
</ng-template>