import { Component, Input } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType, ResponsiveService } from '@hawaiianair/core';
import { RouterDispatchers } from '~app/store';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';

@Component({
  selector: 'app-seat-tout',
  templateUrl: './seat-tout.component.html',
  styleUrls: ['./seat-tout.component.scss']
})
@ContentSchema({
  name: 'SeatTout',
  description: 'Seat Tout',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'extraComfort', type: PropertyType.Object },
    { name: 'seeAvailableSeats', type: PropertyType.Text}
  ]
})
export class SeatToutComponent {
  @Input() rendering: ComponentRendering;
  @Input() flightId: string;

  constructor(
    public responsiveService: ResponsiveService,
    private routerDispatchers: RouterDispatchers
  ) { }

  routeToSeatmap() {
    this.routerDispatchers.routeToSeatMapById(CHECKIN_ROUTES.ROUTE_SEAT.component, {flightId: this.flightId});
  }
}
