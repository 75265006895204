import { Component, Input } from "@angular/core";
import {
  ComponentRendering,
  ContentSchema,
  PropertyType,
  CurrentLocaleService
} from "@hawaiianair/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "web-footer",
  templateUrl: "./web-footer.component.html",
  styleUrls: ["./web-footer.component.scss"]
})
@ContentSchema({
  name: 'WebFooter',
  description: "Web Footer Component",
  props: [
    { name: "support", type: PropertyType.Text },
    { name: "contactUsLink", type: PropertyType.Hyperlink },
    { name: "helpCenterLink", type: PropertyType.Hyperlink },
    { name: "guestWithDisabilitiesLink", type: PropertyType.Hyperlink },
    { name: "legalLink", type: PropertyType.Hyperlink },
    { name: "termsOfUseLink", type: PropertyType.Hyperlink },
    { name: "allFeesLink", type: PropertyType.Hyperlink },
    { name: "teamKokuaLink", type: PropertyType.Hyperlink },
    { name: "aboutUs", type: PropertyType.Text },
    { name: "contractOfCarriageLink", type: PropertyType.Hyperlink },
    { name: "careersLink", type: PropertyType.Hyperlink },
    { name: "investorRelationsLink", type: PropertyType.Hyperlink },
    { name: "cargoLink", type: PropertyType.Hyperlink },
    { name: "fareDealsLink", type: PropertyType.Hyperlink },
    { name: "customerServicePlanLink", type: PropertyType.Hyperlink },
    { name: "tarmacDelayPlanLink", type: PropertyType.Hyperlink },
    { name: "privacyLink", type: PropertyType.Hyperlink },
    { name: "shopOurStoreLink", type: PropertyType.Hyperlink },
    { name: "copyright", type: PropertyType.RichText },
    { name: "socialLinks", type: PropertyType.Object },
    { name: "bagFeesLink", type: PropertyType.Hyperlink },
    { name: "giftCardLink", type: PropertyType.Hyperlink },
    { name: "damageReliefLink", type: PropertyType.Hyperlink },
    { name: "estaLink", type: PropertyType.Hyperlink },
    { name: "travelAgentsLink", type: PropertyType.Hyperlink },
    { name: "fareRulesLink", type: PropertyType.Hyperlink },
    { name: "fuelSurchargeLink", type: PropertyType.Hyperlink },
  ]
})
export class WebFooterComponent {
  @Input() rendering: ComponentRendering;
  creditCardSupportedCountries: string[] = new Array();

  constructor(private currentLocale: CurrentLocaleService) {
    this.creditCardSupportedCountries.push("us");
  }

  public isCreditCardOfferAvailable(): boolean {
    const localeLanguage = this.currentLocale.locale.split('-')[1];
    if ( this.creditCardSupportedCountries.find(language => language === localeLanguage)) {
      return true;
    }
    return false;
  }

  get year() {
    return new Date().getFullYear();
  }
}
