<mat-card class="contact-tracing-passenger-card-container container align-content-center">
  <div class="row align-items-center">
    <span class="col-md d-flex align-items-center">
      <i class="ha-icon icon-check-circle success-green icon-24" *ngIf="contactTracingPassenger?.isCompleted"></i>
      <h6 class="medium m-0">
        {{ contactTracingPassenger?.passenger?.firstName | titlecase }} {{ contactTracingPassenger?.passenger?.lastName | titlecase }}
      </h6>
    </span>
    <span class="col-md-auto">
      <button *ngIf="!isATTS" (click)="buildContactTracingForm()" class="btn btn-default foot-note-bold add-edit-button">
        {{ (contactTracingPassenger?.isCompleted ? 'edit' : 'Add') | translate }}
      </button>
    </span>
  </div>
</mat-card>
<ha-modal #contactTracingModal size="small" [disableClose]="true" panelType="fullScreen">
  <div ha-modal-title *haText="passengerFullName | titlecase" class="text-center"></div>
  <div class="contact-tracing-form">
    <div class="body-copy" *haText="rendering?.props?.enterContactDetails"></div>
    <form [formGroup]="contactTracingForm" *ngIf="contactTracingForm" class="contact-tracing-form__content">
      <div class="contact-tracing-form__section">
        <div class="body-text-medium" *haText="rendering?.props?.email"></div>
        <app-ha-input
          [control]="contactTracingForm?.get('email')"
          [placeholder]="rendering?.props?.emailAddress"
          [label]="rendering?.props?.emailAddress"
          class="contact-tracing-form__input"
        ></app-ha-input>
      </div>
      <div class="contact-tracing-form__section">
        <div class="body-text-medium" *haText="rendering?.props?.phoneNumber"></div>
        <div class="contact-tracing-form__section-fields">
          <mat-form-field appearance="outline" class="body-copy">
            <mat-label *haText="rendering.props?.countryCode"></mat-label>
            <mat-select id="primary-country-code" class="body-copy" [formControl]="contactTracingForm?.get('primaryPhoneNumber.countryCode')"
                        disableRipple>
              <mat-option class="body-copy" [value]="country?.country2LetterCode" *ngFor="let country of countryList">
                +{{country?.countryPhonePrefix}} {{ country?.displayName }}</mat-option>
            </mat-select>
            <mat-error>{{'required' | translate}} {{'noMatch' | translate}}</mat-error>
          </mat-form-field>
          <app-ha-input inputId="phoneNumber"
                        [control]="contactTracingForm?.get('primaryPhoneNumber.number')"
                        [placeholder]="rendering?.props?.phoneNumber"
                        [label]="rendering?.props?.phoneNumber"
                        class="contact-tracing-form__input"
          >
          </app-ha-input>
        </div>
      </div>
      <div class="contact-tracing-form__section">
        <div class="body-text-medium" *haText="rendering?.props?.secondaryEmergency"></div>
        <div>
          <app-ha-input inputId="fullName" [control]="contactTracingForm?.get('emergencyContact.name')"
                        [label]="rendering?.props?.fullName"
                        [placeholder]="rendering?.props?.fullName"
                        class="contact-tracing-form__input"
          ></app-ha-input>
          <div class="contact-tracing-form__section-fields body-copy">
            <mat-form-field appearance="outline" class="body-copy">
              <mat-label *haText="rendering.props?.countryCode"></mat-label>
              <mat-select id="emergency-country-code" class="body-copy" [formControl]="contactTracingForm?.get('emergencyContact.phoneNumber').get('countryCode')"
                          disableRipple>
                <mat-option class="body-copy" [value]="country?.country2LetterCode" *ngFor="let country of countryList">
                  +{{country?.countryPhonePrefix}} {{ country?.displayName }}</mat-option>
              </mat-select>
              <mat-error *ngIf="contactTracingForm?.get('emergencyContact.phoneNumber')?.get('countryCode').errors?.required">{{'required' | translate}}</mat-error>
            </mat-form-field>
            <app-ha-input inputId="emergencyContactPhoneNumber"
                          [control]="contactTracingForm?.get('emergencyContact.phoneNumber').get('number')"
                          [placeholder]="rendering?.props.phoneNumber"
                          [label]="rendering?.props.phoneNumber"
                          class="contact-tracing-form__input"
            ></app-ha-input>
          </div>
        </div>
      </div>
      <div class="contact-tracing-form__section">
        <div class="body-text-medium" *haText="rendering?.props?.address"></div>
        <div>
          <mat-radio-group class="contact-tracing-form__transit" [formControl]="contactTracingForm?.get('inTransit')">
             <mat-radio-button id="address-opt-1"
                               class="body-copy mat-radio-button-margin mat-radio-button-transit"
                [value]="false" disableRipple>
               {{ rendering?.props?.addressOption1 }}
             </mat-radio-button>
            <mat-radio-button id="address-opt-2" class="body-copy mat-radio-button-margin"
                              [value]="true" disableRipple>
              {{ rendering?.props?.addressOption2 }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div *ngIf="!contactTracingForm?.get('inTransit')?.value">
          <div class="contact-tracing-form__section-fields">
            <app-ha-input inputId="address"
                          [control]="contactTracingForm?.get('destinationAddress.addressLine1')"
                          [placeholder]="rendering?.props?.address"
                          [label]="rendering?.props?.address"
                          class="contact-tracing-form__input"
            ></app-ha-input>
            <app-ha-input inputId="city"
                          [control]="contactTracingForm?.get('destinationAddress.cityName')"
                          [placeholder]="rendering?.props?.city"
                          [label]="rendering?.props?.city"
                          class="contact-tracing-form__input"
            ></app-ha-input>
          </div>
          <div class="contact-tracing-form__section-fields" *ngIf="!contactTracingForm?.get('inTransit')?.value">
            <mat-form-field appearance="outline" class="body-copy">
              <mat-label *haText="rendering.props?.state"></mat-label>
              <mat-select id="destination-state" class="body-copy" [formControl]="contactTracingForm?.get('destinationAddress.stateCode')"
                          disableRipple>
                <mat-option class="body-copy" [value]="states?.value" *ngFor="let states of rendering?.props?.states?.states">
                  {{ states?.text }}</mat-option>
              </mat-select>
              <mat-error>{{'required' | translate}} {{'noMatch' | translate}}</mat-error>
            </mat-form-field>
            <app-ha-input inputId="zipcode" class="contact-tracing-form__input"
                          [control]="contactTracingForm?.get('destinationAddress.zipCode')"
                          [placeholder]="rendering?.props?.zipCode"
                          [label]="rendering?.props?.zipCode"
            ></app-ha-input>
          </div>
        </div>
      </div>
      <div class="contact-tracing-form__section">
        <div class="body-text-medium" *haText="rendering?.props?.confirmationHeader"></div>
        <mat-checkbox class="contact-tracing-form__checkbox" (change)="isAcknowledge = !isAcknowledge" [checked]="isAcknowledge">
          <div class="body-copy text-wrap" *haText="rendering?.props?.confirmationOption1"></div>
        </mat-checkbox>
        <mat-checkbox class="contact-tracing-form__checkbox" (change)="isCompletedConfirmed = !isCompletedConfirmed" [checked]="isCompletedConfirmed">
          <div class="body-copy text-wrap" *haText="rendering?.props?.confirmationOption2"></div>
        </mat-checkbox>
      </div>
      <div class="contact-tracing-form__section" *ngIf="showSaveAllPaxOption">
        <div class="body-text-medium" *haText="rendering?.props?.useInformationForAllGuests"></div>
        <mat-checkbox class="contact-tracing-form__checkbox" (change)="isSameContactInformationSelected = !isSameContactInformationSelected" [checked]="isSameContactInformationSelected">
          <div class="body-copy text-wrap" *haText="rendering?.props?.sameInformationForAllGuestsAcknowledgement"></div>
        </mat-checkbox>
      </div>
      <div ha-modal-actions class="contact-tracing-form__footer">
        <ha-button [text]="'Cancel' | translate" buttonStyle="stroked" (click)="cancelContactTracingModal()"></ha-button>
        <ha-button [text]="'Save' | translate" [disabled]="!contactTracingForm.valid || !isAcknowledge || !isCompletedConfirmed"
                   (click)="onContactTracingSubmit(contactTracingPassenger)"
                   buttonType="primary"
        ></ha-button>
      </div>
    </form>
  </div>
</ha-modal>

