import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ModalContentsService } from '../modal-contents.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { CartDispatchers } from '../../store/services/cart/cart.dispatchers';
import { Observable, Subscription } from 'rxjs';
import { CartSelectors } from '../../store/services/cart/cart.selectors';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { SessionSelectors } from '~app/store/services/session/session.selectors';
import { SessionData } from '~app/models/sessiondata.model';
import { Flight } from '~app/models/flight.model';
import { Segment } from '~app/models/segment.model';

export interface BagPriceConfirmationModalData {
  totalBagFees: number;
}

@Component({
  selector: 'app-bags-price-confirmation-modal',
  templateUrl: './bags-price-confirmation-modal.component.html',
  styleUrls: ['./bags-price-confirmation-modal.component.scss']
})
@ContentSchema({
  description: 'Bags Price Confirmation Modal Component',
  name: 'BagsPriceConfirmationModal',
  props: [
    { name: 'bagsPricesDetailsTitle', type: PropertyType.Text },
    { name: 'totalBagFeesHeader', type: PropertyType.Text },
    { name: 'bagsDisclaimer', type: PropertyType.Text },
    { name: 'btnContinue', type: PropertyType.Text },
    { name: 'btnUndo', type: PropertyType.Text }
  ]
})

export class BagsPriceConfirmationModalComponent implements OnInit, OnDestroy {
  rendering: ComponentRendering;
  subscriptions = new Subscription();
  bagsTotal: number;
  bagsTotal$: Observable<number>;
  baggageRoute = CHECKIN_ROUTES.ROUTE_BAGGAGE.route;
  sessionState$: Observable<SessionData>;
  segmentFlights$: Observable<Flight[]>;
  selectedSegment$: Observable<Segment>;

  constructor(
    public dialogRef: MatDialogRef<BagsPriceConfirmationModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private cartDispatchers: CartDispatchers,
    private cartSelectors: CartSelectors,
    private sessionSelectors: SessionSelectors,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.bagsTotal$ = this.cartSelectors.bagsTotalCost$;
    this.sessionState$ = this.sessionSelectors.session$;
    this.segmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.selectedSegment$ = this.sessionSelectors.sessionSelectedSegment$;
  }

  confirmBagsPrices() {
    this.cartDispatchers.confirmBagsPrice();
    this.dialogRef.close(true);
  }

  cancelBagsPriceConfirmation() {
    this.dialogRef.close(true);
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('bags-price-confirmation-modal');
    this.subscriptions.add(this.bagsTotal$.subscribe(total => this.bagsTotal = total));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
