<app-checkin-header [heading]="rendering.props?.heading" [description]="rendering.props?.description" [hideHeaderInfo]="loading$ | async"></app-checkin-header>
<div *ngIf="loading$ | async; else passportPage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #passportPage>
  <div class="container px-0 px-md-4">
    <mat-card *ngFor="let pax of passengers; index as paxId">
      <div class="container py-4 py-md-0">
        <div class="row align-items-center">
          <div id="passenger-info-{{paxId}}" class="col-12 col-md text-left">
            <h6 id="passenger-card-name" class="medium mb-0">
              {{ pax?.passengerName?.firstName | titlecase }} {{ pax?.passengerName?.lastName | titlecase }}
            </h6>
            <div *ngIf="!pax?.isPassportScanned" class="row d-none d-md-block">
              <div id="passenger-info-{{paxId}}-disclaimer" class="col-12 col-lg-7 foot-note secondary-text"
                *haText="rendering?.props?.scanPassportDisclaimer">
              </div>
            </div>
          </div>
          <div class="col-auto ml-md-auto align-self-center">
            <div *ngIf="pax?.isPassportScanned; else scanPassportButton" class="pt-4 pt-md-0 scan-passport-complete">
              <i class="ha-icon icon-24 icon-check-circle success-green"></i>
              <span *haText="rendering.props?.scanPassportComplete" class="call-out"></span>
            </div>
            <ng-template #scanPassportButton>
              <button id="scan-passport-button" class="btn btn-default body-copy-bold medium scan-passport-btn"
                *haText="rendering.props?.scanPassport" (click)="scanPassport(pax?.id)">
              </button>
              <div id="passenger-info-{{paxId}}-disclaimer" class="d-block d-md-none foot-note secondary-text text-left" *haText="rendering?.props?.scanPassportDisclaimer"></div>
            </ng-template>
          </div>
        </div>
        <app-manual-passport-form *ngIf="isManualPassportFormEnabled" [passenger]="pax" [rendering]="rendering.components?.['manual-passport-form']?.[0]"></app-manual-passport-form>
      </div>
    </mat-card>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props?.navButtons" [disableDefaultNavButton]="!isSaveButtonEnabled"></app-checkin-footer>
</ng-template>
