import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as CatalogAction from '../../actions';
import { EntityState } from '../../reducers';

@Injectable()
export class CatalogDispatchers {
  constructor(private store: Store<EntityState>) { }

  getCatalogItinerary() {
    this.store.dispatch(CatalogAction.itineraryGetCatalog());
  }

  getCatalogSeats() {
    this.store.dispatch(CatalogAction.seatsGetCatalog());
  }

  getCatalogBags() {
    this.store.dispatch(CatalogAction.bagsGetCatalog());
  }

  getCatalogDashboard() {
    this.store.dispatch(CatalogAction.dashboardGetCatalog());
  }
}

