import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalContentsService } from '../modal-contents.service';
import { AuthTokenDispatchers } from '../../store/services/auth-token/auth-token.dispatchers';
import { MatDialogRef } from '@angular/material/dialog';
import { SessionSelectors } from '../../store/services/session/session.selectors';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { SessionData } from '~app/models/sessiondata.model';
import { Observable, Subscription } from 'rxjs';
import { AuthTokenSelectors } from '../../store/services/auth-token/auth-token.selectors';
import { RouterDispatchers } from '../../store/services/router/router.dispatchers';
import { TripSearch } from '~app/models/trip-search.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-auth-token-expiration-modal',
  templateUrl: './auth-token-expiration-modal.component.html',
  styleUrls: ['./auth-token-expiration-modal.component.scss']
})
@ContentSchema({
  description: 'Auth Token Expiration Modal Component',
  name: 'AuthTokenExpirationModal',
  props: [
    { name: 'SessionEndedTitle', type: PropertyType.Text },
    { name: 'SessionEndedHeader', type: PropertyType.Text },
    { name: 'AuthTokenSessionEndedHeader', type: PropertyType.Text },
    { name: 'SessionAboutToExpireTitle', type: PropertyType.Text },
    { name: 'ContinueSessionHeader', type: PropertyType.Text },
    { name: 'btnOk', type: PropertyType.Text },
    { name: 'btnCancel', type: PropertyType.Text },
    { name: 'btnContinue', type: PropertyType.Text },
    { name: 'btnYes', type: PropertyType.Text },
    { name: 'btnNo', type: PropertyType.Text }
  ]
})
export class AuthTokenExpirationModalComponent implements OnInit, OnDestroy {
  rendering: ComponentRendering;
  title: string;
  header: string;
  btnOk: string;
  btnCancel: string;
  btnContinue: string;
  btnYes: string;
  btnNo: string;
  sessionData: SessionData;
  sessionData$: Observable<SessionData>;
  sessionTripSearch$: Observable<TripSearch>;
  sessionSubs: Subscription;
  authLoading$: Observable<boolean>;
  authTokenSubs: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<AuthTokenExpirationModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private authTokenDispatcher: AuthTokenDispatchers,
    private authTokenSelectors: AuthTokenSelectors,
    private sessionSelectors: SessionSelectors,
    private routerDispatchers: RouterDispatchers
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.sessionTripSearch$ = this.sessionSelectors.sessionTripSearch$;
    this.authLoading$ = this.authTokenSelectors.authTokenLoading$;
  }

  getNewAuthToken() {
    let tripSearch: TripSearch;
    this.sessionTripSearch$.pipe(take(1)).subscribe(search => tripSearch = search);
    this.authTokenDispatcher.getAuthToken(tripSearch, 'Auth Token Expiration Modal');
    this.authTokenSubs.add(this.authLoading$.subscribe(loading => {
      if (!loading) {
        this.dialogRef.close(true);
      }
    }));
  }

  endSession() {
    this.routerDispatchers.routeToCheckin('Auth Token Expiration Modal');
  }

  ngOnInit() {
    this.sessionSubs = this.sessionData$.subscribe(sessionData => this.sessionData = sessionData);

    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('auth-token-expiration-modal');

    this.btnOk = this.rendering.props['btnOk'];
    this.btnCancel = this.rendering.props['btnCancel'];
    this.btnContinue = this.rendering.props['btnContinue'];
    this.btnYes = this.rendering.props['btnYes'];
    this.btnNo = this.rendering.props['btnNo'];
    this.title = this.rendering.props['SessionEndedTitle'];
    this.header = this.rendering.props['AuthTokenSessionEndedHeader'];
  }

  ngOnDestroy() {
    this.authTokenSubs.unsubscribe();
  }
}
