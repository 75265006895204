<mat-card>
    <div class="p-4">
        <h4 class="subtitle-bold ml-lg-1 ml-md-4 mb-3" *haText="rendering.props['lookup']"></h4>
        <form [formGroup]="adultSearchForm">
            <div class="container px-0 mb-4">
                <div class="row">
                    <app-ha-input
                    class="col-lg-6 col-xs-12" 
                    formControlName="lastName"
                    inputId="lastName"
                    [label]="rendering.props['lastName']"
                    [placeholder]="rendering.props['lastName']"
                    [control]="adultSearchForm.get('lastName')"></app-ha-input>
                    <app-ha-input
                    class="col-lg-6 col-xs-12" 
                    formControlName="confirmationCode"
                    inputId="confirmationCode"
                    [label]="rendering.props['confirmationCode']"
                    [placeholder]="rendering.props['confirmationCode']"
                    [control]="adultSearchForm.get('confirmationCode')" maxlength="6">
                    </app-ha-input>
                </div>
            </div>
        </form>
    </div>
</mat-card>