<app-checkin-header [heading]="rendering?.props?.heading" [description]="rendering?.props?.description">
</app-checkin-header>
<div class="contact-tracing container px-0 px-md-4">
  <p class="subtitle pt-4 pt-md-0" *haText="rendering?.props?.cdcMessage"></p>
  <app-contact-tracing-passenger-card class="p-0" *ngFor="let pax of contactTracingPassengers; let isFirst = first;"
    [rendering]="contactTracingPassengerCardRendering" 
    [contactTracingPassenger]="pax"
    [showSaveAllPaxOption]="isFirst && contactTracingPassengers?.length > 1"
    (saveAllPax)="onSaveAllPaxClick()"></app-contact-tracing-passenger-card>
  <a tabindex="0" (click)="actStatementModal.openDialog()" (keydown.enter)="actStatementModal.openDialog()" *haText="rendering?.props?.actStatementModal?.title">
  </a>
  <a tabindex="0" (click)="burdenStatementModal.openDialog()" (keydown.enter)="burdenStatementModal.openDialog()"
    *haText="burdenStatementModalContent?.title">
  </a>
  <div class="foot-note" *haRichText="rendering?.props?.ombControl"></div>
  <ha-modal #actStatementModal size="small" [disableClose]="true" panelType="fullScreen"
    [title]="rendering?.props?.actStatementModal?.title" [secondaryButtonLabel]="'Close' | translate"
    (secondaryButtonClick)="actStatementModal.closeDialog()">
    <div class="body-copy" *haText="rendering?.props?.actStatementModal?.content"></div>
  </ha-modal>
  <ha-modal #burdenStatementModal size="small" [disableClose]="true" panelType="bottomSheet"
    [title]="burdenStatementModalContent?.title" [secondaryButtonLabel]="'Close' | translate"
    (secondaryButtonClick)="burdenStatementModal.closeDialog()">
    <div class="body-copy" *haText="burdenStatementModalContent?.content"></div>
  </ha-modal>
</div>
<app-checkin-footer [disableDefaultNavButton]="!isAllPaxCompleted" [navButtons]="rendering?.props?.navButtons"></app-checkin-footer>
