import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { FlightDetail } from '~app/models/flightdetail.model';
import { PassengerName } from '~app/models/passengername.model';
import { SessionDispatchers, SessionSelectors, TripSelectors } from '~app/store';

export interface EliteStandByPassenger {
  checked?: boolean;
  externalId: string;
  passengerName: PassengerName;
  customerLevel: string;
  isCheckedIn?: boolean;
  passengerId: string;
  flightDetails?: FlightDetail[];
}

@Component({
    selector: 'app-seat-passenger-list',
    templateUrl: './seat-upgrade-passenger-list.component.html',
    styleUrls: ['./seat-upgrade-passenger-list.component.scss']
})
@ContentSchema({
    description: 'SeatUpgradePassengerListComponent',
    name: 'SeatUpgradePassengerList',
    props: [
        { name: 'heading', type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text },
        { name: 'list', type: PropertyType.Object },
        { name: 'confirmation', type: PropertyType.Text },
        { name: 'navButtons', type: PropertyType.Object },
        { name: 'title', type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text }
    ]
})

export class SeatUpgradePassengerListComponent implements OnInit {
    @Input() rendering: ComponentRendering;
    seatUpgradePassengerCardRendering: ComponentRendering;
    passengerList$: Observable<EliteStandByPassenger[]>;
    subscription = new Subscription();
    eliteStandByPassengerList: EliteStandByPassenger[] = [];
    tripLoading$: Observable<boolean>;

    constructor(
        private sessionSelectors: SessionSelectors,
        private sessionDispatchers: SessionDispatchers,
        private tripSelectors: TripSelectors,
        private modalContentsService: ModalContentsService
    )   {
        this.passengerList$ = this.sessionSelectors.eliteStandByEligiblePassengers$;
        this.tripLoading$ = this.tripSelectors.tripLoading$;
    }

    ngOnInit() {
      this.seatUpgradePassengerCardRendering = (this.rendering.components['SeatUpgradePassengerCard'] || [{}])[0];
      this.modalContentsService.addRendering('seat-upgrade-error-modal', this.rendering.components['seat-upgrade-error-modal']);
    }

    updateEliteStandByPassengerList(cardData: EliteStandByPassenger) {
      if (!!cardData) {
        const isPassengerInList = !!this.eliteStandByPassengerList.find(pax => pax.externalId === cardData.externalId);
        if (cardData.checked && !isPassengerInList) {
          this.eliteStandByPassengerList.push(cardData);
        } else if (!cardData.checked && isPassengerInList) {
          this.eliteStandByPassengerList = this.eliteStandByPassengerList.filter(pax => pax.externalId !== cardData.externalId);
        }
      }
    }

    processEliteStandBySelection(): void {
      this.sessionDispatchers.updateEliteStandByData(this.eliteStandByPassengerList);
    }
}
