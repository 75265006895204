<h6 id="add-guest-error-title" class="bold medium" mat-dialog-title>
    <span>{{ 'Sorry' | translate }}</span>
</h6>
<mat-dialog-content id="generic-error-content" class="body-copy">
    <span *haText="rendering?.props['notFound']"></span>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button id="ok-button" mat-button mat-dialog-close cdkFocusInitial class="btn btn-default">
        <span *haText="rendering.props['tryAgain']"></span>
    </button>
</mat-dialog-actions>
