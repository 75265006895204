import { createAction, props } from '@ngrx/store';
import { BagOffers } from '~app/models/offer.model';

export const createBagOffersAction = (actionType: string) =>
    createAction(actionType, props<{ bagOffers: BagOffers }>());

export const getBagsOffers = createAction(
    '[Bags] Get Bags Offers'
);

export const getBagOffersSuccess = createBagOffersAction (
    '[Bags] Get Bag Offers Success'
);

export const getBagOffersError = createAction (
    '[Bags] Get Bag Offers Error',
    props<{ error: any }>()
);