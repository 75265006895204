<mat-dialog-content class="dlg-content mat-typography" align="left">
  <div *ngIf="showHM" id="hawaiianMiles">
    <span class="call-out-bold" *haText="rendering.props['hawaiianMilesNumber']"></span>
    <div class="body-copy" *haRichText="rendering.props['hawaiianMilesDesc']"></div>
  </div>
  <div id="redressNumber">
    <span class="call-out-bold" *haText="rendering.props['redressNumber']"></span>
    <div class="body-copy" *haRichText="rendering.props['redressDesc']"></div>
  </div>
  <div id="knownTravelerNumber">
    <span class="call-out-bold" *haText="rendering.props['knownTravelerNumber']"></span>
    <div class="body-copy" *haRichText="rendering.props['knownTravelerDesc']"></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions id="passenger-faq-close" align="center">
  <button mat-button mat-dialog-close id="faq-okay-button" class="btn btn-default" cdkFocusInitial>{{ rendering?.props['ok'] }}</button>
</mat-dialog-actions>