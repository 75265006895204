import { ModalContentsService } from '../modal-contents.service';
import { Component, Input, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { TripDispatchers } from '~app/store/services/trip/trip.dispatchers';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TripSelectors } from '~app/store/services/trip/trip.selectors';
import { Observable, Subscription } from 'rxjs';
import { PassengerName } from '~app/models/passengername.model';
import { MilitaryTravelType } from '~app/constants/ha-constants';


export interface PaxData {
  segmentId: string;
  passengerId: string;
  passengerName: PassengerName;
  militaryTravelType: string;
}


@Component({
  selector: 'app-military-save-type',
  templateUrl: './military-save-type.component.html',
  styleUrls: [ './military-save-type.component.scss' ],
})
@ContentSchema({
  description: 'Military Save Type Component',
  name: 'MilitarySaveType',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'validIdDescription', type: PropertyType.Text },
    { name: 'militaryTypeDescription', type: PropertyType.Text },
    { name: 'no', type: PropertyType.Text },
    { name: 'yes', type: PropertyType.Text },

  ]
})
export class MilitarySaveTypeComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  title: string;
  description: string;
  validIdDescription: string;
  ordersTypeDescription: string;
  leisureTypeDescription: string;
  no: string;
  yes: string;
  tripId: string;
  militaryLoading$: Observable<boolean>;
  subscriptions: Subscription;
  paxData: PaxData[];
  militaryTravelType = {
    NONE: MilitaryTravelType.NONE,
    LEISURE: MilitaryTravelType.LEISURE,
    ORDERS: MilitaryTravelType.ORDERS
  };
  constructor(
    public dialogRef: MatDialogRef<MilitarySaveTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalContentsSvc: ModalContentsService,
    private tripDispatchers: TripDispatchers,
    private tripSelectors: TripSelectors,

  ) {
    this.militaryLoading$ = this.tripSelectors.militaryLoading$;
  }

  ngOnInit () {
    this.subscriptions = new Subscription();
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('military-save-type-modal');

    this.title = this.rendering.props['title'];
    this.description = this.rendering.props['description'];
    this.validIdDescription = this.rendering.props['validIdDescription'];
    this.ordersTypeDescription = this.rendering.props['ordersTypeDescription'];
    this.leisureTypeDescription = this.rendering.props['leisureTypeDescription'];
    this.no = this.rendering.props['no'];
    this.yes = this.rendering.props['yes'];
    this.paxData = this.data.passengerData.filter(pax => pax.militaryTravelType);
    // <<< load contents
  }


  clickYes() {
    this.tripDispatchers.updatePassengerMilitaryType(
      this.data.tripId,
      this.paxData,
      this.data.routeTo
      );
      this.subscriptions.add(this.militaryLoading$.subscribe(loading => {
        if (!loading) {
          this.dialogRef.close(true);
        }
      }));
  }
}
