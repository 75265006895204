import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from '../base.service';
import { environment } from '~environments/environment';
import { Response } from '../../../models/response.model';
import { SessionSelectors } from '../session/session.selectors';
import { StationCode } from '~app/models/stationCode.model';
import { Airport } from '~app/models/airport.model';
import { Country } from "~app/models/country.model";

export interface ReferenceDataService {
    getStationCode(cityCode: string): Observable<Response<StationCode>>;
    getAirportsData(airports: string[]): Observable<Response<Airport>>;
    getCountries(): Observable<Response<Country>>;
}

@Injectable({
    providedIn: 'root'
})
export class ReferenceDataService extends BaseService implements ReferenceDataService {

    constructor(
        http: HttpClient,
        sessionSelectors: SessionSelectors
    ) {
        super(http, sessionSelectors);
    }
    
    getStationCode(cityCode: string): Observable<Response<StationCode>> {
        const url = `${environment["exp-web-checkin-v2-api"]}/referenceData/stationCodes?cityCode=${cityCode}`;
        return this.http.get<any>(url);
    }

    getAirportsData(airports: string[]): Observable<Response<Airport>> {
        const airportsStr = airports.join(",");
        const url = `${environment["exp-web-checkin-v2-api"]}/referenceData/airports?codes=${airportsStr}`;
        return this.http.get<any>(url);
    }

    getCountries(): Observable<Response<Country>> {
        const url = `${environment["exp-web-checkin-v2-api"]}/referenceData/country`;
        return this.http.get<Response<Country>>(url);
      }
}
