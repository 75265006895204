import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { RemoveInfantData } from '~app/models/infantpassengerinfo.model';
import { TripDispatchers, TripSelectors } from '~app/store';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-remove-infant-confirmation',
  templateUrl: './remove-infant-confirmation-modal.component.html',
  styleUrls: ['./remove-infant-confirmation-modal.component.scss']
})
@ContentSchema({
  description: 'Remove Infant Confirmation Component',
  name: 'RemoveInfantConfirmation',
  props: [
    { name: 'removeInfantHeading', type: PropertyType.Text },
    { name: 'removeInfantButton', type: PropertyType.Text },
    { name: 'removeInfant', type: PropertyType.Text },
    { name: 'backButton', type: PropertyType.Text }
  ]
})
export class RemoveInfantConfirmationComponent implements OnInit, OnDestroy {
  rendering: ComponentRendering;
  savingLapInfantInfo$: Observable<boolean>;
  subscriptions = new Subscription;

  constructor(
    public dialogRef: MatDialogRef<RemoveInfantConfirmationComponent>,
    private modalContentsSvc: ModalContentsService,
    @Inject(MAT_DIALOG_DATA) public data: RemoveInfantData,
    private tripSelectors: TripSelectors,
    private tripDispatchers: TripDispatchers,
  ) {
    this.savingLapInfantInfo$ = this.tripSelectors.savingLapInfantInfo$;
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('remove-infant-confirmation-modal');
  }

  removeInfant() {
    this.tripDispatchers.removeLapInfant(this.data.tripId, this.data.confirmationCode, this.data.passengerId);
    this.subscriptions.add(this.savingLapInfantInfo$.subscribe(loading => {
      if (!loading) {
        this.dialogRef.close(true);
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
