<div class="footer-container background-white">
  <app-seatmap-nav *ngIf="navButtons?.flights" [navButtons]="navButtons" [browserID]="'desktop'"></app-seatmap-nav>
  <div class="container">
    <div class="row align-items-center" id="button-wrapper">
      <div class="col" *ngIf="navButtons?.fees">
        <div *ngIf="navButtons?.fees" class="row">
          <div class="col d-flex">
            <p class="body-copy-bold medium">{{navButtons?.fees?.text}}</p>
          </div>
          <div class="col d-flex">
            <p id="footer-fees-total" class="body-copy-bold medium">{{totalFees | customCurrency }}</p>
          </div>
        </div>
      </div>
      <div class="col" id="button-col">
        <button id="footer-secondary-button" *ngIf="navButtons?.secondary && !(sortedActions?.length && sortedActions[0]?.route.includes(route))"
          class="btn btn-secondary" [class.btn-secondary-ignore]="!!navButtons?.secondary?.ignoreEsc" mat-button [disabled]="disableButtons" (click)="secondaryNavButtonClick()">
          <div [class.sub-text-btn]="navButtons?.secondary?.subText">
            <span>{{navButtons?.secondary?.text}}</span>
            <span *ngIf="navButtons?.secondary?.subText"
              class="body-copy small">{{navButtons?.secondary?.subText}}</span>
          </div>
        </button>
        <!-- When editing the passenger card and still in focus, the blur event takes precedence over click.
            To allow going to the next route, mousedown event will fire first before blur -->
        <button id="footer-default-button" *ngIf="navButtons?.default" class="btn btn-default" mat-button
          [disabled]="disableDefaultNavButton || disableButtons" (mousedown)="defaultNavButtonClick()"
          (keydown.enter)="defaultNavButtonClick()" (keydown.space)="defaultNavButtonClick()">
          <div *ngIf="(sortedActions?.length && sortedActions[0]?.route.includes(route)); else footerDefaultText">
            <span>{{ 'Continue' | translate }}</span>
          </div>
          <ng-template #footerDefaultText>
            <div [class.sub-text-btn]="navButtons?.default?.subText">
              <span>{{navButtons?.default?.text}}</span>
              <span *ngIf="navButtons?.default?.subText" class="body-copy-bold small">{{navButtons?.default?.subText}}</span>
            </div>
          </ng-template>
        </button>
      </div>
    </div>
  </div>
</div>
