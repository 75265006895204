<h6 id="infant-aged-out-title" class="bold medium" mat-dialog-title>
    <span>{{ 'Sorry' | translate }}</span>
</h6>
<mat-dialog-content id="infant-aged-out-content" class="body-copy pb-3">
    <span *haRichText="this.rendering.props['content']"></span>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button id="infant-aged-out-close" mat-button [mat-dialog-close]="true" class="btn btn-default" accesskey="o">
        <span>{{ 'Ok' | translate }}</span>
    </button>
</mat-dialog-actions>
