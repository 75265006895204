import { Injectable } from '@angular/core';
import { EntityState } from '~app/store';
import { Store } from '@ngrx/store';
import * as InternationalDetailsActions from '~app/store/actions/international-details.actions'
import { PassengerRegulatoryDetails } from '~app/models/passengerRegulatoryDetails.model';
import { PassengerAddress } from '~app/models/passengerAddress.model';
import { EmergencyContactInformation } from '~app/models/emergencyContact.model';
import { ContactTracingDetails } from '~app/models/contactTracingDetails.model';


@Injectable()
export class InternationalDetailsDispatchers {
  constructor(private store: Store<EntityState>) { }

  addPersonalRegulatoryDetails(passengerRegulatoryDetails: PassengerRegulatoryDetails[]) {
    this.store.dispatch(InternationalDetailsActions.addPersonalRegulatoryDetails({ passengerRegulatoryDetails }));
  }

  postAddressInformation(passengerAddress: PassengerAddress[]) {
    this.store.dispatch(InternationalDetailsActions.postAddressInformation({ passengerAddress }));
  }

  postEmergencyContactInformation(emergencyContactInformation: EmergencyContactInformation[]) {
    this.store.dispatch(InternationalDetailsActions.postEmergencyContactInformation({ emergencyContactInformation }));
  }

  postContactTracingInformation(contactTracingDetails: ContactTracingDetails) {
    this.store.dispatch(InternationalDetailsActions.postContactTracingInformation({ contactTracingDetails }));
  }

  postContactTracingInformationForAllPassengers(contactTracingDetails: ContactTracingDetails[]) {
    this.store.dispatch(InternationalDetailsActions.postContactTracingInformationForAllPassengers({ contactTracingDetails }));
  }
}
