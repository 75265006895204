import { PassengerName } from './passengername.model';

export interface SeatType {
  name: string;
  level: number;
}

export const SEAT_TYPES = {
  COACH: { name: 'Coach', level: 10},
  PREFERRED_SEAT: { name: 'Preferred Seat', level: 10},
  EXTRA_COMFORT: { name: 'Extra Comfort', level: 10},
  FIRST_CLASS: { name: 'First Class', level: 20}
};

export interface SeatAssignment {
  paxId: string;
  flightId: string;
  seatId: string;
  seatClass?: string;
  paxName?: PassengerName;
  upgradeFrom?: SeatType;
  upgradeTo?: SeatType;
  isExitRow?: boolean;
  seatCategory?: string;
  cost?: number;
  offerId?: string;
  productId?: string;
  pricedCabin?: string;
  description?: string;
}
