import { createAction, props } from '@ngrx/store';
import { StationCode } from '~app/models/stationCode.model';
import { Response } from '~app/models/response.model';

export const getStationCode = createAction(
  '[Checkin Start] Get Station Code',
  props<{ cityCode: string}>()
);

export const getStationCodeSuccess = createAction(
  '[Checkin Start] Get Station Code Success',
  props<{ stationCode: Response<StationCode>}>()
);

export const getStationCodeError = createAction(
  '[Checkin Start] Get Station Code Error', props<{ error: any }>()
);


