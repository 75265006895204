import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { RouterSelectors, RouterDispatchers, SessionSelectors } from '~app/store';
import { Observable, Subscription } from 'rxjs';
import { CHECKIN_ROUTES, ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import { Flight } from '~app/models/flight.model';
import { FooterButtons } from '../../../shared/checkin-footer/checkin-footer.component';
import { DynamicContentService } from '~app/services/dynamic-content.service';

@Component({
  selector: 'app-seatmap-nav',
  templateUrl: './seatmap-nav.component.html',
  styleUrls: ['./seatmap-nav.component.scss'],
})

export class SeatmapNavComponent implements OnInit, OnDestroy {
  @Input() browserID: string;
  @Input() navButtons: FooterButtons;
  subs: Subscription;
  segmentFlights$: Observable<Flight[]>;
  routerState$: Observable<ICheckinRouteSerializerState>;

  flightCounterString: string;
  previousFlight: Flight;
  nextFlight: Flight;
  flightIndex: number;

  constructor(
    private routerSelectors: RouterSelectors,
    private routerDispatchers: RouterDispatchers,
    private sessionSelectors: SessionSelectors,
    private dynamicContentService: DynamicContentService,
  ) {
    this.segmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.routerState$ = this.routerSelectors.routerState$;
  }

  ngOnInit() {
    this.subs = new Subscription();
    this.subs.add(this.routerState$.subscribe(
      state => {
        if (!!state) {
          this.segmentFlights$.subscribe(flights => {
            if (!!flights) {
              this.flightIndex = flights.findIndex(flight => flight.id === state.queryParams.flightId);
              if (typeof flights[this.flightIndex - 1] !== 'undefined') {
                this.previousFlight = flights[this.flightIndex - 1];
              }
              if (typeof flights[this.flightIndex + 1] !== 'undefined') {
                this.nextFlight = flights[this.flightIndex + 1];
              }
              const flightCounterContent = {
                currentFlightIndex: (this.flightIndex + 1).toString(),
                totalFlights: flights.length
              };
              this.flightCounterString = this.dynamicContentService.getContentString(
                this.navButtons.flights.flightCounterString, flightCounterContent);
            }
          });
        }
      }
    ));
  }

  routeToFlight(flightId: string) {
    this.routerDispatchers.routeToSeatMapById(CHECKIN_ROUTES.ROUTE_SEATMAP.component, { flightId });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
