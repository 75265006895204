import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberMask'
})

export class NumberMaskPipe implements PipeTransform {
  transform(number: string): string {

    const asterick = '*';
    const maskedLength = number.length;
    
    const visibleDigits = 4;

    const visibleSection = number.substring(number.length - visibleDigits, number.length);

    const maskedString = `${asterick.repeat(maskedLength - visibleDigits)}` + visibleSection;

    return maskedString;
  }
}
