import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { HelperService } from '~app/services/helper.service';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Flight } from '~app/models/flight.model';
import { Passenger } from '~app/models/passenger.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { CartSelectors } from '~app/store/services/cart/cart.selectors';
import {
  AircraftImageDispatchers,
  AircraftImageSelectors,
  AIRLINE_CODE_HA,
  AnalyticsDispatchers,
  CatalogSelectors,
  RouterDispatchers,
  SecurityInfoSelectors,
  SessionDispatchers,
  SessionSelectors,
  TripSelectors,
} from '~app/store';
import { Observable, Subscription, take } from 'rxjs';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { Constants, standByPriorityCodes } from '~app/constants/ha-constants';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { FlightDetail } from '~app/models/flightdetail.model';
import { AircraftImageMap, AircraftImageState } from '~app/store/reducers/aircraft-image/aircraft-image.reducers';
import { DOCUMENT } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';

interface PassengerWithSeatAssignment extends Passenger {
  seatAssignment?: string | boolean;
  legId: string;
  seatType: string;
  seatCategoryType: string;
  isAddedToUpgradeList?: boolean;
}

@Component({
  selector: 'app-seats',
  templateUrl: './seats.component.html',
  styleUrls: ['./seats.component.scss'],
})
@ContentSchema({
  description: 'SeatsComponent',
  name: 'Seats',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'toBeAssigned', type: PropertyType.Text },
    { name: 'addedToUpgradeList', type: PropertyType.Text },
    { name: 'viewAndChange', type: PropertyType.Text },
    { name: 'descriptionNonRev', type: PropertyType.Text },
    { name: 'operatedByDescription', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
    { name: 'standbyForSeatUpgrade', type: PropertyType.Text }
  ]
})
export class SeatsComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  selectedSegmentFlights$: Observable<Flight[]>;
  selectedSegmentFlights: Flight[];
  seatUpsellRendering: ComponentRendering;
  seatToutRendering: ComponentRendering;
  sessionData: ISessionState;
  subscriptions: Subscription = new Subscription();
  isLoading = false;
  isNonRev = false;
  seatList: any[] = [];
  codeHA: string;
  sessionData$: Observable<ISessionState>;
  cartLoading$: Observable<boolean>;
  originallyAssignedSeats$: Observable<SeatAssignment[]>;
  assignedSeats$: Observable<SeatAssignment[]>;
  aircraftImageState$: Observable<AircraftImageState>;
  catalogLoading$: Observable<boolean>;
  tripLoading$: Observable<boolean>;
  securityInfoLoading$: Observable<boolean>;
  flights2Checkin: Flight[] = [];
  flightsIsOperatedByHA: any[] = [];
  pax2Checkin: PassengerWithSeatAssignment[] = [];
  seatAssignments: SeatAssignment[] = [];
  showEliteStandby: boolean;
  goldMember = Constants.customerLevelGold;
  platinumMember = Constants.customerLevelPlatinum;
  isAnyMCB = false;
  hostElement: HTMLElement;
  tabletBreakpoint: MediaQueryList;
  window: Window;
  private currentlySelectedFlightIdx = 0;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private aircraftImageSelectors: AircraftImageSelectors,
    private helperService: HelperService,
    private sessionSelectors: SessionSelectors,
    private cartSelectors: CartSelectors,
    private aircraftImageDispatchers: AircraftImageDispatchers,
    private sessionDispatchers: SessionDispatchers,
    private catalogSelectors: CatalogSelectors,
    private routerDispatchers: RouterDispatchers,
    private tripSelectors: TripSelectors,
    private securityInfoSelectors: SecurityInfoSelectors,
    private dynamicContentService: DynamicContentService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private elementRef: ElementRef,
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.originallyAssignedSeats$ = this.sessionSelectors.originallyAssignedSeats$;
    this.assignedSeats$ = this.sessionSelectors.recentlySavedSeats$;
    this.selectedSegmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.aircraftImageState$ = this.aircraftImageSelectors.aircraftImageState$;
    this.catalogLoading$ = this.catalogSelectors.catalogLoading$;
    this.tripLoading$ = this.tripSelectors.tripLoading$;
    this.codeHA = AIRLINE_CODE_HA;
    this.securityInfoLoading$ = this.securityInfoSelectors.securityInfoLoading$;
    this.hostElement = this.elementRef.nativeElement as HTMLElement;
    this.window = this.document.defaultView;
    this.tabletBreakpoint = this.window.matchMedia(`(min-width: ${this.window.getComputedStyle(this.document.documentElement).getPropertyValue('--breakpointTabletMin') ?? '768px'})`);
  }

  public get isTablet(): boolean {
    return this.tabletBreakpoint.matches;
  }

  ngOnInit(): void {
    this.seatUpsellRendering = (this.rendering.components['seat-upsell'] || [{}])[0];
    this.seatToutRendering = (this.rendering.components['seat-tout'] || [{}])[0];
    this.sessionDispatchers.getState();

    this.subscriptions.add(this.sessionData$
      .subscribe(session => {
        if (!!session) {
          this.sessionData = session;
          // TODO: Add back once functionality is added
          //this.showEliteStandby = !!session.eliteStandByEligiblePassengers.length && !session.selectedEliteStandByPassengers.length;
          this.showEliteStandby = false;
          if (!!this.pax2Checkin) {
            this.updatePax2CheckinUpgradeListStatus();
          }
        }
      }));

    this.subscriptions.add(this.originallyAssignedSeats$.subscribe(seats => {
      if (seats) {
        this.seatAssignments = seats;
      }
    }));

    this.subscriptions.add(this.assignedSeats$.subscribe(seats => {
      if (seats) {
        const paxIds2Checkin = this.sessionData.passengerIds;
        const passengerInformation = this.sessionData.trip2Checkin?.passengers?.entries.filter(pax => paxIds2Checkin.includes(pax.id));
        this.seatAssignments = seats;
        if (!!passengerInformation && !!this.flights2Checkin && !!this.seatList && !!this.seatAssignments) {
          this.pax2Checkin = this.helperService.createPassengerFlightInformation(
            passengerInformation,
            this.flights2Checkin,
            this.seatList,
            this.seatAssignments
          );
          this.updatePax2CheckinUpgradeListStatus();
        }
      }
    }));

    this.isAnyMainCabinSeat();

    this.subscriptions.add(this.selectedSegmentFlights$.subscribe(flights => {
      if (flights) {
        this.selectedSegmentFlights = flights;
        this.initSeats();
        this.isAnyMainCabinSeat();
      }
    }));
    this.analyticsDispatchers.seatPageLoaded();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initSeats(): void {
    this.flights2Checkin = [];
    this.pax2Checkin = [];

    if (!this.helperService.getIsPlatformBrowser()) {
      return;
    }

    // isNonRev
    if (this.sessionData.trip2Checkin.isPassTravel && !this.sessionData.trip2Checkin.isPositiveSpace) {
      this.isNonRev = true;
    }

    const flightIds = this.helperService.getFlightIds(this.sessionData.trip2Checkin, this.sessionData.selectedSegmentId);
    this.flights2Checkin = this.selectedSegmentFlights.filter(flight => flightIds.includes(flight.id));

    this.flightsIsOperatedByHA = this.flights2Checkin.map(leg => {
      return {
        operatedByHA: leg.operatedBy === AIRLINE_CODE_HA ? true : false,
        operatedByDescription: this.dynamicContentService.getContentString(
          this.rendering.props['operatedByDescription'],
          { otherAirline: leg.operatedByDescription }
        )
      };
    });

    let aircraftImages: AircraftImageMap[];
    this.aircraftImageState$.pipe(take(1)).subscribe(state => aircraftImages = state.aircraftImages);
    // Get Seat maps for flights
    this.selectedSegmentFlights.forEach((trip, idx) => {
      if (trip.operatedBy === AIRLINE_CODE_HA && !aircraftImages?.[idx]) {
        this.aircraftImageDispatchers.getAircraftImage(idx);
      }
    });

    const paxIds2Checkin = this.sessionData.passengerIds;
    const passengerInformation = this.sessionData.trip2Checkin.passengers.entries.filter(pax => paxIds2Checkin.includes(pax.id));

    // create array of passeneger with Seat Category and Type
    this.subscriptions.add(this.aircraftImageState$.subscribe(aircraftImageState => {
      if (aircraftImageState.aircraftSeatList) {
        this.seatList = aircraftImageState.aircraftSeatList;
        this.pax2Checkin = this.helperService.createPassengerFlightInformation(
          passengerInformation,
          this.flights2Checkin,
          this.seatList,
          this.seatAssignments
        );
        this.updatePax2CheckinUpgradeListStatus();
      }
    }));
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentlySelectedFlightIdx = tabChangeEvent.index;
    this.isAnyMainCabinSeat();
  }

  private isAnyMainCabinSeat(): void {
    this.isAnyMCB = false;
    this.hostElement.style.setProperty('--seats-body-display', 'flex');
    const allSegments = this.sessionData.trip2Checkin.segments.entries;
    const selectedSegmentFlightId = this.selectedSegmentFlights ? this.selectedSegmentFlights[this.currentlySelectedFlightIdx]?.id : '';
    if (selectedSegmentFlightId) {
      allSegments.forEach(segment => {
        segment.details.forEach(pax => {
          pax.flightDetails.forEach(flight => {
            if (!flight.seatCategory && selectedSegmentFlightId === flight.flightId) {
              this.isAnyMCB = true;
              this.hostElement.style.setProperty('--seats-body-display', this.isTablet ? 'grid' : 'flex');
              this.tabletBreakpoint.addEventListener('change', event => {
                this.hostElement.style.setProperty('--seats-body-display', event.matches ? 'grid' : 'flex');
              });
              return;
            }
          });
        });
      });
    }
  }

  public goToSeatmap(flightId: string): void {
    this.routerDispatchers.routeToSeatMapById(CHECKIN_ROUTES.ROUTE_SEAT.component, { flightId });
  }

  updatePax2CheckinUpgradeListStatus() {
    this.pax2Checkin = this.pax2Checkin && this.pax2Checkin.map(pax => {
      const segmentDetail = this.sessionData.selectedSegment?.details?.find(detail => detail.passengerId === pax.id);
      let flightDetail: FlightDetail;
      flightDetail = segmentDetail?.flightDetails?.find(flight => flight?.flightId === pax?.legId);
      return {
        ...pax,
        isAddedToUpgradeList: !!this.sessionData.selectedEliteStandByPassengers.find(passenger => passenger.passengerId === pax.id)
          || flightDetail?.priorityListCode === standByPriorityCodes[Constants.customerLevelPlatinum]
          || flightDetail?.priorityListCode === standByPriorityCodes[Constants.customerLevelGold]
      };
    });
  }

  getTotalSeatPrice(): number {
    return this.seatAssignments.reduce((acc, currItem) => acc + (!!currItem.cost ? Number(currItem.cost) : 0), 0);
  }

  clickStandBySeatUpgrade() {
    this.routerDispatchers.routeToSeatUpgradeList(CHECKIN_ROUTES.ROUTE_SEAT.component);
  }
}
