export enum DocumentType {
  PASSPORT = 'Passport',
  ALIENCARD = 'AlienCard',
  PERMANENTRESIDENTCARD = 'PermantResidentCard',
  FACILITATIONDOC = 'FacilitationDoc',
  MILITARYID = 'MilitaryId',
  NATURALIZATIONDOC = 'NaturalizationDoc',
  REENTRYPERMIT = 'ReEntryPermit',
  BORDERCROSSINGCARD = 'BorderCrossingCard',
  DATEOFBIRTH = 'DateOfBirth',
  NATIONALID = 'NationalId',
  NEXUSCARD = 'NexusCard',
  DIPLOMATICPASSPORT = 'DiplomaticPassport',
  SPECIALPASSPORT = 'SpecialPassport',
  MISSIONSERVICEPASSPORT = 'MissionServicePassport'
}

export enum AddressType {
  DESTINATION = 'Destination',
  RESIDENT = 'Resident'
}

export enum ValidationType {
  TIMATIC = 'Timatic',
  DOCV = 'DOCV',
  DHS = 'DHS',
  GOV = 'GOV',
  APP = 'APP',
  DOCAD = 'DOCA/D'
}

export const iFrameLocaleMap = {
  'ja-jp': 'ja-JP',
  'ko-kr': 'ko-KR',
  'zh-cn': 'zh-CN'
};

export const localeStationCodesMap = {
  'en-us': 'HDQ',
  'ja-jp': 'HND',
  'ko-kr': 'ICN',
  'zh-cn': 'HDQ',
  'en-au': 'AUD',
  'en-nz': 'AKL'
};

export const languageMarketMap = {
  en: 'us',
  ja: 'jp',
  ko: 'kr',
  zh: 'cn',
  'en-au': 'au',
  'en-nz': 'nz'
};

