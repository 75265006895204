import { CartItem } from '../../models/cartitem.model';
import { Response } from '../../models/response.model';
import { Cart } from '~app/models/cart.model';
import { createAction, props } from '@ngrx/store';
import { PaymentItem } from '~app/models/paymentitem.model';
export const ERR_TYPE_CONCURRENCY_PAYMENT_IN_PROGRESS = 'Payment In Progress';
export const ERR_TYPE_CONCURRENCY_CHECKIN_IN_PROGRESS = 'Checkin In Progress';
export const ERR_TYPE_CONCURRENCY_SESSION_ENDED = 'Session Ended';

// Helper Actions
export const getCart = (actionType: string) => (
  createAction(actionType, props<{ cartId: string, route?: string}>())
);
export const removeCart = (actionType: string) => (
  createAction(actionType, props<{cartId: string}>())
);
export const cartApiSuccess = (actionType: string) => (
  createAction(actionType, props<{ cart: Response<Cart>, route?: string }>())
);
export const cartApiError = (actionType: string) => (
  createAction(actionType, props<{ error: any }>())
);
export const getCartWithItems = (actionType: string) => (
  createAction(actionType, props<{cartId: string, cartItems: CartItem[], component: string, catalogId?: string, itemType?: string}>())
);
export const updateCartWithItems = (actionType: string) => (
  createAction(actionType, props<{cartId: string, cart: Response<Cart>, cartItem: CartItem[], catalogId: string, itemType: string}>())
);
export const checkoutRequest = (actionType: string) => (
  createAction
  (actionType, props<{confirmationCode: string, cartId: string, referrer: string, iframeResponse?: any, paymentType?: string}>())
);
export const createCart = (actionType: string) => (
  createAction(actionType, props<{ confirmationCode: string, segmentId: string, passengerIds: string, route?: string }>())
);
export const recreateCart = (actionType: string) => (
  createAction(actionType, props<{ cartId: string, confirmationCode: string, segmentId: string, passengerId: string, route?: string }>())
);
export const updateCart = (actionType: string) => (
  createAction(actionType,  props<{ cartId: string, cartItems: CartItem[] }>())
);
export const checkoutCart = (actionType: string) => (
  createAction(actionType, props<{ confirmationCode: string, cartId: string, paymentItem?: PaymentItem }>())
);
export const checkoutCartFailure = (actionType: string) => (
  createAction(actionType, props<{ error: any, cartId: string }>())
);
export const checkoutCartSuccess = (actionType: string) => (
  createAction(actionType, props<{ response: Response<any>, cart?: Response<Cart> }>())
);
// >>>>> create cart
export const createCartPassengers = createCart(
  '[Passenger Component] Create Cart'
);
export const createCartDashboard = createCart(
  '[Dashboard Component] Create Cart',
);
export const createCartError = cartApiError(
  '[Cart API] Create Cart Error'
);
export const createCartErrPaymentInProgress = cartApiError(
  '[Cart API] Create Cart Error: ' + ERR_TYPE_CONCURRENCY_PAYMENT_IN_PROGRESS
);
export const createCartErrCheckinInProgress = cartApiError(
  '[Cart API] Create Cart Error: ' + ERR_TYPE_CONCURRENCY_CHECKIN_IN_PROGRESS
);
export const createCartErrSessionEnded = cartApiError(
  '[Cart API] Create Cart Error: ' + ERR_TYPE_CONCURRENCY_SESSION_ENDED
);
export const createCartSuccessPassengers = cartApiSuccess(
  '[Passenger Component] Create Cart Success',
);
export const createCartSuccessDashboard = cartApiSuccess(
  '[Dashboard Component] Create Cart Success',
);
export const getCartSuccessPassengers = cartApiSuccess(
  '[Passenger Component] Get Cart Success',
);
export const getCartSuccessDashboard = cartApiSuccess(
  '[Dashboard Component] Get Cart Success',
);

// <<<<< create cart

// >>>>> get cart
export const getCartSeatmapModal = getCart(
  '[Seatmap Modal] Get Cart'
);
export const getCartError = cartApiError(
  '[Cart API] Get Cart Error'
);
export const getCartSuccess = cartApiSuccess(
  '[Cart API] Get Cart Success'
);
// <<<<< get cart

// >>>>> delete cart
export const deleteCart = removeCart(
  '[Cart API] Delete Cart'
);
export const deleteCartError = cartApiError(
  '[Cart API] Delete Cart Error'
);
export const deleteCartSuccess = createAction(
  '[Cart API] Delete Cart Success',
  props<{ res: any }>()
);
// <<<<< delete cart

// >>>>> update cart with items
export const updateCartError = cartApiError(
  '[Cart API] Update Cart Error'
);
export const updateCartSuccess = cartApiSuccess(
  '[Cart API] Update Cart Success'
);
// <<<<< update cart with items

// >>>>> replace all items in the cart
export const replaceAllItems = getCartWithItems(
  '[Seatmap Modal] Replace All Items Request',
);
// <<<<< replace all items in the cart

// >>>>> recreate cart
export const recreateCartPassengers = recreateCart(
  '[Passenger Component] Recreate Cart',
);
export const recreateCartSuccessPassengers = cartApiSuccess(
  '[Passenger Component] Recreate Cart Success'
);
export const recreateCartDashboard = recreateCart(
  '[Dashboard Component] Recreate Cart',
);
export const recreateCartSuccessDashboard = cartApiSuccess(
  '[Dashboard Component] Recreate Cart Success'
);
export const recreateCartError = cartApiError(
  '[Cart Api]  Recreate cart Error'
);
// <<<<< recreate cart

export const getCartBagsComponent = getCart(
  '[Bags Form Component] Get Cart'
);
export const getCartAfterCreate = getCart(
  '[Create Cart Effect] Get Cart'
);
export const refreshCartAfterCreate = getCart(
  '[Create Cart Effect] Refresh Cart'
);
export const getCartWithBags = getCartWithItems(
  '[Bags Form Component] Get Cart With Bags'
);
export const getCartWithSeats = getCartWithItems(
  '[Seatmap Modal] Get Cart With Seats'
);
export const updateCartWithItemsSuccess = cartApiSuccess(
  '[Cart API] Update Cart With Items Success'
);
export const updateCartWithItemsError = cartApiError(
  '[Cart API] Update Cart With Items Error'
);

// seatmap cart update
export const updateCartByRemovingASeatItem = createAction(
  '[Seatmap Modal] Update Cart by Removing A Seat Item',
  props<{ cartId: string, segmentId: string, flightId: string, passengerId: string }>()
);

export const checkoutRequestFromPaymentRoute = createAction(
  '[Payment Route] Checkout Request'
);
export const checkoutRequestProcessed = createAction(
  '[Cart Effect] Checkout Request Processed'
);
// >>>>> checkout

// >>>>> checkout cart
export const checkoutCartFromSeatRoute = checkoutCart(
  '[Seat Route] Checkout Cart',
);
export const checkoutCartFromBagRoute = checkoutCart(
  '[Baggage Route] Checkout Cart',
);
export const checkoutCartFromPaymentRoute = createAction(
  '[Payment Route] Checkout Cart',
  props<{ paymentItem: PaymentItem }>()
);

export const checkoutCartFromPaymentRouteSuccess = checkoutCartSuccess(
  '[Payment Route] Checkout Cart Success',
);
export const checkoutCartFromPaymentRouteFailure = checkoutCartFailure(
  '[Payment Route] Checkout Cart Failure',
);
// <<<<< checkout

export const cartErrorAccepted = createAction(
  '[Error Modal Service] Cart Error Accepted'
);

export const cartUpdateCart = updateCart(
  '[Action]'
);

export const militaryUpdateCart = createAction(
  '[Military Route] Military Update Cart'
);

export const checkIfAllSeatsHasBeenUpgraded = createAction(
  '[Seat-Map] Check to see if all seats have been upgraded'
);

export const openBagsPriceConfirmationModal = createAction(
  '[Bags] Open bags price confirmation modal'
);

export const confirmBagsPrices = createAction(
  '[Bags Price Confirmation Modal] Confirm bags price'
);

// save current cart items, any further changes can be restored using restoreCartItems
export const storeCartItems = createAction(
  'Store Cart Items',
);

export const restoreCartItems = createAction(
  'Restore Cart Items'
);
