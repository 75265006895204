import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering, CurrentLocaleService } from '@hawaiianair/core';
import { CustomCurrencyPipe } from '~app/pipes/custom-currency.pipe';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { AnalyticsDispatchers, RouterDispatchers } from '~app/store';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';

export interface SeatUpsellData {
  imgClass: string;
  imgTitle: string;
  imgCost: string;
  description: string;
}
@Component({
  selector: 'app-seat-upsell',
  templateUrl: './seat-upsell.component.html',
  styleUrls: [ './seat-upsell.component.scss' ],
})
@ContentSchema({
  name: 'SeatUpsell',
  description: 'Seat Upsell',
  props: [
    { name: 'seeAvailableSeats', type: PropertyType.Text },
    { name: 'from', type: PropertyType.Text },
    { name: 'extraComfort', type: PropertyType.Object },
    { name: 'premiumCabin', type: PropertyType.Object }
  ]
})
export class SeatUpsellComponent implements OnInit {
  @Input() flightId: string;
  @Input() rendering: ComponentRendering;
  @Input() item: any;
  currencyCode: string;
  seatCostString: string;

  constructor(
    private routerDispatchers: RouterDispatchers,
    private analyticsDispatchers: AnalyticsDispatchers,
    private localeService: CurrentLocaleService,
    private dynamicContentService: DynamicContentService,
    private customCurrencyPipe: CustomCurrencyPipe
  ) { }

  ngOnInit() {
    this.currencyCode = this.localeService.country === 'cn' ? 'USD' : this.localeService.currency;
    this.seatCostString = this.dynamicContentService.getContentString(this.rendering.props['from'], {
      seatCost: `${this.customCurrencyPipe.transform(this.item && this.item.price)} ${this.currencyCode}`
    });
  }

  routeToSeatmap() {
    this.analyticsDispatchers.seatsUpsellClick(this.item);
    this.routerDispatchers.routeToSeatMapById(CHECKIN_ROUTES.ROUTE_SEAT.component, {flightId: this.flightId});
  }
}
