<mat-card class="mat-elevation-z3">
  <div class="container pt-4 pt-md-0">
    <div class="row mb-3 foot-note secondary-text">
      <div class="col-7 col-md-6">
        <span id="airline-code-segment-{{idx}}" class="mr-1">{{ segment.airlineCode }}</span>
        <span id="airline-number-segment-{{idx}}">{{ segment.flightNumber }}</span>
        <span class="mx-2">-</span>
        <span id="flight-departure-date-segment-{{idx}}">{{ segment?.departure?.airportDateTimeString | date:'mediumDate' }}</span>
        <span class="mx-2">-</span>
        <span id="flight-total-number-segment-{{idx}}" *haText="rendering?.props['flight']"></span>
      </div>
      <div class="col-5 col-md-6">
        <span id="check-in-status-segment-{{idx}}" *haText="segment.areAllPassengersCheckedIn? rendering.props['alreadyCheckedInStatus'] : rendering.props['notCheckedInStatus']"></span>
      </div>
    </div>
    <div class="row mb-5 mb-md-0 justify-content-between">
      <div class="col-8 col-md-3 mb-md-0 foot-note">
        <div class="row">
          <div class="col-4">
            <h6 class="bold" id="airport-code-origin-segment-{{idx}}">{{ segment?.origin }}</h6>
            <span id="city-name-origin-segment-{{idx}}">{{ segment?.departureCity }}</span>
            <p id="departure-time-origin-segment-{{idx}}"
              class="flight-information-time">{{ segment?.departure?.airportDateTimeString | date:'h:mma' }}</p>
          </div>
          <div class="col-4">
            <span><i class="ha-icon icon-long-arrow-right"></i></span>
          </div>
          <div class="col-4 foot-note">
            <h6 id="airport-code-destination-segment-{{idx}}" class="bold">{{ segment?.destination }}</h6>
            <span id="city-name-destination-segment-{{idx}}">{{ segment?.arrivalCity }}</span>
            <p id="arrival-time-destination-segment-{{idx}}"
              class="flight-information-time">{{ segment?.arrival?.airportDateTimeString | date:'h:mma' }}</p>
          </div>
        </div>
      </div>
      <div class="col-md d-flex justify-content-end text-right select-flight-container-mobile">
        <div>
          <div class="d-none d-md-block">
            <button mat-button class="btn btn-default mx-0" [disabled]="!canCheckIn" (click)="selectSegment(segment)">
              <span id="select-flight-button-segment-{{idx}}" *haText="rendering?.props['selectFlight']"></span>
            </button>
          </div>
          <div class="alert-reason-message d-md-flex">
            <span class="foot-note pr-1" *ngIf="!canCheckIn">
              * <span *haRichText="alertMessage" class="alert-message"></span>
            </span>
          </div>
          <div class="d-md-none" *ngIf="canCheckIn">
            <i class="icon-arrow-right icon-angle-right" (click)="selectSegment(segment)"
              (keyup)="selectSegment(segment)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
