import { Action, createReducer, on } from "@ngrx/store";
import { BagOffers } from "~app/models/offer.model";
import * as OffersAction from '../../actions/offers.actions';


export interface BagOffersState {
    bagOffers: BagOffers;
    loading: boolean;
    error: boolean;
}

export const initialState: BagOffersState = {
    bagOffers: null,
    loading: false,
    error: false
};

export const bagOffersReducer = createReducer(
    initialState,
    on(OffersAction.getBagsOffers, state => ({...state, bagOffers: null, loading: true, error: false})),
    on(OffersAction.getBagOffersSuccess, (state, { bagOffers }) => ({
        ...state,
        loading: false,
        bagOffers,
        error: false
    })),
    on(OffersAction.getBagOffersError, state => ({ ...state, bagOffers: null, loading: false, error: true}))
);

export function reducer(state: BagOffersState | undefined, action: Action) {
    return bagOffersReducer(state, action);
}