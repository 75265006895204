import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { Injectable } from "@angular/core";
import { ModalsService } from '~app/modals/modals.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, tap } from 'rxjs';
import { concatMap, catchError, withLatestFrom, switchMap } from 'rxjs/operators';
import { OrderFulfillService } from '~app/store/services/orderFulfill/orderFulfill.service';
import * as OrderFulfillActions from '~app/store/actions/orderFulfill.actions';
import * as AnalyticsActions from '~app/store/actions/analytics.actions';
import * as TripActions from '~app/store/actions/trip.actions';
import { Store, select, Action } from '@ngrx/store';
import { EntityState } from '~app/store/reducers';
import * as fromSession from '../../services/session/session.selectors';
import * as RouterActions from '~app/store/actions/router.actions';
import {
  RANGE_REF_BAG_FULFILLMENT,
  RANGE_REF_BAG_PAYMENT,
  RANGE_REF_PAYMENT_INFO,
  RANGE_REF_SEAT_ASSIGNMENT,
  RANGE_REF_SEAT_PAYMENT } from '~app/models/partialsuccess.model';
import { OrderStatusErrorCodes } from '~app/constants/ha-constants';
import { Response } from '~app/models/response.model';
import { OrderStatus } from '~app/models/orderStatus.model';

@Injectable()
export class OrderFulfillEffects {
  constructor(
    private actions$: Actions,
    private orderFulfillService: OrderFulfillService,
    private modalsService: ModalsService,
    private store: Store<EntityState>,
  ) { }

  orderFulfillCartEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderFulfillActions.orderFulfill),
      withLatestFrom(
        this.store.pipe(select(fromSession.getSessionState))
      ),
      switchMap(([action, state]) =>
        this.orderFulfillService.orderFulfill(state.cartId, action?.paymentInformation).pipe(
          tap(response => {
             if (response.status !== 'Success') {
               throw { error: 'order fulfill failed', response };
             }
          }),
          concatMap((response) => {
            return [
              OrderFulfillActions.orderFulfillSuccess(),
              OrderFulfillActions.getOrderStatus({ orderId: response.results[0].orderId }),
            ];
          }),
          catchError(error => {
            this.modalsService.openGenericErrorMessage(error);
            return of(OrderFulfillActions.orderFulfillFailure({ error }));
          }),
        )
      )
    )
  );
  getOrderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderFulfillActions.getOrderStatus),
      switchMap(action =>
        this.orderFulfillService.getStatus(action.orderId).pipe(
          tap(status => {
            if (status.results[0].orderAttributes?.status !== 'SUCCESS') {
              throw { error: status.results?.[0]?.orderAttributes?.errorDetails?.errorCodes }
            }
          }),
          concatMap(order => [
            AnalyticsActions.trackAEPOrderSuccess(),
            OrderFulfillActions.getOrderStatusSuccess(),
            this.getSeatTransactionPaxIds(order).length
              ? TripActions.paymentCheckin({ passengerIds: this.getSeatTransactionPaxIds(order) }) 
              : RouterActions.routeToResult({ componentName: CHECKIN_ROUTES.ROUTE_PAYMENT.component, data: { callResultGetTrip: true } }),
          ]),
          catchError(error => {
            return this.handleOrderStatusError(error?.error);
          })
        )
      )
    )
  );

  private handleOrderStatusError(errorCodes?: string[]) {
    const actions: Action[] = [OrderFulfillActions.getOrderStatusError({ error: errorCodes })];
    if (errorCodes?.some(errorCode => errorCode in OrderStatusErrorCodes)) {
      let rangeReferences = [];
      errorCodes.forEach((fail) => {
        switch (fail) {
          case OrderStatusErrorCodes.PBFF:
            rangeReferences.push(RANGE_REF_BAG_PAYMENT);
            break;
          case OrderStatusErrorCodes.FBFF:
          case OrderStatusErrorCodes.PCNI:
          case OrderStatusErrorCodes.BFNO:
          case OrderStatusErrorCodes.BFNI:
            rangeReferences.push(RANGE_REF_BAG_FULFILLMENT);
            break;
          case OrderStatusErrorCodes.PSAF:
            rangeReferences.push(RANGE_REF_SEAT_PAYMENT);
            break;
          case OrderStatusErrorCodes.SFF:
          case OrderStatusErrorCodes.FSAF:
            rangeReferences.push(RANGE_REF_SEAT_ASSIGNMENT); 
            break;
          case OrderStatusErrorCodes.FOPF:
            rangeReferences.push(RANGE_REF_PAYMENT_INFO);
            break;
          default:
            break;
        }
      });
      this.modalsService.openPartialSuccessModal([...new Set(rangeReferences)]);
      actions.push(RouterActions.routeToResult({ componentName: CHECKIN_ROUTES.ROUTE_PAYMENT.component, data: { callResultGetTrip: true } }));
    } else {
      this.modalsService.openGenericErrorMessage(errorCodes);
    }
    return actions;
  }

  private getSeatTransactionPaxIds(cart: Response<OrderStatus>): string[] {
    const seats = cart?.results[0]?.order?.cart?.items?.seats;
    const paxIds = seats ? seats.map(seat => seat.associatedPassenger.passengerId) : [];
    return paxIds;
  }

};
