<!-- eslint-disable @angular-eslint/template/no-negated-async  -->
<app-checkin-header [heading]="rendering.props['mainTitleText']" [description]="rendering.props['description']"
  [hideHeaderInfo]="loading$ | async"></app-checkin-header>
<div *ngIf="loading$ | async; else internationalTravelerSelection">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #internationalTravelerSelection>
  <div class="container px-0 px-md-4">
    <mat-card *ngFor="let result of passengerCardInformation; index as passIdx">
      <div class="container">
        <div class="row">
          <div id="passenger-info-{{passIdx}}" class="col text-left">
            <span id="passenger-card-name" class="subtitle-bold">
              {{ result?.firstName | titlecase }} {{ result?.lastName | titlecase }}
            </span>
          </div>
          <div class="col-auto ml-auto align-self-center">
            <div *ngIf="result?.hasTravelDoc; else passengerAdd">
              <i class="ha-icon icon-16 icon-check-circle success-green mr-2"></i>
              <span class="call-out secondary-text" *haText="rendering.props['passengerDone']"></span>
            </div>
            <ng-template #passengerAdd>
                <button id="passenger-card-button-{{passIdx}}" class="btn btn-default pax-card-btn" mat-button
                  *haText="rendering.props['tileAddButton']" (click)="addTravelerInfo(result.id)"></button>
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  
  <app-checkin-footer [navButtons]="rendering?.props['navButtons']"
    [disableDefaultNavButton]="!(hasAllTravelDocs$ | async)"></app-checkin-footer>
</ng-template>