import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentRendering, ContentSchema, PropertyType } from "@hawaiianair/core";
import { ModalContentsService } from "../modal-contents.service";

@Component({
    selector: 'app-minor-checkin-error-modal',
    templateUrl: './minor-checkin-error-modal.component.html',
    styleUrls: [ './minor-checkin-error-modal.component.scss' ]
})
@ContentSchema({
    description: 'Minor Checkin Error Modal Component',
    name: 'MinorCheckinErrorModal',
    props: [
        { name: 'title',  type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text },
        { name: 'itineraryMismatchError', type: PropertyType.Object },
        { name: 'reservationNotFoundError', type: PropertyType.Object },
        { name: 'adultNotCheckedInError', type: PropertyType.Object }
    ]
})
export class MinorCheckinErrorModalComponent implements OnInit {
    rendering: ComponentRendering;

    constructor(
        @Inject(MAT_DIALOG_DATA) public errorType: string,
        private modalContentsSvc: ModalContentsService,
    ) { }

    ngOnInit() {
        this.rendering = {};
        this.rendering.props = this.modalContentsSvc.getRendering('minor-checkin-error-modal');
    }

}
