import { createAction, props } from '@ngrx/store';
import { SessionData } from '~app/models/sessiondata.model';
import { Segment } from '~app/models/segment.model';
import { PassengerList } from '~app/models/passengerlist.model';
import { Flight } from '~app/models/flight.model';
import { Terms } from '../../models/terms.model';
import { Response } from '../../models/response.model';
import { Passenger } from '~app/models/passenger.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { CartContentItems } from '~app/models/cartcontentItems.model';
import { Catalog } from '~app/models/catalog.model';
import { TripSearch } from '~app/models/trip-search.model';
import { EliteStandByPassenger } from '~app/components/seat/seat-upgrade-passenger-list/seat-upgrade-passenger-list.component';

export const createSessionAction = (actionType: string) =>
    createAction(actionType, props<{ session: SessionData }>());

export const createSetSessionItemAction = (actionType: string) =>
    createAction(actionType, props<{ name: any, value: any }>());

export const createSessionErrorAction = (actionType: string) =>
    createAction(actionType, props<{ error: any }>());

export const createNoopAction = (actionType: string) =>
    createAction(actionType);

export const resetHeader = createAction(
    '[Checkin Start] Reset Header Image'
);

export const getSession = createAction(
    '[Checkin Online] Get Session'
);

export const getSessionError = createSessionErrorAction(
    '[Checkin Online] Get Session Error'
);

export const getSessionSuccess = createSessionAction(
    '[Checkin Online] Get Session Success'
);

export const setAirportOriginLongName = createSetSessionItemAction(
    '[Passengers Component] Set Aiport Origin Long Name'
);

export const setAirportDestinationLongName = createSetSessionItemAction(
    '[Passengers Component] Set Aiport Destination Long Name'
);

export const setSessionTripId = createSetSessionItemAction(
    '[Passengers] Set Session Trip ID'
);

export const setSessionTripIdSuccess = createSessionAction(
    '[Passengers] Set Session Trip ID Success'
);

export const setSessionAppBannerClose = createAction(
    '[Checkin Online] Set Session App Banner Close',
    props<{ appBannerClose: boolean }>()
);

export const setSessionTripParams = createAction(
    '[Passengers] Set Session Trip Params',
    props<{ tripSearch: TripSearch, confirmationCode?: string }>()
);

export const updateSessionSelectedSegment = createAction(
  '[Guidelines] Update Session Selected Segment',
  props<{ segment: Segment }>()
);

export const setSessionSelectedSegment = createAction(
    '[Passengers] Set Session Selected Segment',
    props<{ name: any, value: any, errorCode: any }>()
);

export const setSessionSelectedSegmentData = createAction(
  '[Passengers] Set Session Selected Segment Data',
  props<{ name: any, value: any, errorCode: any }>()
);


export const setSessionSelectedSegmentSuccess = createAction(
    '[Passengers] Set Session Selected Segment Success',
    props<{ segment: Segment, segmentFlights: Flight[], errorCode?: string }>()
);

export const setSessionSelectedSegmentError = createAction(
    '[Passengers] Set Session Selected Segment Error',
    props<{ errorCode: string }>()
);

export const setSessionPassengerIds = createSetSessionItemAction(
    '[Passengers] Set Session Passenger IDs'
);

export const addSessionPassengerIds = createAction(
    '[Passengers] Add Session Passenger IDs',
    props<{ name: any, passengerIds: string }>()
);

export const setSessionOriginallyAssignedSeats = createAction(
    '[Passengers] Set Session Originally Assigned Seats',
    props<{ assignedSeats: any }>()
);

export const setSelectedPassengers = createAction(
    '[Passengers] Set Selected Passengers',
    props<{ passengers: Passenger[] }>()
);

export const setMostRecentlySavedSeats = createAction(
    '[Seatmap Component] Set Most Recently Saved Seats',
);

export const setRecentlySavedSeatsSuccess = createAction(
    '[Seatmap Component] Set Recently Saved Seats Success',
    props<{ recentlySavedSeats: SeatAssignment[] }>()
);

export const setSessionTrip2Checkin = createSetSessionItemAction(
    '[Passengers] Set Session Trip2Checkin'
);

export const setSessionAuthToken = createSetSessionItemAction(
    '[Check In Start] Set Session Auth Token'
);

export const getSessionAuthToken = createSetSessionItemAction(
    '[Passengers] Get Session Auth Token'
);

export const setSessionCartId = createSetSessionItemAction(
    '[Passengers] Set Session Cart Id'
);

export const setSessionTrip2CheckinPassengers = createAction(
    '[Passengers] Set Session Trip2Checkin Passengers',
    props<{ name: string, passengers: PassengerList, routeTo?: string }>()
);

export const setSessionCheckinComplete = createSetSessionItemAction(
    '[Checkin Result] Set Session Checkin Complete'
);

export const setSessionFullTripData = createSetSessionItemAction(
    '[Checkin Result] Set Session Full Trip Data'
);

export const setSessionResultData = createSetSessionItemAction(
    '[Checkin Result] Set Session Result Data'
);

export const getSessionResultData = createAction(
    '[Boarding Pass Page] Get Session Result Data'
);

export const setSessionResultDataSuccess = createSetSessionItemAction(
    '[Checkin Result Component] Set Session Result Data Success',
);

export const setSessionResultDataError = createSessionErrorAction(
    '[Checkin Result Component] Set Session Result Data Error'
);

export const setSessionOAFlightFlag = createAction(
    '[Passengers Component] Set Session OA Flight Flag',
    props<{ isOAFlight: boolean }>()
);

export const setSessionTermsAndAgreement = createAction(
    '[Health Attestation Component] Set Session Terms Agreement',
    props<{ terms: Terms }>()
);

export const setSessionInfantConfirmation = createAction(
    '[Remove Infant] Set Infant Confirmation',
    props<{ confirmedInfant: boolean }>()
);

export const getSessionFullTripData = createAction(
    '[Boarding Pass Page] Get Session Full Trip Data'
);

export const clearFullTripData = createAction(
    '[Passengers] Clear Session Full Trip Data'
);

export const updateSessionState = createAction(
    '[Local Storage] Update Session State',
    props<{ payload: SessionData }>()
);

export const clearSessionDataCheckinStart = createAction(
    '[Checkin Start] Clear Session Data'
);

export const setSessionCorrelationId = createAction(
    '[Interceptor] Set Session Correlation Id',
    props<{ correlationId: string }>()
);

export const setMobileDevice = createAction(
    '[Checkin Online] Set Session Mobile Device',
    props<{ isMobile: boolean }>()
);

export const setOSDevice = createAction(
    '[Checkin Online] Set Session OS Device',
    props<{ os: string }>()
);

export const setBrowserDevice = createAction(
    '[Checkin Online] Set Session Browser Device',
    props<{ browser: string }>()
);

export const addCartContent = createAction(
    '[Cart Content] Add Cart Content Items',
    props<{ cartContentItems: CartContentItems[] }>()
);

export const updateCartContent = createAction(
    '[Cart Content] Update Cart Content Items',
    props<{ catalog: Response<Catalog> }>()
);

export const updateCartContentSuccess = createAction(
    '[Cart Content] Update Cart Content Items Success',
    props<{ cartContentItems: CartContentItems[] }>()
);

export const getEliteStandByData = createAction(
  '[Checkin Online] Get Elite Standby Data'
);

export const updateEliteStandByData = createAction(
  '[Checkin Online] Update Elite Standby Data',
  props<{ selectedEliteStandByPassengers: EliteStandByPassenger[] }>()
);
