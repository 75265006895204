import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingAnimationService } from '@hawaiianair/core';

@Component({
  selector: 'app-checkin-loader',
  templateUrl: './checkin-loader.component.html',
  styleUrls: [ './checkin-loader.component.scss' ],
})

export class CheckinLoaderComponent implements OnInit, OnDestroy {
  constructor(private loading: LoadingAnimationService) { }

  ngOnInit() {
    this.loading.show();
  }

  ngOnDestroy() {
    this.loading.hide();
  }
}
