import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { SessionSelectors } from '../session/session.selectors';
import { RegulatoryDetails } from '~app/models/internationalDetails.model';
import { Observable } from 'rxjs';
import { environment } from '~environments/environment';
import { HttpClient } from '@angular/common/http';
import { PassportInformation } from '~app/models/passport.model';
import { EmergencyContactInformation } from '~app/models/emergencyContact.model';
import { PassengerRegulatoryDetails } from '~app/models/passengerRegulatoryDetails.model';
import { InternationalDetailsPartialResponse } from '~app/models/internationalDetailsPartialResponse.model';
import { Response } from '~app/models/response.model';
import { take } from 'rxjs/operators';
import { PassengerAddress } from '~app/models/passengerAddress.model';
import { ContactTracingDetails } from '~app/models/contactTracingDetails.model';

@Injectable({
  providedIn: 'root',
})
export class InternationalDetailsService extends BaseService {
  private getJourneyId(): string {
    let journeyId: string;
    this.sessionSelectors.sessionSelectedSegmentId$.pipe(take(1)).subscribe(id => journeyId = id);
    return journeyId;
  }
  constructor(
    http: HttpClient,
    private sessionSelectors: SessionSelectors,
  ) {
    super(http, sessionSelectors);
  }

  getMissingRegulatoryDetails(): Observable<RegulatoryDetails> {
    const URL = `${environment["exp-web-checkin-v2-api"]}/passengers/journeys/${this.getJourneyId()}/missingRegulatoryDetails`;
    return this.http.get<RegulatoryDetails>(URL);
  }
  
  addPersonalRegulatoryDetails(personalRegulatoryDetails: PassengerRegulatoryDetails[]): Observable<Response<InternationalDetailsPartialResponse>>{
    const URL = `${environment["exp-web-checkin-v2-api"]}/passengers/journeys/${this.getJourneyId()}/personalDetails`;
    return this.http.patch<Response<InternationalDetailsPartialResponse>>(URL, personalRegulatoryDetails);
  }
  
  postPassport(passengerPassportDetails: PassportInformation[]): Observable<Response<InternationalDetailsPartialResponse>> {
    const url = `${environment['exp-web-checkin-v2-api']}/passengers/journeys/${this.getJourneyId()}/passport`;
    return this.http.post<Response<InternationalDetailsPartialResponse>>(url, passengerPassportDetails);
  }

  postEmergencyContact(passengerEmergencyContactDetails: EmergencyContactInformation[]): Observable<Response<InternationalDetailsPartialResponse>> {
    const url = `${environment['exp-web-checkin-v2-api']}/passengers/journeys/${this.getJourneyId()}/emergencyContact`;
    return this.http.post<Response<InternationalDetailsPartialResponse>>(url, passengerEmergencyContactDetails);
  }

  postAddressInformation(passengerAddress: PassengerAddress[]): Observable<Response<InternationalDetailsPartialResponse>> {
    const url = `${environment['exp-web-checkin-v2-api']}/passengers/journeys/${this.getJourneyId()}/address`;
    return this.http.post<Response<InternationalDetailsPartialResponse>>(url, passengerAddress);
  }

  postContactTracing(contactTracingDetails: ContactTracingDetails): Observable<Response<InternationalDetailsPartialResponse>> {
    const url = `${environment['exp-web-checkin-v2-api']}/passengers/journeys/${this.getJourneyId()}/contactTracing`
    return this.http.post<Response<InternationalDetailsPartialResponse>>(url, contactTracingDetails)
  }
}
