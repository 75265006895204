import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { ICartState } from '../../reducers/cart/cart.reducers';

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const cartState = createSelector(
  getEntityState,
  (state: EntityState) => state.cart
);

export const cartLoading = createSelector(
  cartState,
  (state: ICartState) => state.loading
);

export const cartError = createSelector(
  cartState,
  (state: ICartState) => state.error
);

export const cart = createSelector(
  cartState,
  (state: ICartState) => state.cart
);

export const cartItemsUpdated = createSelector(
  cartState,
  (state: ICartState) => state.cartItemsUpdateSuccess
);

export const cartResultItems = createSelector(
  cartState,
  (state: ICartState) => state.cartResultItems
);

export const cartItems = createSelector(
  cartState,
  (state: ICartState) => state.cartItems
);

export const bagItems = createSelector(
  cartState,
  (state: ICartState) => state.bags
);

export const seatItems = createSelector(
  cartState,
  (state: ICartState) => state.seats
);

export const grandTotal = createSelector(
  cartState,
  (state: ICartState) => state.grandTotal
);

export const bagsTotalCost = createSelector(
  cartState,
  (state: ICartState) => state.bagsTotalCost
);

export const seatsTotalCost = createSelector(
  cartState,
  (state: ICartState) => state.seatsTotalCost
);

export const cartCheckout = createSelector(
  cartState,
  (state: ICartState) => state.checkout
);

@Injectable()
export class CartSelectors {
  constructor(private store: Store<EntityState>) { }
  cartState$ = this.store.select(cartState);
  cart$ = this.store.select(cart);
  cartLoading$ = this.store.select(cartLoading);
  cartError$ = this.store.select(cartError);
  cartResultItems$ = this.store.select(cartResultItems);
  cartItems$ = this.store.select(cartItems);
  cartItemsUpdated$ = this.store.select(cartItemsUpdated);
  bagItems$ = this.store.select(bagItems);
  seatItems$ = this.store.select(seatItems);
  grandTotal$ = this.store.select(grandTotal);
  bagsTotalCost$ = this.store.select(bagsTotalCost);
  seatsTotalCost$ = this.store.select(seatsTotalCost);
  checkout$ = this.store.select(cartCheckout);
}
