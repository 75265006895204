<div *ngIf='!!fullTripData' class="container">
  <section>
    <div class="print-area">
      <div class="row action-btns d-none d-lg-inline">
        <div class="col action">
          <button mat-button id="boarding-pass-header-exit" class="btn btn-secondary" (click)='onExit()'>
            <span *haText="rendering.props['exit']"></span>
          </button>
          <button mat-button id="boarding-pass-header-print" class="btn btn-default" (click)="onPrintBp()">
            <span *haText="rendering.props['print']"></span>
            <span *haText="fullTripData.length > 1 ? rendering.props['boardingPasses'] : rendering.props['boardingPass']"></span>
          </button>
        </div>
      </div>
      <div *ngFor="let trip of allTrips; let index = index" class="mt-5">
        <!-- Mobile Boarding Pass Start -->
        <div class="d-block d-lg-none d-print-none">
          <div class="row mb-3" *ngIf="allTrips.length > 1">
            <div class="col small text-center">
              {{rendering.props['boardingPass']}} {{trip.boardingPassIndex}} (of {{allTrips.length}})
            </div>
          </div>
          <app-mobile-boarding-pass [trip]="trip" *ngIf="!!boardingPassRendering" [rendering]="boardingPassRendering">
          </app-mobile-boarding-pass>
        </div>
        <!-- Mobile Boarding Pass End -->
        <!-- Desktop Boarding Pass Start -->
        <div class="d-none d-lg-block d-print-block">
          <app-desktop-boarding-pass [trip]="trip" *ngIf="!!boardingPassRendering" [rendering]="boardingPassRendering">
          </app-desktop-boarding-pass>
        </div>
        <!-- Desktop Boarding Pass End -->

        <!-- Page Break for Boarding Pass Print -->
        <p class="page-break" *ngIf="trip.boardingPassIndex !== allTrips.length && allTrips.length !== 1"></p>
        <!-- Page Break for Boarding Pass Print -->
      </div>
      <div class="row action-btns d-none d-lg-inline">
        <div class="col action">
          <button mat-button id="boarding-pass-footer-exit" class="btn btn-secondary" (click)='onExit()'>
            <span *haText="rendering.props['exit']"></span>
          </button>
          <button mat-button id="boarding-pass-footer-print" class="btn btn-default" (click)="onPrintBp()">
            <span *haText="rendering.props['print']"></span>
            <span *haText="fullTripData.length > 1 ? rendering.props['boardingPasses'] : rendering.props['boardingPass']"></span>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>