import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, tap, switchMap, withLatestFrom } from 'rxjs/operators';
import { CatalogService } from '~app/store/services/catalogs/catalog.service';
import * as CatalogActions from '~app/store/actions';
import * as SessionActions from '~app/store/actions';
import * as fromSession from '~app/store/services/session/session.selectors';
import { EntityState } from '~app/store';
import { Store, select } from '@ngrx/store';
import { ModalsService } from '~app/modals/modals.service';

@Injectable()
export class CatalogEffects {
  getCatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogActions.bagsGetCatalog, CatalogActions.seatsGetCatalog,
        CatalogActions.itineraryGetCatalog, CatalogActions.dashboardGetCatalog),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromSession.getSessionState))),
        concatMap(([_, sessionState]) =>
          this.catalogService.get(sessionState.selectedSegmentId, sessionState.passengerIds.join()).pipe(
            tap(catalog => {
              if (!catalog) {
                throw { error: 'no data', requestData: catalog };
              }
            }),
            concatMap(catalog =>
              [
                CatalogActions.getCatalogSuccess({ catalog }),
                SessionActions.updateCartContent({ catalog })
              ]
            ),
            catchError(error => {
              return of(CatalogActions.getCatalogError(error));
            })
          )
        )
      ))
    )
  );

  getCatalogError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CatalogActions.getCatalogError),
      concatMap(action => of(action).pipe(
        tap(_ => {
          this.modalsService.openOffersErrorModal();
        })
      ))
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private catalogService: CatalogService,
    private store: Store<EntityState>,
    private modalsService: ModalsService
  ) { }

}
