import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { HaPageLoaderService } from '@hawaiianair/page-loader';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { catchError, concatMap, finalize, from, map, of, switchMap, take, tap, withLatestFrom } from 'rxjs';
import { ALERTS } from '~app/constants/ha-constants';
import { ModalsService } from '~app/modals/modals.service';
import { EntityState, getPassportInformation, InternationalDetailsService } from '~app/store';
import * as InternationalDetailsActions from '~app/store/actions/international-details.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import * as TripActions from '~app/store/actions/trip.actions';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import * as fromInternationalDetails from '~app/store/services/international-details/international-details.selectors';
import * as fromSessionState from '~app/store/services/session/session.selectors';

const supportedDetails = ['identityDocument', 'personalDetails', 'destinationAddress', 'emergencyContact'];

@Injectable()
export class InternationalDetailsEffects {
  constructor(
    private store: Store<EntityState>,
    private actions$: Actions,
    private internationalDetailsService: InternationalDetailsService,
    private modalService: ModalsService,
    private pageLoaderService: HaPageLoaderService,
    private titleCasePipe: TitleCasePipe,
    private translate: TranslateService,
  ) { }

  getMissingRegulatoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.getMissingRegulatoryDetails),
      withLatestFrom(
        this.store.pipe(select(fromInternationalDetails.getInternationalPassengers)),
        this.store.pipe(select(fromSessionState.getSessionTripSearch)),
        this.store.pipe(select(fromSessionState.sessionSelectedSegment))
      ),
      concatMap(([action, selectedPaxes, tripSearch, selectedSegment]) => {
        return this.internationalDetailsService.getMissingRegulatoryDetails().pipe(
          concatMap(details => {
            let actions: Action[] = [InternationalDetailsActions.getMissingRegulatoryDetailsSuccess({ details })];
            const selectedPaxIds = selectedPaxes.map(pax => pax.id);
            const filteredPaxDetails = details.passengers.filter(pax => selectedPaxIds.includes(pax.passengerId));
            if (filteredPaxDetails.every(pax => pax.statusCleared) && !action?.skipRouting) {
              if (action.componentName === CHECKIN_ROUTES.ROUTE_EMERGENCY_CONTACT.component || selectedSegment.contactTracingRequired) {
                actions.push(
                  TripActions.resultGetTrip({ tripSearch }),
                  RouterActions.routeToGuidelines({ componentName: action.componentName })
                );
              } else {
                actions.push(RouterActions.routeToEmergencyContact({ componentName: action.componentName }));
              }
            } else {
              const missingDetails = filteredPaxDetails.filter(pax => !pax.statusCleared).flatMap(pax => pax.missingDetails?.map(missingDetail =>
                // Bugfix: ensure passport/identityDocument is included when nationalityCountryCode needed (CS issue)
                missingDetail.detailsCategory === 'personalDetails' && missingDetail.detailsChoices.some(choice => choice.requiredDetailsFields.includes('nationalityCountryCode'))
                  ? 'identityDocument' : missingDetail.detailsCategory
              ));

              // Ensure only supported details are listed
              const unsupportedDetails = missingDetails.filter(detail => !supportedDetails.includes(detail));
              if (unsupportedDetails.length) {
                throw { error: 'Unsupported Missing Regulatory Details', missingDetails: unsupportedDetails, requestData: details };
              }

              if (!action?.skipRouting) {
                // Route to the appropriate page for missing detail
                if (missingDetails.includes('identityDocument')) {
                  actions.push(RouterActions.routeToPassportInformation({ componentName: action.componentName }));
                } else if (missingDetails.includes('personalDetails')) {
                  actions.push(RouterActions.routeToResidenceInformation({ componentName: action.componentName }));
                } else if (missingDetails.includes('destinationAddress')) {
                  actions.push(RouterActions.routeToTravelInformation({ componentName: action.componentName }));
                } else if (missingDetails.includes('emergencyContact')) {
                  actions.push(RouterActions.routeToEmergencyContact({ componentName: action.componentName }));
                }
              }
            }
            return actions;
          }),
          catchError(error => {
            this.modalService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
            return of(InternationalDetailsActions.getMissingRegulatoryDetailsError(error));
          })
        )
      })
    )
  );

  addPersonalRegulatoryDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.addPersonalRegulatoryDetails),
      switchMap(action =>
        this.internationalDetailsService.addPersonalRegulatoryDetails(action.passengerRegulatoryDetails).pipe(
          concatMap(_ => [
            InternationalDetailsActions.addPersonalRegulatoryDetailsSuccess(),
            InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_RESIDENCE_INFORMATION.component }),
          ]),
          catchError(error => {
            this.modalService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
            return of(InternationalDetailsActions.addPersonalRegulatoryDetailsError({ error }));
          }),
        ),
      ),
    ),
  )

  postPassportInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.postPassportInformation),
      withLatestFrom(this.store.pipe(select(getPassportInformation))),
      switchMap(([_, passportInformation]) =>
        this.internationalDetailsService.postPassport(passportInformation).pipe(
          tap(response => {
            if (response?.errors || response?.results?.some(result => !!result?.error)) {
              throw { error: 'no data', requestData: response };
            }
          }),
          concatMap(_ => [
            InternationalDetailsActions.postPassportInformationSuccess(),
            InternationalDetailsActions.addNationalityCode({
              passengerRegulatoryDetails: passportInformation.map(pax => {
                return { passengerId: pax.passengerId, personalDetails: { nationalityCode: pax.passport.nationality } };
              })
            }),
            InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_PASSPORT_INFORMATION.component }),
          ]),
          catchError(error => {
            this.modalService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
            return of(InternationalDetailsActions.postPassportInformationError({ error }));
          }),
        ),
      ),
    ),
  )

  postNationalityCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.addNationalityCode),
      switchMap(action =>
        this.internationalDetailsService.addPersonalRegulatoryDetails(action.passengerRegulatoryDetails).pipe(
          map(_ => InternationalDetailsActions.addNationalityCodeSuccess()),
          catchError(error => of(InternationalDetailsActions.addNationalityCodeError({ error }))),
        ),
      ),
    )
  );

  postAddressInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.postAddressInformation),
      switchMap(action =>
        this.internationalDetailsService.postAddressInformation(action.passengerAddress).pipe(
          tap(response => {
            if (response?.errors || response?.results?.some(result => !!result?.error)) {
              throw { error: 'no data', requestData: response };
            }
          }),
          concatMap(_ => [
            InternationalDetailsActions.postAddressInformationSuccess(),
            InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_TRAVEL_INFORMATION.component })
          ]),
          catchError(error => {
            this.modalService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
            return of(InternationalDetailsActions.postAddressInformationError({ error }))
          }),
        ),
      ),
    ),
  )

  postEmergencyContactInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.postEmergencyContactInformation),
      switchMap(action =>
        this.internationalDetailsService.postEmergencyContact(action.emergencyContactInformation).pipe(
          tap(response => {
            if (response?.errors || response?.results?.some(result => !!result?.error)) {
              throw { error: 'no data', requestData: response };
            }
          }),
          concatMap(_ => [
            InternationalDetailsActions.postEmergencyContactInformationSuccess(),
            InternationalDetailsActions.getMissingRegulatoryDetails({ componentName: CHECKIN_ROUTES.ROUTE_EMERGENCY_CONTACT.component }),
          ]),
          catchError(error => {
            this.modalService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
            return of(InternationalDetailsActions.postEmergencyContactInformationError({ error }));
          }),
        ),
      ),
    ),
  )

  postContactTracingInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.postContactTracingInformation),
      withLatestFrom(this.store.pipe(select(fromInternationalDetails.getInternationalPassengers))),
      concatMap(([action, intlPaxes]) => {
        const intlPax = intlPaxes.find(pax => pax.id === action.contactTracingDetails.passenger.passengerId);
        this.pageLoaderService.showEllipsis = true;
        this.pageLoaderService.descriptionText = this.translate.instant('ThisMayTakeAFewMinutes');
        this.pageLoaderService.statusText = this.translate.instant('SaveInformationForPax', { paxName: this.titleCasePipe.transform(intlPax?.passengerName?.firstName) });
        this.pageLoaderService.show();
        return this.internationalDetailsService.postContactTracing(action.contactTracingDetails).pipe(
          tap(response => {
            if (response?.errors || response?.results?.some(result => !!result?.error)) {
              throw { error: 'no data', requestData: response };
            }
          }),
          concatMap(_ => [
            InternationalDetailsActions.postContactTracingInformationSuccess({ paxId: action.contactTracingDetails.passenger.passengerId }),
          ]),
          catchError(error => {
            return of(InternationalDetailsActions.postContactTracingInformationError({ error, paxId: action.contactTracingDetails.passenger.passengerId }));
          }),
          finalize(() => {
            this.pageLoaderService.hide();
          })
        );
      })
    )
  );

  postContactTracingInformationForAllPassengers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InternationalDetailsActions.postContactTracingInformationForAllPassengers),
      switchMap(action => {
        const contactTracingDetails$ = from(action.contactTracingDetails).pipe(
          concatMap(contactTracingDetails =>
            of(InternationalDetailsActions.postContactTracingInformation({ contactTracingDetails }))
          )
        );
        return contactTracingDetails$.pipe(
          concatMap(contactTracingAction => {
            this.store.dispatch(contactTracingAction);
            return this.actions$.pipe(
              ofType(InternationalDetailsActions.postContactTracingInformationSuccess),
              take(1)
            );
          }),
          concatMap(_ =>
            of(InternationalDetailsActions.postContactTracingInformationForAllPassengersSuccessFinished({
              submittedPaxIds: action.contactTracingDetails.map(pax => pax.passenger.passengerId),
              total: action.contactTracingDetails?.length
            }))
          )
        );
      })
    )
  );

}
