import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryName'
})

export class CountryNamePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) { }

  transform(text: string): string {
    if (!text) return null;
    let words = text.split(' ');
    const countryName = words.map(word => this.isPreposition(word) ? word.toLowerCase() : this.titleCasePipe.transform(word));
    return countryName.join(' ');
  }

  private isPreposition(text: string): boolean {
    const prepositions = ['of', 'the', 'a', 'to', 'and'];
    return prepositions.includes(text.toLowerCase());
  }
}
