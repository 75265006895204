<div #legend id="seatmap-legend" class="legend-container">
  <div class="row">
    <div [id]="isInternational ? 'legend-business-class' : 'legend-first-class'" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square" [ngClass]="isLieFlat ? 'background-lie-flat' : 'background-first-class'"></div>
      <div class="pl-3 body-copy">
        {{ isInternational ? rendering?.props['businessClass'] : rendering?.props['firstClass'] }}
      </div>
    </div>
    <div id="legend-extra-comfort" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-extra-comfort"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['extraComfort']"></div>
    </div>
    <div id="legend-economy" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-economy"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['standard']"></div>
    </div>
    <div id="legend-preferred" *ngIf="isPreferredAvailable" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-preferred"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['preferred']"></div>
    </div>
    <div id="legend-unavailable" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-unavailable"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['unavailable']"></div>
    </div>
    <div id="legend-selected" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-selected"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['selected']"></div>
    </div>
    <div id="legend-exit" class="col-6 py-3 d-flex align-items-center">
      <div class="seat-square background-exit"></div>
      <div class="pl-3 body-copy" *haText="rendering?.props['exit']"></div>
    </div>
  </div>
</div>
<button id="seatmap-legend-button" class="btn btn-default seatmap-footer-btn d-md-none d-block" mat-button
  [ngClass]="areAllSeatsSelected ? 'save-seats-btn' : 'legend-btn'" (click)="clickSeatmapLegendButton(legend)">
  <span *haText="areAllSeatsSelected ? rendering?.props['saveSeats'] : rendering?.props['legend']"></span>
</button>
