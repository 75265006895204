import { Validators, ValidatorFn, ValidationErrors, FormGroup } from '@angular/forms';

export class FrequentFlyerValidators extends Validators {
  static createKTNValidator(setErrors?: boolean): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const ktn: string = form.get('ktn').value;
      const issuingCountry: string = form.get('issuingCountry').value;

      if (setErrors) {
        if (!ktn && issuingCountry) {
          form.get('ktn').setErrors({ 'required': true });
        }
        if (ktn && !issuingCountry) {
          form.get('issuingCountry').setErrors({ 'required': true });
        }
        if (!ktn && !issuingCountry) {
          form.get('ktn').setErrors(null);
          form.get('issuingCountry').setErrors(null);
        }
        form.markAllAsTouched();
      }

      return (ktn && issuingCountry) || (!ktn && !issuingCountry) ? null : { missingParam: true };
    }
  }

  static createRedressValidator(setErrors?: boolean): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const redress: string = form.get('redress').value;
      const issuingCountry: string = form.get('issuingCountry').value;

      if (setErrors) {
        if (!redress && issuingCountry) {
          form.get('redress').setErrors({ 'required': true });
        }
        if (redress && !issuingCountry) {
          form.get('issuingCountry').setErrors({ 'required': true });
        }
        if (!redress && !issuingCountry) {
          form.get('redress').setErrors(null);
          form.get('issuingCountry').setErrors(null);
        }
        form.markAllAsTouched();
      }

      return (redress && issuingCountry) || (!redress && !issuingCountry) ? null : { missingParam: true };
    }
  }
}
