import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ContentSchema, PropertyType } from '@hawaiianair/core';
import { RouterDispatchers, SessionSelectors, RouterSelectors } from '~app/store';
import { Subscription, Observable } from 'rxjs';
import { FooterButtons } from '~app/shared/checkin-footer/checkin-footer.component';
import { CHECKIN_ROUTES, ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import { Flight } from '~app/models/flight.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { HelperService } from '~app/services/helper.service';

@Component({
  selector: 'app-seatmap-header',
  templateUrl: './seatmap-header.component.html',
  styleUrls: ['./seatmap-header.component.scss'],
})
@ContentSchema({
  description: 'SeatmapHeaderComponent',
  name: 'SeatmapHeader',
  props: [
    // TODO: replace this example schema property with real definitions
    { name: 'navButtons', type: PropertyType.Object },
  ]
})
export class SeatmapHeaderComponent implements OnInit, OnDestroy {
  @Input() navButtons: FooterButtons;
  @Input() selectedPaxId: string;
  @Output() selectPax = new EventEmitter<SeatAssignment>();

  subs: Subscription;
  routerState$: Observable<ICheckinRouteSerializerState>;
  segmentFlights$: Observable<Flight[]>;
  assignedSeats$: Observable<SeatAssignment[]>;

  segmentFlight: Flight;
  seatAssignments: SeatAssignment[] = [];
  origin: string;
  destination: string;
  isHAFlight: boolean;

  constructor(
    private routerDispatchers: RouterDispatchers,
    private routerSelectors: RouterSelectors,
    private sessionSelectors: SessionSelectors,
    private helperService: HelperService,
  ) {
    this.routerState$ = this.routerSelectors.routerState$;
    this.segmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.assignedSeats$ = this.sessionSelectors.recentlySavedSeats$;
  }

  ngOnInit() {
    this.subs = new Subscription();
    this.subs.add(this.routerState$.subscribe(
      state => {
        if (!!state) {
          this.segmentFlights$.subscribe(flights => {
            if (!!flights) {
              this.segmentFlight = flights.find(flight => flight.id === state.queryParams.flightId);
              this.isHAFlight = this.helperService.isHAFlight(state.queryParams.flightId, flights);
              this.origin = this.segmentFlight && this.segmentFlight.origin;
              this.destination = this.segmentFlight && this.segmentFlight.scheduledDestination;
            }
          });

          this.subs.add(this.assignedSeats$.subscribe(seats => {
            if (this.segmentFlight) {
              this.seatAssignments = seats && seats.filter(seat => seat.flightId === this.segmentFlight.id);
            }
          }));
        }
      }
    ));
  }

  ngOnDestroy() {
    if (!!this.subs) {
      this.subs.unsubscribe();
    }
  }

  defaultNavButtonClick() {
    this.routerDispatchers.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route);
  }

  secondaryNavButtonClick() {
    this.routerDispatchers.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route);
  }

  selectPaxChip(pax: SeatAssignment) {
    this.selectPax.emit(pax);
  }
}
