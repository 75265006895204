<app-checkin-header [heading]="rendering.props?.heading" [description]="rendering.props?.description"
                    [hideHeaderInfo]="loading$ | async"></app-checkin-header>
<div *ngIf="loading$ | async; else travelInformation">
  <app-checkin-loader></app-checkin-loader>
</div>

<ng-template #travelInformation>
  <div class="container px-0 px-md-4 text-left">
    <mat-card *ngFor="let pax of internationalPassengers; index as paxId; first as first">
      <div class="container py-4 py-md-0">
        <div class="row align-items-center">
          <div id="passenger-info-{{paxId}}" class="col-12 col-md text-left">
            <h6 id="passenger-card-name" class="medium mb-4">
              {{ pax.passengerName?.firstName | titlecase }} {{ pax.passengerName?.lastName | titlecase }}
            </h6>
          </div>
        </div>
        <div *ngIf="!first" class="row" [ngClass]="{'mb-4': !pax.isSameTravelInfo }">
          <mat-checkbox class="col-12 aloha-pink"
                        id="travel-information-checkbox-{{paxId}}"
                        name="travel-info" [checked]="pax.isSameTravelInfo"
                        (change)="pax.isSameTravelInfo = !pax.isSameTravelInfo; pax.destinationInfoForm.reset()">
            <div class="subheader-text body-copy"
                 *haText="rendering?.props?.sameTravelInformationCheckboxContent"></div>
          </mat-checkbox>
        </div>
        <div *ngIf="!pax.isSameTravelInfo" class="row d-md-block">
          <div class="col-12 body-copy first-day-description mb-3"
               *haText="rendering.props?.firstDayDescription"></div>
          <app-destination-information class="full-width"
                                       [rendering]="rendering.components?.destinationInformation?.[0]"
                                       [destinationInfoForm]="pax.destinationInfoForm"
          ></app-destination-information>
        </div>
      </div>
    </mat-card>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props?.navButtons"
                      (defaultNavButtonClicked)="submitTravelInformation()"
                      [disableDefaultNavButton]="!isDestinationInformationFormValid"
  ></app-checkin-footer>
</ng-template>
