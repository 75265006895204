import { createAction, props } from '@ngrx/store';
import { AlertReasonCodeData } from '~app/models/alert-code-logging.model';

export const logRequestData = createAction(
  '[Interceptor] Log Api Request Data',
  props<{data: Object}>()
);

export const logResponseData = createAction(
  '[Interceptor] Log Api Response Data',
  props<{data: Object}>()
);

export const logAlertError = createAction(
  '[Error Modal] Log Alert Error',
  props<{ error: Object }>()
);

export const seatsUpsellClick = createAction(
  '[Seats Upsell] Upsell Route to Seat Map',
  props<{ data?: Object }>()
);

export const referOutAlertCode = createAction(
  '[Segment Selector] Refer Out Alert Code',
  props<{ data: AlertReasonCodeData }>()
);

export const resultPageLoaded = createAction(
  '[Result Page] Result Page Loaded',
  props<{ data?: Object }>()
);

export const boardingPassPageLoaded = createAction(
  '[BoardingPass Page] Result Page Loaded',
  props<{ data?: Object }>()
);

export const passengerPageLoaded = createAction(
  '[Passenger Page] Passenger Page Loaded',
  props<{ data?: Object }>()
);

export const baggagePageLoaded = createAction(
  '[Baggage Page] Baggage Page Loaded',
  props<{ data?: Object }>()
);

export const baggageAdvisoryItemsPageLoaded = createAction(
  '[Baggage Page] Baggage Advisory Items Page Loaded',
  props<{ data?: Object }>()
);

export const checkInPageLoaded = createAction(
  '[Check In Page] Check In Page Loaded',
  props<{ data?: Object }>()
);

export const dashboardPageLoaded = createAction(
  '[Dashboard Page] Dashboard Page Loaded',
  props<{ data?: Object }>()
);

export const groupPassengersPageLoaded = createAction(
  '[Group Passengers Page] Group Passengers Page Loaded',
  props<{ data?: Object }>()
);

export const guidelinesPageLoaded = createAction(
  '[Guidelines Page] Guidelines Page Loaded',
  props<{ data?: Object }>()
);

export const healthAttestationPageLoaded = createAction(
  '[Health Attestation Page] Health Attestation Page Loaded',
  props<{ data?: Object }>()
);

export const infantPageLoaded = createAction(
  '[Infant Page] Infant Page Loaded',
  props<{ data?: Object }>()
);

export const infantInfoPageLoaded = createAction(
  '[Infant Info Page] Infant Info Page Loaded',
  props<{ data?: Object }>()
);

export const internationalTravelerInformationPageLoaded = createAction(
  '[International Traveler Information Page] International Traveler Information Page Loaded',
  props<{ data?: Object }>()
);

export const internationalTravelerSelectionPageLoaded = createAction(
  '[International Traveler Selection Page] International Traveler Selection Page Loaded',
  props<{ data?: Object }>()
);

export const militaryPageLoaded = createAction(
  '[Military Page] Military Page Loaded',
  props<{ data?: Object }>()
);

export const minorPageLoaded = createAction(
  '[Minor Page] Minor Page Loaded',
  props<{ data?: Object }>()
);

export const paymentPageLoaded = createAction(
  '[Payment Page] Payment Page Loaded',
  props<{ data?: Object }>()
);

export const segmentSelectorPageLoaded = createAction(
  '[Segment Selector Page] Segment Selector Page Loaded',
  props<{ data?: Object }>()
);

export const seatPageLoaded = createAction(
  '[Seat Page] Seat Page Loaded',
  props<{ data?: Object }>()
);

export const seatmapPageLoaded = createAction(
  '[Seatmap Page] Seatmap Page Loaded',
  props<{ data?: Object }>()
);

export const paymentInformationSubmitted = createAction(
  '[Payment Page] Payment Information Submitted',
  props<{ data?: Object }>()
)

export const trackAEPOrderSuccess = createAction(
  '[Payment Page] Track Adobe Order Success',
)
