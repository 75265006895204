import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsDispatchers } from '../../store/services/analytics/analytics.dispatchers';
import { Observable } from 'rxjs';
import { PassengerInfo } from '~app/models/passengerinfo.model';
import { take } from 'rxjs/operators';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { InternationalDetailsSelectors } from '~app/store';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-passport-match-error',
  templateUrl: './passport-match-error.component.html',
  styleUrls: ['./passport-match-error.component.scss'],
})
@ContentSchema({
  description: '',
  name: 'PassportMatchError',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'messageWrongPaxPassport', type: PropertyType.RichText }
  ]
})
export class PassportMatchErrorComponent implements OnInit {
  rendering: ComponentRendering;
  passengerInfo$: Observable<PassengerInfo>;
  messageWrongPaxPassport: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PassportMatchErrorComponent>,
    private modalContentsSvc: ModalContentsService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private dynamicContentService: DynamicContentService,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private titleCasePipe: TitleCasePipe
  ) { }

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('passport-match-error-modal');
    this.internationalDetailsSelectors.internationalDetailsState$.pipe(take(1)).subscribe(state => {
      const selectedPassportPax = state.internationalPassengers.find(pax => pax.id === state.passportPaxId);
      if (this.rendering.props && selectedPassportPax) {
        this.messageWrongPaxPassport = this.dynamicContentService.getContentString(
          this.rendering.props['messageWrongPaxPassport'], 
          { paxName: this.titleCasePipe.transform(`${selectedPassportPax.passengerName.firstName} ${selectedPassportPax.passengerName.lastName}`) }
        );
      }
    });

    // log error to App Insights
    this.analyticsDispatchers.logAlertError(this.data);
  }
}
