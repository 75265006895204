import { Component, Input } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'get-our-app',
  templateUrl: './get-our-app.component.html',
  styleUrls: ['./get-our-app.component.scss']
})
@ContentSchema({
  name: 'GetOurApp',
  description: "Get Our App Component",
  props: [
    { name: 'getNewApp', type: PropertyType.Text },
    { name: 'phoneNumberInput', type: PropertyType.Text },
    { name: 'countryCodeInput', type: PropertyType.Text },
    { name: 'ratesMayApply', type: PropertyType.Text },
    { name: 'appMessage', type: PropertyType.Text },
    { name: 'appleStoreUrl', type: PropertyType.Hyperlink },
    { name: 'googlePlayStoreUrl', type: PropertyType.Hyperlink },
  ]
})
export class GetOurAppComponent {
  @Input() rendering: ComponentRendering;

  countryCode: any[] = [
    {value: 'usa', viewValue: '+1 USA'},
    {value: 'japan', viewValue: '+81 Japan'},
    {value: 'new-zealand', viewValue: '+64 New Zealand'}
  ];
}
