<app-checkin-header [heading]="!sessionData?.isOAFlight ? rendering.props['heading'] : rendering.props['OAheading']"
                    [description]="!sessionData?.isOAFlight ? rendering.props['description'] : rendering.props['OAdescription']">
</app-checkin-header>
<div class="container">
  <div class="spinner-wrapper" [hidden]="!savingBags">
    <div id="bags-special-items-spinner" class="spinner-border" role="status">
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let item of specialItemsCatalog; let idx = index">
      <ng-container *ngIf="!!associatedPax && !!item">
        <app-bags-quantity class="col-md-6" id="bags-special-{{idx}}" [tagId]="idx" [associatedPax]="associatedPax" [bagDetail]="item"
        [bagContent]="item.productContent" [paxIdx]="0">
      </app-bags-quantity>
      </ng-container>
    </ng-container>
  </div>
  <div class="row">
    <div class="col action call-out text-left">
      {{rendering.props['weightVerification']}} {{rendering.props['weightVerificationAcceptance']}}
    </div>
  </div>
</div>
<app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
