<div id="header">
    <h6 class="subtitle-bold" *haText="rendering.props['header']"></h6>
</div>
<mat-dialog-content class="dlg-content mat-typography">
    <div id="row">
        <span class="call-out" *haText="rendering.props['content']"></span>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-dialog-close mat-button id="close-flight-information-button" class="btn btn-default w-50">{{ 'Ok' | translate }}</button>
</mat-dialog-actions>