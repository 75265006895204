<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideFlightStatus]="true">
</app-checkin-header>
<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(loading$ | async) &&  !(airportLoading$ | async)">
  <div id="group-spinner" class="spinner-border" role="status"></div>
</div>
<div class="container background px-0 px-md-4">
  <mat-card id="passenger-card-{{index}}" class="d-flex pax-card" *ngFor="let groupPassenger of groupPassengersList; let index = index">
    <div class="container">
      <div class="row align-items-center">
        <div id="passenger-name-{{index}}" class="col text-left subtitle-bold">
          {{ groupPassenger.passengerName.firstName | titlecase }} {{ groupPassenger.passengerName.lastName | titlecase }}
        </div>
        <i id="passenger-added-icon-{{index}}"
          class="col-auto ha-icon icon-16 icon-check-circle success-green mr-4"></i>
      </div>
    </div>
  </mat-card>
  <mat-card id="add-guest-form" *ngIf="showAddPaxForm; else addPaxFormFooter" class="add-another-guest-section">
    <div class="container row text-left pt-3 pt-lg-0">
      <div id="add-guest-title-text" class="col-12 subtitle-bold"
        *haText="!groupPassengersList?.length ? rendering.props['addGuestText'] : rendering.props['addAnotherGuestText']">
      </div>
      <div id="add-guest-description-text" class="col-12 body-copy secondary-text mb-2"
        *haText="rendering.props['addGuestDescription']">
      </div>
    </div>
    <form class="container mb-3 mb-lg-0" [formGroup]="groupPassengersAddForm" #formDir="ngForm">
      <div class="row no-gutters align-items-center">
        <div class="col-12 col-lg pr-lg-4">
          <app-ha-input inputId="pax-first-name-input" formControlName="paxFirstName"
            [label]="rendering.props['paxFirstNameLabel']" [placeholder]="rendering.props['paxFirstNameLabel']"
            [control]="groupPassengersAddForm.get('paxFirstName')">
          </app-ha-input>
        </div>
        <div class="col-12 col-lg pr-lg-4">
          <app-ha-input inputId="pax-middle-name-input" formControlName="paxMiddleName"
            [label]="rendering.props['paxMiddleNameLabel']" [placeholder]="rendering.props['paxMiddleNameLabel']"
            [control]="groupPassengersAddForm.get('paxMiddleName')">
          </app-ha-input>
        </div>
        <div class="col-12 col-lg pr-lg-4 pb-4 pb-lg-0">
          <app-ha-input inputId="pax-last-name-input" formControlName="paxLastName"
            [label]="rendering.props['paxLastNameLabel']" [placeholder]="rendering.props['paxLastNameLabel']"
            [control]="groupPassengersAddForm.get('paxLastName')">
          </app-ha-input>
        </div>
        <button type="button" id="add-passenger-button" mat-button (click)="groupPassengerAdd()"
          class="add-guest-btn col col-lg-auto btn btn-default mx-0" [disabled]="groupPassengersAddForm.invalid">
          {{ rendering?.props['addGuestText'] | titlecase }}
        </button>
      </div>
    </form>
  </mat-card>
  <ng-template #addPaxFormFooter>
    <div class="add-another-guest-section container pt-5 pb-4 py-md-0 px-md-0 px-4 text-md-left">
      <button *ngIf="!isMaxPassengers(); else limitReachedText" id="add-another-guest-button" mat-button
        (click)="addAnotherButtonClick()" class="btn btn-secondary ml-0">
        {{ rendering?.props['addAnotherGuestText'] | titlecase }}
      </button>
      <ng-template #limitReachedText>
        <span id="limit-reached-text" class="call-out secondary-text"
          *haText="rendering.props['limitReachedText']"></span>
      </ng-template>
    </div>
  </ng-template>
</div>
<app-checkin-footer [disableDefaultNavButton]="!groupPassengersList?.length || (loading$ | async)"
  (defaultNavButtonClicked)="submitGroupBookingInformation($event)"
  [navButtons]="rendering?.props['defaultNavButtons']">
</app-checkin-footer>
