import { createAction, props } from '@ngrx/store';
import { AuthToken } from '~app/models/auth-token.model';
import { Response } from '~app/models/response.model';
import { TripSearch } from '~app/models/trip-search.model';

// Helper Actions
export const getAuth = (actionType: string) => (
  createAction(actionType, props<{ tripSearch: TripSearch }>())
);
export const authTokenApiSuccess = (actionType: string) => (
  createAction(actionType, props<{ authToken: Response<AuthToken>, tripSearch: TripSearch }>())
);
export const authTokenApiError = (actionType: string) => (
  createAction(actionType, props<{ error: any }>())
);

// >>>>> get authToken
export const checkinGetAuthToken = getAuth(
  '[Checkin Start] Get Auth Token'
);
export const checkinGetAuthTokenError = authTokenApiError(
  '[Checkin Start] Get Auth Token Error'
);
export const checkinGetAuthTokenSuccess = authTokenApiSuccess(
  '[Checkin Start] Get Auth Token Success'
);
export const authTokenExpirationModalGetAuthToken = getAuth(
  '[Auth Token Expiration Modal] Get Auth Token'
);
export const authTokenExpirationGetAuthTokenError = authTokenApiError(
  '[Auth Token Expiration Modal] Get Auth Token Error'
);
export const authTokenExpirationGetAuthTokenSuccess = authTokenApiSuccess(
  '[Auth Token Expiration Modal] Get Auth Token Success'
);
// <<<<< get authToken

export const authTokenExpired = createAction(
  '[Session Timeout Service] Expired'
);

export const startWatchingAuthToken = createAction(
  '[Auth Token Effects] Start Watching New Auth Token'
);


