import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentLocaleService } from '@hawaiianair/core';
import { environment } from '~environments/environment';
import { LaunchDarklyClientService } from '~app/services/launch-darkly-client.service';

@Injectable({
  providedIn: 'root'
})
export class SpaGuard implements CanActivate {
  constructor(
    public currentLocale: CurrentLocaleService,
    private ldClientService: LaunchDarklyClientService
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.ldClientService.waitUntilReady()?.then(() => {
      if (this.ldClientService.getFlag('web-check-in-spa-enabled')) {
        return true;
      }

      window.location.href = environment.spaDisabledUrls[this.currentLocale.locale] || environment.spaDisabledUrls['en-us'];
      return false;
    }) || false;
  }
}
