import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { ContentBlockComponent } from '~app/shared/content-block/content-block.component';
import { BaggageModule } from './baggage/bags.module';
import { CheckinModule } from './checkin/checkin.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { GroupPassengerModule } from './groups/group-passenger.module';
import { GuidelinesPageModule } from './guidelines-page/guidelines-page.module';
import { HealthAttestationModule } from './health-attestation/health-attestation.module';
import { InfantModule } from './infant/infant.module';
import { InternationalTravelerInformationModule } from './international/international.module';
import { MilitaryModule } from './military/military.module';
import { MinorModule } from './minor/minor.module';
import { PassengerModule } from './passenger/passenger.module';
import { PaymentModule } from './payment/payment.module';
import { ResultModule } from './result/result.module';
import { SeatModule } from './seat/seats.module';
import { SegmentSelectorModule } from './segment-selector/segment-selector.module';
import { ContactTracingModule } from './contact-tracing/contact-tracing.module';

@NgModule({
  imports: [
    CommonModule,
    AppSharedModule,
    CoreModule.forChild({
      components: [
        ContentBlockComponent,
      ],
    }),
      BaggageModule,
      CheckinModule,
      ContactTracingModule,
      DashboardModule,
      GroupPassengerModule,
      GuidelinesPageModule,
      HealthAttestationModule,
      InfantModule,
      InternationalTravelerInformationModule,
      MilitaryModule,
      MinorModule,
      PassengerModule,
      PaymentModule,
      ResultModule,
      SeatModule,
      SegmentSelectorModule
  ],
  exports: [
    AppSharedModule
  ],
  declarations: [
    ContentBlockComponent,
  ],
})
export class AppComponentsModule { }
