import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Constants } from "~app/constants/ha-constants";
import { HeaderContent } from "~app/models/headerContent.model";


@Injectable({
  providedIn: 'root'
})
export class HeaderContentService {
  headerContentSub: BehaviorSubject<HeaderContent>;
  defaultHeaderContent: any; 


  constructor(
  ) {     
    this.defaultHeaderContent = {
      destinationImgUrl: Constants.defaultdestinationImgUrl,
      headerText: Constants.mainHeaderText
    };
    this.headerContentSub = new BehaviorSubject<HeaderContent>(this.defaultHeaderContent);
  }

  setHeaderContent(headerContent: HeaderContent) {   
    if (!headerContent.destinationImgUrl) {
      this.headerContentSub.next(this.defaultHeaderContent);
    } else {
      this.headerContentSub.next(headerContent);
    }
  }
}
