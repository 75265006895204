<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="tripLoading$ | async"></app-checkin-header>
<div *ngIf="tripLoading$ | async; else upgradePage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #upgradePage>
  <div class="background container text-left">
    <ul id="list" *ngIf="rendering?.props['list']">
        <li id="item-{{index}}" *ngFor="let item of rendering.props['list']; let index = index" class="call-out">{{ item.text }}</li>
    </ul>
    <h6 class="text-left mb-4 subtitle-bold" *haText="rendering.props['confirmation']"></h6>
    <div *ngFor="let passenger of (passengerList$ | async); let index = index">
        <app-seat-upgrade-passenger-card [rendering]="seatUpgradePassengerCardRendering"
        [passenger]="passenger"
        [index]= "index"
        (updateEliteStandByPassengerList)="updateEliteStandByPassengerList($event)"
        >
        </app-seat-upgrade-passenger-card>
    </div>
    </div>
    <app-checkin-footer [navButtons]="rendering?.props['navButtons']" (defaultNavButtonClicked)="processEliteStandBySelection()"></app-checkin-footer>
</ng-template>
