import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { environment } from '~environments/environment';
import { AuthToken } from '~app/models/auth-token.model';
import { Response } from '~app/models/response.model';
import { SessionSelectors } from '../session/session.selectors';
import { TripSearch } from '~app/models/trip-search.model';

export interface IAuthTokenService {
  get(tripSearch: TripSearch): Observable<Response<AuthToken>>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService extends BaseService {
  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors
  ) {
    super(http, sessionSelectors);
  }

  get(tripSearch: TripSearch): Observable<Response<AuthToken>> {
    let URL = `${environment["exp-web-checkin-v2-api"]}/tripauths`;
    URL += this.getSearchCredentials(tripSearch);
    return this.http.get<Response<AuthToken>>(URL);
  }
}
