<section class="footer-section-border">
    <div class="row result-footer">
        <div class="col-sm-1 ha-logo-container text-center">
            <i class="ha-logo"></i> <h5 id="get-app-title" class="d-block d-sm-none">{{rendering.props['appMessage']}}</h5>
        </div>

        <div class="col-sm-7 d-none d-md-block d-lg-block d-xl-block" id="footer-content">
            <div class="row footer-spacing">
                <h5 id="get-app-title">{{rendering.props['appMessage']}}</h5>
                    <!-- <mat-form-field appearance="fill" id="country-code">
                        <mat-label>{{rendering.props['countryCodeInput']}}</mat-label>
                        <mat-select [disabled]='true' [(value)]="countryCode[0].value">
                        <mat-option *ngFor="let code of countryCode" [value]="code.value" id="country-code-text">
                            {{code.viewValue}}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" id="phone-number">
                        <mat-label>{{rendering.props['phoneNumberInput']}}</mat-label>
                        <input id="phone-number-text" matInput>
                    </mat-form-field>
                    <button mat-raised-button id="result-email" class="btn btn-default btn-spacing"> Send </button>
                <p>* {{rendering.props['ratesMayApply']}}</p> -->
            </div>
        </div>
        <div class="col-sm-4 app-stores text-right">
            <a *haLink="rendering?.props?.appleStoreUrl">
                <img id="apple-store" alt="Apple App Store">
              </a>
              <a *haLink="rendering?.props?.googlePlayStoreUrl">
                <img id="google-play" alt="Google Play Store">
              </a>
        </div>
    </div>
</section>
