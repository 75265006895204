<h6 id="next-steps-title" class="bold medium" mat-dialog-title>{{ nextStepsTitle }}</h6>
<mat-dialog-content *ngIf="!isInternational; else internationalBaggageInfo" align="center" class="dlg-content mat-typography body-copy">
  <span id="bags-to-check-in-sub-title" *ngIf="data; else noBagsToCheckInSubTitle" class="call-out-bold">
    {{bagsToCheckSubTitle}}
  </span>
  <ng-template #noBagsToCheckInSubTitle>
    <span id="no-bags-to-check-in-sub-title" class="call-out-bold">
      {{noBagsToCheckSubTitle}}
    </span>
  </ng-template>
  <br>
  <span id="kiosk-instructions" class="secondary-text" *ngIf="data; else noBagsToCheckInBody">
    {{kioskInstructions}}
  </span>
  <img [hidden]="!data" id="kiosk-image" src="/media/images/kiosk/kiosk.png" alt="HA Kiosk">
  <ng-template #noBagsToCheckInBody>
    <div id="tsa-instructions" class="secondary-text">
      {{tsaInstructions}}
    </div>
    <div id="save-time-instructions" class="secondary-text">
      {{saveTimeInstructions}}
    </div>
  </ng-template>
</mat-dialog-content>
<ng-template #internationalBaggageInfo>
  <mat-dialog-content>
    <h6 id="international-pax-without-check-in-items-heading">
      {{ boardingPassWithoutCheckinItemsTitle }}
  </h6>
  <p class="call-out" *haRichText="boardingPassWithoutCheckinItemsInternational">
  </p>
  <br>
  <h6 id="international-pax-with-check-in-items-heading">
      {{ boardingPassWithCheckinItemsTitle }}
  </h6>
  <p class="call-out" *haRichText="boardingPassWithCheckinItemsInternational">
  </p>
  <br>
  <h6 id="baggage-drop-off-time-requirement-description">
      {{ boardingPassBaggageInternational }}
  </h6>
  </mat-dialog-content>
</ng-template>
<mat-dialog-actions align="center">
  <div class="full-width">
    <button id="close-next-steps-modal" mat-button mat-dialog-close class="btn btn-default">{{btnOk}}</button>
  </div>
</mat-dialog-actions>
