import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const SESSION_ENDED_TYPE_CART_EXPIRED = 'cart expired';

export interface ConcurrencyModalData {
  isPaymentInProgress: boolean;
  isCheckInInProgress: boolean;
  isSessionEnded: boolean;
  passengerNames?: string[];
  sessionEndedType?: string;
}

@Component({
  selector: 'app-concurrency-modal',
  templateUrl: './concurrency-modal.component.html',
  styleUrls: ['./concurrency-modal.component.scss']
})
@ContentSchema({
  name: 'ConcurrencyModal',
  description: 'Concurrency Modal Component',
  props: [
    { name: 'CheckInProgressTitle', type: PropertyType.Text },
    { name: 'CheckInProgressHeader', type: PropertyType.Text },
    { name: 'CheckInProgressContent', type: PropertyType.Text },
    { name: 'CheckInProgressSecondaryContent', type: PropertyType.Text },
    { name: 'PaymentProgressTitle', type: PropertyType.Text },
    { name: 'PaymentProgressHeader', type: PropertyType.Text },
    { name: 'PaymentProgressContent', type: PropertyType.Text },
    { name: 'PaymentProgressSecondaryContent', type: PropertyType.Text },
    { name: 'SessionEndedTitle', type: PropertyType.Text },
    { name: 'SessionEndedHeader', type: PropertyType.Text },
    { name: 'CartSessionEndedHeader', type: PropertyType.Text },
    { name: 'btnOk', type: PropertyType.Text },
    { name: 'btnCancel', type: PropertyType.Text },
    { name: 'btnContinue', type: PropertyType.Text },
  ]
})
export class ConcurrencyModalComponent implements OnInit {
  rendering: ComponentRendering;
  title: string;
  header: string;
  content: string;
  secondaryContent: string;
  btnOk: string;
  btnCancel: string;
  btnContinue: string;

  constructor(
    public dialogRef: MatDialogRef<ConcurrencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConcurrencyModalData,
    private modalContentsSvc: ModalContentsService
  ) { }

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('concurrency-modal');

    this.btnOk = this.rendering.props['btnOk'];
    this.btnCancel = this.rendering.props['btnCancel'];
    this.btnContinue = this.rendering.props['btnContinue'];

    if (this.data.isPaymentInProgress) {
      this.title = this.rendering.props['PaymentProgressTitle'];
      this.header = this.rendering.props['PaymentProgressHeader'];
      this.content = this.rendering.props['PaymentProgressContent'];
      this.secondaryContent = this.rendering.props['PaymentProgressSecondaryContent'];
    } else if (this.data.isCheckInInProgress) {
      this.title = this.rendering.props['CheckInProgressTitle'];
      this.header = this.rendering.props['CheckInProgressHeader'];
      this.content = this.rendering.props['CheckInProgressContent'];
      this.secondaryContent = this.rendering.props['CheckInProgressSecondaryContent'];
    } else if (this.data.isSessionEnded) {
      this.title = this.rendering.props['SessionEndedTitle'];
      if (!!this.data.sessionEndedType && this.data.sessionEndedType === SESSION_ENDED_TYPE_CART_EXPIRED) {
        this.header = this.rendering.props['CartSessionEndedHeader'];
      } else {
        this.header = this.rendering.props['SessionEndedHeader'];
      }
      this.content = '';
      this.secondaryContent = '';
    }
    // <<< load contents
  }
}
