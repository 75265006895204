<h6 id="seat-upgrade-error-title" class="bold medium" mat-dialog-title>
    <span *haText="rendering?.props['title']"></span>
</h6>
<mat-dialog-content id="seat-upgrade-error-content" class="body-copy">
  <span *haText="rendering?.props['description']"></span>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button id="ok-button" mat-button mat-dialog-close cdkFocusInitial class="btn btn-default">
        {{ 'Ok' | translate }}
    </button>
</mat-dialog-actions>
