<div class="row">
  <div *ngFor="let item of upsellItems" class="d-none d-md-block col-md-12 col-lg-6">
    <app-seat-upsell [rendering]="rendering" [item]="item" [flightId]="flightId"></app-seat-upsell>
  </div>
</div>
<div class="d-block d-md-none">
  <div class="grid-wrapper" (swipeleft)="swipe(-1)" (swiperight)="swipe(1)">
    <div *ngFor="let item of upsellItems; let i = index" id="{{item?.type}}{{i}}" class="upsell-item-container w-100">
      <app-seat-upsell class="upsell-item h-100" [rendering]="rendering" [item]="item" [flightId]="flightId"></app-seat-upsell>
    </div>
  </div>
  <div *ngIf="upsellItems?.length > 1" id="upsell-radio-group" aria-label="Select an option">
    <span id="upsell-{{i}}" *ngFor="let item of upsellItems; let i = index" class="upsell-grid-dot mx-2" (click)="scrollToItem(item?.type+i)"
      (keyup)="scrollToItem(item?.type+i)" [ngClass]="{'checked': item?.type+i === selectedRadioValue}"></span>
  </div>
</div>