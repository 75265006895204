import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { EntityState } from '../../reducers';
import * as OffersAction from '../../actions';

@Injectable()
export class OffersDispatchers {
    constructor(private store: Store<EntityState>) { }

    getBagOffers() {
        this.store.dispatch(OffersAction.getBagsOffers());
    } 
}
