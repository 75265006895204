import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { fadeAnimation, slideAnimation } from '~app/animations/animations';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { SessionSelectors, SessionDispatchers, RouterDispatchers, AnalyticsDispatchers } from '~app/store';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { ModalsService } from '~app/modals/modals.service';

@Component({
  selector: 'app-boardingpass-page',
  templateUrl: './boarding-pass-page.component.html',
  styleUrls: ['./boarding-pass-page.component.scss'],
  animations: [
    fadeAnimation,
    slideAnimation,
  ],
})
@ContentSchema({
  name: 'BoardingPassPage',
  description: 'Check-In Result',
  props: [
    { name: 'boardingPass', type: PropertyType.Text },
    { name: 'boardingPasses', type: PropertyType.Text },
    { name: 'exit', type: PropertyType.Text },
    { name: 'print', type: PropertyType.Text },
    { name: 'downloadPdf', type: PropertyType.Text },
    { name: 'email', type: PropertyType.Text },
    { name: 'mainHeaderText', type: PropertyType.Text }
  ]
})
export class BoardingPassPageComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  resultData: Response<Trip>;
  resultData$: Observable<Response<Trip>>;
  fullTripData$: Observable<any[]>;
  tripData$: Observable<any[]>;
  sessionState$: Observable<ISessionState>;

  private subscriptions: Subscription = new Subscription();
  boardingPassRendering: ComponentRendering;
  trips: any[];
  fullTripData: any[];
  allTrips: any[] = [];
  destinationCode: string;

  constructor(
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private routerDispatchers: RouterDispatchers,
    private analyticsDispatchers: AnalyticsDispatchers,
    private modalsService: ModalsService,
  ) {
    this.resultData$ = this.sessionSelectors.sessionResultData$;
    this.fullTripData$ = this.sessionSelectors.sessionFullTripData$;
    this.tripData$ = this.sessionSelectors.sessionFullTripData$;
    this.sessionState$ = this.sessionSelectors.session$;
  }

  ngOnInit() {
    this.boardingPassRendering = (this.rendering.components['boarding-pass'] || [{}])[0];
    this.subscriptions.add(this.resultData$.subscribe(
      resultData => (resultData)
        ? this.resultData = resultData
        : this.sessionDispatchers.getSessionResultData())
    );
    this.subscriptions.add(this.fullTripData$.subscribe(
      fullTripData => (fullTripData)
        ? this.fullTripData = fullTripData
        : this.sessionDispatchers.getSessionFullTripData())
    );
    this.subscriptions.add(this.tripData$.subscribe(
      trip => this.trips = trip)
    );
    if (!!this.trips) {
      if (this.trips.some(bp => bp.dhsStatus === 'SELECTEE')) {
        this.modalsService.openGenericErrorMessage({ error: 'CI16' });
        return;
      }
      // Format trip to handle Infant Boarding pass and trip index
      this.formatTrips();
    }
    this.analyticsDispatchers.boardingPassPageLoaded();
  }

  formatTrips(): void {
    let tripIndex = 1;
    this.trips.forEach(trip => {
      this.allTrips.push({
        ...trip,
        boardingPassIndex: tripIndex++,
        isInfant: false
      });
      if (trip.hasInfant) {
        // Creating new Trip Object for Infant boarding pass
        this.allTrips.push({
          ...trip,
          boardingPassIndex: tripIndex++,
          hasInfant: false,
          isInfant: true,
          passengerName: {
            firstName: trip.lapInfant.infantName.firstName,
            lastName: trip.lapInfant.infantName.lastName
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onExit(): void {
    this.sessionDispatchers.clearFullTripData();
    this.routerDispatchers.routeToCheckin(CHECKIN_ROUTES.ROUTE_BOARDING_PASS_PAGE.component);
  }

  onPrintBp(): void {
    try {
      if(!document.execCommand('print', false, null)) {
        window.print();
      }
    } catch {
      window.print();
    }
  }
}
