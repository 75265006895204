import { AmadeusPaymentEnvironment } from '@hawaiianair/payment';
import { HeaderKeys } from '~app/models/payment-headers.model';
import { Environment } from './checkin-environment-interface';

export const environment: Environment = {
  production: true,
  contentApiHost: "/",
  appName: "checkin-angular-web",
  appId: "298dab6a-1f5a-4c07-b792-2212e5e93408",
  appPath: "checkin",
  allowedLocales: "en,ja,ko,zh,zh-Hant,en-NZ,en-AU",
  defaultLanguage: "en",
  defaultCountry: "us",
  defaultCurrency: "USD",
  externalDomain: "flightcheckin.hawaiianairlines.com",
  defaultServerRoute: "/",
  updateChromeOnLanguageChange: true,
  pageChromeRoute: "/page-chrome",
  ldClientSideId: "5c9ad6eb4de8100813855a4c",
  buildInfoCheckMinutes: 5,
  buildArtifactsPath: "./dist",
  deployRootPath: "/",
  logging: {
    logToConsole: false,
    instrumentationKey: 'f3b46137-5868-43fd-8629-034880a00645', // insert production ApplicationInsights key here
  },
  api: {
    baseUrl: 'https://public.itservices.hawaiianairlines.com',
    basePath: {
      default: 'exp-web-day-of-travel/v2/api',
    },
    tokenEnv: 'prod',
    headers: {
      xHaChannel: {
        // @ts-ignore
        key: HeaderKeys.XHaChannel,
        value: 'WEB'
      },
      xHaBusinessDomain: {
        // @ts-ignore
        key: HeaderKeys.XHaBusinessDomain,
        value: 'CHECKIN'
      },
      contentType: {
        // @ts-ignore
        key: HeaderKeys.ContentType,
        value: 'application/json'
      },
      xRootCorrelationId: {
        // @ts-ignore
        key: HeaderKeys.XRootCorrelationId,
        value: ''
      },
      Authorization: {
        // @ts-ignore
        key: HeaderKeys.Authorization,
        value: ''
      },
      xRootSessionId: {
        // @ts-ignore
        key: HeaderKeys.XRootSessionId,
        value: ''
      }
    },
  },
  paymentProfileId: 'qfNwRH',
  expWebCheckinApiAuth: "cb554e00004a4e01be961da701463f02:8dF6d09EFecf45daA7Fd22c8b69bE5b6",
  "exp-web-checkin-v2-api": "https://public.itservices.hawaiianairlines.com/exp-web-day-of-travel/v2/api",
  customerInternalReference: "WEB_CHECKIN_SPA",
  amadeusPaymentApi: {
    url: 'https://hawaiian.airlines.api.amadeus.com/v1',
    primaryAccount: {
      clientId: 'j5bCWtBlW10NukXrTtaevX8StGGv5Adi',
      clientSecret: '7k0xZMt4oxPl80kA',
    },
    secondaryAccount: {
      clientId: 'PROD_AMADEUS_CHECKOUT_SDK_SECONDARY_CLIENT_ID',
      clientSecret: 'PROD_AMADEUS_CHECKOUT_SDK_SECONDARY_CLIENT_SECRET',
    },
    officeId: 'HNLHA08CI',
    sdkUrl: 'https://paypages.payment.amadeus.com/1ASIATP/ARIAPP/static/checkout/2.1.1/sdk-es2015.js',
    sdkId: 'SDKWEB-TOKENIZATION-CHECKIN',
    environment: AmadeusPaymentEnvironment.prd
  },
  taggingScript: 'https://assets.adobedtm.com/e272d7f0f44e/04b2c583e809/launch-5554b1a511c6.min.js',
  spaDisabledUrls: {
    'ja-jp': 'https://www.hawaiianairlines.co.jp/system-maintenance',
    'ko-kr': 'https://www.hawaiianairlines.co.kr/system-maintenance',
    'en-au': 'https://www.hawaiianairlines.com.au/system-maintenance',
    'en-nz': 'https://www.hawaiianairlines.co.nz/system-maintenance',
    'en-us': 'https://www.hawaiianairlines.com/system-maintenance',
  }
};
