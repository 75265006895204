import { createAction, props } from '@ngrx/store';
import { Params } from '@angular/router';
import { Segment } from '~app/models/segment.model';

export const routeToSuccess = (actionType: string) =>
    createAction(actionType);

export const routeToWithParamsAction = (actionType: string) =>
    createAction(actionType, props<{componentName: string, queryParams: Params}>());

export const routeToAction = (actionType: string) =>
    createAction(actionType, props<{componentName: string}>());

export const routeToWithData = (actionType: string) =>
    createAction(actionType, props<{componentName: string, data?: any}>());

export const getTripSuccessRouting = createAction(
    '[Get Trip API Success] Get Trip Routing'
);

export const defaultNavButtonClick = (actionType: string) =>
    createAction(`${actionType} Default Nav Button Click`);

export const secondaryNavButtonClick = (actionType: string) =>
    createAction(`${actionType} Secondary Nav Button Click`);

export const savePassengersOnRoute = createAction(
  '[Passengers] Save Passengers'
);

export const setSelectedSegmentRouting = createAction(
    '[Set Selected Segment Success Effect] Set Selected Segment Routing',
    props<{componentName: string, segment: Segment}>()
);

export const routeToPassengers = routeToWithData(
    'Route To Passengers Component'
);

export const routeToGroupCheckin = routeToWithData(
    'Route To Group Checkin Component'
);

export const routeToResult = routeToWithData(
    'Route To Result Component'
);

export const routeToCheckin = routeToAction(
    'Route To Checkin'
);

export const routeToSegmentSelector = routeToAction(
    'Route To Segment Selector'
);

export const routeToMilitary = routeToAction(
    'Route To Military'
);

export const routeToGuidelines = routeToAction(
  'Route To Guidelines'
);

export const routeToHealthAttestation = routeToWithData(
    'Route To Health Attestation',
);

export const routeToDashboard = routeToWithData(
    'Route To Dashboard'
);

export const routeToSeats = routeToAction(
  'Route To Seats',
);

export const routeToSeatUpgradeList = routeToAction(
    'Route To Seat Upgrade List'
);

export const routeToSeatUpgrade = routeToAction(
  'Route To Seat Upgrade'
);

export const routeToBaggage = routeToAction(
  'Route To Baggage',
);

export const routeToBagAdvisory = routeToAction(
    'Route To Bag Advisory',
);

export const actionRouting = createAction(
    '[DashBoard] Action Routing',
    props<{actions: string[]}>()
);

export const actionRoutingSuccess = createAction(
    '[Dashboard] Action Routing Success',
    props<{routerState: any, sortedActions: any}>()
);

export const routeToSeatMapById = routeToWithParamsAction(
  '[Seat Map] Route To Seat Map By Id',
);

export const routeToInfantAdditionalInfo = routeToWithData(
    'Route To Infant Additional Info'
);

export const routeToPassportInformation = routeToAction(
    'Route To Passport Information Page'
);

export const routeToResidenceInformation = routeToAction(
  'Route To Residence Information Page'
);

export const routeToTravelInformation = routeToAction(
  'Route To Travel Information Page'
);

export const routeToEmergencyContact = routeToAction(
  'Route To Emergency Contact Page'
);

export const routeToBagSpecialItems = routeToWithParamsAction(
    'Route To Bag Special Items'
);

export const routeToInfant = routeToAction(
    'Route To Infant'
);

export const routeToPayment = routeToAction(
  'Route To Payment'
);

export const routeToBoardingPass = routeToAction(
    'Route To Boarding Pass'
);

export const routeToPassengerInformation = routeToAction(
    'Route To Passenger Information'
);

export const openCheckinConfirmationModal = routeToAction(
    'Open checkin confirmation modal'
);

export const openCancelConfirmationModal = routeToAction(
    'Open checkin cancelation modal'
);

export const routeToGroupBookingPage = routeToAction(
    'Route To Group Booking Page'
);

export const routeToLinMinorToAdultPage = routeToAction(
    'Route To Link Minor To Adult Page'
);

export const routeToContactTracing = routeToAction(
    'Route to Contact Tracing Page'
);
