<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="!segmentDetails"></app-checkin-header>
<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(militaryLoading$ | async)">
  <div id="military-save-type-spinner" class="spinner-border" role="status"></div>
</div>
<div *ngIf="!segmentDetails">
  <app-checkin-loader></app-checkin-loader>
</div>
<div *ngIf="!!segmentDetails" class="container background">
  <div class="row">
    <div class="col px-0 px-md-4">
      <div class="offset-md-6 d-flex justify-content-around" *ngIf="segmentDetails.length > 1">
        <button id="select-all-orders-button" class="aloha-pink body-copy d-none d-xl-block select-all-button ml-5 pl-5"
          mat-button (click)="selectAll('Orders')" disableRipple> {{rendering.props['selectAll']}} </button>
        <button id="select-all-leisure-button" class="aloha-pink body-copy d-none d-xl-block select-all-button"
          mat-button (click)="selectAll('Leisure')" disableRipple> {{rendering.props['selectAll']}} </button>
      </div>
      <div *ngFor='let detail of segmentDetails; let paxIndex = index'>
        <app-military-pax-card [rendering]="paxMilitaryCardRendering" [paxDetail]="detail" [paxIndex]="paxIndex"></app-military-pax-card>
      </div>
    </div>
  </div>
  <div class="military-id-text">
    <div class="container">
      <div id="military-id-title" class="row bold" *haText="rendering.props['idRequiredTitle']"></div>
      <div id="military-id-description" class="row military-id-description" *haText="rendering.props['idRequiredDescription']"></div>
    </div>
  </div>
</div>
<app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
