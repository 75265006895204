import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { PassportScanDocument } from "~app/models/passportscandata.model";
import * as PassportScanActions from '~app/store/actions/passport-scan.actions';
import { EntityState } from '~app/store/reducers';

@Injectable()
export class PassportScanDispatchers {
  constructor(private store: Store<EntityState>) { }

  initiatePassportScan(paxId: string) {
    this.store.dispatch(PassportScanActions.initiatePassportScan({ paxId }));
  }

  getPassportScanStatus(scanReference: string) {
    this.store.dispatch(PassportScanActions.getPassportScanStatus({ scanReference }));
  }

  getPassportScanData(scanReference: string, passport?: { paxId: string, passportDocument: PassportScanDocument }) {
    this.store.dispatch(PassportScanActions.getPassportScanData(passport ? { scanReference, passport } : { scanReference }));
  }

  showMaxPassportScanError(err: any) {
    this.store.dispatch(PassportScanActions.getPassportScanDataError({ error: 'max jumio attempts', requestData: err }));
  }
}
