import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { SecurityInfo } from '~app/models/securityinfo.model';
import { AnalyticsDispatchers } from '~app/store';
import { SecurityInfoDispatchers } from '~app/store/services/security-info/security-info.dispatchers';
import { SecurityInfoSelectors } from '~app/store/services/security-info/security-info.selectors';

@Component({
  selector: 'app-international-traveler-selection',
  templateUrl: './international-traveler-selection.component.html',
  styleUrls: ['./international-traveler-selection.component.scss']
})

@ContentSchema({
  description: 'InternationalTravelerSelectionComponent',
  name: 'InternationalTravelerSelection',
  props: [
      { name: 'mainTitleText', type: PropertyType.Text },
      { name: 'tileAddButton', type: PropertyType.Text },
      { name: 'passengerDone', type: PropertyType.Text },
      { name: 'navButtons', type: PropertyType.Object },
  ]
})

export class InternationalTravelerSelectionComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  subs: Subscription = new Subscription();
  passengerCardInformation: any;
  loading$: Observable<boolean>;
  securityInfo$: Observable<SecurityInfo[]>;
  hasAllTravelDocs$: Observable<boolean>;

  constructor(
    private securityInfoDispatcher: SecurityInfoDispatchers,
    private securityInfo: SecurityInfoSelectors,
    private AnalyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.loading$ = this.securityInfo.securityInfoLoading$;
    this.securityInfo$ = this.securityInfo.securityInfo$;
    this.hasAllTravelDocs$ = this.securityInfo.hasAllTravelDocs$;
  }

  ngOnInit(): void {
    this.subs.add(this.securityInfo$.subscribe(securityInfo => {
      if (!!securityInfo) {
        this.passengerCardInformation = securityInfo.map(info => {
          return {
              ...info.passengerInfo,
              hasTravelDoc: !!info.travelDocuments
          };
        });
      }
    }));
    this.AnalyticsDispatchers.internationalTravelerSelectionPageLoaded();
  }

  addTravelerInfo(travelerId: string): void {
    this.securityInfoDispatcher.travelerSelectionAddInfo(travelerId);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
