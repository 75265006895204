import {ActionReducer, INIT} from '@ngrx/store';
import * as sessionActions from '~app/store/actions/session.actions';
import { EntityState } from '.';

export function clearEntityState(reducer: ActionReducer<EntityState>): ActionReducer<EntityState> {
  return (state, action) => {
    if ( action != null && action.type === sessionActions.clearSessionDataCheckinStart.type) {
      return reducer( undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}

