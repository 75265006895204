import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { InfantComponent } from './add-infant/infant.component';
import { InfantAdditionalInfoComponent } from './infant-additional-info/infant-additional-info.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatCardModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule,
        CoreModule.forChild({
            components: [
                InfantComponent,
                InfantAdditionalInfoComponent
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatCardModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSelectModule
    ],
    declarations: [
        InfantComponent,
        InfantAdditionalInfoComponent
    ],
})
export class InfantModule { }
