import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '~app/store/reducers';
import { Store } from '@ngrx/store';
import { TripState } from '~app/store/reducers/trip/trip.reducers';

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getTripState = createSelector(
    getEntityState,
    (state: EntityState) => state.trip
);

export const getTrip = createSelector(
    getTripState,
    (state: TripState) => state.trip
);

export const getTripLoading = createSelector(
    getTripState,
    (state: TripState) => state.loading
);

export const getMilitaryLoading = createSelector(
    getTripState,
    (state: TripState) => state.militaryLoading
);

export const getSavingLapInfantInfo = createSelector(
    getTripState,
    (state: TripState) => state.savingLapInfantInfo
);

export const getTripReset = createSelector(
    getTripState,
    (state: TripState) => state.reset
);

export const isMultiSegment = createSelector(
    getTripState,
    (state: TripState) => state.isMultiSegment
);

export const getIsTripCapable = createSelector(
    getTripState,
    (state: TripState) => state.isCapable
);

export const getTripError = createSelector(
    getTripState,
    (state: TripState) => state.error
);

export const getTripEntries = createSelector(
    getTripState,
    (state: TripState) => state.entries
);

export const getTripPassengersList = createSelector(
    getTripState,
    (state: TripState) => state.passengerList
);

export const isSinglePax = createSelector(
    getTripState,
    (state: TripState) => state.isSinglePax
);

export const isSinglePaxCheckin = createSelector(
    getTripState,
    (state: TripState) => state.isSinglePaxCheckin
);

export const getTripId = createSelector(
  getTripState,
  (state: TripState) => state.tripId
);

export const getTripSavingPassengerInformation = createSelector(
    getTripState,
    (state: TripState) => state.savingPassengerInfo
);

export const militaryPaxInformation = createSelector(
    getTripState,
    (state: TripState) => state.militaryPaxInformation
);

export const isInternational = createSelector(
    getTripState,
    (state: TripState) => state.isInternational
);

export const getTripFlightEntries = createSelector(
  getTripState,
  (state: TripState) => state.flightEntries
);

export const getGroupPassengerList = createSelector(
    getTripState,
    (state: TripState) => state.groupPassengerList
  );

export const isGroupBooking = createSelector(
    getTripState,
    (state: TripState) => state.isGroupBooking
);

export const getSavingAdultToMinorInfo = createSelector(
    getTripState,
    (state: TripState) => state.savingAdultToMinorInfo
);

@Injectable()
export class TripSelectors {
    constructor(private store: Store<EntityState>) {}

    trip$ = this.store.select(getTrip);
    tripState$ = this.store.select(getTripState);
    tripLoading$ = this.store.select(getTripLoading);
    tripReset$ = this.store.select(getTripReset);
    tripIsMultiSegment$ = this.store.select(isMultiSegment);
    tripError$ = this.store.select(getTripError);
    tripEntries$ = this.store.select(getTripEntries);
    tripIsCapable$ = this.store.select(getIsTripCapable);
    tripPassengersList$ = this.store.select(getTripPassengersList);
    isSinglePax$ = this.store.select(isSinglePax);
    isSinglePaxCheckin$ = this.store.select(isSinglePaxCheckin);
    tripId$ = this.store.select(getTripId);
    militaryLoading$ = this.store.select(getMilitaryLoading);
    savingLapInfantInfo$ = this.store.select(getSavingLapInfantInfo);
    tripSavingPassengerInformation$ = this.store.select(getTripSavingPassengerInformation);
    militaryPaxInformation$ = this.store.select(militaryPaxInformation);
    isInternational$ = this.store.select(isInternational);
    tripFlightEntries$ = this.store.select(getTripFlightEntries);
    groupPassengerList$ = this.store.select(getGroupPassengerList);
    isGroupBooking$ = this.store.select(isGroupBooking);
    savingAdultToMinorInfo$ = this.store.select(getSavingAdultToMinorInfo);
}
