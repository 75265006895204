import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable } from 'rxjs';
import { Constants } from '~app/constants/ha-constants';
import { Catalog } from '~app/models/catalog.model';
import { Product } from '~app/models/product.model';
import { Segment } from '~app/models/segment.model';
import { CatalogSelectors, SessionSelectors } from '~app/store';

export interface SeatUpsellData {
  imgClass: string;
  imgTitle: string;
  imgCost: string;
  description: string;
}
@Component({
  selector: 'app-seat-upsell-grid',
  templateUrl: './seat-upsell-grid.component.html',
  styleUrls: ['./seat-upsell-grid.component.scss'],
})
@ContentSchema({
  description: 'Seat Upsell Grid',
  name: 'SeatUpsellGrid',
  props: [
    { name: 'seeAvailableSeats', type: PropertyType.Text },
    { name: 'imgCost', type: PropertyType.Text },
    { name: 'from', type: PropertyType.Text },
    { name: 'usd', type: PropertyType.Text },
    { name: 'extraComfort', type: PropertyType.Object },
    { name: 'premiumCabin', type: PropertyType.Object }
  ]
})
export class SeatUpsellGridComponent implements OnInit {
  @Input() flightId: string;
  @Input() rendering: ComponentRendering;
  upsellItems = [];
  selectedRadioValue: string;
  selectedIndex: number;
  catalogSeats$: Observable<Catalog>;
  selectedSegment$: Observable<Segment>;
  premiumSeatCatalog: Product;
  firstClassCatalog: Product;
  isMCB = false;
  isCoach = false;
  isPremiumSeat = false;
  isFirstClass = false;
  marketType: string;

  constructor(
    private catalogSelectors: CatalogSelectors,
    private sessionSelectors: SessionSelectors
  ) {
    this.catalogSeats$ = this.catalogSelectors.catalogSeats$;
    this.selectedSegment$ = this.sessionSelectors.sessionSelectedSegment$;
  }


  ngOnInit() {
    this.selectedSegment$.subscribe(segment => {
      this.initSegmentDetails(segment);
    });

    this.catalogSeats$.subscribe(seats => {
      this.initSeatsCatalog(seats);
    });
  }

  initSegmentDetails(segment: Segment) {
    if (segment) {
      segment.details.forEach(detail => detail.flightDetails.forEach(flightDetail => {
        if (flightDetail.flightId === this.flightId) {
          this.marketType = flightDetail.marketType;
          if (flightDetail.seatCategory === Constants.seatClassTypeCoach) {
            this.isCoach = true;
          } else if (flightDetail.seatCategory === Constants.seatClassTypeExtraComfort) {
            this.isPremiumSeat = true;
          } else if (flightDetail.seatCategory === Constants.seatClassTypeFirstClass) {
            this.isFirstClass = true;
          } else if (flightDetail.bookingClass === Constants.bookingClassMCB) {
            this.isMCB = true;
          }
        }
      }));
    }
  }

  initSeatsCatalog(seats: Catalog) {
    if (seats) {
      const flightSeats = seats.entries.find(entry => entry.flightId === this.flightId);
      if (flightSeats) {
        this.premiumSeatCatalog = flightSeats.products.find(product => product.type === Constants.premiumSeatProductType);
        this.firstClassCatalog = flightSeats.products.find(product => product.type === Constants.firstClassSeatProductType);
        if (this.premiumSeatCatalog || this.firstClassCatalog) {
          this.initUpsellItems();
        }
      }
    }
  }

  initUpsellItems() {
    this.upsellItems = [];

    if (!(this.isMCB || this.isFirstClass)) {
      if ( this.isCoach || this.isPremiumSeat) {
        if (this.isCoach) {
          if (this.premiumSeatCatalog && this.premiumSeatCatalog.priceQuotes && this.premiumSeatCatalog.priceQuotes[0]
              && this.premiumSeatCatalog.priceQuotes[0].quantity > 0) {
              this.upsellItems.push({
                ...this.rendering.props["extraComfort"],
                price: this.premiumSeatCatalog.priceQuotes[0].totalPrice
              });
          }
        }

        if (this.firstClassCatalog && this.firstClassCatalog.priceQuotes && this.firstClassCatalog.priceQuotes[0]
          && this.firstClassCatalog.priceQuotes[0].quantity > 0) {
          this.upsellItems.push({
            ...this.rendering.props["premiumCabin"],
            price: this.firstClassCatalog.priceQuotes[0].totalPrice
          });
        }
      }
    }
    if (this.upsellItems && this.upsellItems[0]) {
      this.selectedRadioValue = this.upsellItems[0].type + "0";
    }
  }

  scrollToItem(id: string) {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({behavior: 'smooth'});
      this.selectedRadioValue = id;
    }
  }

  swipe(change: number) {
   const currentIndex = this.upsellItems.findIndex((item, index) => item.type + index === this.selectedRadioValue);
   const changedIndex = currentIndex - change;
   if (changedIndex >= 0 && changedIndex < this.upsellItems.length) {
     this.selectedRadioValue = this.upsellItems[changedIndex].type + changedIndex;
     this.scrollToItem(this.selectedRadioValue);
   }
  }
}
