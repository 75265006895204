import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';

@Component({
    selector: 'app-seat-upgrade-app-banner',
    templateUrl: './seat-upgrade-app-banner.component.html',
    styleUrls: ['./seat-upgrade-app-banner.component.scss']
})

@ContentSchema({
    name: 'SeatUpgradeAppBanner',
    description: 'SeatUpgradeAppBannerComponent',
    props: [
        { name: 'heading', type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text },
        { name: 'qrDescription', type: PropertyType.Text }
    ]
})

export class SeatUpgradeAppBannerComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  getOurAppRendering: ComponentRendering;

  ngOnInit(): void {
    this.getOurAppRendering = (this.rendering.components['get-our-app'] || [{}])[0];
  }

}
