import { createAction, props } from '@ngrx/store';
import { PassengerRegulatoryDetails } from '~app/models/passengerRegulatoryDetails.model';
import { RegulatoryDetails } from '~app/models/internationalDetails.model';
import { PassengerAddress } from '~app/models/passengerAddress.model';
import { EmergencyContactInformation } from '~app/models/emergencyContact.model';
import { ContactTracingDetails } from '~app/models/contactTracingDetails.model';

export const getMissingRegulatoryDetails = createAction(
  '[International Checkin] Get Missing Regulatory Details',
  props<{ componentName: string, skipRouting?: boolean }>()
);

export const getMissingRegulatoryDetailsSuccess = createAction(
  '[International Checkin] Get Missing Regulatory Details Success',
  props<{ details: RegulatoryDetails }>()
);

export const getMissingRegulatoryDetailsError = createAction(
  '[International Checkin] Get Missing Regulatory Details Error',
  props<{ error: any }>()
);

export const postPassportInformation = createAction(
  '[International Checkin] Post Passport',
);

export const postPassportInformationSuccess = createAction(
  '[International Checkin] Post Passport Success',
);

export const postPassportInformationError = createAction(
  '[International Checkin] Post Passport Error',
  props<{ error: any }>(),
);

export const postAddressInformation = createAction(
  '[International Checkin] Post Address',
  props<{ passengerAddress: PassengerAddress[] }>()
);

export const postAddressInformationSuccess = createAction(
  '[International Checkin] Post Address Success',
);

export const postAddressInformationError = createAction(
  '[International Checkin] Post Address Error',
  props<{ error: any }>()
);

export const postEmergencyContactInformation = createAction(
  '[International Checkin] Post Emergency Contact',
  props<{ emergencyContactInformation: EmergencyContactInformation[] }>(),
);

export const postEmergencyContactInformationSuccess = createAction(
  '[International Checkin] Post Emergency Contact Success',
);

export const postEmergencyContactInformationError = createAction(
  '[International Checkin] Post Emergency Contact Error',
  props<{ error: any }>(),
);

export const addPersonalRegulatoryDetails = createAction(
  '[International Checkin] Add Personal Regulatory Details',
  props<{ passengerRegulatoryDetails: PassengerRegulatoryDetails[] }>()
);

export const addPersonalRegulatoryDetailsSuccess = createAction(
  '[International Checkin] Add Personal Regulatory Details Success',
);

export const addPersonalRegulatoryDetailsError = createAction(
  '[International Checkin] Add Personal Regulatory Details Error',
  props<{ error: any }>()
);

export const addNationalityCode = createAction(
  '[International Checkin] Add Nationality Code',
  props<{ passengerRegulatoryDetails: PassengerRegulatoryDetails[] }>()
);

export const addNationalityCodeSuccess = createAction(
  '[International Checkin] Add Nationality Code Success',
);

export const addNationalityCodeError = createAction(
  '[International Checkin] Add Nationality Code Error',
  props<{ error: any }>()
);

export const postContactTracingInformation = createAction(
  '[International Checkin] Post Contact Tracing Information',
  props<{ contactTracingDetails: ContactTracingDetails }>()
);

export const postContactTracingInformationSuccess = createAction(
  '[International Checkin] Post Contact Tracing Information Success',
  props<{ paxId: string }>()
);

export const postContactTracingInformationForAllPassengers = createAction(
  '[International Checkin] Post Contact Tracing Information If User Select Same Information For All Users',
  props<{ contactTracingDetails: ContactTracingDetails[] }>()
);

export const postContactTracingInformationForAllPassengersSuccessFinished = createAction(
  '[International Checkin] Post Contact Tracing Information Success for Multiple Selected Users',
  props<{ submittedPaxIds: string[], total: number }>()
);

export const postContactTracingInformationError = createAction(
  '[International Checkin] Post Contact Tracing Information Error',
  props<{ error: any, paxId: string }>()
);
