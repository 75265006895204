<div *ngIf="!!content && !isBagPage" class="row special-items-container">
  <div class="d-none d-sm-block col-sm-2">
    <div *ngIf="content.icon === 'icon-checked-bags'; else cartContentIcons" class="{{content.icon}} special-bags-icon"></div>
    <ng-template #cartContentIcons>
      <div class="{{content.icon}} special-bags-icon icon-64"></div>
    </ng-template>
  </div>
  <div class="col-6 col-sm-5 text-left">
    <span id="bags-item-txt-{{tagId}}" class="body-copy">{{content.title}}</span>
  </div>
  <div class="col-6 col-sm-5 ml-auto">
    <div class="row bags-row">
      <div class="col*">
        <i id="remove-bag-{{tagId}}" [ngClass]="count === 0 ? 'icon-circleminus icon-desc-gray' : 'icon-circleminus icon-desc'"
          (click)="saveBags(-1)"
          (keydown.enter)="saveBags(-1)"
          (keydown.space)="saveBags(-1)"
          aria-label="Remove bag"
          role="button"
          [tabIndex]="count === 0 ? -1 : 0"
          title="Remove bag"></i>
      </div>
      <div class="bold col* mr-4 ml-4">
        <span id="bags-count-{{tagId}}" class="body-copy">{{count}}</span>
      </div>
      <div class="col*">
        <i id="add-bag-{{tagId}}" class="icon-circleplus icon-desc"
          (click)="saveBags(1)"
          (keydown.enter)="saveBags(1)"
          (keydown.space)="saveBags(1)"
          aria-label="Add bag"
          role="button"
          tabindex="0"
          title="Add bag"></i>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!!content && isBagPage" class="row bags-row">
  <div class="col-md-7">
    <div class="passenger-name text-left row align-content-center">
      <div id="passenger-name-{{tagId}}" class="col-12 subtitle-bold">{{associatedPax.passengerName | titlecase }}</div>
      <div id="passenger-bags-additional-info" class="col">
        <span *ngIf="!!associatedPax?.militaryTravelType" id="military-type-{{tagId}}" class="mr-5 small">
          <i class="icon-elite-benefits"></i>
          {{content.militaryIndicator}}
        </span>
        <span id="added-bags-count-{{tagId}}" *ngIf="associatedPax.alreadyAddedRegularBags > 0" class="small">
          {{ bagsAlreadyAdded }}
        </span>
        <span id="free-bags-count-{{tagId}}" *ngIf="associatedPax.alreadyAddedRegularBags === 0 && associatedPax.freeBagsCount > 0" class="small">{{associatedPax.freeBagsCount}}
          {{content.free}}</span>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <div *ngIf="!sessionData.isOAFlight || (sessionData.isOAFlight && isOARegularBagsEnabled); else showOperatedByOADescription" class="row bags-row regular-bags-row">
      <div class="col d-md-none d-block">
        <span id="content-title" class="text-left d-block d-md-none body-copy">{{content.title}}</span>
      </div>
      <div class="col* d-none d-md-block mr-4">
        <span id="total-cost-per-pax" *ngIf="associatedPax.bagsTotalCost > 0" class="body-copy">{{associatedPax.bagsTotalCost | customCurrency}}</span>
      </div>
      <div class="col*">
        <i id="remove-bag-{{tagId}}" [ngClass]="count === 0 ? 'icon-circleminus icon-desc-gray' : 'icon-circleminus icon-desc'"
          (click)="saveBags(-1)"
          (keydown.enter)="saveBags(-1)"
          (keydown.space)="saveBags(-1)"
          aria-label="Remove bag"
          role="button"
          [tabIndex]="count === 0 ? -1 : 0"
          title="Remove bag"></i>
      </div>
      <div class="col*">
        <span id="bags-count-{{tagId}}" class="subtitle-bold mr-4 ml-4 d-none d-md-block">{{count}}</span>
        <span id="bags-count-{{tagId}}" class="body-copy-bold medium mr-4 ml-4 d-block d-md-none">{{count}}</span>
      </div>
      <div class="col*">
        <i id="add-bag-{{tagId}}" class="icon-circleplus icon-desc"
          (click)="saveBags(1)"
          (keydown.enter)="saveBags(1)"
          (keydown.space)="saveBags(1)"
          aria-label="Add bag"
          role="button"
          tabindex="0"
          title="Add bag"></i>
      </div>
    </div>
    <ng-template #showOperatedByOADescription>
      <div class="row bags-row regular-bags-row">
        <div class="col*">
          <span class="text-left body-copy-bold medium d-block">{{ operatedByDescription }}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>
