import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { fadeAnimation, slideAnimation } from '~app/animations/animations';
import { SessionData } from '~app/models/sessiondata.model';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import {
  TripSelectors, SessionSelectors, SessionDispatchers,
  FlightSelectors, AIRLINE_CODE_HA, RouterDispatchers, AnalyticsDispatchers, TripDispatchers
} from '~app/store';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { Flight } from '~app/models/flight.model';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { ModalsService } from '~app/modals/modals.service';
import { AirportMap } from '~app/models/airport.model';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { Constants } from '~app/constants/ha-constants';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
  animations: [
    fadeAnimation,
    slideAnimation,
  ],
})
@ContentSchema({
  name: 'Result',
  description: 'Check-In Result',
  props: [
    { name: 'stopCounter', type: PropertyType.Text },
    { name: 'OAflightCheckedInTitle', type: PropertyType.Text },
    { name: 'oaCheckedInMessage', type: PropertyType.Text },
    { name: 'oaNotCheckedInMessage', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithoutCheckinItemsInternational', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItemsTitle', type: PropertyType.Text },
    { name: 'boardingPassWithCheckinItemsInternational', type: PropertyType.Text },
    { name: 'boardingPassBaggageInternational', type: PropertyType.Text },
    { name: 'loading', type: PropertyType.Text },
    { name: 'anotherSegmentButton', type: PropertyType.Text },
    { name: 'boardingTime', type: PropertyType.Text },
    { name: 'getBoardingPassText', type: PropertyType.Text },
    { name: 'getMobileBoardingPassText', type: PropertyType.Text },
    { name: 'getBoardingPassTitle', type: PropertyType.Text },
    { name: 'getBoardingPassPluralTitle', type: PropertyType.Text },
    { name: 'downloadPdf', type: PropertyType.Text },
    { name: 'gateNumber', type: PropertyType.Text },
    { name: 'emailBoardingPasses', type: PropertyType.Text },
    { name: 'nextStepsTitle', type: PropertyType.Text },
    { name: 'nextStepsText', type: PropertyType.Text },
    { name: 'checkedBagsBoldText', type: PropertyType.Text },
    { name: 'checkedBagsProceed', type: PropertyType.Text },
    { name: 'checkedBagsDescription', type: PropertyType.Text },
    { name: 'printBoardingPasses', type: PropertyType.Text },
    { name: 'printBoardingPass', type: PropertyType.Text }
  ]
})
export class ResultComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  infoLinksRendering: ComponentRendering;
  getOurAppRendering: ComponentRendering;
  footerRendering: ComponentRendering;

  sessionData: SessionData;
  resultData: Response<Trip>;
  resultData$: Observable<Response<Trip>>;
  fullTripData$: Observable<any[]>;
  loading$: Observable<Boolean>;
  sessionData$: Observable<SessionData>;
  sessionState$: Observable<ISessionState>;
  flight$: Observable<Response<Flight>>;
  isMultiSegment$: Observable<boolean>;
  isSinglePaxCheckin$: Observable<boolean>;
  isInternational$: Observable<boolean>;
  private subscriptions: Subscription = new Subscription();
  origin: string;
  destination: string;
  departure: string;
  flightStops: number;
  boardingTime: any;
  originGate: string;
  hasCheckedBags: boolean;
  haCode: string;
  passengerID: string;
  flightId: string;
  airports$: Observable<AirportMap>;
  oaCheckedInMessage: Array<string>;
  oaNotCheckedInMessage: Array<string>;

  constructor(
    private tripSelectors: TripSelectors,
    private sessionDispatchers: SessionDispatchers,
    private sessionSelectors: SessionSelectors,
    private flightSelectors: FlightSelectors,
    private modalContentsService: ModalContentsService,
    private modalsService: ModalsService,
    private routerDispatchers: RouterDispatchers,
    private airportSelectors: ReferenceDataSelectors,
    private dynamicContentService: DynamicContentService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private tripDispatchers: TripDispatchers
  ) {
    this.resultData$ = this.tripSelectors.trip$;
    this.loading$ = this.tripSelectors.tripLoading$;
    this.sessionData$ = this.sessionSelectors.session$;
    this.fullTripData$ = this.sessionSelectors.sessionFullTripData$;
    this.sessionState$ = this.sessionSelectors.session$;
    this.isSinglePaxCheckin$ = this.tripSelectors.isSinglePaxCheckin$;
    this.flight$ = this.flightSelectors.flight$;
    this.haCode = AIRLINE_CODE_HA;
    this.isMultiSegment$ = this.tripSelectors.tripIsMultiSegment$;
    this.airports$ = this.airportSelectors.airports$;
    this.isInternational$ = this.tripSelectors.isInternational$;
   }

  ngOnInit() {
    this.infoLinksRendering = (this.rendering.components['info-links'] || [{}])[0];
    this.getOurAppRendering = (this.rendering.components['get-our-app'] || [{}])[0];
    this.footerRendering = (this.rendering.components['web-footer'] || [{}])[0];
    this.setErrorModalContents();

    this.initCheckinResult();
    this.subscriptions.add(this.sessionState$.subscribe(state => {
      this.origin = state.origin;
      this.destination = state.destination;
      this.flightStops = state.flightCount - 1;
      this.rendering.props['stopCounter'] = this.dynamicContentService.getContentString(
        this.rendering.props['stopCounter'], { numberOfStops: this.flightStops }
      );
    }));

    this.subscriptions.add(this.flight$.subscribe(flight => {
      const flightInfo = flight?.results?.[0];
      if (flightInfo) {
        this.flightId = flightInfo?.id;
        this.departure = flightInfo?.scheduledDeparture?.airportDateTimeString;
        this.originGate = flightInfo?.status?.originGate;
        const tripFlightBoardingTime = this.resultData?.results?.[0]?.flights?.entries?.find(flight => flight?.id === this.flightId)?.scheduledBoardingTime?.airportDateTimeString;
        this.boardingTime = flightInfo?.status?.boardingStartTime?.airportDateTimeString ?? tripFlightBoardingTime;
      }
    }));

    this.subscriptions.add(this.fullTripData$.subscribe(fullTrip => {
      this.validateCheckin(fullTrip);

      if (fullTrip) {
        this.oaCheckedInMessage = fullTrip.map(trip =>
          this.dynamicContentService.getContentString(this.rendering.props['oaCheckedInMessage'], {
            otherAirline: trip.operatedByDescription,
            otherAirlineFlight: `${trip.operatedBy} ${trip.flightNumber}`,
            origin: trip.airportOriginLongName
          })
        );
        this.oaNotCheckedInMessage = fullTrip.map(trip =>
          this.dynamicContentService.getContentString(this.rendering.props['oaNotCheckedInMessage'], {
            otherAirline: trip.operatedByDescription,
            origin: trip.airportOriginLongName
          })
        );
      }
    }));
  }

  private initCheckinResult(): void {
    this.subscriptions.add(this.sessionData$.subscribe(sessionData =>
      (sessionData)
      ? this.sessionData = sessionData
      : this.sessionDispatchers.getState()
    ));

    this.getCheckinResult();
  }

  private getCheckinResult(): void {
    this.subscriptions.add(this.resultData$.subscribe(trip => {
      if (trip) {
        this.resultData = trip;
        this.hasCheckedBags = trip.results[0].segments.entries.some(entry => entry.details.some(segment => segment.bags.length > 0));
        this.passengerID = this.sessionData.passengerIds[0];
        this.analyticsDispatchers.resultPageLoaded({ trip });
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  clickInfoLink(link: string): void {
    // if (link === 'saveTripCalendar') {
    //   console.log('save trip to calendar');
    // } else if (link === 'tripDetailsStatus') {
    //   console.log('show trip details & status');
    // } else if (link === 'emailReceipt') {
    //   console.log('email receipt');
    // } else
    if (link === 'nextSteps') {
      this.modalsService.openNextStepsDialog(this.hasCheckedBags);
    }
  }

  routeToBoardingPass(): void {
    this.routerDispatchers.routeToBoardingPass(CHECKIN_ROUTES.ROUTE_RESULT.component);
  }

  anotherFlight(): void {
    this.routerDispatchers.routeToSegmentSelector(CHECKIN_ROUTES.ROUTE_RESULT.component);
  }

  private setErrorModalContents() {
    this.modalContentsService.addRendering('generic-error-modal', this.rendering.components['generic-error-modal']);
    this.modalContentsService.addRendering('boarding-pass-next-steps-modal', this.rendering.components['boarding-pass-next-steps-modal']);
  }

  validateCheckin(fullTrip: any[]): void {
    fullTrip?.forEach(flightDetail => {
      if (flightDetail.operatedBy === Constants.operatedByHA) {
        if (flightDetail.isCheckedIn === false || flightDetail.boardingPassData === "") {
          this.tripDispatchers.checkinError("checkin-error", fullTrip);
          return;
        }
      }
    });
  }
}
