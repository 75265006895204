import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-ha-input',
  templateUrl: './ha-input.component.html',
  styleUrls: ['./ha-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HaInputComponent),
      multi: true
    }
  ]
})

export class HaInputComponent implements ControlValueAccessor, OnInit, AfterViewInit  {
  @Input() inputId = '';
  @Input() label: string;
  @Input() list = '';
  @Input() control?: FormControl = new FormControl();
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() maxlength?: number;
  @Input() iconId?: string;
  @Input() icon?: string;
  @Input() ariaLabel?: string;
  @Input() autoFocus?: boolean;
  @Input() accessKey?: string;
  @Output() iconClicked?: EventEmitter<void> = new EventEmitter();
  @ViewChild('haInput', { static: true }) haInput: ElementRef;

  showLabel = false;
  hintId: string;
  value = '';
  onChange;
  onTouched;


  ngOnInit() {
    this.hint ? this.hintId = this.hint.replace(/\s/g, '') : this.hintId = '';
  }

  ngAfterViewInit() {
    if (!!this.autoFocus) {
      setTimeout(() => { this.haInput.nativeElement.focus(); }, 1);
    }
  }
  
  writeValue(value): void {
    if (this.value !== value) {
      this.value = value;
      this.control.patchValue(value);
      this.control.markAsTouched();
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }

  hasValidationErrors(): boolean {
    if (this.control) {
      if (this.control.errors && (this.control.dirty || this.control.touched)) {
        return true;
      }
    }
    return false;
  }
}
