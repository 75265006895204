import { RouterModule } from '@angular/router';
import { BagAdvisoryItemsComponent } from './bag-advisory-items/bag-advisory-items.component';
import { BagSpecialItemsComponent } from './bag-special-items/bag-special-items.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { BagQuantityComponent } from './bag-quantity/bag-quantity.component';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { BagsComponent } from './bags/bags.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
         // import Material Design Components
        MatButtonModule,
        MatIconModule,
        CoreModule.forChild({
            components: [
                BagsComponent,
                BagQuantityComponent,
                BagSpecialItemsComponent,
                BagAdvisoryItemsComponent
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatButtonModule,
        MatIconModule,
        RouterModule
    ],
    declarations: [
        BagsComponent,
        BagQuantityComponent,
        BagSpecialItemsComponent,
        BagAdvisoryItemsComponent
    ],
})

export class BaggageModule { }
