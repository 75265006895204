<div *ngIf="isCheckInView || (tripLoading$ | async) || (authLoading$ | async) || (airportLoading$ | async); else checkInStartPage">
  <app-checkin-header [hideHeaderInfo]="true"></app-checkin-header>
  <app-checkin-loader *ngIf="(tripLoading$ | async) || (authLoading$ | async) || (airportLoading$ | async)"></app-checkin-loader>
</div>
<ng-template #checkInStartPage>
  <section id="mainContentBox">
    <div class="row banner-small d-none d-md-block d-lg-block d-xl-block" *ngIf="!sessionData?.appBannerClose">
      <div class="col-md-12">
        <div class="row app-banner-small">
          <div class="col-sm-7">
            <div class="float-left">
              <img id="ha-logo" class="ha-logo" src="/media/images/logos/HANativeApp.png" alt="HA Logo">
            </div>
            <div class="row">
              <div class="col">
                <span *haText="rendering.props['appMessage']" class="app-message-header"></span> <br>
                <a class='call-out-bold link' href="https://www.hawaiianairlines.com/app">{{rendering.props['learnMore']}}</a>
              </div>
            </div>
          </div>
          <div class="col-sm-5 app-stores">
            <div class="row float-right">
              <div class="col* pt-2">
                <a *haLink="getOurAppRendering?.props?.appleStoreUrl">
                  <img id="apple-store" alt="Apple App Store" src="/media/images/logos/apple-store.png">
                </a>
              </div>
              <div class="col* pt-2 pl-3">
                <a *haLink="getOurAppRendering?.props?.googlePlayStoreUrl">
                  <img id="google-play" alt="Google Play Store" src="/media/images/logos/google-play.png">
                </a>
              </div>
              <div class="col* pl-4 pr-3 float-right">
                <span class="exit-icon nav-btn" (click)="appBannerClose()" (keyup)="appBannerClose()">&times;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 my-3">
        <p id="checkin-description" *haRichText="rendering.props['description']"></p>
      </div>
    </div>
    <app-find-trip-search *ngIf="findTripSearchRendering" [rendering]="findTripSearchRendering"></app-find-trip-search>
    <app-classic-web-login *ngIf="classicWebLoginRendering" [rendering]="classicWebLoginRendering"></app-classic-web-login>
    <div class="row mt-5">
      <div id="footer-text" class="col-md-12 text-left" *haRichText="rendering.props['footerDescription']"></div>
    </div>
  </section>
  <section class="top">
    <button (click)="scrollToTop()" class="toTheTop">
      <span class="aloha-pink icon-circleup icon20 toTheTopIcon"></span>
      <span class="toTheTopText">{{'Back to top' | translate}}</span></button>
  </section>
  <footer>
    <get-our-app *ngIf="getOurAppRendering" [rendering]="getOurAppRendering"></get-our-app>
    <web-footer *ngIf="footerRendering" [rendering]="footerRendering"></web-footer>
  </footer>
</ng-template>
