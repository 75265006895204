import { createAction, props } from '@ngrx/store';

export const getAircraftImage = createAction(
    '[Seatmap Component] Get Aircraft Image',
    props<{flightIndex: number}>()
);

export const getAircraftImageSuccess = createAction(
    '[Seatmap Component] Get Aircraft Image Success',
    props<{aircraftImage: string, flightIndex: number, aircraftSeatList: any}>()
);

export const getAircraftImageError = createAction(
    '[Seatmap Component] Get Aircraft Image Error',
    props<{ error: any }>()
);

export const resetAircraftImageState = createAction(
    '[Seatmap Component] Reset Aircraft Image State'
);
