import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperService } from '~app/services/helper.service';
import { SessionDispatchers } from '~app/store/services/session/session.dispatchers';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Segment } from '~app/models/segment.model';
import { Flight } from '~app/models/flight.model';
import { ALERTS, Constants } from '~app/constants/ha-constants';
import { HeaderContentService } from '~app/services/header-content.service';
import { AlertReasonCodeData } from '~app/models/alert-code-logging.model';
import { DynamicContentService } from '~app/services/dynamic-content.service';

export interface TripWithFlightNumber extends Segment {
  airlineCode: string;
  flightNumber: string;
  departureCity: string;
  arrivalCity: string;
}

@Component({
  selector: 'app-segment-card',
  templateUrl: './segment-card.component.html',
  styleUrls: ['./segment-card.component.scss'],
})

@ContentSchema({
  name: 'SegmentCard',
  description: 'Segment Card Component',
  props: [
    { name: 'flight', type: PropertyType.Text },
    { name: 'alreadyCheckedInStatus', type: PropertyType.Text },
    { name: 'partiallyCheckedInStatus', type: PropertyType.Text },
    { name: 'notCheckedInStatus', type: PropertyType.Text },
    { name: 'selectFlight', type: PropertyType.Text },
    { name: 'alerts', type: PropertyType.Object },
    { name: 'resultPageHeaderText', type: PropertyType.Object }
  ]
})

export class SegmentCardComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  @Input() segment: TripWithFlightNumber;
  @Input() flights: Flight[];
  @Input() idx: number;
  @Output() alertCodeEventEmitter = new EventEmitter<AlertReasonCodeData>();
  checkInTime: any;
  alertMessage: string;
  alertReasonCode = "";
  canCheckIn = false;

  constructor(
    public helperService: HelperService,
    private headerContentService: HeaderContentService,
    private sessionDispatchers: SessionDispatchers,
    private dynamicContentService: DynamicContentService,
    ) {
  }

  ngOnInit(): void {
    this.rendering.props['flight'] = this.dynamicContentService.getContentString(
      this.rendering.props['flight'], { numberOfFlights: this.segment.details[0].flightDetails.length }
    );

    const isInternational = this.segment.marketType === Constants.marketTypeInternational;
    this.canCheckIn = (isInternational && this.getAlertReasonCode() === ALERTS.regulatoryDetailsInhibition) || this.segment.canAllPassengersCheckIn;
    if (!this.canCheckIn) {
      this.setAlertReasonCode();
    }

    this.alertCodeEventEmitter.emit({code: this.alertReasonCode, message: this.alertMessage, segmentId: this.segment.id});
  }

  setAlertReasonCode() {
    this.alertReasonCode = this.getAlertReasonCode();
    if (!this.alertReasonCode || !this.rendering.props.alerts[0].props[this.alertReasonCode]) {
      this.alertReasonCode = ALERTS.referOutToAgent;
    }
    const content = this.rendering.props.alerts[0].props[this.alertReasonCode].content;
    this.alertMessage = Array.isArray(content) ? content.join(" ") : content;

    if (this.alertReasonCode === ALERTS.earlyCheckInAlert[0]) {
      const flight = this.flights.find(flightId =>  flightId.id === this.segment.details[0].flightDetails[0].flightId);
      const airportTime = this.flights && new Date(flight.scheduledDeparture.airportDateTimeString);
      this.checkInTime = airportTime.setDate(airportTime.getDate() - 1);
      this.alertMessage = this.dynamicContentService.getContentString(
        this.alertMessage, {
          time: this.helperService.getLocalFullDateString(this.checkInTime),
          origin: !!this.segment.origin ? this.segment.origin : "",
          destination: !!this.segment.destination ? this.segment.destination : ""
        }
      );
    }
  }

  selectSegment(seg: TripWithFlightNumber) {
    const { airlineCode, flightNumber, departureCity, arrivalCity, ...segment } = seg;
    this.sessionDispatchers.setSessionSelectedSegment('selectedSegment', segment, this.rendering.props['alerts'][0].props);
    const airportDetails = this.helperService.getAirportDetails(seg.destination);
      if (!!airportDetails && airportDetails.destinationImgUrl !== "") {
        const resultHeaderContent = {
          destinationImgUrl: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url('${airportDetails.destinationImgUrl}')`,
          headerText: this.rendering.props['resultPageHeaderText']
        };
        this.headerContentService.setHeaderContent(resultHeaderContent);
      }
  }

  private getAlertReasonCode(): string {
    let alertReasonCode;
    this.segment.details.find(detail => detail.flightDetails.find(flight => {
      const code = flight.alertReasonCode.split(",")[0];
      if (code) {
        alertReasonCode = code;
      }
    }));

    return alertReasonCode;
  }
}
