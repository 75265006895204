import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { CartPassengerInfo } from '~app/models/cartpassengerinfo.model';
import { bagsIdentifiers } from '~app/store/services/cart/cart.service';
import { Collection } from '~app/models/collection.model';
import { CatalogSelectors, SessionSelectors, SessionDispatchers, RouterSelectors, CartSelectors } from '~app/store';
import { Observable, Subscription } from 'rxjs';
import { ICheckinRouteSerializerState, CHECKIN_ROUTES} from '~app/store/reducers/router/checkin-route-serializer';
import { SessionData } from '~app/models/sessiondata.model';
import { Trip } from '~app/models/trip.model';
import { CartContentItems } from '~app/models/cartcontentItems.model';

@Component({
  selector: 'app-special-items',
  templateUrl: './bag-special-items.component.html',
  styleUrls: ['./bag-special-items.component.scss'],
})
@ContentSchema({
  description: '',
  name: 'BagSpecialItems',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'OAheading', type: PropertyType.Text },
    { name: 'OAdescription', type: PropertyType.Text },
    { name: 'save', type: PropertyType.Text },
    { name: 'weightVerification', type: PropertyType.Object },
    { name: 'weightVerificationAcceptance', type: PropertyType.Object },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})
export class BagSpecialItemsComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  sessionData: SessionData;
  associatedPax: CartPassengerInfo;
  savingBags: boolean;
  passengerId: string = null;
  specialItemsCatalog: any;
  specialItems: any;
  bagsCatalogData: Collection[];
  trip2Checkin: Trip;
  cartContentItems: CartContentItems[];
  subscriptions: Subscription = new Subscription();
  bagsEntries$: Observable<Collection[]>;
  sessionData$: Observable<SessionData>;
  routerState$: Observable<ICheckinRouteSerializerState>;
  cartLoading$: Observable<boolean>;
  isOAFlight: boolean;
  cartContentItems$: Observable<CartContentItems[]>;

  constructor(
    private catalogSelectors: CatalogSelectors,
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private routerSelectors: RouterSelectors,
    private cartSelectors: CartSelectors
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.bagsEntries$ = this.catalogSelectors.getCatalogSortedByProducts$;
    this.routerState$ = this.routerSelectors.routerState$;
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.cartContentItems$ = this.sessionSelectors.cartContent$;
  }

  ngOnInit(): void {
    this.subscriptions.add(this.sessionData$.subscribe(sessionData => {
      sessionData ? this.sessionData = sessionData : this.sessionDispatchers.getState();
      if (this.sessionData) {
        this.trip2Checkin = this.sessionData.trip2Checkin;
      }
    }));

    this.subscriptions.add(this.routerState$.subscribe(state => {
      if (state && state.params.serverRoute &&
        (state.params.serverRoute).includes(CHECKIN_ROUTES.ROUTE_BAG_SPECIAL_ITEMS.route)) {
        this.passengerId = state.queryParams.paxId;
      }
    }
    ));

    this.subscriptions.add(this.cartContentItems$
      .subscribe(contentItems => {
        if (!!contentItems) {
          this.cartContentItems = contentItems;
        }
      }));

    this.subscriptions.add(this.bagsEntries$.subscribe(entries => {
      if (entries) {
        this.bagsCatalogData = entries;
        const catalog = this.bagsCatalogData.find(selection => selection.passengerId === this.passengerId);
        if (catalog) {
          this.fillCatalog(catalog);
        }
      }
    }));
    this.subscriptions.add(this.cartLoading$.subscribe(loading => this.savingBags = loading));
  }

  getBagContent(productId: string): CartContentItems {
    return this.cartContentItems.find(item => item.code ===  productId);
  }

  fillCatalog(catalog: Collection): void {
    this.associatedPax = {
      passengerId: catalog.passengerId,
      segmentId: catalog.segmentId,
    };
    this.specialItemsCatalog = catalog.products.filter(item =>
      item.type === 'specialBag' || (item.productId !== bagsIdentifiers.freeItem.id &&
      item.productId !== bagsIdentifiers.regularBag.id)
    ).map(product => ({
      ...product,
      productContent: !this.sessionData.isOAFlight ?
      this.getBagContent(product.productId) : {
        title: product.commercialName,
        icon: 'icon-checked-bags'
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
