import { Action, createReducer, on } from '@ngrx/store';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import * as TripActions from '../../actions/trip.actions';
import * as SessionActions from '../../actions/session.actions';
import { PassengerList } from '~app/models/passengerlist.model';
import { PassengerDetail } from '~app/models/passengerdetail.model';
import { Constants } from '~app/constants/ha-constants';
import { Flight } from '~app/models/flight.model';

export interface TripState {
    trip: Response<Trip>;
    loading: boolean;
    militaryLoading: boolean;
    reset: boolean;
    error: any;
    isCapable: boolean;
    isMultiSegment: boolean;
    entries: Array<{}>;
    passengerList: PassengerList;
    isSinglePax: boolean;
    isSinglePaxCheckin: boolean;
    savingPassengerInfo: boolean;
    savingLapInfantInfo: boolean;
    savingAdultToMinorInfo?: boolean;
    isPassTravel?: boolean;
    isPositiveSpace?: boolean;
    confirmationCode?: string;
    correlationId?: string;
    tripId?: string;
    militaryPaxInformation?: PassengerDetail[];
    isInternational: boolean;
    flightEntries?: Flight[];
    groupPassengerList: PassengerList;
    isGroupBooking?: boolean;
}

export const initialState: TripState = {
    trip: null,
    loading: false,
    militaryLoading: false,
    reset: false,
    error: false,
    isCapable: true,
    isMultiSegment: false,
    entries: null,
    passengerList: null,
    isSinglePax: false,
    isPassTravel: false,
    isPositiveSpace: false,
    confirmationCode: '',
    correlationId: '',
    tripId: '',
    savingPassengerInfo: false,
    savingLapInfantInfo: false,
    savingAdultToMinorInfo: false,
    militaryPaxInformation: null,
    isSinglePaxCheckin: false,
    isInternational: false,
    flightEntries: null,
    groupPassengerList: null,
    isGroupBooking: false
};

function modifyTripState(
    tripState: TripState,
    tripChanges: Partial<Response<Trip>>
  ): TripState {
    return {
      ...tripState,
      loading: false,
      correlationId: tripState.correlationId,
      trip: tripState && tripState.trip && tripState.trip.results.map(trip => {
          if (trip.id === (tripChanges && tripChanges.results[0].id)) {
              return {...tripState.trip, ...tripChanges };
          } else {
              return tripState.trip;
          }
      })[0]
    };
}

function updateTripPassengers(tripData: Response<Trip>, passengerList: PassengerList): Response<Trip> {
  if (!!tripData.results[0].passengers) {
    tripData.results.map(trip => {
      if (trip.id === passengerList.tripId) {
          return { ...tripData, ...passengerList};
      }
  });
  }
  return tripData;
}

// This function accepts both an array of PassengerDetail and a single PassengerDetail
function updateMilitaryPaxInformation(state: TripState, passengerUpdates: any): TripState {
  const isMultiUpdate = passengerUpdates.hasOwnProperty('length');
    return {
    ...state,
    militaryPaxInformation: state.militaryPaxInformation.map((pax) => {
        if (isMultiUpdate) {
            return {
                ...pax,
                ...passengerUpdates.find(p => pax.passengerId === p.passengerId)
            };
        } else if (!isMultiUpdate && pax.passengerId === passengerUpdates.passengerId) {
            return {
                ...pax,
                ...passengerUpdates
            };
        } else {
            return {...pax};
        }
    })
    };
}

function updateGroupPassengerList(state: PassengerList, groupPassengerList: PassengerList ) {
    if (!!state) {
        return state = {
          ...state,
            entries: [...state.entries, ...groupPassengerList.entries.filter(entry =>
              !state.entries.find(existingEntry => existingEntry.id === entry.id)
            )]
        };
      } else {
        return {...groupPassengerList};
      }
}

function resetTripState() {
    return {
        trip: null,
        loading: false,
        reset: false,
        error: false,
        isCapable: true,
        isMultiSegment: false,
        entries: null,
        passengerList: null,
        isSinglePax: false,
        isPositiveSpace: false,
        isPassTravel: false,
        confirmationCode: '',
        correlationId: '',
        tripId: '',
        militaryLoading: false,
        savingPassengerInfo: false,
        savingLapInfantInfo: false,
        savingAdultToMinorInfo: false,
        militaryPaxInformation: null,
        isSinglePaxCheckin: false,
        isInternational: false,
        flightEntries: null,
        groupPassengerList: null,
        isGroupBooking: false,
    };
}
export const tripReducer = createReducer(
    initialState,
    on(TripActions.getByPnr, state => ({...state, trip: null, loading: true})),
    on(TripActions.resultGetTrip, state => ({...state, trip: null, loading: true})),
    on(TripActions.getByPnrError, state => ({...state, loading: false, reset: true})),
    on(TripActions.getByPnrSuccess, (state, { trip }) => ({
        ...state,
        loading: false,
        trip,
        isMultiSegment: trip.results[0].segments.entries.filter(s => s.canAllPassengersCheckIn).length > 1,
        entries: trip.results[0].segments.entries.filter(s => s.canAllPassengersCheckIn),
        passengerList: trip.results[0].passengers,
        isSinglePax: checkIsSinglePax(trip),
        isPassTravel: trip.results[0].isPassTravel,
        isPositiveSpace: trip.results[0].isPositiveSpace,
        confirmationCode: trip.results[0].confirmationCode,
        correlationId: trip.correlationId,
        tripId: trip.results[0].id,
        militaryLoading: false,
        isInternational: !!trip.results[0].segments.entries.some(s => s.marketType === Constants.marketTypeInternational),
        flightEntries: trip.results[0].flights.entries,
        isGroupBooking: trip.results[0].isGroupBooking
    })),
    on(SessionActions.setSelectedPassengers, (state, { passengers }) => ({
        ...state,
        isSinglePaxCheckin: passengers.length === 1,
    })),
    on(SessionActions.setSessionSelectedSegmentSuccess, (state, {segment}) => ({
        ...state,
        isInternational: segment.marketType === Constants.marketTypeInternational,
    })),
    on(TripActions.updatePassengerMilitaryTypeSuccess, (state, { trip }) => ({
      ...state,
      loading: false,
      trip,
      isMultiSegment: trip.results[0].segments.entries.length > 1,
      entries: trip.results[0].segments.entries,
      isPassTravel: trip.results[0].isPassTravel,
      isPositiveSpace: trip.results[0].isPositiveSpace,
      confirmationCode: trip.results[0].confirmationCode,
      tripId: trip.results[0].id,
      militaryLoading: false,
    })),
    on(TripActions.paymentCheckin, state => ({ ...state, loading: true })),
    on(TripActions.checkIn, state => ({ ...state, loading: true })),
    on(TripActions.guidelinesCheckin, state => ({ ...state, loading: true })),
    on(TripActions.checkinError, state => ({...state, loading: false, reset: true})),
    on(TripActions.checkinSuccess, (state, { trip }) => modifyTripState(state, trip)),
    on(TripActions.paymentCheckinSuccess, (state, { trip }) => modifyTripState(state, trip)),
    on(TripActions.checkinSuccessAlreadyCheckedIn, (state , { trip }) => modifyTripState(state, trip)),
    on(TripActions.updatePassenger, state => ({...state, savingPassengerInfo: true})),
    on(TripActions.removeLapInfant, state => ({ ...state, savingLapInfantInfo: true })),
    on(TripActions.removeLapInfantError, (state, action) => ({
        ...state,
        savingLapInfantInfo: false,
        error: { error: action.error, from: action.from }
    })),
    on(TripActions.removeLapInfantSuccess, (state, { trip }) => ({
        ...state,
        trip,
        passengerList: trip.results[0].passengers,
        savingLapInfantInfo: false,
        error: false
    })),
    on(TripActions.addLapInfant, state => ({ ...state, savingLapInfantInfo: true })),
    on(TripActions.addLapInfantError, (state, action) => ({
        ...state,
        savingLapInfantInfo: false,
        error: { error: action.error }
    })),
    on(TripActions.addLapInfantSuccess, (state, { passengerList }) => ({
        ...state,
        trip: updateTripPassengers(state.trip, passengerList),
        passengerList,
        savingLapInfantInfo: false,
        error: false
    })),
    on(TripActions.addMinorToAdult, state => ({ ...state, savingAdultToMinorInfo: true })),
    on(TripActions.addMinorToAdultSuccess, (state ) => ({
        ...state,
        savingAdultToMinorInfo: false,
        error: false
    })),
    on(TripActions.addMinorToAdultError, (state, action) => ({
        ...state,
        savingAdultToMinorInfo: false,
        error: { error: action.error }
    })),
    on(TripActions.updatePassengerMilitary, state => ({...state, militaryLoading: true})),
    on(TripActions.militarySaveTypeModalUpdatePassenger, state => ({...state, militaryLoading: true})),
    on(TripActions.addMilitaryInformation, (state, { passengerUpdates }) => ({
        ...state,
        militaryPaxInformation: passengerUpdates})),
    on(TripActions.updateMilitaryInformation, (state, { passengerUpdates }) =>
        updateMilitaryPaxInformation(state, passengerUpdates)
    ),
    on(TripActions.updatePassengerSuccess, (state, { passengerList }) => ({
        ...state,
        trip: updateTripPassengers(state.trip, passengerList),
        savingPassengerInfo: false,
        passengerList,
        militaryLoading: false,
        error: false
    })),
    on(TripActions.updatePassengerError, TripActions.updatePassengerMilitaryTypeError, (state, action) => ({
        ...state,
        militaryLoading: false,
        savingPassengerInfo: false,
        error: { error: action.error, from: action.from }
    })),
    on(TripActions.clearTripDataCheckinStart, _ => resetTripState()),
    on(TripActions.getGroupPassengerByName, state => ({...state, loading: true})),
    on(TripActions.getByPassengerId, state => ({...state, loading: true})),
    on(TripActions.updateGroupPassenger, ( state, { passengerList  }) => ({
        ...state,
        loading: false,
        groupPassengerList: updateGroupPassengerList(state.groupPassengerList, passengerList.results[0])
        })
    ),
    on(TripActions.getGroupBookingFlowSuccess, state => ({ ...state, groupPassengerList: state.passengerList })),
    on(TripActions.groupPassengerNotFoundError, state => ({ ...state, loading: false })),
    on(TripActions.addToEliteStandByList, state => ({ ...state, loading: true })),
    on(TripActions.noEliteStandByListPassengers, state => ({ ...state, loading: false })),
    on(
      TripActions.addToEliteStandByListErrorModalClosed,
      TripActions.addToEliteStandByListSuccess,
      state => ({ ...state, loading: false })
    ),
);

export function reducer(state: TripState | undefined, action: Action) {
    return tripReducer(state, action);
}

export function checkIsSinglePax(trip: Response<Trip>) {
    const passengerList = trip.results[0].passengers;
    if (passengerList.entries.length === 1) {
        return true;
    } else {
        return false;
    }
}
