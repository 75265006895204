import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { HelperService } from '~app/services/helper.service';

@Component({
  selector: 'app-flight-delayed-modal',
  templateUrl: './flight-delayed-modal.component.html',
  styleUrls: ['./flight-delayed-modal.component.scss'],
})
@ContentSchema({
  name: 'FlightDelayedModal',
  description: 'FlightDelayedModalComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'delayMessage', type: PropertyType.RichText },
    { name: 'cancelText', type: PropertyType.Text },
    { name: 'delayedTime', type: PropertyType.RichText }
  ]
})
export class FlightDelayedModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FlightDelayedModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private dynamicContentService: DynamicContentService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('flight-delayed-modal');
    this.rendering = this.dynamicContentService.fillContentProps(
      this.rendering, {
      origin: this.data.origin,
      destination: this.data.destination,
      departTime: this.helperService.getLocalFullDateString(this.data.estimatedDepartureTime)
    });
  }
}
