import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { AlertReasonCodeData } from "~app/models/alert-code-logging.model";
import * as AnalyticsActions from '~app/store/actions/analytics.actions';
import { EntityState } from '~app/store/reducers';

@Injectable()
export class AnalyticsDispatchers {
  constructor(private store: Store<EntityState>) { }

  logRequestData(data: Object) {
    this.store.dispatch(AnalyticsActions.logRequestData({ data }));
  }

  logResponseData(data: Object) {
    this.store.dispatch(AnalyticsActions.logResponseData({ data }));
  }

  logAlertError(error: Object) {
    this.store.dispatch(AnalyticsActions.logAlertError({ error }));
  }

  seatsUpsellClick(data?: Object) {
    this.store.dispatch(AnalyticsActions.seatsUpsellClick({ data }));
  }

  referOutAlertCode(data?: AlertReasonCodeData) {
    this.store.dispatch(AnalyticsActions.referOutAlertCode({ data }));
  }

  resultPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.resultPageLoaded({ data }));
  }

  boardingPassPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.boardingPassPageLoaded({ data }));
  }

  passengerPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.passengerPageLoaded({ data }));
  }

  baggagePageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.baggagePageLoaded({ data }));
  }

  baggageAdvisoryItemsPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.baggageAdvisoryItemsPageLoaded({ data }));
  }
  
  dashboardPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.dashboardPageLoaded({ data }));
  }

  groupPassengersPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.groupPassengersPageLoaded({ data }));
  }

  minorPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.minorPageLoaded({ data }));
  }

  infantPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.infantPageLoaded({ data }));
  }

  checkInPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.checkInPageLoaded({ data }));
  }

  militaryPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.militaryPageLoaded({ data }));
  }

  guidelinesPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.guidelinesPageLoaded({ data }));
  }

  infantInfoPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.infantInfoPageLoaded({ data }));
  }

  internationalTravelerInformationPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.internationalTravelerInformationPageLoaded({ data }));
  }

  internationalTravelerSelectionPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.internationalTravelerSelectionPageLoaded({ data }));
  }

  healthAttestationPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.healthAttestationPageLoaded({ data }));
  }

  paymentPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.paymentPageLoaded({ data }));
  }

  segmentSelectorPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.segmentSelectorPageLoaded({ data }));
  }

  seatPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.seatPageLoaded({ data }));
  }

  seatmapPageLoaded(data?: Object) {
    this.store.dispatch(AnalyticsActions.seatmapPageLoaded({ data }));
  }

  paymentInformationSubmitted(data?: Object) {
    this.store.dispatch(AnalyticsActions.paymentInformationSubmitted({ data }));
  }
}
