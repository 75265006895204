import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Response } from '~app/models/response.model';
import { Trip } from '~app/models/trip.model';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Segment } from '~app/models/segment.model';
import { SessionSelectors } from '~app/store/services/session/session.selectors';
import { SegmentDetail } from '~app/models/segmentdetail.model';
import { TripSelectors, CartSelectors, RouterDispatchers, AnalyticsDispatchers } from '~app/store';
import { TripDispatchers } from '~app/store/services/trip/trip.dispatchers';
import { SessionData } from '~app/models/sessiondata.model';
import { Cart } from '~app/models/cart.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { PassengerDetail } from '~app/models/passengerdetail.model';
import { Constants, MilitaryTravelType } from '~app/constants/ha-constants';

@Component({
  selector: 'app-military',
  templateUrl: './military.component.html',
  styleUrls: ['./military.component.scss'],
})
@ContentSchema({
  name: 'Military',
  description: '',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'idRequiredText', type: PropertyType.Text },
    { name: 'idRequiredTitle', type: PropertyType.Text },
    { name: 'idRequiredDescription', type: PropertyType.Text },
    { name: 'selectAll', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})
export class MilitaryComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  paxMilitaryCardRendering: ComponentRendering;
  tripData: Response<Trip>;
  segmentDetails: SegmentDetail[];
  subscriptions: Subscription = new Subscription();
  sessionSelectedSegment: Segment;
  militaryLoading$: Observable<boolean>;
  sessionData$: Observable<SessionData>;
  sessionData: SessionData;
  cart$: Observable<Response<Cart>>;
  previous: string;
  queryParams: any;
  tripId: string;
  paxData: PassengerDetail;
  selectedSegmentId: string;
  militaryPaxInformation: PassengerDetail[];
  militaryPaxInformation$: Observable<PassengerDetail[]>;
  valid = Constants.validForm;

  constructor(
    public dialog: MatDialog,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
    private cartSelectors: CartSelectors,
    public tripDispatchers: TripDispatchers,
    private routerDispatchers: RouterDispatchers,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.militaryLoading$ = this.tripSelectors.militaryLoading$;
    this.cart$ = this.cartSelectors.cart$;
    this.militaryPaxInformation$ = this.tripSelectors.militaryPaxInformation$;
  }

  ngOnInit() {
    this.paxMilitaryCardRendering = (this.rendering.components['pax-military-card'] || [{}])[0];
    this.subscriptions.add(this.sessionData$.subscribe(sessionData => {
      if (sessionData) {
        this.sessionData = sessionData;
        if (!!sessionData && !!sessionData.selectedSegment && !!sessionData.passengerIds) {
          this.sessionSelectedSegment = sessionData.selectedSegment;
          this.segmentDetails = this.sessionSelectedSegment.details;
          if (!!this.segmentDetails) {
            this.segmentDetails =  !!sessionData.passengerIds && !!sessionData.passengerIds.length ?
            this.segmentDetails.filter(detail => sessionData.passengerIds.includes(detail.passengerId))
            : this.segmentDetails.filter(detail => detail.flightDetails.filter(flight => !!flight.canCheckIn));
          }
        }
      }

      this.subscriptions.add(this.militaryPaxInformation$.subscribe(militaryPaxInfo => {
        if (militaryPaxInfo) {
          this.militaryPaxInformation = militaryPaxInfo;
        }
      }));
    }));
    this.militaryPaxInformation = this.segmentDetails && this.segmentDetails.map((detail: SegmentDetail) => ({
      passengerId: detail.passengerId,
      militaryTravelType: detail.militaryTravelType || MilitaryTravelType.NONE,
      segmentId: this.sessionSelectedSegment.id,
      passengerName: detail.passengerName
    }));
    this.tripDispatchers.addMilitaryInformation(this.militaryPaxInformation);

    this.subscriptions.add(this.cart$.subscribe(cart => {
      if (!!cart) {
        this.previous = CHECKIN_ROUTES.ROUTE_BAGGAGE.route;
        this.queryParams = null;
      } else {
        this.previous = CHECKIN_ROUTES.ROUTE_PASSENGER.route;
      }
    }));
    this.analyticsDispatchers.militaryPageLoaded();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectAll(currentSelection: string): void {
    const hasSameSelection = this.militaryPaxInformation.every(pax => pax.militaryTravelType === currentSelection);
    const militaryTravelType = hasSameSelection ? MilitaryTravelType.NONE : currentSelection;
    const payload = this.militaryPaxInformation.map((detail: PassengerDetail) => ({
        passengerId: detail.passengerId,
        militaryTravelType,
        segmentId: this.sessionSelectedSegment.id,
        passengerName: detail.passengerName
      }));
    this.tripDispatchers.addMilitaryInformation(payload);
  }

  navigateBack() {
    if (this.previous === CHECKIN_ROUTES.ROUTE_BAGGAGE.route) {
      this.routerDispatchers.routeToBaggage(CHECKIN_ROUTES.ROUTE_MILITARY.component);
    } else {
      this.routerDispatchers.routeToPassenger(CHECKIN_ROUTES.ROUTE_MILITARY.component);
    }
  }
}
