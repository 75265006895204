import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as FlightAction from '../../actions';
import { EntityState } from '../../reducers';

@Injectable()
export class FlightDispatchers {
  constructor(private store: Store<EntityState>) { }

  seatMapModalGetFlightById(flightId: string, flightIdx: number) {
    this.store.dispatch(FlightAction.seatMapModalGetFlightById({
      flightId: flightId,
      flightIdx: flightIdx,
    }));
  }

  itineraryDetailGetFlightById(flightId: string, flightIdx: number) {
    this.store.dispatch(FlightAction.itineraryDetailGetFlightById({
      flightId: flightId,
      flightIdx: flightIdx,
    }));
  }

  boardingPassGetFlightsById() {
    this.store.dispatch(FlightAction.boardingPassGetAllFlights());
  }

  resetFlightState() {
    this.store.dispatch(FlightAction.resetFlightState());
  }

  passengerGetAllFlights() {
    this.store.dispatch(FlightAction.passengerGetAllFlights());
  }

  seatsGetAllFlights() {
    this.store.dispatch(FlightAction.seatsGetAllFlights());
  }
}

