import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { ISecurityInfoState } from '~app/store/reducers/security-info/security-info.reducers';

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getSecurityInfoState = createSelector(
    getEntityState,
    (state: EntityState) => state.securityInfo
);

export const validations = createSelector(
    getSecurityInfoState,
    (state: ISecurityInfoState) => state.validations
);

export const passengerInfo = createSelector(
    getSecurityInfoState,
    (state: ISecurityInfoState) => state.passengerInfo
);

export const getSecurityInfoLoading = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.loading
);

export const getSecurityInfo = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.securityInfo
);

export const getTravelDocuments = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.travelDocuments
);

export const getTripPassengersInfo = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.passengers
);

export const getDefaultPassenger = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.defaultPassenger
);

export const getAddressInfo = createSelector(
    getSecurityInfoState,
    (securityInfo: ISecurityInfoState) => securityInfo.addressInfo
);

export const getHasAllTravelDocs = createSelector(
  getSecurityInfoState,
  (securityInfo: ISecurityInfoState) => securityInfo.hasAllTravelDocs
);

export const getRequiredInfoSummary = createSelector(
  getSecurityInfoState,
  (securityInfo: ISecurityInfoState) => securityInfo.requiredInfoSummary
);

@Injectable()
export class SecurityInfoSelectors {
    constructor(private store: Store<EntityState>) {}
    securityInfoState$ = this.store.select(getSecurityInfoState);
    validations$ = this.store.select(validations);
    passengerInfo$ = this.store.select(passengerInfo);
    securityInfoLoading$ = this.store.select(getSecurityInfoLoading);
    securityInfo$ = this.store.select(getSecurityInfo);
    travelDocuments$ = this.store.select(getTravelDocuments);
    tripPassengers$ = this.store.select(getTripPassengersInfo);
    defaultPassenger$ = this.store.select(getDefaultPassenger);
    hasAllTravelDocs$ = this.store.select(getHasAllTravelDocs);
    requiredInfoSummary$ = this.store.select(getRequiredInfoSummary);
}
