export interface PricingHeaders {
  currencyCode: string;
  pcc: string;
}

// Right now we are mapping via locale but long term solution we would be mapping with trip departure city. Thus the name.
export const departureCityPricingMap = {
  "en-us": {
    currencyCode: 'USD',
    pcc: 'HNL'
  } as PricingHeaders,
  "ja-jp": {
    currencyCode: 'JPY',
    pcc: 'HND'
  } as PricingHeaders,
  "ko-kr": {
    currencyCode: 'KRW',
    pcc: 'ICN'
  } as PricingHeaders,
  "en-au": {
    currencyCode: 'AUD',
    pcc: 'SYD'
  } as PricingHeaders,
  "en-nz": {
    currencyCode: 'NZD',
    pcc: 'AKL'
  } as PricingHeaders,
  "zh-cn": {
    currencyCode: 'USD',
    pcc: 'HNL'
  } as PricingHeaders
};
