<mat-card class="mat-card fill" (click)="passengerCheckboxChange(passengerCheckbox)">
  <div class="container">
    <div class="row">
      <mat-checkbox id="passenger-select-checkbox" (click)="$event.preventDefault()" class="aloha-pink center-checkbox pt-3 mr-3" #passengerCheckbox>
      </mat-checkbox>
      <!-- Desktop View Start -->
      <div class="col passenger-name d-none d-md-block pt-2">
        <span class="row subtitle-bold" id="passenger-name-{{index}}">
          {{ passenger?.passengerName.firstName | titlecase}} {{ passenger?.passengerName.lastName | titlecase}}
          <i class="icon-star-{{passenger?.customerLevel | lowercase}}-16 icon-16 d-flex align-self-center text-nowrap ml-2 member-level"></i>
            <span class="pl-2 my-auto body-copy-light-text" *haText="rendering.props['pualani']">
              {{ passenger?.customerLevel }}
            </span>
        </span>
      </div>
      <!-- Desktop View End -->
      <!-- Mobile View Start -->
      <div class="col d-sm-block d-md-none">
        <span id="passenger-name-mobile" class="subtitle-bold text-center">
          {{ passenger?.passengerName.firstName | titlecase}} {{ passenger?.passengerName.lastName | titlecase}}
        </span>
        <div *ngIf="passenger?.customerLevel" id="passenger-{{passenger?.customerLevel | lowercase}}-member-mobile"
        class="icon-star-{{passenger?.customerLevel | lowercase}}-16 icon-16 d-flex ml-1">
          <span class="pl-2 small-light-text" *haText="rendering.props['pualani']">
            {{ passenger?.customerLevel }}
          </span>
        </div>
      </div>
      <!-- Mobile View End-->
    </div>
  </div>
</mat-card>
