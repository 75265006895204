import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { ReferenceDataState, IAirportState, StationCodeDataState, ICountriesState } from "~app/store/reducers/reference-data/reference-data.reducer";
import { AirportMap } from "~app/models/airport.model";


const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getReferenceDataState = createSelector(
    getEntityState,
    (state: EntityState) => state.referenceData
);

export const getReferenceDataLoading = createSelector(
  getReferenceDataState,
  (state: ReferenceDataState) => state.loading
);

// station codes
export const getStationCodeDataState = createSelector(
    getReferenceDataState,
    (state: ReferenceDataState): StationCodeDataState => state.stationCodeData
);

export const getStationCode = createSelector(
    getStationCodeDataState,
    (state: StationCodeDataState) => state.stationCodeData
  );

export const getStationCodeLoading = createSelector(
   getStationCodeDataState,
    (state: StationCodeDataState) => state.loading
);

export const getStationCodePointOfSale = createSelector(
  getStationCodeDataState,
   (state: StationCodeDataState) => state.pointOfSale
);

export const getStationCodeCurrencyCode = createSelector(
  getStationCodeDataState,
   (state: StationCodeDataState) => state.currencyCode
);

// airport
export const getAirportState = createSelector(
    getReferenceDataState,
    (state: ReferenceDataState): IAirportState => state.airports
);

export const getAirportMap = createSelector (
    getAirportState,
    (state: IAirportState): AirportMap => state.airports
);

export const getAirportLoading = createSelector(
    getAirportState,
    (state: IAirportState) => state.loading
);

export const getAirportCityNm = createSelector(
    getAirportState,
    (state: IAirportState, id: string) => {
        if (state.airports) {
        const airport = state.airports[id];
        return airport ? airport.cityNm : '';
        }
        return '';
    }
);

export const getAirportData = createSelector(
    getAirportState,
    (state: IAirportState, id: string) => state.airports ? state.airports[id] : null
);

// country
export const getCountriesState = createSelector(
    getReferenceDataState,
    (state: ReferenceDataState): ICountriesState  => state.countries
  );

  export const getCountries = createSelector(
    getCountriesState,
    (state: ICountriesState) => state.countriesData
  );

  export const getCountriesLoading = createSelector(
    getCountriesState,
    (state: ICountriesState) => state.loading
  );

@Injectable()
export class ReferenceDataSelectors {
    constructor(private store: Store<EntityState>) {}
    referenceDataState$ = this.store.select(getReferenceDataState);
    referenceDataLoading$ = this.store.select(getReferenceDataLoading);
    stationCodeDataState$ = this.store.select(getStationCodeDataState);
    stationCode$ = this.store.select(getStationCode);
    stationCodePointOfSale$ = this.store.select(getStationCodePointOfSale);
    stationCodeCurrencyCode$ = this.store.select(getStationCodeCurrencyCode);
    stationCodeLoading$ = this.store.select(getStationCodeLoading);
    airportState$ = this.store.select(getAirportState);
    airports$ = this.store.select(getAirportMap);
    airportLoading$ = this.store.select(getAirportLoading);
    countries$ = this.store.select(getCountries);
    countriesState$ = this.store.select(getCountriesState);
    countriesLoading$ = this.store.select(getCountriesLoading);
    airportCityNm$(id: string) {
        return this.store.select(getAirportCityNm, id);
    }
    airportData$(id: string) {
        return this.store.select(getAirportData, id);
    }
}
