<h6 class="bold medium" mat-dialog-title>
  <span>{{header}}</span>
</h6>
<mat-dialog-content class="dlg-content mat-typography">
  <span class="body-copy">{{content}}</span>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button [mat-dialog-close]="" class="btn btn-default">
    <span>{{btnContinue}}</span>
  </button>
</mat-dialog-actions>
