<div id="minor-traveling-alone-modal">
    <div class="d-none d-md-block my-3">
        <i class="ha-icon icon-attention icon-32 aloha-pink"></i>
    </div>
    <h6 id="minor-traveling-alone-heading" class="bold medium" mat-dialog-title>
        <span *haText="rendering?.props['minorTravelingAloneHeading']"></span>
    </h6>
    <mat-dialog-content>
        <div id="minor-traveling-alone-description" class="body-copy traveling-alone-description" *haText="rendering?.props['minorTravelingAloneDescription']"></div>
    </mat-dialog-content>
        <mat-dialog-actions id="minor-traveling-alone-modal-button" class="d-flex justify-content-center">
            <button id="okay-button" mat-button class="btn btn-default" (click)="clickOkayButton()">
                {{ 'Ok' | translate}}
            </button>
        </mat-dialog-actions>
</div>