import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { TripSearch } from "~app/models/trip-search.model";
import * as AuthTokenActions from '~app/store/actions/auth-token.actions';
import { EntityState } from '~app/store/reducers';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';

@Injectable()
export class AuthTokenDispatchers {
  constructor(private store: Store<EntityState>) { }

  getAuthToken(tripSearch: TripSearch, dispatcher: string) {
    if (dispatcher === CHECKIN_ROUTES.ROUTE_CHECKIN.component) {
      this.store.dispatch(AuthTokenActions.checkinGetAuthToken({ tripSearch }));
    } else {
      // Default Auth Token Expiration Modal Dispatched Action
      this.store.dispatch(AuthTokenActions.authTokenExpirationModalGetAuthToken({ tripSearch }));
    }
  }

  setAuthTokenExpirationFlag() {
    this.store.dispatch(AuthTokenActions.authTokenExpired());
  }

  startWatchingAuthTokenExpiration() {
    this.store.dispatch(AuthTokenActions.startWatchingAuthToken());
  }
}
