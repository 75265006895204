import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { combineLatest, Subscription } from 'rxjs';
import { ContactTracingDetails, ContactTracingPassenger } from '~app/models/contactTracingDetails.model';
import { InternationalDetailsDispatchers, InternationalDetailsSelectors, SessionSelectors } from '~app/store';
import {
  ContactTracingPassengerCardComponent,
} from './contact-tracing-passenger-card/contact-tracing-passenger-card.component';

@Component({
  selector: 'app-contact-tracing',
  templateUrl: './contact-tracing.component.html',
  styleUrls: ['./contact-tracing.component.scss'],
})
@ContentSchema({
  name: 'ContactTracing',
  description: 'ContactTracingComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'cdcMessage', type: PropertyType.Text },
    { name: 'burdenStatementModalContent', type: PropertyType.Object },
    { name: 'ombControl', type: PropertyType.RichText },
    { name: 'navButtons', type: PropertyType.Object },
    { name: 'actStatementModal', type: PropertyType.Object },
  ]
})
export class ContactTracingComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  @ViewChild(ContactTracingPassengerCardComponent) contactTracingPassengerCardComponent: ContactTracingPassengerCardComponent;
  subs = new Subscription();
  burdenStatementModalContent: {
    title: string;
    content: string;
  };

  contactTracingPassengerCardRendering: ComponentRendering;
  contactTracingPassengers: ContactTracingPassenger[];
  isAllPaxCompleted = false;

  constructor(
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private sessionSelectors: SessionSelectors,
    private internationalDetailsDispatchers: InternationalDetailsDispatchers,
  ) { }

  ngOnInit(): void {
    this.contactTracingPassengerCardRendering = (this.rendering?.components?.['contact-tracing-passenger-card'] || [{}])[0];
    this.burdenStatementModalContent = this.rendering?.props?.burdenStatementModalContent;
    this.subs.add(
      combineLatest([
        this.internationalDetailsSelectors.internationalPassengers$,
        this.sessionSelectors.selectedPassengers$,
        this.sessionSelectors.sessionSelectedSegment$,
        this.sessionSelectors.sessionSelectedSegmentFlights$,
      ]).subscribe((([intlPaxes, paxes, segment, flights]) => {
        // filter out lap infants & pax who already submitted contact tracing
        this.contactTracingPassengers = intlPaxes.filter(intlPax => !intlPax.isLapInfant).map(intlPax => {
          const paxInfo = paxes?.find(pax => pax.id === intlPax.id);
          return {
            isCompleted: paxInfo?.specialKeywords?.includes('ATTS') ?? false,
            contactTracingForm: null,
            passengerInfo: paxInfo,
            passenger: {
              passengerId: intlPax.id,
              lastName: intlPax.passengerName.lastName,
              firstName: intlPax.passengerName.firstName,
              specialKeywords: paxInfo?.specialKeywords,
            },
            passengerSegments: segment?.details?.find(segDetail => intlPax.id === segDetail.passengerId)?.flightDetails?.map(flightDetail => {
              const flightInfo = flights.find(flight => flight.id === flightDetail?.flightId);
              return {
                passengerSegmentId: flightDetail.passengerSegmentId,
                marketingAirlineCode: flightInfo.marketedBy,
                flightNumber: flightInfo.flightNumber,
                departureDate: flightInfo.scheduledDeparture.airportDateTimeString.split('T')[0],
                origin: flightInfo.origin
              };
            })
          };
        });
      }))
    );

    this.subs.add(this.internationalDetailsSelectors.submittedContactTracingPaxIds$.subscribe(submittedPaxIds => {
      this.contactTracingPassengers = this.contactTracingPassengers?.map(pax => ({
        ...pax,
        isCompleted: pax?.isCompleted || submittedPaxIds.includes(pax?.passenger?.passengerId)
      }));
      this.isAllPaxCompleted = this.contactTracingPassengers.every(item => item.isCompleted);
    }));
  }

  onSaveAllPaxClick(): void {
    const contactTracingDetails: ContactTracingDetails[] = this.contactTracingPassengers.map(contactTracingPassenger => {
      contactTracingPassenger.contactTracingForm = this.contactTracingPassengers[0].contactTracingForm;
      return this.contactTracingPassengerCardComponent?.makeContactDetailsForSubmit(contactTracingPassenger);
    });
    this.internationalDetailsDispatchers.postContactTracingInformationForAllPassengers(contactTracingDetails);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
