import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, tap, switchMap, map, withLatestFrom } from 'rxjs/operators';
import * as OffersAction from '~app/store/actions';
import { ModalsService } from '~app/modals/modals.service';
import { OffersService } from "~app/store/services/offers/offers.service";
import { select, Store } from '@ngrx/store';
import { EntityState } from '~app/store/reducers';
import * as fromSession from '~app/store/services/session/session.selectors';

@Injectable()
export class OffersEffects {
  constructor(
    private actions$: Actions,
    private offersService: OffersService,
    private modalsService: ModalsService,
    private store: Store<EntityState>,
  ) { }

  getBagOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersAction.getBagsOffers),
      withLatestFrom(
        this.store.pipe(select(fromSession.getSessionPassengerIds)),
        this.store.pipe(select(fromSession.getTrip2Checkin)),
        this.store.pipe(select(fromSession.sessionSelectedSegmentId)),
      ),
      switchMap(([_, paxIds, trip2Checkin, selectedSegmentId]) => {
        const selectedJourneyIndex = trip2Checkin?.segments?.entries.findIndex(segment => segment.id === selectedSegmentId);
        return this.offersService.getBagOffers().pipe(
          tap(bagOffersData => {
            const paxProducts = bagOffersData?.catalogs?.[0]?.passengerProducts
            if (!bagOffersData) {
              throw { error: 'no data', requestData: bagOffersData };
            } else if (!paxIds.every(paxId => paxProducts.find(product => product?.journeyId === selectedJourneyIndex.toString() && product?.dcsPassengerId === paxId))) {
              /* For provided PNRs that have associations with another PNR confirmation code,
                 offers can only retrieve pax on one PNR which can cause a mismatch. */
              throw { error: 'pax mismatch', requestData: bagOffersData };
            }
          }),
          map(bagOffers => {
            return OffersAction.getBagOffersSuccess({ bagOffers });
          }),
          catchError(error => {
            return of(OffersAction.getBagOffersError(error));
          })
        );
      })
    )
  );


  getBagOffersError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersAction.getBagOffersError),
      tap(_ => {
        this.modalsService.openOffersErrorModal();
      })
    ), { dispatch: false }
  );

}
