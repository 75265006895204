import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from '~app/store/services/base.service';
import { SessionSelectors } from '../session/session.selectors';
import { environment } from '~environments/environment';
import { Flight } from '~app/models/flight.model';
import { take } from 'rxjs/operators';
import { Segment } from '~app/models/segment.model';
import { CabinType, SeatMapCabinType } from '~app/constants/ha-constants';

export interface IAircraftImageService {
  getAircraftImage(idx: number): Observable<string>;
}
@Injectable()
export class AircraftImageService extends BaseService implements IAircraftImageService {
  sessionSelectedSegmentFlight$: Observable<Flight[]>;
  sessionSelectedSegment$: Observable<Segment>;
  passengerIds$: Observable<string[]>;
  constructor(
    http: HttpClient,
    sessionSelectors: SessionSelectors,
  ) {
    super(http, sessionSelectors);
    this.sessionSelectedSegmentFlight$ = sessionSelectors.sessionSelectedSegmentFlights$;
    this.sessionSelectedSegment$ = sessionSelectors.sessionSelectedSegment$;
    this.passengerIds$ = sessionSelectors.sessionPassengerIds$;
  }

  getAircraftImage(idx: number): Observable<any> {
    let flightInfo: Flight;
    this.sessionSelectedSegmentFlight$.pipe(take(1)).subscribe(flight => flightInfo = flight[idx]);

    let cabinType = SeatMapCabinType.All;
    this.sessionSelectedSegment$.pipe(take(1)).subscribe(segment => {
      if (segment?.details?.every(detail => detail.flightDetails?.find(flight => flight.flightId === flightInfo?.id)?.cabinType === CabinType.First)) {
        cabinType = SeatMapCabinType.First_Class;
      } else if (segment?.details?.every(detail => detail.flightDetails?.find(flight => flight.flightId === flightInfo?.id)?.cabinType === CabinType.Economy)) {
        cabinType = SeatMapCabinType.Economy;
      }
    });

    // create passenger id string ist
    let paxIds: string;
    this.passengerIds$.pipe(take(1)).subscribe(ids => paxIds = ids?.join(','));

    let confirmationCode: string;
    const svgUrl = `${environment["exp-web-checkin-v2-api"]}/seatmap?`;
    this.sessionConfirmationCode$.pipe(take(1)).subscribe(code => confirmationCode = code);
    const queryParams = {
      airline: flightInfo.operatedBy,
      departureDate: flightInfo.scheduledDeparture.airportDateTimeString.split('T')[0],
      // id will always be in the format HA-1234-20230809-HNL-BOS so this gets the first set of digits aka the flight number
      // this is to ensure we get the HA flight number for seatmap even if marketed by another airline
      flightNumber: flightInfo.id.match(/\d+/)[0],
      origin: flightInfo.origin,
      destination: flightInfo.scheduledDestination,
      passengerIds: paxIds,
      cabinType,
      orientation: 'Vertical',
      confirmationCode,
      requestType: 'Payload',
      layers: 'All',
      seatMapSource: 'ACS'
    }
    return this.http.get(svgUrl, { responseType: 'text', params: queryParams });

  }
}