import { ModalContentsService } from '~app/modals/modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
@ContentSchema({
  name: 'DialogModal',
  description: '',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'primaryBtn', type: PropertyType.Text },
    { name: 'secondaryBtn', type: PropertyType.Text }
  ]
})
export class DialogModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<DialogModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalContentsSvc: ModalContentsService
  ) { }

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering(this.data);
  }
}
