<!-- eslint-disable @angular-eslint/template/no-negated-async  -->
<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
    [hideHeaderInfo]="(securityInfoLoading$ | async)">
</app-checkin-header>
<div *ngIf="(securityInfoLoading$ | async); else travelerInfoPage">
    <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #travelerInfoPage>
    <div class="container text-left">
        <div class="row scan-passport-section">
            <div *ngIf="!(travelDocuments$ | async); else passportScanned" class="col-12 py-5">
                <div class="header-text subtitle-bold" *haText="rendering.props['scanPassport']"></div>
                <div class="subheader-text call-out mb-4" *haText="rendering.props['scanPassportDescription']"></div>
                <button id="scan-passport-button" (click)="scanPassport()"
                    class="btn btn-default body-copy-bold medium scan-passport-button">
                    <i class="ha-icon icon-24 icon-camera2 mr-4 align-middle"></i>
                    <span *haText="rendering.props['scanPassport']"></span>
                </button>
                <div class="disclaimer-text body-copy-light-text d-flex">
                    <i class="ha-icon icon-16 icon-question-mark mr-3 mt-2"></i>
                    <span *haText="rendering.props['scanPassportDisclaimer']"></span>
                </div>
            </div>
            <ng-template #passportScanned>
                <div id="passportDone" class="col-12 call-out py-4">
                    <i class="ha-icon icon-24 icon-check-circle success-green mr-3 align-middle"></i>
                    <span *haText="rendering.props['scanPassportComplete']"></span>
                </div>
            </ng-template>
        </div>
        <ng-container *ngIf="!canShowForm; else hasAllResidenceInfo">
            <div class="row pt-4 pt-md-0">
                <div class="header-text col-12 py-3 subtitle-bold" *haText="rendering.props['residenceInformationTitle']">
                </div>
                <i class="ha-icon icon-24 icon-check-circle success-green ml-4 mr-3 align-middle"></i>
                <p id="residence-info-complete" *haText="rendering.props['residenceInfoComplete']"></p>
            </div>
        </ng-container>
        <ng-template #hasAllResidenceInfo>
        <div id="has-all-residence-info" class="row pt-4 pt-md-0">
            <div class="header-text col-12 py-3 subtitle-bold" *haText="rendering.props['residenceInformationTitle']">
            </div>
            <div class="col-md-4" id="traveler-country-main">
                <app-ha-datalist inputId="resident-country-datalist" list="travel-info-residence-country"
                    [formControl]="countryOfResidence" 
                    [control]="countryOfResidence"
                    [label]="rendering.props['countryOfResidenceLabel']" [options]="countriesList"
                    [placeholder]="rendering.props['countryOfResidenceLabel']"
                    (change)="submitCountryOfResidence($event.target.value)">
                </app-ha-datalist>
            </div>
        </div>
        </ng-template>
        <div class="row pt-4 pt-md-0" *ngIf="isDestinationFormRequired">
            <div class="header-text col-12 py-3 subtitle-bold" *haText="rendering.props['travelInfoTitle']"></div>
            <mat-checkbox *ngIf="defaultPaxSecurityInfo" class="py-3 col-12 aloha-pink" id="travel-information-checkbox"
                name="travel-info" [checked]="isSameTravelInfo" (change)="toggleCheckbox($event)">
                <div class="subheader-text subtitle" *haText="rendering?.props['sameTravelInformationCheckboxContent']"></div>
            </mat-checkbox>
            <div *ngIf="!isSameTravelInfo" class="full-width">
                <div class="subheader-text call-out py-3 col-12" *haText="rendering.props['firstDayDescription']"></div>
                <app-destination-information class="full-width"
                    (destinationInformationFormIsValid)="isTravelInformationFormValid = $event"
                    [rendering]="travelInformationRendering"></app-destination-information>
            </div>
        </div>
        <div class="row">
            <div class="header-text col-12 py-3 subtitle-bold" *haText="rendering.props['contactInformationTitle']">
            </div>
            <ng-container *ngIf="!canShowForm ; else hasAllTravelDocs">
                <i class="ha-icon icon-24 icon-check-circle success-green ml-4 mr-3 align-middle"></i>
                <p id="emergency-info-complete" *haText="rendering.props['emergencyInfoComplete']"></p>
            </ng-container>
            <ng-template #hasAllTravelDocs>
                <mat-checkbox *ngIf="defaultPaxSecurityInfo" class="col-12 py-3 aloha-pink" id="emergency-contact-checkbox"
                    name="emergency-contact" [checked]="isSameEmergencyContact" (change)="toggleCheckbox($event)">
                    <div class="subheader-text subtitle" *haText="rendering?.props['sameContactInformationCheckboxContent']"></div>
                </mat-checkbox>
                <app-emergency-contact-info *ngIf="!isSameEmergencyContact" class="full-width"
                (emergencyInfoContactFormIsValid)="isEmergencyContactInfoFormValid = $event"
                [rendering]="emergencyContactInfoRendering"></app-emergency-contact-info>
            </ng-template>
        </div>
    </div>
    <app-checkin-footer [navButtons]="rendering?.props['navButtons']"
        [disableDefaultNavButton]="(!isEmergencyContactInfoFormValid || !isTravelInformationFormValid || !(travelDocuments$ | async) || !countryOfResidence)">
    </app-checkin-footer>
</ng-template>