import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { SegmentDetail } from '~app/models/segmentdetail.model';
import { Subscription, Observable } from 'rxjs';
import { MatRadioButton } from '@angular/material/radio';
import { FormGroup, FormControl } from '@angular/forms';
import { RouterDispatchers, TripDispatchers, TripSelectors } from '~app/store';
import { PassengerList } from '~app/models/passengerlist.model';
import { Passenger } from '~app/models/passenger.model';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { MilitaryTravelType } from '~app/constants/ha-constants';
import { PassengerDetail } from '~app/models/passengerdetail.model';

@Component({
  selector: 'app-military-pax-card',
  templateUrl: './military-pax-card.component.html',
  styleUrls: ['./military-pax-card.component.scss'],
})
@ContentSchema({
  name: 'MilitaryPaxCard',
  description: '',
  props: [
    { name: 'ordersOption', type: PropertyType.Text },
    { name: 'leisureOption', type: PropertyType.Text },
    { name: 'selectAll', type: PropertyType.Text },
  ]
})
export class MilitaryPaxCardComponent implements OnInit, OnDestroy {
  @Input() paxDetail: SegmentDetail;
  @Input() rendering: ComponentRendering;
  @Input() paxIndex: number;
  subscriptions: Subscription;

  readonly militaryTypeOrders = MilitaryTravelType.ORDERS;
  readonly militaryTypeLeisure = MilitaryTravelType.LEISURE;

  militaryTravelTypeFormGroup: FormGroup;
  option: string;
  passengerList$: Observable<PassengerList>;
  militaryPaxInformation$: Observable<PassengerDetail[]>;
  pax: PassengerDetail[];

  constructor(
    private tripSelectors: TripSelectors,
    public tripDispatchers: TripDispatchers,
    private routerDispatchers: RouterDispatchers
  ) {
    this.passengerList$ = this.tripSelectors.tripPassengersList$;
    this.militaryPaxInformation$ = this.tripSelectors.militaryPaxInformation$;

  }

  ngOnInit() {
    this.subscriptions = new Subscription();
    this.option = null;
    this.subscriptions.add(this.passengerList$.subscribe(list => {
      if (!!list && !!list.entries && !!list.entries.length) {
        const entry = list.entries.find(pax => pax.id === this.paxDetail.passengerId);
        if (!!entry) {
          this.createForm(entry);
        }
      } else {
        this.routerDispatchers.routeToCheckin(CHECKIN_ROUTES.ROUTE_MILITARY.component);
      }
    }));
    this.subscriptions.add(this.militaryPaxInformation$.subscribe(militaryPaxInfo => {
      this.pax = militaryPaxInfo;
      const militaryType = this.pax.find(detail => this.paxDetail.passengerId === detail.passengerId);
      if (this.militaryTravelTypeFormGroup !== undefined && militaryType.militaryTravelType ) {
        this.militaryTravelTypeFormGroup.get('militaryTravelType').setValue(militaryType.militaryTravelType);
        this.option = militaryType.militaryTravelType;
      }
    }));
  }

  ngOnDestroy() {
    if (!!this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  createForm(list: Passenger) {
    this.militaryTravelTypeFormGroup = new FormGroup({
      passengerId: new FormControl(this.paxDetail.passengerId),
      militaryTravelType: new FormControl(this.paxDetail.militaryTravelType),
    });
    if (!!this.paxDetail.militaryTravelType) {
    this.option = this.paxDetail.militaryTravelType;
    }
  }

  clickRadio(event: MouseEvent, button: MatRadioButton) {
    event.preventDefault();
    this.militaryTravelTypeFormGroup.get('passengerId').setValue(this.paxDetail.passengerId);
    if (this.option === button.value) {
      this.militaryTravelTypeFormGroup.get('militaryTravelType').setValue(MilitaryTravelType.NONE);
      const militaryPaxData = this.militaryTravelTypeFormGroup.value;
      this.tripDispatchers.updateMilitaryInformation(militaryPaxData);
      this.option = null;
    } else {
      this.militaryTravelTypeFormGroup.get('militaryTravelType').setValue(button.value);
      this.option = button.value;
      const militaryPaxData = this.militaryTravelTypeFormGroup.value;
      this.tripDispatchers.updateMilitaryInformation(militaryPaxData);
    }  
  } 
}
