import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError, map, concatMap } from 'rxjs/operators';
import { AircraftImageService } from '../../services/aircraft-image/aircraft-image.service';
import * as AircraftImageActions from '../../actions';
import { AnalyticsDispatchers } from "~app/store/services/analytics/analytics.dispatchers";

@Injectable()
export class AircraftImageEffects {
    constructor(
        private actions$: Actions,
        private aircraftImageService: AircraftImageService,
        private analyticsDispatchers: AnalyticsDispatchers,
    ) { }

    getAircraftImage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AircraftImageActions.getAircraftImage),
            concatMap((action) => {
                return this.aircraftImageService.getAircraftImage(action.flightIndex).pipe(
                    map(aircraftImage => {
                      const domparser = new DOMParser();
                      const theSeats = domparser.parseFromString(aircraftImage, "image/svg+xml");
                      return AircraftImageActions.getAircraftImageSuccess({
                          aircraftImage,
                          flightIndex: action.flightIndex,
                          aircraftSeatList: Array.from(theSeats.documentElement.querySelectorAll('g[id^=seat-]'))
                      });
                    }),
                    catchError(error => of(AircraftImageActions.getAircraftImageError({ error: error}))),
                );
            })
        )
    );

    getAircraftImageError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AircraftImageActions.getAircraftImageError),
            tap(action => {
                this.analyticsDispatchers.logAlertError({
                    contentProps: 'messageGetAircraftImageError',
                    error: 'seat map error for code: '
                });
            })
        ), { dispatch: false });
}
