import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ComponentRendering, ContentSchema, CurrentLocaleService, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { CartDispatchers, CartSelectors, TripSelectors, ReferenceDataSelectors, OrderFulfillDispatchers, AnalyticsDispatchers } from '~app/store';
import { Countries } from '~app/store/reducers/reference-data/reference-data.reducer';
import { DropDownOption } from '@hawaiianair/drop-down';
import { StatesDropDown } from '~app/constants/payment-constants';
import { HaPaymentComponent } from '@hawaiianair/payment';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { AmadeusPaymentData, PaymentInformation } from '@hawaiianair/payment/lib/models/amadeus-payment.model';
import { environment } from '~environments/environment';
import { Country } from '~app/models/country.model';
import { barclaysBinNumbers, CreditCardTypeNames } from '~app/constants/ha-constants';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
@ContentSchema({
  name: 'Payment',
  description: 'PaymentComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'payNow', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Object },
    { name: 'paymentMethod', type: PropertyType.Text },
    { name: 'paymentMethodDescription', type: PropertyType.Text },
    { name: 'signIn', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
  ],
})
export class PaymentComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  @ViewChild('haPayment') haPayment: HaPaymentComponent;
  subscriptions: Subscription = new Subscription();
  cartLoading$: Observable<boolean>;
  grandTotal$: Observable<number>;
  tripLoading$: Observable<boolean>;
  countries$: Observable<Countries>;
  grandTotal: number;

  countries: Country[];
  states: DropDownOption[] = StatesDropDown;
  auData: DropDownOption[];
  jaData: DropDownOption[];
  koData: DropDownOption[];
  nzData: DropDownOption[];
  amadeusPaymentData: AmadeusPaymentData;
  showPaymentMethod = false;

  constructor(
    private cartDispatchers: CartDispatchers,
    private orderFulfillDispatchers: OrderFulfillDispatchers,
    private cartSelectors: CartSelectors,
    private tripSelectors: TripSelectors,
    private referenceDataSelectors: ReferenceDataSelectors,
    private modalContentsService: ModalContentsService,
    private currentLocaleService: CurrentLocaleService,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.grandTotal$ = this.cartSelectors.grandTotal$;
    this.tripLoading$ = this.tripSelectors.tripLoading$;
    this.countries$ = this.referenceDataSelectors.countries$;
  }

  ngOnInit() {
    this.cartDispatchers.checkoutRequestFromPaymentRoute();
    this.subscriptions.add(
      this.countries$.subscribe(countries => {
        this.countries = countries?.countryList;
      })
    );
    this.auData = this.rendering?.components?.['au-dropdown']?.[0]?.props?.['auDropDown'];
    this.jaData = this.rendering?.components?.['ja-dropdown']?.[0]?.props?.['jaDropDown'];
    this.koData = this.rendering?.components?.['ko-dropdown']?.[0]?.props?.['koDropDown'];
    this.nzData = this.rendering?.components?.['nz-dropdown']?.[0]?.props?.['nzDropDown'];
    this.modalContentsService.addRendering('partial-cart-modal', this.rendering.components['partial-cart-modal']);
    this.subscriptions.add(this.grandTotal$.subscribe(total => this.grandTotal = total));
    this.amadeusPaymentData = {
      api: environment.amadeusPaymentApi,
      locale: this.currentLocaleService.locale,
      totalPrice: {
        currency: this.currentLocaleService.currency,
        amount: this.grandTotal
      }
    };
    this.analyticsDispatchers.paymentPageLoaded();
  }

  postPayment(): void {
    this.haPayment?.submitPaymentForm()?.then(paymentInformation => {
      if (paymentInformation) {
        this.orderFulfillDispatchers.orderFulfill(paymentInformation);
        this.analyticsDispatchers.paymentInformationSubmitted(
          {
            haBookingConfirmation: {
              billingCity: paymentInformation.address?.city,
              billingState: paymentInformation.address?.state,
              billingPostalCode: paymentInformation.address?.postalCode,
              billingCountry: paymentInformation.address?.countryCode,
              grandTotal: this.grandTotal,
              paymentType: this.buildTaggingPaymentMethods(paymentInformation),
              voucherAmountUsed: this.totalVoucherAmount(paymentInformation),
            }
          }
        );
      } else {
        throw { error: paymentInformation };
      }
    }).catch(_ => document.getElementById('payment-form')?.scrollIntoView());
  }

  totalVoucherAmount(paymentInfo: PaymentInformation): number {
    return paymentInfo?.vouchers?.reduce((total, voucher) => total + voucher.amount, 0);
  }

  buildTaggingPaymentMethods(paymentInfo: PaymentInformation): string {
    const paymentMethods = [];
    paymentInfo?.creditCards?.forEach((cardInfo) => {
      paymentMethods.push(
        Object.keys(barclaysBinNumbers)?.includes(cardInfo?.paymentToken.slice(0, 6)) ?
          barclaysBinNumbers?.[cardInfo.paymentToken.slice(0, 6)] :
          CreditCardTypeNames?.[cardInfo.vendorCode] || 'CREDITDEBIT'
      );
    });

    paymentInfo?.vouchers?.forEach(() => paymentMethods.push('TRAVEL VOUCHER'));
    paymentInfo?.miles?.forEach(() => paymentMethods.push('MILES'));

    return paymentMethods.sort().join('|').toUpperCase();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
