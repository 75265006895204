import { Component,  Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '../modal-contents.service';
import { MatDialogRef, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { TripDispatchers } from '../../store/services/trip/trip.dispatchers';

@Component({
  selector: 'app-military-type-success-modal',
  templateUrl: './military-type-success-modal.component.html',
  styleUrls: [ './military-type-success-modal.component.scss' ],
})
@ContentSchema({
  description: '',
  name: 'MilitaryTypeSuccessModal',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'okay', type: PropertyType.Text },
  ]
})
export class MilitaryTypeSuccessModalComponent implements OnInit {
  rendering: ComponentRendering;
  title: string;
  description: string;
  okay: string;


  constructor(
    public dialogRef: MatDialogRef<MilitaryTypeSuccessModalComponent>,
    private modalContentsSvc: ModalContentsService,
    public tripDispatchers: TripDispatchers,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
  }

  ngOnInit () {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('military-type-success-modal');

    this.title = this.rendering.props['title'];
    this.description = this.rendering.props['description'];
    this.okay = this.rendering.props['okay'];
 
    // <<< load contents
  }

  goToPreviousPage() {
    this.dialogRef.close();
    this.tripDispatchers.militaryTypeSuccessModalClosed(this.data.routeTo);  
  }
}
