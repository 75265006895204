<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="(cartLoading$ | async) || (tripLoading$ | async)"></app-checkin-header>
<div *ngIf="(cartLoading$ | async) || (tripLoading$ | async); else payment">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #payment>
  <div class="container background text-left">
    <div class="row">
      <div class="col-12 px-0 px-md-4">
        <app-checkin-cart></app-checkin-cart>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0 px-md-4 py-3 py-md-0">
        <mat-card class="mat-card payment-methods" *ngIf="showPaymentMethod">
          <div class="col-12 px-md-0">
            <div id="payment-methods-title" class="payment-methods-title"
              *haText="rendering.props['paymentMethod']"></div>
          </div>
          <div class="col-12 px-md-0">
            <div id="payment-methods-description" class="payment-methods-description"
              *haText="rendering.props['paymentMethodDescription']"></div>
          </div>
          <div class="col-12 px-md-0">
            <a id="payment-methods-sign-in-link" class="payment-methods-link" href=""
              *haText="rendering.props['signIn']"></a>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0 px-md-4">
        <ha-payment #haPayment id="payment-form" [content]="rendering?.components?.['ha-payment']?.[0]?.props?.['content']"
          [cvvRequired]="false" [countries]="countries" [stateDropDownData]="states" [auDropDownData]="auData"
          [nzDropDownData]="nzData" [jaDropDownData]="jaData" [koDropDownData]="koData" [amadeusPaymentData]="amadeusPaymentData"></ha-payment>
      </div>
    </div>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props['navButtons']" [disableButtons]="!haPayment.isPaymentFormValid"
    (defaultNavButtonClicked)="postPayment()"></app-checkin-footer>
</ng-template>