import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ComponentRendering } from '@hawaiianair/core';
import { EliteStandByPassenger } from '../seat-upgrade-passenger-list/seat-upgrade-passenger-list.component';

@Component({
  selector: 'app-seat-upgrade-passenger-card',
  templateUrl: './seat-upgrade-passenger-card.component.html',
  styleUrls: ['./seat-upgrade-passenger-card.component.scss']
})

export class SeatUpgradePassengerCardComponent {
  @Input() rendering: ComponentRendering;
  @Input() passenger: EliteStandByPassenger;
  @Input() index: string;
  @Output() updateEliteStandByPassengerList: EventEmitter<EliteStandByPassenger> = new EventEmitter();

  passengerCheckboxChange(event: MatCheckboxChange) {
    if (!!event) {
      event.checked = !event.checked;
      this.updateEliteStandByPassengerList.emit({
        ...this.passenger,
        checked: event.checked
      });
    }
  }
}
