<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="!sessionData || (offersLoading$ | async) || !cart || (tripLoading$ | async)"></app-checkin-header>
<div class="spinner-wrapper" [hidden]="!savingBags">
  <div id="bags-spinner" class="spinner-border" role="status"></div>
</div>
<div *ngIf="!sessionData || (offersLoading$ | async) || !cart || (tripLoading$ | async); else bagPage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #bagPage>
  <div class="container">
    <div class="row">
      <div class="col-md-12 px-0 px-md-4">
        <div class="row mb-2 d-none d-md-flex">
          <div class="col-md">
            <span class="text-right d-none d-md-block foot-note-bold secondary-text">
              {{ rendering?.props['regularBagsHeader'] | uppercase }}</span>
          </div>
          <div *ngIf="false && !sessionData.isOAFlight" class="col-md-4">
            <span class="text-right d-none d-md-block foot-note-bold secondary-text pr-4">
              {{rendering.props['overweightSpecialItemsHeader']}}
            </span>
          </div>
        </div>
        <mat-card id="bags-pax-{{idx}}" *ngFor='let detail of pax; let idx = index' class="mat-card d-flex">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md passenger-name">
                <app-bags-quantity id="bags-special-{{idx}}" [tagId]="idx" [isBagPage]="true" [associatedPax]="detail.paxDetail"
                  [bagDetail]="detail.bagDetail" [bagContent]="rendering?.props['regularBags']" [paxIdx]="idx"></app-bags-quantity>
              </div>
              <div *ngIf="false" class="col-md-4">
                <div class="row special-items-row">
                  <div class="col">
                    <span *ngIf="sessionData.isOAFlight; else elseBlockButtonHeader" class="text-left d-block d-md-none body-copy">
                      {{ rendering.props['regularBagsHeader'] }}</span>
                    <ng-template #elseBlockButtonHeader>
                      <span class="text-left d-block d-md-none body-copy">{{rendering.props['specialItemsHeader']}}</span>
                    </ng-template>
                  </div>
                  <div id="special-bags-count" class="bold col*">
                    <span id="special-bags-count-{{idx}}" class="text-right mr-5 subtitle-bold d-md-block d-none">
                      {{ detail.specialBagsCount }}
                    </span>
                    <span *ngIf="detail.specialBagsCount !== 0" id="special-bags-count-{{idx}}" class="text-right body-copy-bold medium d-md-none d-block special-bags-count-mobile">
                      {{ detail.specialBagsCount }}
                    </span>
                  </div>
                  <div class="col* button-margin">
                    <button id="bags-open-special-{{idx}}" class="btn btn-secondary btn-secondary-ignore add-button d-md-block d-none bags-open-special-{{idx}}"
                      (click)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.enter)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.space)="openBagSpecialItems(detail?.passengerId)"
                      aria-label="Add/edit special baggage items"
                      title="Add/edit special baggage items">
                      {{ detail.specialBagsCount === 0 ? rendering.props['add'] : rendering.props['edit'] }}
                    </button>
                    <a *ngIf="sessionData.isOAFlight; else regularBagsLink" id="oa-bags-open-{{idx}}" class="body-copy aloha-pink d-md-none d-block"
                      (click)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.enter)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.space)="openBagSpecialItems(detail?.passengerId)"
                      aria-label="Add/edit regular bags"
                      role="button"
                      title="Add/edit regular bags">
                      {{ detail.specialBagsCount === 0 ? rendering.props['addRegularBags'] : rendering.props['edit'] }}
                    </a>
                    <ng-template #regularBagsLink>
                      <a id="bags-open-special-md-{{idx}}" class="body-copy aloha-pink d-md-none d-block bags-open-special-{{idx}}"
                      (click)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.enter)="openBagSpecialItems(detail?.passengerId)"
                      (keydown.space)="openBagSpecialItems(detail?.passengerId)"
                      aria-label="Add/edit special baggage items"
                      role="button"
                      title="Add/edit special baggage items">
                      {{ detail.specialBagsCount === 0 ? rendering.props['addSpecialItems'] : rendering.props['edit'] }}
                      </a>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row mt-5 text-left" id="bag-info-row">
      <div class="col-md-4 foot-note secondary-text">
        <div class="call-out-bold mb-3">
          {{ rendering?.props['specialItemsHeading'] }}
        </div>
        {{ rendering.props['specialItemsDescription'] }}
      </div>
      <div class="col-6 col-md-4 d-flex align-items-center">
        <div class="icon-oversized-baggage88x64"></div>
        <span class="foot-note secondary-text pl-4">
          {{ rendering.props['bagSizeDescription'] }}
        </span>
      </div>
      <div class="col-6 col-md-4 d-flex align-items-center">
        <div class="icon-overweight-baggage88x64"></div>
        <span class="foot-note secondary-text pl-4">
          {{ rendering.props['bagWeightDescription'] }}
        </span>
      </div>
    </div>
    <div id="bag-disclaimer-container" class="row bag-disclaimer-wrapper">
      <div class="bag-disclaimer-left-align offset-md-4 col-md-8 col-12">
          <span id="bag-disclaimer" class="foot-note secondary-text ">
              * {{rendering.props['bagsDisclaimer']}}
          </span>
      </div>
    </div>
    <app-info-links [rendering]="infoLinksRendering" [marginTop]="'80px'" (infoLinkEvent)="clickInfoLink($event)"
      [linkArray]="['bagAdvisory', 'military']">
    </app-info-links>
  </div>
  <app-checkin-footer [navButtons]="bagsFooterButton"></app-checkin-footer>
</ng-template>
