import { Action, createReducer, on } from '@ngrx/store';
import * as InternationalDetailsAction from '../../actions/international-details.actions';
import * as PassportScanActions from '../../actions/passport-scan.actions';
import * as SessionActions from '../../actions/session.actions';
import { RegulatoryDetails } from '~app/models/internationalDetails.model';
import { PassportScanSetup } from '~app/models/passportscansetup.model';
import { PassportInformation } from '~app/models/passport.model';
import { PassportScanData } from '~app/models/passportscandata.model';
import { PassengerName } from '~app/models/passengername.model';
import { Passenger } from '~app/models/passenger.model';

export interface InternationalPassenger {
  id: string;
  passengerName: PassengerName;
  dateOfBirth?: string;
  gender: string;
  isLapInfant?: boolean;
}

export interface InternationalDetailsState {
  regulatoryDetails: RegulatoryDetails;
  passportScan?: PassportScanSetup;
  passportPaxId: string;
  passportInformation: PassportInformation[];
  internationalPassengers: InternationalPassenger[];
  submittedContactTracingPaxIds: string[];
  loading: boolean;
  error: boolean;
}

export const initialState: InternationalDetailsState = {
  regulatoryDetails: null,
  passportScan: null,
  passportPaxId: null,
  passportInformation: [],
  internationalPassengers: [],
  submittedContactTracingPaxIds: [],
  loading: false,
  error: false,
};

// INTL-TEST: Uncomment below for passport testing
// const mockPassportData = require('~mock/json/passportScanData.json').results[0];

export const internationalDetailsReducer = createReducer(
  initialState,

  // Initialize with international passengers list
  on(SessionActions.setSelectedPassengers, (state, { passengers }) => ({
    ...state,
    internationalPassengers: createInternationalPassengers(passengers),
  })),

  // Jumio Passport Scan Actions
  on(PassportScanActions.initiatePassportScan, (state, { paxId }) => ({
    ...state,
    passportScan: null,
    passportPaxId: paxId,
  })),
  on(PassportScanActions.initiatePassportScanSuccess, (state, action) => ({
    ...state,
    passportScan: {
      scanReference: action.scanReference,
      redirectUrl: action.redirectUrl,
      scanComplete: false
    },
    // INTL-TEST: Uncomment below for passport testing
    // passportInformation: addPassportInformation(mockPassportData, state),
  })),
  on(PassportScanActions.getPassportScanStatus, state => ({
    ...state,
    passportScan: {
      ...state.passportScan,
      redirectUrl: null
    }
  })),
  on(PassportScanActions.getPassportScanData, (state, action) => ({
    ...state,
    passportPaxId: action?.passport ? action.passport.paxId : state.passportPaxId,
  })),
  on(PassportScanActions.getPassportScanDataError, state => ({ ...state, passportScan: null })),
  on(PassportScanActions.getPassportScanDataSuccess, (state, { passportData, passengerSegmentIds }) => ({
    ...state,
    passportInformation: addPassportInformation(passportData, state, passengerSegmentIds),
    passportScan: {
      ...state.passportScan,
      scanComplete: true
    },
  })),

  on(InternationalDetailsAction.getMissingRegulatoryDetails, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.getMissingRegulatoryDetailsSuccess, (state, { details }) => ({
    ...state,
    regulatoryDetails: details,
    loading: false,
    error: false
  })),
  on(InternationalDetailsAction.getMissingRegulatoryDetailsError, state => ({ ...state, loading: false, error: true })),
  on(InternationalDetailsAction.addPersonalRegulatoryDetails, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.addPersonalRegulatoryDetailsSuccess, state => ({ ...state, loading: false, error: false })),
  on(InternationalDetailsAction.addPersonalRegulatoryDetailsError, state => ({ ...state, loading: false, error: true })),
  on(InternationalDetailsAction.postPassportInformation, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.postPassportInformationSuccess, state => ({ ...state, loading: false, error: false })),
  on(InternationalDetailsAction.postPassportInformationError, state => ({ ...state, loading: false, error: true })),
  on(InternationalDetailsAction.postAddressInformation, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.postAddressInformationSuccess, state => ({ ...state, loading: false, error: false })),
  on(InternationalDetailsAction.postAddressInformationError, state => ({ ...state, loading: false, error: true })),
  on(InternationalDetailsAction.postEmergencyContactInformation, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.postEmergencyContactInformationSuccess, state => ({ ...state, loading: false, error: false })),
  on(InternationalDetailsAction.postEmergencyContactInformationError, state => ({ ...state, loading: false, error: true })),
  on(InternationalDetailsAction.postContactTracingInformation, state => ({ ...state, loading: true, error: false })),
  on(InternationalDetailsAction.postContactTracingInformationSuccess, (state, { paxId }) => ({
    ...state,
    loading: false,
    error: false,
    submittedContactTracingPaxIds: [...state.submittedContactTracingPaxIds.filter(id => id !== paxId), paxId]
  })),
  on(InternationalDetailsAction.postContactTracingInformationError, (state, { paxId }) => ({
    ...state,
    loading: false,
    error: true,
    submittedContactTracingPaxIds: state.submittedContactTracingPaxIds.filter(id => id !== paxId)
  })),
);

export function reducer(state: InternationalDetailsState | undefined, action: Action) {
  return internationalDetailsReducer(state, action);
}

function createInternationalPassengers(passengers: Passenger[]): InternationalPassenger[] {
  let paxes: InternationalPassenger[] = [];
  passengers.forEach(pax => {
    paxes.push({ id: pax.id, passengerName: pax.passengerName, dateOfBirth: pax?.dateOfBirth, gender: pax.gender, isLapInfant: false });
    if (pax.hasInfant) {
      paxes.push({ id: pax.lapInfant?.id, passengerName: pax.lapInfant?.infantName, dateOfBirth: pax.lapInfant?.dateOfBirth, gender: pax?.lapInfant?.gender, isLapInfant: true });
    }
  });
  return paxes;
}

function addPassportInformation(passportData: PassportScanData, state: InternationalDetailsState, passengerSegmentIds: string[]): PassportInformation[] {
  const newPassport: PassportInformation = {
    passengerId: state.passportPaxId,
    passengerSegmentIds: passengerSegmentIds,
    passport: {
      number: passportData.document.number,
      expiryDate: passportData.document.expiry,
      issuanceCountry: passportData.document.issuingCountry,
      countryOfRegistration: passportData.document.nationality,
      nationality: passportData.document.nationality,
      firstName: passportData.document.firstName,
      lastName: passportData.document.lastName
    }
  };
  return [...state.passportInformation, newPassport];
}
