import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { Segment } from '~app/models/segment.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { FlightRoute } from '../models/flightroute.model';
import { AIRLINE_CODE_HA } from '~app/store/services/flight/flight.service';
import { Flight } from '~app/models/flight.model';
import { seatCategoryArray } from '~app/constants/ha-constants';
import { Subscription } from 'rxjs';
import { AirportMap } from '~app/models/airport.model';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { DatePipe, formatDate, isPlatformBrowser } from '@angular/common';
import { CurrentLocaleService } from '@hawaiianair/core';
import { ISessionState } from '~app/store/reducers/session/session.reducers';

export const HA_TRIPTYPES = [
  'ow',
  'rt',
  'ms',
];

export const CAPABILITY_MARKETS = {
  "Neighbor Island": 'domestic-ni',
  "International": 'intl-inbound',
  "North America": 'domestic-na'
};

export const HA_MARKETS = [
  'Interisland',
  'TransPacific',
  'SouthPacific'
];

export const HA_MARKETTYPES = [
  'domestic-ni',
  'domestic-na',
  'intl-inbound',
  'intl-outbound',
];

export const HA_MARKET_INTERISLAND = "domestic-ni";
export const HA_MARKET_DOMESTIC = "domestic-na";
export const HA_MARKET_INTERNATIONAL_OUT = "intl-outbound";
export const HA_MARKET_INTERNATIONAL_IN = "intl-inbound";

/*
 * HelperService will play with ha data
 */
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  seatMapResult: any;
  airports: AirportMap;
  subscriptions: Subscription;

  constructor(
    private airportSelectors: ReferenceDataSelectors,
    public datepipe: DatePipe,
    @Inject(PLATFORM_ID) private platformId: any,
    private currentLocaleService: CurrentLocaleService,
  ) {
    this.subscriptions = this.airportSelectors && this.airportSelectors.airports$.subscribe(airports => {
      this.airports = airports;
    });
  }
  /* return all the segment entries in the trip
   */
  public getSegmentEntries(tripData: Response<Trip>): Segment[] {
    if (!tripData || !tripData.results || !tripData.results.length
      || !tripData.results[0].segments || !tripData.results[0].segments.entries
      || !tripData.results[0].segments.entries.length) {
      return [];
    }

    return tripData.results[0].segments.entries;
  }

  public getFlightMarketType(flight: Flight): string {

    const originAirport = this.airports && this.airports[flight.origin];
    const destinationAirport = this.airports && this.airports[flight.scheduledDestination];

    if (originAirport && destinationAirport) {
      return this.getMarketType(originAirport.airportData && originAirport.airportData.market,
        destinationAirport.airportData && destinationAirport.airportData.market);
    }

    return null;
  }

  /* getMarketType
   * returned string can be one of the five type
   * "domestic-ni", "domestic-na",
   * "intl-inbound", "intl-outbound",
   * and "" in case of errors
   */
  private getMarketType(originMarket: string, destinationMarket: string): string {
    if (!HA_MARKETS.includes(originMarket) || !HA_MARKETS.includes(destinationMarket)) {
      // unknown market
      return '';
    }

    if (originMarket === HA_MARKETS[0]) {
      if (destinationMarket === HA_MARKETS[0]) {
        return HA_MARKETTYPES[0];
      } else if (destinationMarket === HA_MARKETS[1]) {
        return HA_MARKETTYPES[1];
      } else if (destinationMarket === HA_MARKETS[2]) {
        return HA_MARKETTYPES[3];
      }
    } else if (originMarket === HA_MARKETS[1]) {
      if (destinationMarket === HA_MARKETS[0]) {
        return HA_MARKETTYPES[1];
      } else if (destinationMarket === HA_MARKETS[1]) {
        return HA_MARKETTYPES[1];
      } else if (destinationMarket === HA_MARKETS[2]) {
        return HA_MARKETTYPES[3];
      }
    } else if (originMarket === HA_MARKETS[2]) {
      if (destinationMarket === HA_MARKETS[0]) {
        return HA_MARKETTYPES[2];
      } else if (destinationMarket === HA_MARKETS[1]) {
        return HA_MARKETTYPES[2];
      } else if (destinationMarket === HA_MARKETS[2]) {
        return HA_MARKETTYPES[3];
      }
    }

    return '';
  }

  /* getAirportName
   *
   */
  public getAirportName(airportCode: string): string {
    const airport = this.airports && this.airports[airportCode];
    return airport ? airport.cityNm : airportCode;
  }

  /* getAirportDetails
   *
   */
  public getAirportDetails(airportCode: string): any {
    const airportDetails = this.airports && this.airports[airportCode];
    return airportDetails && airportDetails.airportData;
  }

  /*getLayoverTime
    *layover for multi leg flights (ex: Leg 1: Portland -> Honolulu Leg 2: Honolulu -> Kahului)
  */

  public getLayoverTime(arrival: string, departure: string): string {
    const firstLegArrivalTime = new Date(arrival);
    const secondLegDepartureTime = new Date(departure);
    const difference = (secondLegDepartureTime.valueOf() - firstLegArrivalTime.valueOf()) / 60000;
    const hrs = Math.floor(difference / 60);
    const mins = ((difference % 60).toString().length === 1) ? `0${difference % 60}` : difference % 60;
    return `${hrs}h ${mins}m`;
  }

  // return array of FlightRoute of the segment for the given segmentId
  public getRoutes(tripResult: Trip, selectedSegmentId: string): FlightRoute[] {
    const tripSegments = tripResult.segments;
    if (!tripSegments || !tripSegments.entries || !tripSegments.entries.length) {
      // if tripData is falsy
      return [];
    }
    const tripFlights = tripResult.flights;
    if (!tripFlights || !tripFlights.entries || !tripFlights.entries.length) {
      // if tripData is falsy
      return [];
    }

    // get the segment
    const selSeg = tripSegments.entries.find(segment => segment.id === selectedSegmentId);
    if (!selSeg) {
      return [];
    }

    // get the flight ids in the segment
    const flightIds = selSeg.details[0].flightDetails.map(flight => flight.flightId);

    // get route pair with flight ids.
    const flightsInTheSeg = tripFlights.entries.filter(flight => flightIds.includes(flight.id));

    // create FlightRoute and push it while looping flightsInTheSeg
    const toRet = [];
    flightsInTheSeg.map(flight => {
      if (flight.operatedBy === AIRLINE_CODE_HA) {
        const FR: FlightRoute = {
          id: flight.id,
          from: flight.origin,
          to: flight.scheduledDestination
        };

        toRet.push(FR);
      }
    });

    return toRet;
  }

  // Returns Seat Id for Assigned Seat
  public getSeatId(paxId: string, flightId: string, seatsObject: SeatAssignment[]): string {
    let theAssign: SeatAssignment;
    theAssign = seatsObject.find(seat => seat.paxId === paxId && flightId === seat?.flightId);
    return (theAssign && theAssign.seatId) ? theAssign.seatId : '';
  }

  // Returns Seat Category Location for the Assigned seat
  public getSeatCategory(paxId: string, flightId: string, seatsObject: SeatAssignment[]): string {
    const theCategory = seatsObject.find((seat: SeatAssignment) => seat.paxId === paxId && seat.flightId === flightId);
    return (theCategory && theCategory.seatCategory) ? theCategory.seatCategory : '';
  }

  // Creates array of PAX objects that include Seat Id, Seat Category, Type of Seat.
  createPassengerFlightInformation(passengerInformation, flights2Checkin: Flight[], seatList: any[], seatAssignments: SeatAssignment[]) {
    const pax2Checkin = [];
    flights2Checkin.forEach((leg, index) => {
      passengerInformation.forEach(pax => {
        let seatType = "";
        let seatCategory = "";
        if (!!seatList) {
          for (const seatMap of seatList) {
            if(seatMap.flightIndex === index) {
              this.seatMapResult = seatMap;
            }
          }
        }
        const assignedSeatId = this.getSeatId(pax.id, leg.id, seatAssignments);
        if(!!seatAssignments && !!assignedSeatId) { seatCategory = this.getSeatCategory(pax.id, leg.id, seatAssignments) };
        pax2Checkin.push({
          ...pax,
          seatAssignment: this.getSeatId(pax.id, leg.id, seatAssignments),
          legId: leg.id,
          seatType: seatType,
          seatCategoryType: seatCategory,
        });
      });
    });
    return pax2Checkin;
  }

  // Returns Text for seat type based on the assigned seat and seat map.
  public getSeatType (assignedSeatId: string, seatMapResult: any) {
    let seatTypeText = "";
    if (!!assignedSeatId) {
      for (const seat of seatMapResult.aircraftSeatList) {
        const seatIdSplits = seat.id.split('-');
        if (seatIdSplits[1] === assignedSeatId) {
          if (seatIdSplits.length > 2) {
            if (seatIdSplits[2].includes("a")) {
              seatTypeText = "Aisle";
            } else if (seatIdSplits[2].includes("w")) {
              seatTypeText = "Window";
            }
            if (seatIdSplits[2].includes("x")) {
              seatTypeText = seatTypeText ? `${seatTypeText}, Exit` : "Exit";
            }
          }
        }
      }
    } else {
        seatTypeText = "";
    }
    return seatTypeText;
  }

  public setSeatCategory(category: string): string {
    if (!category) {
      return '';
    }
    const categoryToUpperCase = category.toUpperCase();
    const seatCategory = seatCategoryArray.find(cat => categoryToUpperCase === cat.categoryType);
    return (seatCategory && seatCategory.displayName) || '';
  }

  // return array of flightId of the segment for the given segmentId
  public getFlightIds(tripResult: Trip, selectedSegmentId: string): string[] {
    const toRet = [];
    const segment = tripResult.segments.entries.find(seg => seg.id === selectedSegmentId);
    if (!segment) {
      return toRet;
    }
    segment.details[0].flightDetails.forEach(flight => toRet.push(flight.flightId));
    return toRet;
  }

  // return boolean if flight is operated by HA
  public isHAFlight(flightId: string, flights: Flight[]): boolean {
    const segmentFlight = flights?.find(flight => flightId === flight?.id);
    if (segmentFlight && segmentFlight.operatedBy === AIRLINE_CODE_HA) {
      return true;
    }
    return false;
  }

  public getDateDifferenceInDays(arrivalDateTimeString: string, departureDateTimeString: string): number {
    return this.convertMillisecondsToDays(new Date(this.datepipe.transform(new Date(arrivalDateTimeString), 'yyyy-MM-dd')).getTime()) -
           this.convertMillisecondsToDays(new Date(this.datepipe.transform(new Date(departureDateTimeString), 'yyyy-MM-dd')).getTime());
  }

  public convertMillisecondsToDays(milliseconds: number): number {
    return Math.floor(milliseconds / (60 * 60 * 24 * 1000));
  }

  public getIsPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  /*
   * getLocalFullDateString()
   * Input: Date or String of date/time
   * Output: String with locale formatted date
      - en/en-au/en-nz = `6:00 pm, 8 Aug 2021`
      - ja/zh = `18:00, 2021年8月5日`
      - ko = `18:00, 2021년8월5일`
  */
  public getLocalFullDateString(dateTimeString: Date | string): string {
    return this.currentLocaleService.language === 'en'
      ? formatDate(dateTimeString, 'h:mm a, d MMM yyyy', this.currentLocaleService.locale)
      : `${formatDate(dateTimeString, 'HH:mm', this.currentLocaleService.locale)}, ${formatDate(dateTimeString, 'longDate', this.currentLocaleService.locale)}`;
  }

  public getAmadeusStyleFlightId(flight: Flight): string {
    const departureTimeSplit = flight.scheduledDeparture.airportDateTimeString.split('T'); '2023-07-03T7:00:00.000';
    const hourMinsSeconds = departureTimeSplit[1].split(':');
    const time = hourMinsSeconds[0].padStart(2, '0') + hourMinsSeconds[1];
    const splitFlightId = flight.id.split("-");
    return "SEG-" + splitFlightId[0] + splitFlightId[1].replace(/^0+/, '') + "-" + splitFlightId[3] + splitFlightId[4] + "-" + splitFlightId[2].replace(/(\d{4})(\d{2})/, "$1-$2-") + "-" + time;
  }

  public hasSameFlights(entry: Segment, state: ISessionState): boolean {
    return state?.selectedSegment?.details[0]?.flightDetails?.every((flight, idx) => 
      entry.details[0]?.flightDetails[idx].flightId === flight?.flightId
    );
  }
}
