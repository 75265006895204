import { AppSharedModule } from '~app/shared/app.shared.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { GuidelinesPageComponent } from './guidelines-page.component';

@NgModule({
    imports: [
      AppSharedModule,
      // for Material Design
      // import Material Design Components
      MatButtonModule,
      CoreModule,
      CoreModule.forChild({
        components: [
          GuidelinesPageComponent,
        ]
    }),
    ],
    exports: [
      // export Material Design components
      MatButtonModule,
      RouterModule
    ],
    declarations: [
        GuidelinesPageComponent,
    ],
  })
  export class GuidelinesPageModule { }
