import { ModalContentsService } from '../modal-contents.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TripDispatchers } from '../../store/services/trip/trip.dispatchers';
import { PassengerName } from '~app/models/passengername.model';
import { Observable, Subscription } from 'rxjs';
import { TripSelectors } from '~app/store/services/trip/trip.selectors';

export interface PaxData {
  segmentId: string;
  passengerId: string;
  passengerName: PassengerName;
  militaryTravelType: string;
}

@Component({
  selector: 'app-military-type-empty-modal',
  templateUrl: './military-type-empty-modal.component.html',
  styleUrls: [ './military-type-empty-modal.component.scss' ],
})
@ContentSchema({
  description: 'MilitaryTypeEmptyModalComponent',
  name: 'MilitaryTypeEmptyModal',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'no', type: PropertyType.Text },
    { name: 'yes', type: PropertyType.Text },
  ]
})
export class MilitaryTypeEmptyModalComponent implements OnInit {
  rendering: ComponentRendering;
  heading: string;
  description: string;
  no: string;
  yes: string;
  name: String;
  code: String;
  paxData: PaxData[];
  subscriptions: Subscription;
  militaryLoading$: Observable<boolean>;
  constructor(
    public dialogRef: MatDialogRef<MilitaryTypeEmptyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalContentsSvc: ModalContentsService,
    private tripDispatchers: TripDispatchers,
    private tripSelectors: TripSelectors,
  ) { 
    this.militaryLoading$ = this.tripSelectors.militaryLoading$;
  }
 
  ngOnInit() {
    this.subscriptions = new Subscription();
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('military-type-empty-modal');

    this.heading = this.rendering.props['heading'];
    this.description = this.rendering.props['description'];
    this.no = this.rendering.props['no'];
    this.yes = this.rendering.props['yes'];
    // <<< load contents
    this.paxData = this.data.passengerData.filter(pax => pax.militaryTravelType);
  }

  clickYes() {
    this.tripDispatchers.updatePassengerMilitaryType(
      this.data.tripId, 
      this.paxData,
      this.data.routeTo
      );
    this.subscriptions.add(this.militaryLoading$.subscribe(loading => {
      if (!loading) {
        this.dialogRef.close(true);
      }
    }));
  }
}


