import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as CartActions from '~app/store/actions/cart.actions';
import { EntityState } from '~app/store/reducers';
import { CartItem } from '~app/models/cartitem.model';
import { PaymentItem } from "~app/models/paymentitem.model";

@Injectable()
export class CartDispatchers {
  constructor(private store: Store<EntityState>) { }

  createCartPassengers(confirmationCode: string, segmentId: string, passengerIds: string, route?: string) {
    if (!!route) {
      this.store.dispatch(CartActions.createCartDashboard({ confirmationCode, segmentId, passengerIds, route }));
    } else {
      this.store.dispatch(CartActions.createCartPassengers({ confirmationCode, segmentId, passengerIds }));
    }
  }

  recreateCartPassengers(cartId: string, confirmationCode: string, segmentId: string, passengerId: string, route?: string) {
    if (!!route) {
      this.store.dispatch(
        CartActions.recreateCartDashboard({ cartId, confirmationCode, segmentId, passengerId, route })
      );
    } else {
      this.store.dispatch(CartActions.recreateCartPassengers({ cartId, confirmationCode, segmentId, passengerId }));
    }
  }

  getCart(component: string, cartId: string) {
    if (component === 'bagsFormComponent') {
      this.store.dispatch(CartActions.getCartBagsComponent({ cartId: cartId }));

    } else if (component === 'seatmapModalComponent') {
      this.store.dispatch(CartActions.getCartSeatmapModal({ cartId: cartId }));
    }
  }

  deleteCart(cartId: string) {
    this.store.dispatch(CartActions.deleteCart({ cartId: cartId }));
  }

  replaceAllItems(cartId: string, cartItems: CartItem[], catalogId: string, itemType: string, component: string) {
    this.store.dispatch(CartActions.replaceAllItems({ cartId, cartItems, catalogId, itemType, component }));
  }

  getCartWithBags(cartId: string, cartItems: CartItem[], catalogId: string, itemType: string, component: string) {
    this.store.dispatch(CartActions.getCartWithBags({ cartId, cartItems, catalogId, itemType, component }));
  }

  getCartWithSeats(cartId: string, cartItems: CartItem[], catalogId: string, itemType: string, component: string) {
    this.store.dispatch(CartActions.getCartWithSeats({cartId, cartItems, catalogId, itemType, component}));
  }

  updateCartByRemovingASeatItem(cartId: string, segmentId: string, flightId: string, passengerId: string) {
    this.store.dispatch(CartActions.updateCartByRemovingASeatItem({ cartId, segmentId, flightId, passengerId }));
  }

  checkoutRequestFromPaymentRoute() {
    this.store.dispatch(CartActions.checkoutRequestFromPaymentRoute());
  }

  cartErrorAccepted() {
    this.store.dispatch(CartActions.cartErrorAccepted());
  }

  storeCartItems() {
    this.store.dispatch(CartActions.storeCartItems());
  }

  restoreCartItems() {
    this.store.dispatch(CartActions.restoreCartItems());
  }

  confirmBagsPrice() {
    this.store.dispatch(CartActions.confirmBagsPrices());
  }

  checkoutCartFromPaymentRoute(paymentItem: PaymentItem) {
    this.store.dispatch(CartActions.checkoutCartFromPaymentRoute({ paymentItem }));
  }
}
