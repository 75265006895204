import { Component, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '../modal-contents.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-add-guest-error-modal',
    templateUrl: './add-guest-error-modal.component.html',
    styleUrls: [ './add-guest-error-modal.component.scss' ],
})

@ContentSchema({
    name: 'AddGuestErrorModal',
    description: 'Add Guest Error Modal',
    props: [
        { name: 'notFound', type: PropertyType.Text },
        { name: 'tryAgain', type: PropertyType.Text }
    ]
})

export class AddGuestErrorModalComponent implements OnInit {
    rendering: ComponentRendering;

    constructor(
        public dialogRef: MatDialogRef<AddGuestErrorModalComponent>,
        private modalContentsSvc: ModalContentsService,
    ) { }

    ngOnInit() {
        this.rendering.props = this.modalContentsSvc.getRendering('add-guest-error-modal') || [{}];
    }
}
