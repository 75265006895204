import { Action, createReducer, on, ActionReducer } from '@ngrx/store';
import { Segment } from '~app/models/segment.model';
import { Trip } from '~app/models/trip.model';
import * as SessionActions from '../../actions/session.actions';
import * as TripActions from '~app/store/actions/trip.actions';
import * as CartActions from '~app/store/actions/cart.actions';
import * as RouterActions from '~app/store/actions/router.actions';
import { Response } from '~app/models/response.model';
import { Flight } from '~app/models/flight.model';
import { Terms } from '../../../models/terms.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { Passenger } from '~app/models/passenger.model';
import { SegmentDetail } from '~app/models/segmentdetail.model';
import { CartContentItems } from '~app/models/cartcontentItems.model';
import { HelperService } from '~app/services/helper.service';
import { TripSearch } from '~app/models/trip-search.model';
import { CHECKIN_ROUTES } from '../router/checkin-route-serializer';
import { EliteStandByPassenger } from '~app/components/seat/seat-upgrade-passenger-list/seat-upgrade-passenger-list.component';
import { FlightDetail } from '~app/models/flightdetail.model';
import { Constants } from '~app/constants/ha-constants';
import { LocalStorageService } from '@hawaiianair/core';

export interface ISessionState {
  tripId: string;
  correlationId?: string;
  passengerIds: string[];
  selectedSegment: Segment;
  selectedSegmentId: string;
  trip2Checkin: Trip;
  cartId: string;
  error: boolean;
  errorCode: string;
  checkinComplete: boolean;
  fullTripData?: any[];
  resultData?: Response<Trip>;
  origin?: string;
  destination?: string;
  hasAlreadyCheckedIn?: boolean;
  code?: string;
  tripSearch?: TripSearch;
  firstFlightId?: string;
  airportOriginLongName?: string;
  aiportDestinationLongName?: string;
  flightCount?: number;
  authToken?: string;
  segmentFlights?: Flight[];
  originallyAssignedSeats?: SeatAssignment[];
  recentlySavedSeats?: SeatAssignment[];
  selectedPassengers?: Passenger[];
  isOAFlight?: boolean;
  terms?: Terms;
  confirmedInfant?: boolean;
  appBannerClose?: boolean;
  isMobile?: boolean;
  os?: string;
  browser?: string;
  cartContent: CartContentItems[];
  resetHeader: boolean;
  eliteStandByEligiblePassengers?: EliteStandByPassenger[];
  selectedEliteStandByPassengers?: EliteStandByPassenger[];
  addInfantAdultId?: string;
  finishCheckin?: boolean;
}

export const initialState: ISessionState = {
  tripId: '',
  correlationId: '',
  passengerIds: [],
  selectedSegment: null,
  selectedSegmentId: '',
  trip2Checkin: null,
  cartId: '',
  error: false,
  errorCode: '',
  checkinComplete: false,
  origin: '',
  destination: '',
  hasAlreadyCheckedIn: false,
  code: '',
  tripSearch: null,
  airportOriginLongName: '',
  aiportDestinationLongName: '',
  authToken: null,
  segmentFlights: null,
  isOAFlight: false,
  terms: null,
  confirmedInfant: false,
  originallyAssignedSeats: null,
  recentlySavedSeats: null,
  selectedPassengers: null,
  appBannerClose: false,
  isMobile: false,
  os: '',
  browser: '',
  cartContent: [],
  resetHeader: false,
  eliteStandByEligiblePassengers: [],
  selectedEliteStandByPassengers: [],
  addInfantAdultId: '',
  finishCheckin: false
};

function modifySessionState(
  sessionState: ISessionState,
  sessionChanges: any
): ISessionState {
  return {
    ...sessionState,
    [sessionChanges.name]: sessionChanges.value
  };
}

function addPreSelectedPassengerIDs(
  details: SegmentDetail[]
): string[] {
  return details.filter(detail => {
    if (detail.flightDetails.some(flight => !flight.isCheckedIn)) {
      return detail;
    }
  }).map(detail => detail.passengerId);
}

function getEliteStandByEligiblePassengers(passengers: Passenger[], segmentDetails: SegmentDetail[]): EliteStandByPassenger[] {
  if (!!passengers && !!segmentDetails) {
    const elitePassengers = passengers.filter(pax =>
      pax.customerLevel === Constants.customerLevelPlatinum || pax.customerLevel === Constants.customerLevelGold);

    if (!!elitePassengers) {
      const eligiblePassengers = elitePassengers.map(pax => {
        const flightDetails: FlightDetail[] = [];
        const detail = segmentDetails.find(segmentDetail => segmentDetail.passengerId === pax.id);
        if (!!detail && !!detail.flightDetails) {
          detail.flightDetails.forEach(flightDetail => {
            if (flightDetail.isEligibleForUpgrade && !flightDetail.priorityListCode) {
              flightDetails.push(flightDetail);
            }
          });
        }
          return {
            customerLevel: pax.customerLevel,
            passengerName: pax.passengerName,
            externalId: !!flightDetails.length ? flightDetails[0].externalId : "",
            isCheckedIn: !!flightDetails.length ? flightDetails[0].isCheckedIn : "",
            passengerId: detail.passengerId,
            flightDetails
          } as EliteStandByPassenger;
      });

      return eligiblePassengers.filter(pax => !!pax.flightDetails.length);
    }

  }
  return [];
}

export const sessionReducer = createReducer(
  initialState,
  on(SessionActions.getSession, state => ({ ...state })),
  on(SessionActions.getSessionError, state => ({ ...state, error: true })),
  on(SessionActions.getSessionSuccess, state => ({ ...state })),
  on(SessionActions.setSessionTripId, (state, action) =>
    modifySessionState(state, action)
  ),
  on(TripActions.checkinSuccess, (state, { trip }) => ({
    ...state,
    trip2Checkin: trip.results[0]
  })),
  on(SessionActions.setSessionCorrelationId, (state, action) => ({
    ...state,
    correlationId: action.correlationId
  })),
  on(SessionActions.setSessionOriginallyAssignedSeats, (state, { assignedSeats }) => ({
    ...state,
    originallyAssignedSeats: assignedSeats,
    recentlySavedSeats: assignedSeats,
    eliteStandByEligiblePassengers: getEliteStandByEligiblePassengers(state.selectedPassengers,
      state.selectedSegment && state.selectedSegment.details)

  })),
  on(SessionActions.setRecentlySavedSeatsSuccess, (state, { recentlySavedSeats }) => ({
    ...state,
    recentlySavedSeats,
    eliteStandByEligiblePassengers: getEliteStandByEligiblePassengers(state.selectedPassengers,
      state.selectedSegment && state.selectedSegment.details)
  })),
  on(SessionActions.setSelectedPassengers, (state, { passengers }) => ({
    ...state,
    selectedPassengers: passengers,
    eliteStandByEligiblePassengers: getEliteStandByEligiblePassengers(passengers,  state.selectedSegment && state.selectedSegment.details)
  })),
  on(SessionActions.setSessionSelectedSegmentSuccess, (state, action) => ({
    ...state,
    selectedSegment: action.segment,
    selectedSegmentId: action.segment.id,
    firstFlightId: action.segment.details[0].flightDetails[0].flightId,
    origin: action.segment.origin,
    destination: action.segment.destination,
    hasAlreadyCheckedIn: action.segment.areAllPassengersCheckedIn,
    errorCode: action.errorCode,
    flightCount: action.segment.details[0].flightDetails.length,
    segmentFlights: action.segmentFlights,
    passengerIds: addPreSelectedPassengerIDs(action.segment.details),
    eliteStandByEligiblePassengers: getEliteStandByEligiblePassengers(state.selectedPassengers, action.segment.details)
  })),
  on(SessionActions.updateSessionSelectedSegment, (state, { segment }) => ({ ...state, selectedSegment: segment, selectedSegmentId: segment?.id })),
  on(SessionActions.setSessionSelectedSegmentError, (state, { errorCode }) => ({ ...state, error: true, errorCode })),
  on(SessionActions.setSessionPassengerIds, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setAirportOriginLongName, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setAirportDestinationLongName, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setSessionTrip2Checkin, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setSessionAuthToken, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setSessionCartId, (state, action) =>
    modifySessionState(state, action)
  ),
  on(CartActions.deleteCartSuccess, state => ({
    ...state,
    cartId: ''
  })),
  on(SessionActions.setSessionTrip2CheckinPassengers, (state, { passengers }) => ({
    ...state,
    trip2Checkin: {
      ...state.trip2Checkin,
      passengers
    }
  })),
  on(SessionActions.addSessionPassengerIds, (state, { passengerIds }) => ({
    ...state,
    passengerIds: [...state.passengerIds, passengerIds]
  })),
  on(SessionActions.setSessionTripIdSuccess, state => ({ ...state })),
  on(SessionActions.setSessionCheckinComplete, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setSessionFullTripData, (state, action) =>
    modifySessionState(state, action)
  ),
  on(SessionActions.setSessionResultData, (state, action) =>
    action.value ? modifySessionState(state, action) : state
  ),
  on(RouterActions.routeToInfantAdditionalInfo, (state, action) => ({
    ...state,
    addInfantAdultId: action.data
  })),
  on(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_SEATMAP.route), (state) => ({
    ...state,
  })),
  on(TripActions.addLapInfantSuccess, (state, { passengerList }) => ({
    ...state,
    selectedPassengers: state.selectedPassengers.map(selectedPax =>
      passengerList.entries.find(pax => selectedPax.id === pax.id)
    )
  })),
  on(TripActions.removeLapInfantSuccess, (state, { trip }) => ({
    ...state,
    resultData: trip,
    trip2Checkin: trip.results[0],
    selectedPassengers: state.selectedPassengers.map(pax =>
      trip.results[0].passengers.entries.find(entry => pax.id === entry.id)
    )
  })),
  on(SessionActions.setSessionOAFlightFlag, (state, { isOAFlight }) => ({
    ...state,
    isOAFlight
  })),
  on(SessionActions.setSessionTermsAndAgreement, (state, { terms }) => ({
    ...state,
    terms
  })),
  on(SessionActions.setSessionInfantConfirmation, (state, { confirmedInfant }) => ({
    ...state,
    confirmedInfant
  })),
  on(SessionActions.clearFullTripData, state => ({
    ...state,
    fullTripData: null
  })),
  on(SessionActions.getSessionResultData, state => ({ ...state })),
  on(SessionActions.getSessionFullTripData, state => ({ ...state })),
  on(SessionActions.clearSessionDataCheckinStart, _ => ({
    ...initialState
  })),
  on(SessionActions.resetHeader, state => ({
    ...state,
    resetHeader: true
  })),
  on(SessionActions.setSessionTripParams, (state, { tripSearch, confirmationCode }) => ({
    ...state,
    tripSearch,
    code: !!confirmationCode ? confirmationCode : state.code
  })),
  on(TripActions.updatePassengerMilitaryTypeSuccess, (state, { trip }) => ({
    ...state,
    selectedSegment: trip.results[0].segments.entries.find(entry => entry.id === state.selectedSegmentId)
  })),
  on(CartActions.recreateCartSuccessPassengers, (state, _) => ({
    ...state,
    recentlySavedSeats: state.originallyAssignedSeats
  })),
  on(SessionActions.setSessionAppBannerClose, (state, { appBannerClose }) => ({
    ...state,
    appBannerClose: appBannerClose
  })),
  on(SessionActions.setMobileDevice, (state, { isMobile }) => ({
    ...state,
    isMobile: isMobile
  })),
  on(SessionActions.setOSDevice, (state, { os }) => ({
    ...state,
    os: os
  })),
  on(SessionActions.setBrowserDevice, (state, { browser }) => ({
    ...state,
    browser: browser
  })),
  on(SessionActions.addCartContent, (state, { cartContentItems: cartContentItems }) => ({
    ...state,
    cartContent: cartContentItems
  })),
  on(SessionActions.updateCartContentSuccess, (state, { cartContentItems: cartContentItems }) => ({
    ...state,
    cartContent: cartContentItems
  })),
  on(SessionActions.updateEliteStandByData, (state, { selectedEliteStandByPassengers }) => ({
    ...state,
    selectedEliteStandByPassengers: selectedEliteStandByPassengers
  })),
  on(TripActions.addToEliteStandByListErrorModalClosed, (state) => ({
    ...state,
    selectedEliteStandByPassengers: [],
    eliteStandByEligiblePassengers: []
  })),
  on(RouterActions.defaultNavButtonClick(CHECKIN_ROUTES.ROUTE_PASSENGER.route), (state) => ({...state, finishCheckin: false})),
  on(RouterActions.secondaryNavButtonClick(CHECKIN_ROUTES.ROUTE_PASSENGER.route), (state) => ({...state, finishCheckin: true})),
);

const helperService: HelperService = new HelperService(null, null, null, null);
export const localStorageService: LocalStorageService = helperService.getIsPlatformBrowser() ? new LocalStorageService('browser') : null;

export function persistStateReducer(aReducer: ActionReducer<ISessionState>) {
  const localStorageKey = '__session';
  return (state: ISessionState | undefined, action: Action) => {
    if (state === undefined) {
      const persisted =  localStorageService ? localStorageService.get(localStorageKey) : null;
      return persisted ? JSON.parse(persisted) : aReducer(state, action);
    }
    let nextState = aReducer(state, action);

    if (action.type === TripActions.clearTripDataCheckinStart.type) {
      nextState = {
        ...initialState
      };
    }
    if (localStorageService) {
      localStorageService.set(localStorageKey, JSON.stringify(nextState));
    }
    return nextState;
  };
}

export function updateStateReducer(aReducer: ActionReducer<ISessionState>) {
  return (state: ISessionState | undefined, action: Action) => {
    if (action.type === 'UPDATE_SESSION_STATE') {
      return (<any>action).payload.newState;
    }

    return aReducer(state, action);
  };
}

export function reducer(state: ISessionState | undefined, action: Action) {
  return updateStateReducer(persistStateReducer(sessionReducer))(state, action);
}
