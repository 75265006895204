import { Injectable } from '@angular/core';
import { ComponentRendering } from '@hawaiianair/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicContentService {
  /* Function takes ComponentRendering object and replaces first level
     string props and replaces key with associated value in content map
     Input:
      rendering: Component Rendering with string value props
      contentMap: Object where multiple keys are placed in .yml as {{key}} and values to be replaced with in string

     Output:
      ComponentRendering with updated dynamic content
  */
  fillContentProps(rendering: ComponentRendering, contentMap: object): ComponentRendering {
    if (rendering && rendering.props) {
      Object.keys(rendering.props).forEach(prop => {
          if (typeof(rendering.props[prop]) === 'string') {
            rendering.props[prop] = this.getContentString(rendering.props[prop], contentMap);
          }
      });
    }
    return rendering;
  }

  /* Function takes string and replaces key with associated value in content map
     Input:
      str: string with dynamic content to be replaced
      contentMap: Object where key is placed in .yml as {{key}} and value to be replaced with in string

     Output:
      string with updated dynamic content
  */
  getContentString(str: string, contentMap: object): string {
    let contentArr = str && str.split(/\{\{|\}\}/);
    if (contentArr && contentArr.length > 1) {
       contentArr = contentArr.map(val => {
        return contentMap[val] ? contentMap[val] : val;
      });
      return contentArr.join('');
    }

    return str;
  }
}
