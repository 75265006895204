import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as SecurityInfoAction from '../../actions/security-info.actions';
import { EntityState } from '~app/store/reducers';
import { ContactInfo } from '~app/models/contactinfo.model';
import { AddressInfo } from '~app/models/addressinfo.model';
import { AddressType } from "~app/constants/international-constants";

@Injectable()
export class SecurityInfoDispatchers {
    constructor(private store: Store<EntityState>) { }

    addEmergencyContactInfo(contactInfo: ContactInfo) {
        this.store.dispatch(SecurityInfoAction.addEmergencyContactInfo({
            contactInfo: contactInfo
        }));
    }

    addDestinationInfo(addressInfo: AddressInfo) {
        addressInfo = !!addressInfo ? addressInfo : { ...addressInfo, addressType: AddressType.DESTINATION };
        this.store.dispatch(SecurityInfoAction.addDestinationInfo({ addressInfo: addressInfo }));
    }

    travelerSelectionAddInfo(travelerId: string) {
        this.store.dispatch(SecurityInfoAction.travelerSelectionAddInfo({ travelerId }));
    }

    addResidentInfo(addressInfo: AddressInfo) {
      this.store.dispatch(SecurityInfoAction.addResidentInfo({ addressInfo }));
    }
}
