import { UiState } from './../../reducers/index';
import { Injectable } from '@angular/core';
import { createFeatureSelector, Store, createSelector } from '@ngrx/store';
import { ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import { ICheckinRouterState } from '~app/store/reducers/router/checkin-router.reducer';

const getUiState = createFeatureSelector<UiState>('uiCache');

const getRouterFeatureState = createSelector(
  getUiState,
  (state: UiState) => state && state.router && state.router.state
);

const getCheckinRouterState = createSelector(
  getUiState,
  (state: UiState) => state.checkinRouter
);

export const getPreviousRoute = createSelector(
  getCheckinRouterState,
  (state: ICheckinRouterState) => state.previous
);

export const getDashboardActions = createSelector(
  getCheckinRouterState,
  (state: ICheckinRouterState) => state && state.dashboardActions
);

export const getSortedActions = createSelector(
  getCheckinRouterState,
  (state: ICheckinRouterState) => state && state.sortedActions
);

export const getRouterNavigationId = createSelector(
  getUiState,
  (state: UiState) => state.router && state.router.navigationId
);

export const getRouterState = createSelector(
  getRouterFeatureState,
  (state: ICheckinRouteSerializerState) => state
);

export const getRouterUrl = createSelector(
  getRouterFeatureState,
  (state: ICheckinRouteSerializerState) => state && state.url
);

export const getRouterParams = createSelector(
  getRouterFeatureState,
  (state: ICheckinRouteSerializerState) => state && state.params
);

export const getRouterQueryParams = createSelector(
  getRouterFeatureState,
  (state: ICheckinRouteSerializerState) => state.queryParams
);

export const getRouterIsCheckInView = createSelector(
  getRouterFeatureState,
  (state: ICheckinRouteSerializerState) => state && state.isCheckInView
);

@Injectable()
export class RouterSelectors {
  constructor(private store: Store<UiState>) { }

  routerState$ = this.store.select(getRouterState);
  routerUrl$ = this.store.select(getRouterUrl);
  routerParams$ = this.store.select(getRouterParams);
  routerQueryParams$ = this.store.select(getRouterQueryParams);
  navigationId$ = this.store.select(getRouterNavigationId);
  isCheckInView$ = this.store.select(getRouterIsCheckInView);
  previousRoute$ = this.store.select(getPreviousRoute);
  sortedActions$ = this.store.select(getSortedActions);
}
