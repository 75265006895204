<div *ngIf="(nextFlight || previousFlight) && navButtons.flights" class="pink-header px-0 body-copy-bold medium">
  <div class="container">
    <div class="row no-gutters">
      <div class="col text-left">
        <span *ngIf="previousFlight" id="previous-flight-button-{{browserID}}" class="nav-btn" (click)="routeToFlight(previousFlight.id)" (keyup)="routeToFlight(previousFlight.id)">
          <i class="ha-icon icon-angle-left nav-icon"></i>
          <span *haText="navButtons?.flights['btnPrevious']"></span>
        </span>
      </div>
      <div class="col-auto">
        <span id="seatmap-flight-counter" class="body-copy white" *haText="flightCounterString"></span>
      </div>
      <div class="col text-right">
        <span *ngIf="nextFlight" id="next-flight-button-{{browserID}}" class="nav-btn" (click)="routeToFlight(nextFlight.id)" (keyup)="routeToFlight(nextFlight.id)">
          <span *haText="navButtons?.flights['btnNext']"></span>
          <i class="ha-icon icon-angle-right nav-icon"></i>
        </span>
      </div>
    </div>
  </div>
</div>