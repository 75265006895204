import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-link-minor-modal',
  templateUrl: './link-minor-modal.component.html',
  styleUrls: ['./link-minor-modal.component.scss']
})
@ContentSchema({
  description: 'Link Minor Modal Component',
  name: 'LinkMinorModal',
  props: [
    { name: 'linkMinorHeading1', type: PropertyType.Text },
    { name: 'linkMinorHeading2', type: PropertyType.Text },
    { name: 'travelingAloneButton', type: PropertyType.Text },
    { name: 'travelingWithAdultButton', type: PropertyType.Text }
  ]
})
export class LinkMinorModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<LinkMinorModalComponent>,
    private modalContentsSvc: ModalContentsService,
  ) { }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('link-minor-modal');
  }
}
