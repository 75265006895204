import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';

@Component({
  selector: 'app-info-links',
  templateUrl: './info-links.component.html',
  styleUrls: ['./info-links.component.scss']
})
@ContentSchema({
  name: 'InfoLinks',
  description: 'InfoLinksComponent',
  props: [
    { name: 'military', type: PropertyType.Object },
    { name: 'addInfant', type: PropertyType.Object },
    { name: 'frequentFlyerInfo', type: PropertyType.Object },
    { name: 'bagAdvisory', type: PropertyType.Object },
    { name: 'saveTripCalendar', type: PropertyType.Object },
    { name: 'tripDetailsStatus', type: PropertyType.Object },
    { name: 'emailReceipt', type: PropertyType.Object },
    { name: 'nextSteps', type: PropertyType.Object }
  ]
})
export class InfoLinksComponent {
  @Input() rendering: ComponentRendering;
  @Input() linkArray: Object;
  @Input() marginTop: string;
  @Output() infoLinkEvent = new EventEmitter<string>();

  clickInfoLink(value: string) {
    this.infoLinkEvent.emit(value);
  }
}
