import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription, take } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { InternationalDetailsDispatchers, InternationalDetailsSelectors, ReferenceDataSelectors } from '~app/store';
import { PassengerName } from '~app/models/passengername.model';
import { RegexPatterns } from '~app/constants/regex-constants';
import { CustomValidators } from '~app/validators/noMatch.validator';
import { Country } from '~app/models/country.model';

@Component({
  selector: 'app-emergency-contact-info',
  templateUrl: './emergency-contact-info.component.html',
  styleUrls: ['./emergency-contact-info.component.scss']
})

@ContentSchema({
  name: 'EmergencyContactInfo',
  props: [
    { name: 'sameContactInformationCheckboxContent', type: PropertyType.Text },
    { name: 'contactInformationTitle', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'heading', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
  ],
  components: [
    'contact-info-form'
  ]
})
export class EmergencyContactInfoComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  subscriptions = new Subscription();
  loading$: Observable<boolean>;
  countriesList: Country[] = [];

  areAllEmergencyContactInfoFormsValid = false;
  passengers: {
    id: string;
    passengerName: PassengerName;
    useFirstPaxInfo: boolean;
    contactInfoForm: FormGroup;
  }[];
  sameContactString: string;

  constructor(
    private titleCasePipe: TitleCasePipe,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private internationalDetailsDispatchers: InternationalDetailsDispatchers,
    private dynamicContentService: DynamicContentService,
    private referenceDataSelectors: ReferenceDataSelectors,
  ) {
    this.loading$ = this.internationalDetailsSelectors.internationalDetailsLoading$;
  }

  ngOnInit() {
    this.referenceDataSelectors.countries$.pipe(take(1)).subscribe((countries) => {
      this.countriesList = countries.countryDisplayName.map(displayName => {
        const countryCode = countries.countryList.find(country => country.country2LetterCode === countries.countryMap[displayName]);
        return {
          ...countryCode,
          countryNm: displayName
        };
      });
    });

    this.subscriptions.add(this.internationalDetailsSelectors.internationalPassengers$.subscribe(internationalPassengers => {
      this.passengers = internationalPassengers.map((pax, index) => {
        if (index === 0) {
          const paxName = this.titleCasePipe.transform(`${pax.passengerName.firstName} ${pax.passengerName.lastName}`);
          this.sameContactString = this.dynamicContentService.getContentString(this.rendering.props.sameContactInformationCheckboxContent, {
            defaultPaxName: paxName,
          });
        }
        return {
          ...pax,
          useFirstPaxInfo: false,
          contactInfoForm: new FormGroup({
            firstName: new FormControl('', [Validators.required, Validators.pattern(RegexPatterns.alphaValidation)]),
            lastName: new FormControl('', [Validators.required, Validators.pattern(RegexPatterns.alphaValidation)]),
            number: new FormControl('', [Validators.required, CustomValidators.characterCountError(RegexPatterns.phoneNumberValidation)]),
            country: new FormControl('', [Validators.required])
          })
        }
      });
    }));

    this.passengers.forEach(pax => {
      this.subscriptions.add(pax.contactInfoForm.statusChanges.subscribe(_ => {
        this.areAllEmergencyContactInfoFormsValid = this.passengers.every(pax =>
          pax.useFirstPaxInfo || pax.contactInfoForm.valid
        );
      }))
    })
  }

  submitEmergencyContacts() {
    const emergencyContacts = this.passengers.map((passenger) => {
      const contactInfo = passenger.useFirstPaxInfo ? this.passengers[0].contactInfoForm : passenger.contactInfoForm;
      const country: Country = this.countriesList?.find(country => country.country2LetterCode === (contactInfo.get('country').value)?.country2LetterCode);
      return {
        passengerId: passenger.id,
        emergencyContact: {
          name: `${contactInfo.get('firstName').value} ${contactInfo.get('lastName').value}`,
          phoneNumber: {
            number: contactInfo.get('number').value,
            countryCode: country?.country2LetterCode,
            countryPhoneExtension: country?.countryPhonePrefix.toString(),
          }
        }
      }
    });
    this.internationalDetailsDispatchers.postEmergencyContactInformation(emergencyContacts);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
