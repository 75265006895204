export enum RegexPatterns {
  addressValidation = '^[a-z A-Z 0-9-\.\, ]{0,70}$',
  alphaValidation = '[a-z A-Z]+',
  cityValidation = '^[a-z A-Z-\s]{0,35}$',
  stateCodeValidation = '^[a-z A-Z0-9-\s]{0,2}$',
  zipCodeValidation = '^[a-z A-Z0-9-\s]{0,20}$',
  phoneNumberValidation = '^[+]{0,1}[0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]{9,20}$',
  confirmationCodeValidationAmadeus = '^[a-zA-Z0-9]{6}$',
  eTicketValidation = '^[0-9]{3}-[0-9]{10}$|^[0-9]{13}$',
  hawaiianMilesValidation = '^[0-9]{9}$',
  ktnValidation1A = '^[A-Za-z0-9]{5,25}$',
  redressValidation = '[a-zA-Z0-9]{5,25}',
  duplicateCartId1A = '[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}',
  passportNumberValidation = '^[0-9]{8}$',
  countryCodeValidation = '^[A-Z]{3}$',
}
