import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
    imports: [
      AppSharedModule,
      MatCardModule,
      MatCheckboxModule,
      CoreModule,
      CoreModule.forChild({
        components: [
            DashboardComponent,
        ]
    }),
    ],
    exports: [
      // export Material Design components
      MatCardModule,
      MatCheckboxModule,
      RouterModule
    ],
    declarations: [
        DashboardComponent,
    ],
  })
  export class DashboardModule { }
