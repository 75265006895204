import { AEPBookingSuccessEvent, AEPCart, AEPDefaultData, AEPEDDLEvent, AEPFlightInfo, 
  AEPMemberInfoEvent, AEPPageLoadedEvent, AEPProduct, AEPSaveSeatsEvent, 
  AEPSelectedFlightInfo, AEPCartProducts, AEPFareInfo, AEPSeatInfo, AEPLegUpgrades
} from '~app/models/adobe-tagging.model';
import { CabinType, Constants } from '~app/constants/ha-constants';
import { CartItem, CartItemDescription } from '~app/models/cartitem.model';
import { CartSelectors, SessionSelectors, TripSelectors } from '~app/store';
import * as CheckInActions from '~app/store/actions';
import { combineLatestWith, filter, map, take } from 'rxjs/operators';
import { CurrentLocaleService } from '@hawaiianair/core';
import { environment } from '~environments/environment';
import { Flight } from '~app/models/flight.model';
import { HelperService } from './helper.service';
import { ICartState } from '~app/store/reducers/cart/cart.reducers';
import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Response } from '~app/models/response.model';
import { Router } from '@angular/router';
import { SeatAssignment } from '~app/models/seatassignment';
import { Segment } from '~app/models/segment.model';
import { TaggingDataService, TaggingEnvironment } from '@hawaiianair/tagging';
import { Trip } from '~app/models/trip.model';
import { FlightDetail } from '~app/models/flightdetail.model';


export enum AEPEventNames {
  BOOKING_FAILED = 'booking failed',
  ERROR_DISPLAYED = 'error displayed',
  MEMBER_INFO = 'member info',
  NO_ACTION = 'no action',
  PAGE_LOADED = 'page loaded',
  PAYMENT_FAILURE = 'payment failure',
  PAYMENT_INFORMATION = 'payment information',
  PAYMENT_SUCCESS = 'payment success',
  SAVE_SEATS = 'save seats',
  SEATMAP_LOADED = 'seatmap loaded',
}

@Injectable({
  providedIn: 'root'
})

export class CheckinTaggingDataService extends TaggingDataService {
  private tripData$: Observable<Response<Trip>>;
  constructor(
    private cartSelectors: CartSelectors,
    private helperService: HelperService,
    private localeService: CurrentLocaleService,
    private router: Router,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
  ) {
    super();
    this.tripData$ = this.tripSelectors.trip$;
  }
  
  public static mapActionToEvent(action: string): AEPEventNames {
    switch(action) {
      case CheckInActions.checkInPageLoaded.type:
      case CheckInActions.passengerPageLoaded.type:
      case CheckInActions.baggagePageLoaded.type:
      case CheckInActions.baggageAdvisoryItemsPageLoaded.type:
      case CheckInActions.infantPageLoaded.type:
      case CheckInActions.resultPageLoaded.type:
      case CheckInActions.dashboardPageLoaded.type:
      case CheckInActions.guidelinesPageLoaded.type:
      case CheckInActions.infantInfoPageLoaded.type:
      case CheckInActions.boardingPassPageLoaded.type:
      case CheckInActions.minorPageLoaded.type:
      case CheckInActions.paymentPageLoaded.type:
      case CheckInActions.seatPageLoaded.type:
      case CheckInActions.seatmapPageLoaded.type:
      case CheckInActions.segmentSelectorPageLoaded.type:
      case CheckInActions.groupPassengersPageLoaded.type:
      case CheckInActions.healthAttestationPageLoaded.type:
      case CheckInActions.internationalTravelerSelectionPageLoaded.type:
      case CheckInActions.internationalTravelerInformationPageLoaded.type:
        return AEPEventNames.PAGE_LOADED;
      case CheckInActions.getOrderStatusError.type:
        return AEPEventNames.BOOKING_FAILED;
      case CheckInActions.trackAEPOrderSuccess.type:
        return AEPEventNames.PAYMENT_SUCCESS;
      case CheckInActions.checkoutCartFromPaymentRouteFailure.type:
        return AEPEventNames.PAYMENT_FAILURE;
      case CheckInActions.paymentInformationSubmitted.type:
        return AEPEventNames.PAYMENT_INFORMATION;
      case CheckInActions.getByPnrSuccess.type:
        return AEPEventNames.MEMBER_INFO;
      default:
        return AEPEventNames.NO_ACTION;    
    }
  }

  public getPageName(): string {
    const namespace = 'ha';
    const locale = this.localeService.country;
    return `${namespace}:${locale}:checkin${this.router.url.split('?')[0].replace(new RegExp('/', 'g'), ':')}`;
  }

  public getDataForEvent(eventName: string): AEPEDDLEvent {
    let returnData: AEPEDDLEvent;
    switch (eventName) {
      case AEPEventNames.MEMBER_INFO:
      this.getDataForMemberInfo().pipe(take(1)).subscribe(
        event => returnData = event
      );  
      break;
      case AEPEventNames.PAGE_LOADED:
        this.getDataForPageLoad().pipe(take(1)).subscribe(
          event => returnData = event
        );
      break;
      case AEPEventNames.PAYMENT_SUCCESS:
        this.getDataForPaymentSuccess().pipe(take(1)).subscribe(
          event => returnData = event
        );        break;
      case AEPEventNames.SAVE_SEATS:
        this.getDataForSaveSeats().pipe(take(1)).subscribe(
          event => returnData = event
        );
        break;
      case AEPEventNames.BOOKING_FAILED:
      case AEPEventNames.ERROR_DISPLAYED:
      case AEPEventNames.NO_ACTION:
      case AEPEventNames.PAYMENT_FAILURE:
      case AEPEventNames.PAYMENT_INFORMATION:
      case AEPEventNames.SEATMAP_LOADED:
      default:
        returnData = {};
    }
    return merge(this.getDefaults(), returnData);
  }

  public getEnvironment(): TaggingEnvironment {
    return {
      taggingScript: environment.taggingScript
    }
  }

  private getDefaults(): AEPDefaultData {
    return {
      page: {
        name: this.getPageName(),
      },
    }
  }

  private getDataForMemberInfo(): Observable<AEPMemberInfoEvent> {
    return this.tripData$.pipe(
      filter((trip) => !!trip?.results?.length
        && !!trip.results[0]?.passengers?.entries?.length
      ),
      map(trip => {
        return {
          haMemberInfo: {
            memberStatus: trip.results[0].passengers.entries[0]?.customerLevel,
            milesID: trip.results[0].passengers.entries[0]?.hawaiianMilesNumber,
          }
        }
      })
    )
  }

  private getDataForPageLoad(): Observable<AEPPageLoadedEvent> {
    return this.tripData$.pipe(
      combineLatestWith(
        this.sessionSelectors.sessionSelectedSegment$,
        this.sessionSelectors.originallyAssignedSeats$,
      ),
      filter(([trip, segment, ogSeats]) => !!trip?.results?.length
        && !!trip.results[0]?.segments?.entries?.length
        && !!trip.results[0]?.flights?.entries?.length
        && !!segment
        && !!ogSeats
      ),
      map(([trip, segment, oldSeats]) => {
        return {
          flightInfo: this.formatFlightInfo(trip, segment, oldSeats),
          selectedFlightInfo: this.formatSelectedFlightInfo(segment, oldSeats),
        };
      })
    );
  }

  private getDataForPaymentSuccess(): Observable<AEPBookingSuccessEvent> {
    return this.tripData$.pipe(
      combineLatestWith(
        this.cartSelectors.cartState$,
        this.sessionSelectors.sessionSelectedSegment$,
        this.sessionSelectors.originallyAssignedSeats$,
      ),
      filter(([trip]) => !!trip?.results?.length
        && !!trip.results[0]?.segments?.entries?.length
        && !!trip.results[0]?.flights?.entries?.length
      ),
      map(([trip, cart, segment, oldSeats]) => {
        let products = this.formatProducts(cart, segment);
        return {
            fareInfo: this.formatFareInfo(cart.cartItems, segment),
            flightInfo: this.formatFlightInfo(trip, segment, oldSeats),
            haBookingConfirmation: {
              allAirports: this.formatAllAirports(trip.results[0].flights.entries),
              numberOfProducts: products.length,
              products,
              purchaseID: `${moment().format('YYYYMMDDHHmm')}_${trip?.results?.[0]?.confirmationCode}`,
              oldNewSeats: this.formatOldNewSeats(cart.cartItems, oldSeats),
              // Grand Total, Billing Info passed in payment page
            },
            selectedFlightInfo: this.formatSelectedFlightInfo(segment, oldSeats),
          };
        }
      )
    );
  }

  private getDataForSaveSeats(): Observable<AEPSaveSeatsEvent> {
    return this.cartSelectors.cartState$.pipe(
      combineLatestWith(
        this.sessionSelectors.originallyAssignedSeats$,
        this.sessionSelectors.sessionSelectedSegment$,
      ),
      filter(([cart]) => !cart.loading),
      map(([cart, oldSeats, segment]) => {
        return { 
          haCart: this.formatCart(cart, segment),
          seats: this.formatOldNewSeats(cart.cartItems, oldSeats),
          seatInfo: this.formatSeatInfo(cart.cartItems),
        }
      })
    ); 
  }

  private formatFlightInfo(trip: Response<Trip>, segment: Segment, originallyAssignedSeats: SeatAssignment[]): AEPFlightInfo {
    const segments = this.helperService.getSegmentEntries(trip);
    const flights = trip?.results?.[0]?.flights?.entries;
    const tripType = this.getTripTypeCode(segments) || 'N/A';
    const fareType = this.getFareType(segments);
    const destination = this.getDestination(segments);
    const origin = segments?.[0]?.origin;
    const cityPair = `${origin}-${destination}`;
    const departureSegment = segments?.[0];
    const departureFlight = flights?.[0];
    const departureDate = moment(departureSegment.departure.utcDateTime);
    const seatType = originallyAssignedSeats?.some(
      seat => originallyAssignedSeats[0]?.seatCategory !== seat?.seatCategory
    ) ? 'mixed' : this.getSeatTypeCode(originallyAssignedSeats[0]?.seatCategory);
    const allFlightDetails = this.getAllFlightDetails(segment);

    const daysToDeparture = departureDate.diff(moment(), 'days');
    const departFlightNumber = `HA-${departureFlight.flightNumber}`;
    const departFlightDate = departureDate.format('MM/DD/YYYY'); 
    const departFlightDayofWeek = departureDate.format('dddd');
    const departFlightTime = `${moment(departureSegment.departure.airportDateTimeString).format('HH:mm')}`;
    const totalPaxCount = trip?.results?.[0]?.passengers.entries.length;
    const totalMCBCount = allFlightDetails.reduce((total, flightDetail) =>
      total + +(flightDetail.bookingClass === Constants.bookingClassMCB), 0);
    const totalMCCount = allFlightDetails.reduce((total, flightDetail) =>
      total + +(flightDetail.bookingClass === CabinType.Economy), 0);
    const totalFirstCount = allFlightDetails.reduce((total, flightDetail) =>
      total + +(flightDetail.bookingClass === CabinType.First), 0);
    const totalSegments = segments.length;

    let durationOfTrip: number;
    let returnFlightNumber: string;
    let returnFlightTime: string;
    let returnFlightDayofWeek: string;
    let returnFlightDate: string;

    if (tripType !== 'OW') {  // is not OW
      const returnSegmentIndex = segments?.length - 1;
      const returnSegment = segments?.[returnSegmentIndex];
      const returnFlightIndex = flights?.length - returnSegment.details[0].flightDetails.length;
      const returnFlight = flights?.[returnFlightIndex];
      const returnDate = moment(returnSegment.departure.utcDateTime);
      
      durationOfTrip = (returnDate.diff(departureDate, 'days'));
      returnFlightNumber = `HA-${returnFlight.flightNumber}`;
      returnFlightTime = moment(returnFlight.scheduledArrival.airportDateTimeString).format('HH:mm');
      returnFlightDayofWeek = returnDate.format('dddd');
      returnFlightDate = returnDate.format('MM/DD/YYYY');
    }

    return { 
      arrivalCity: destination,
      departCity: origin,
      cityPair,
      daysToDeparture,
      departFlightNumber,
      departFlightDate, 
      departFlightDayofWeek,
      departFlightTime,
      fareType,
      seatType,
      totalSegments,
      totalPaxCount,
      totalMCBCount,
      totalMCCount,
      totalFirstCount,
      tripType,
      durationOfTrip,
      returnFlightNumber,
      returnFlightDate,
      returnFlightDayofWeek,
      returnFlightTime,
    } as AEPFlightInfo;
  }

  private formatFareInfo(cartItems: CartItem[], segment: Segment): AEPFareInfo {
    let numMCBtoPS = 0;
    let numMCBtoEC = 0;
    let numMCBtoFC = 0;
    let numMCtoPS = 0;
    let numMCtoEC = 0;
    let numMCtoFC = 0;
    let mcbToPSUpgradeRevenue = 0;
    let mcbToECUpgradeRevenue = 0;
    let mcbToFCUpgradeRevenue = 0;
    let mcToPSUpgradeRevenue = 0;
    let mcToECUpgradeRevenue = 0;
    let mcToFCUpgradeRevenue = 0;
    let mcbSeatUpgradesGrandTotal = 0;
    let mcSeatUpgradesGrandTotal = 0;

    const allFlightDetails = this.getAllFlightDetails(segment);

    cartItems.forEach(item => {
      const matchingLeg = allFlightDetails.find(flightDetail => 
        flightDetail.id.includes(item.associatedPassenger.passengerId + item.associatedPassenger.flightId)
      );
      if (!!item.seat && !!matchingLeg) {
        if (matchingLeg.bookingClass === Constants.bookingClassMCB) {
          mcbSeatUpgradesGrandTotal += item.priceDifference;
        } else if (matchingLeg.bookingClass === CabinType.Economy) {
          mcSeatUpgradesGrandTotal += item.priceDifference;
        }
        switch(item.description) {
          case CartItemDescription.PREFERRED_SEAT:
            if (matchingLeg.bookingClass === Constants.bookingClassMCB) {
              numMCBtoPS++;
              mcbToPSUpgradeRevenue += item.priceDifference;
            }
            else if (matchingLeg.bookingClass === CabinType.Economy) {
              numMCtoPS++;
              mcToPSUpgradeRevenue += item.priceDifference;
            }
            break;
          case CartItemDescription.EXTRA_COMFORT:
            if (matchingLeg.bookingClass === Constants.bookingClassMCB) {
              numMCBtoEC++;
              mcbToECUpgradeRevenue += item.priceDifference;
            }
            else if (matchingLeg.bookingClass === CabinType.Economy) {
              numMCtoEC++;
              mcToECUpgradeRevenue += item.priceDifference;
            }
            break;
          case CartItemDescription.FIRST_CLASS:
            if (matchingLeg.bookingClass === Constants.bookingClassMCB) {
              numMCBtoFC++;
              mcbToFCUpgradeRevenue += item.priceDifference;
            }
            else if (matchingLeg.bookingClass === CabinType.Economy) {
              numMCtoFC++;
              mcToFCUpgradeRevenue += item.priceDifference;
            }
            break;
        }
      }
    })
    return {
      numMCBtoPS,
      numMCBtoEC,
      numMCBtoFC,
      numMCtoPS,
      numMCtoEC,
      numMCtoFC,
      mcbToPSUpgradeRevenue,
      mcbToECUpgradeRevenue,
      mcbToFCUpgradeRevenue,
      mcToPSUpgradeRevenue,
      mcToECUpgradeRevenue,
      mcToFCUpgradeRevenue,
      mcbSeatUpgradesGrandTotal,
      mcSeatUpgradesGrandTotal,
    }
  }

  private formatSelectedFlightInfo(segment: Segment, originallyAssignedSeats: SeatAssignment[]): AEPSelectedFlightInfo {
    const tripType = this.getTripTypeCode([segment]) || 'N/A';
    const fareType = this.getFareType([segment]);
    const destination = this.getDestination([segment]);
    const origin = segment.origin;
    const cityPair = `${origin}-${destination}`;
    const flight = segment.details[0].flightDetails[0];
    const departureDate = moment(segment.departure.utcDateTime);
    const seatType = this.getSeatTypeCode(originallyAssignedSeats[0].seatCategory);
    const flightNumber = `HA-${parseInt(flight.flightId.split('-')[1])}`;
    const flightDate = departureDate.format('MM/DD/YYYY'); 
    const flightDayofWeek = departureDate.format('dddd');
    const flightTime = `${moment(segment.departure.airportDateTimeString).format('HH:mm')}`;
    const totalPaxCount = segment.details.length;

    return { 
        arrivalCity: destination,
        departCity: origin,
        cityPair,
        flightNumber,
        flightDate, 
        flightDayofWeek,
        flightTime,
        fareType,
        seatType,
        totalPaxCount,
        tripType,
    } as AEPSelectedFlightInfo;
  }

  private formatProducts(cart: ICartState, segment: Segment): AEPProduct[] {
    let product: AEPProduct[] = [];
    const reducedCart = this.reduceCartItems(cart.cartItems);
    const legsWithProducts = this.sortProductsByLeg(segment, cart.cartItems);
    legsWithProducts.forEach(leg =>
      product.push({
        name: leg.name,
        quantity: {
          mainCabinSeatUpgradeQuantity: leg.cart.numMCSeats,
          preferredSeatUpgradeQuantity: leg.cart.numPSSeats,
          extraComfortSeatUpgradeQuantity: leg.cart.numECSeats,
          firstClassSeatUpgradeQuantity: leg.cart.numFCSeats,
        },
        revenue: {
          mainCabinSeatUpgradeRevenue: leg.cart.mcUpgradeRevenue,
          preferredSeatUpgradeRevenue: leg.cart.psUpgradeRevenue,
          extraComfortSeatUpgradeRevenue: leg.cart.ecUpgradeRevenue,
          firstClassSeatUpgradeRevenue: leg.cart.fcUpgradeRevenue,
        }
      })
    );
    if (reducedCart.numBags) {
      product.push({
        name: `${segment.origin}-${this.getDestination([segment])}_BAGS`,
        quantity: {
          regularBagQuantity: reducedCart.numBags,
        },
        revenue: {
          regularBagRevenue: reducedCart.bagRevenue,
        }
      })
    }
    return product;
  }

  private sortProductsByLeg(segment: Segment, cartItem: CartItem[]): AEPLegUpgrades[] {
    const allFlightDetails = this.getAllFlightDetails(segment);
    const legsWithUpgrades: AEPLegUpgrades[] = [];

    // Flights are double counted since each pax has their own unique flight details
    // Get rid of double counted flights w/ 2+ pax
    const uniqueFlightDetails = allFlightDetails.filter((detail, index) => 
      allFlightDetails.map(detail => 
        detail.flightId
        ).indexOf(detail.flightId) === index
    );
    
    uniqueFlightDetails.forEach(flightDetail => {
      const legsItem = cartItem.filter((item) =>
        flightDetail.id.includes(item.associatedPassenger.flightId)
      );
      if (legsItem?.length) {
        legsWithUpgrades.push({
          name: `${flightDetail.flightId.split('-')[3]}-${flightDetail.flightId.split('-')[4]}_UPGRADE`,
          cart: this.reduceCartItems(legsItem),
        });
      }
    });
    return legsWithUpgrades;
  }

  private formatAllAirports(flights: Flight[]): string {
    const allAirportCodes = flights?.map(flight => `${flight.origin}-${flight.scheduledDestination}`);
    return allAirportCodes.join('|');
  }

  private formatCart(cart: ICartState, segment: Segment): AEPCart {
    return {
      products: this.formatProducts(cart, segment),
      baseFare: cart.grandTotal,
      grandTotal: cart.grandTotal,
    };
  }

  // Formats a party's seat change per pax if they changed seats:
  // {old pax#1}-{new pax#1}|{old pax#2}-{new pax#2}... 
  private formatOldNewSeats(cartItems: CartItem[], originalSeats: SeatAssignment[]): string {
    let seatArray = originalSeats.map(originalSeat => {
      const newSeat = cartItems.find((item) => 
        item.associatedPassenger.passengerId === originalSeat.paxId
        && item.associatedPassenger.flightId === originalSeat.flightId
        && !!item.seat
      );
      return `${originalSeat.seatId}-${!!newSeat ? newSeat.seat : originalSeat.seatId}`;
    });
    return seatArray.join('|');
  }

  private formatSeatInfo(cartItems: CartItem[]): AEPSeatInfo {
    let reducedCart = this.reduceCartItems(cartItems);
    return {
      mcSeatsAdded: reducedCart.numMCSeats,
      psSeatsAdded: reducedCart.numPSSeats,
      ecSeatsAdded: reducedCart.numECSeats,
      fcSeatsAdded: reducedCart.numFCSeats,
      mcUpgradeRevenue: reducedCart.mcUpgradeRevenue,
      psUpgradeRevenue: reducedCart.psUpgradeRevenue,
      ecUpgradeRevenue: reducedCart.ecUpgradeRevenue,
      fcUpgradeRevenue: reducedCart.fcUpgradeRevenue,
      wasMcSeatsAdded: !!reducedCart.numMCSeats,
      wasPsSeatsAdded: !!reducedCart.numPSSeats,
      wasEcSeatsAdded: !!reducedCart.numECSeats,
      wasFcSeatsAdded: !!reducedCart.numFCSeats,
    }
  }

  private reduceCartItems(cartItems: CartItem[]): AEPCartProducts {
    let mcQuantity = 0;
    let mcTotalCost = 0;
    let psQuantity = 0;
    let psTotalCost = 0;
    let ecQuantity = 0;
    let ecTotalCost = 0;
    let fcQuantity = 0;
    let fcTotalCost = 0;
    let bagQuantity = 0;
    let bagTotalCost = 0;

    cartItems?.forEach(item => {
      switch(item.description) {
        case CartItemDescription.MAIN_CABIN_SEAT:
          mcQuantity += item.quantity;
          mcTotalCost += item.priceDifference;
          break;
        case CartItemDescription.PREFERRED_SEAT:
          psQuantity += item.quantity;
          psTotalCost += item.priceDifference;
          break;
        case CartItemDescription.EXTRA_COMFORT:
          ecQuantity += item.quantity;
          ecTotalCost += item.priceDifference;
          break;
        case CartItemDescription.FIRST_CLASS:
          fcQuantity += item.quantity;
          fcTotalCost += item.priceDifference;
          break;
        case CartItemDescription.REGULAR_BAG:
          bagQuantity += item.quantity;
          bagTotalCost += item.price;
          break;
        default:
          break;
      }
    });

    return {
      numMCSeats: mcQuantity,
      numPSSeats: psQuantity,
      numECSeats: ecQuantity,
      numFCSeats: fcQuantity,
      numBags: bagQuantity,
      mcUpgradeRevenue: mcTotalCost,
      psUpgradeRevenue: psTotalCost,
      ecUpgradeRevenue: ecTotalCost,
      fcUpgradeRevenue: fcTotalCost,
      bagRevenue: bagTotalCost,
    }
  }

  private getFareType(segments: Segment[]): string {
    /*  There are 3 distinct types: MCB, coach, and first.
        'MCB'   - Main Cabin Basic
        'coach' - Main Cabin, Preferred Seats, Extra Comfort Seats
        'first' - First Class/Business Class
        'mixed' - A combination of the above 
    */
    // Check if all MCB
    const allMCB = segments?.every(
      segment => segment?.details?.every(
        segmentDetail => segmentDetail?.flightDetails?.every(
          flightDetail => flightDetail?.bookingClass === Constants.bookingClassMCB
        )
      )
    );
    if (allMCB) { return 'MCB'; }

    // At least 1 MCB
    const hasMCB = segments?.some(
      segment => segment?.details?.some(
        segmentDetail => segmentDetail?.flightDetails?.some(
          flightDetail => flightDetail?.bookingClass === Constants.bookingClassMCB
        )
      )
    );
    const allCoach = segments?.every(
      segment => segment?.details?.every(
        segmentDetail => segmentDetail?.flightDetails?.every(
          flightDetail => flightDetail?.cabinType === CabinType.Economy
        )
      )
    );
    if (allCoach && !hasMCB) { return 'coach'; }

    const allFirst = segments?.every(
      segment => segment?.details?.every(
        segmentDetail => segmentDetail?.flightDetails?.every(
          flightDetail => flightDetail?.cabinType === CabinType.First
        )
      )
    );
    if (allFirst) { return 'first'; }
    
    return 'mixed';
  }

  private getTripTypeCode(segments: Segment[]): string {
    // Default to OW if segments doesn't exist
    if (segments?.length === 1 || !segments?.length) {
      return 'OW'; // OW
    }
    else if (segments?.length === 2 &&
      segments[0]?.origin === segments[1]?.destination &&
      segments[1]?.origin === segments[0]?.destination
      ) {
      return 'RT'; // Roundtrip
    }
    return 'MC'; // Multicity
  }

  private getDestination(segments: Segment[]): string {
    if (this.getTripTypeCode(segments) === 'RT') {
      return segments?.[1]?.origin;
    }  
    return segments?.[segments?.length - 1]?.destination;
  }

  private getSeatTypeCode(seatCategory: string): string {
    switch (seatCategory) {
      case Constants.seatClassTypeFirstClass:
      case 'First Class':
        return 'fc';
      case Constants.seatClassTypePreffered:
        return 'ps';
      case Constants.seatClassTypeBusiness:
        return 'bc';
      case Constants.seatClassTypeExtraComfort:
      case 'Extra Comfort':
        return 'ec';
      default:
        return 'mc';
    }
  }

  private getAllFlightDetails(segment: Segment): FlightDetail[] {
    return segment.details.flatMap(detail => detail.flightDetails);
  }
}
