import { Injectable } from "@angular/core";
import { PaymentInformation } from "@hawaiianair/payment/lib/models/amadeus-payment.model";
import { Store } from '@ngrx/store';
import * as OrderFulfillActions from '~app/store/actions/orderFulfill.actions';
import { EntityState } from '~app/store/reducers';
@Injectable()
export class OrderFulfillDispatchers {
  constructor(private store: Store<EntityState>) { }
  orderFulfill(paymentInformation: PaymentInformation) {
    this.store.dispatch(OrderFulfillActions.orderFulfill({ paymentInformation }));
  }
}
