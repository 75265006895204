<div class="container h-100">
  <div class="row h-100 py-3">
    <div class="col-12 col-md-6 p-0 overflow-hidden img-column">
      <div class="img-container">
        <img class="h-md-100" [src]="item?.img" alt="">
        <div class="img-text w-100">
          <div class="row ">
            <div class="col-12">
              <h4 id="{{item?.type}}-title" class="white">{{item?.imgTitle}}</h4>
            </div>
          </div>
          <div *ngIf="item?.price" class="row">
            <div class="col-12">
              <span id="{{item?.type}}-price" class="body-copy-bold medium white" *haText="seatCostString"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="description-container col-12 col-md-6 text-left background-gray pb-4">
      <div class="row">
        <div class="col p-3 pt-md-0 pr-md-0 pl-md-4">
          <span id="{{item?.type}}-description" class="body-copy">{{item?.description}}</span>
        </div>
      </div>
      <div class="row seats-link seats-link-md d-none d-md-flex">
        <div class="col-6 col-md-12 mt-4 pl-3 pl-md-4">
          <span id="{{item?.type}}-seats-link" (click)="routeToSeatmap()" (keydown.enter)="routeToSeatmap()" role="link" tabindex="0"
            class="body-copy link" *haText="rendering?.props['seeAvailableSeats']"></span>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex d-md-none text-left background-gray pb-4">
      <div class="row seats-link seats-link-md">
        <div class="col-12 mt-4 pl-3 pl-md-4">
          <span id="{{item?.type}}-seats-link" (click)="routeToSeatmap()" (keydown.enter)="routeToSeatmap()" role="link" tabindex="0"
            class="body-copy link" *haText="rendering?.props['seeAvailableSeats']"></span>
        </div>
      </div>
    </div>
  </div>
</div>