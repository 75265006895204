<div class="container text-left">
  <form [formGroup]="destinationInfoForm" class="row destination-info-form">
    <mat-form-field class="col-md-4">
      <mat-select formControlName="location" id="select-location" class="mat-select-text"
                  [placeholder]="rendering.props?.select" required>
        <mat-option *ngFor="let location of rendering.props?.firstDayLocations" id="select-location-{{location.id}}"
                    class="mat-select-text" [value]="location">
          {{ location.title }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="destinationInfoForm.get('location').errors?.required">{{'required' | translate}}</mat-error>
    </mat-form-field>
    <div *ngIf="destinationInfoForm?.value?.location" formGroupName="destinationForm"
         class="full-width">
      <div class="call-out col-12 mb-3 bold medium">
        {{ destinationInfoForm.value.location.address }}
      </div>
      <div class="col">
        <div class="row">
          <div *ngFor="let field of destinationInfoForm.value.location.fields" class="col-md-4 body-copy">
            <ng-container [ngSwitch]="field.type">
              <app-ha-input *ngSwitchCase="'input'" [inputId]="field.id" [formControlName]="field.id"
                            [control]="destinationInfoForm.get('destinationForm').get(field.id)"
                            [label]="field.label"
                            [placeholder]="field.label">
              </app-ha-input>
              <mat-form-field *ngSwitchCase="'datalist'" class="full-width">
                <mat-select [formControl]="destinationInfoForm.get('destinationForm').get(field.id)" [id]="field.id"
                            class="mat-select-text"
                            [placeholder]="field.label" required>
                  <mat-option *ngFor="let countries of countriesList; index as index" id="select-country-{{index}}"
                              class="mat-select-text" [value]="countryMap?.[countries]">
                    {{ countries }}
                  </mat-option>
                </mat-select>
                <mat-error>{{ (destinationInfoForm.get('destinationForm').get(field.id).errors?.required ? 'required': 'noMatch') | translate}}</mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
