<h6 class="bold medium" mat-dialog-title>
  <span *haText="rendering.props['header']"></span>
</h6>
<mat-dialog-content class="body-copy" align="left">
  <span *haRichText="rendering.props['notice']"></span>
  <ul>
    <li *ngFor="let item of rendering.props['criteria']" ><span *haRichText="item?.text"></span></li>
  </ul>
  <span class="body-copy-bold" *haText="rendering.props['acceptance']"></span>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="seatmap-exit-row-modal-decline" mat-button mat-dialog-close class="btn btn-secondary">
    <span *haText="rendering.props['btnDecline']"></span>
  </button>
  <button id="seatmap-exit-row-modal-accept" mat-button [mat-dialog-close]="true" class="btn btn-default">
    <span *haText="rendering.props['btnAccept']"></span>
  </button>
</mat-dialog-actions>
