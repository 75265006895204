import { Injectable } from "@angular/core";
import { Subscription, Observable } from 'rxjs';
import { SessionData } from '~app/models/sessiondata.model';
import { SessionSelectors } from '~app/store/services/session/session.selectors';

@Injectable({ providedIn: 'root' })
export class SessionDataSource {
  subscriptions: Subscription;
  sessionData$: Observable<SessionData>;

  // data
  sessionData: SessionData;
  constructor(
    private sessionSelectors: SessionSelectors,
    ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.sessionData$.subscribe(session => this.sessionData = session));
  }

  hasAlreadyCheckedIn(): boolean {
    try {
      return this.sessionData.hasAlreadyCheckedIn;
    } catch (e) {
      console.error('Logger >> ', e);
      return false;
    }
  }

  channel(): string {
    return 'WEB';
  }

  origin(): string {
    try {
      return this.sessionData.origin;
    } catch (e) {
      return null;
    }
  }

  destination(): string {
    try {
      return this.sessionData.destination;
    } catch (e) {
      return null;
    }
  }

  selectedPax(): string[] {
    try {
      return this.sessionData.passengerIds;
    } catch (e) {
      return null;
    }
  }

  numberOfSelectedPax(): number {
    try {
      return this.sessionData.passengerIds.length;
    } catch (e) {
      return null;
    }
  }

  correlationId(): string {
    try {
      return this.sessionData.correlationId;
    } catch (e) {
      return null;
    }
  }
}
