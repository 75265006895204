<div id="seatmap-mobile-header" class="seatmap-mobile-header d-block d-md-none">
  <div id="seatmap-header-flight" class="container background-white">
    <div class="row align-items-center">
      <div class="col text-left body-copy aloha-pink">
        <span id="seatmap-header-cancel" (click)="secondaryNavButtonClick()" (keyup)="secondaryNavButtonClick()">
          {{ navButtons?.secondary?.text }}
        </span>
      </div>
      <div id="seatmap-header-flight-info" class="col-auto">
        <div id="seatmap-header-flight-locations" class="body-copy-bold medium">
          {{origin}} <i class="ha-icon icon-long-arrow-right align-middle px-2"></i> {{destination}}
        </div>
        <div id="seatmap-header-flight-number" class="foot-note tertiary-text">
          <span *ngIf="!isHAFlight; else operatingAirlineHeader">{{ segmentFlight?.marketedBy }} {{ segmentFlight?.marketingFlightNumber ?? segmentFlight?.flightNumber }}</span>
          <ng-template #operatingAirlineHeader>{{ segmentFlight?.operatedBy }} {{ segmentFlight?.operatingFlightNumber }}</ng-template>
        </div>
      </div>
      <div class="col text-right body-copy-bold medium aloha-pink">
        <span id="seatmap-header-save" (click)="defaultNavButtonClick()" (keyup)="defaultNavButtonClick()">
          {{ navButtons?.default?.text }}
        </span>
      </div>
    </div>
  </div>
  <app-seatmap-nav id="seatmap-mobile-flight-nav" *ngIf="navButtons?.flights" [navButtons]="navButtons" [browserID]="'mobile'"></app-seatmap-nav>
  <div class="mat-chip-list-background">
    <mat-chip-list [selectable]="isHAFlight" aria-orientation="horizontal" aria-label="passenger-chip selection">
      <mat-chip id="seatmap-mobile-passenger-{{paxIndex}}" *ngFor="let pax of seatAssignments; index as paxIndex" class="pax-chip"
        (click)="selectPaxChip(pax)" [ngClass]="{'selected': pax?.paxId === selectedPaxId}">
        <div class="body-copy-bold medium">
          {{ pax?.paxName?.firstName.length > 5 ? pax?.paxName?.firstName.substring(0, 5) + '.' :
          pax?.paxName?.firstName}}
          {{ pax?.paxName?.lastName.substring(0, 1) + '.' | uppercase }}
          {{ !!pax?.seatId && !!isHAFlight ? pax.seatId : '--' }}
        </div>
      </mat-chip>
    </mat-chip-list>
  </div>
</div>