import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { EntityState } from '~app/store';
import { Injectable } from '@angular/core';
import { BagOffersState } from '~app/store/reducers/offers/offers.reducers';


const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getBagOffersState = createSelector(
  getEntityState,
  (state: EntityState) => state?.offers
);

export const getBagOffers = createSelector(
  getBagOffersState,
  (state: BagOffersState) => state?.bagOffers
);

export const getBagOffersLoading = createSelector(
  getBagOffersState,
  (state: BagOffersState) => state?.loading
);


@Injectable()
export class OffersSelectors {
  constructor(private store: Store<EntityState>) {}
  bagOffersState$ = this.store.select(getBagOffersState);
  bagOffers$ = this.store.select(getBagOffers);
  bagOffersLoading$ = this.store.select(getBagOffersLoading);
}