export const deepEncode = (target: any, restrictedProperties: string[], key?: string | number): any => {
  if (target === null || target === undefined) {
    return target;
  }

  const isRestrictedValue = restrictedProperties.some((restrictedWord) => {
    const lowerCasedRestrictedWord = restrictedWord.toLowerCase();
    if (
      key &&
      key
        .toString()
        .toLowerCase()
        .includes(lowerCasedRestrictedWord)
    ) {
      return true;
    }
  });

  if (isRestrictedValue || key === 'id') {
    return '***********';
  }

  if (target.constructor === Array) {
    const array = target as Array<any>;

    return array.map((value, index) => deepEncode(value, restrictedProperties, index));
  }

  if (typeof target === 'object' && target !== null) {
    const keys = Object.keys(target);
    const copy = {};

    for (const currentKey of keys) {
      copy[currentKey] = deepEncode(target[currentKey], restrictedProperties, currentKey);
    }

    return copy;
  }

  return target;
};
