<mat-accordion>
  <mat-expansion-panel [expanded]="true" id="bags-total-panel" class="bags-total-expansion-panel">
    <mat-expansion-panel-header class="p-0 pr-4 pr-md-0" collapsedHeight="80px" expandedHeight="56px">
      <div class="mat-content container bags-total">
        <span class="text-left" id="bags-total-txt">{{'totalCharges' | translate}}</span>
        <span class="text-right ml-auto" id="bags-total-cost">{{grandTotal | customCurrency }}</span>
          <div *ngIf="false" class="container">
            <div id="checkin-cart-spinner" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
      </div>
    </mat-expansion-panel-header>
    <app-payment-cart></app-payment-cart>
  </mat-expansion-panel>
</mat-accordion>
