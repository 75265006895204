import { createAction, props } from '@ngrx/store';
import { Airport } from '~app/models/airport.model';
import { Response } from '~app/models/response.model';

export const getAirports = createAction(
  'Get Airports'
);

export const getAirportsSuccess = createAction(
  'Get Airports Success',
  props<{ airports: Response<Airport>, airportsList: string[]}>()
);

export const getAirportsError = createAction(
  'Get Airports Error', props<{ error: any }>()
);


