import { MatButtonModule } from '@angular/material/button';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { HealthAttestationComponent } from './health-attestation.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatButtonModule,
        CoreModule
    ],
    exports: [
        // export Material Design components
        MatButtonModule,
        RouterModule
    ],
    declarations: [
        HealthAttestationComponent
    ],
})
export class HealthAttestationModule { }
