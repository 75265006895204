import { Component, Inject, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface SeatConfirmDlgData {
  rendering: ComponentRendering;
  oldSeatInfo: string;
  newSeatInfo: string;
  newSeatClass: string;
  costInfo: number;
}

@Component({
  selector: 'app-seat-change-confirmation',
  templateUrl: './seat-change-confirmation.html',
  styleUrls: [ './seat-change-confirmation.scss' ],
})
@ContentSchema({
  name: 'SeatChangeConfirmation',
  description: 'Seat Change Confirmation Modal',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'oldSeat', type: PropertyType.Text },
    { name: 'newSeat', type: PropertyType.Text },
    { name: 'cost', type: PropertyType.Text },
    { name: 'btnUndo', type: PropertyType.Text },
    { name: 'btnAccept', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
  ]
})
export class SeatChangeConfirmationComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<SeatChangeConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SeatConfirmDlgData
  ) {
  }

  ngOnInit() {
    this.rendering = this.data.rendering;
  }
}
