import { Component, Input, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable } from 'rxjs';
import { TripSelectors } from '~app/store';

@Component({
    selector: 'app-seat-upgrade',
    templateUrl: './seat-upgrade.component.html',
    styleUrls: ['./seat-upgrade.component.scss']
})
@ContentSchema({
    description: 'SeatUpgradeComponent',
    name: 'SeatUpgrade',
    props: [
        { name: 'heading', type: PropertyType.Text },
        { name: 'description', type: PropertyType.Text },
        { name: 'list', type: PropertyType.Object },
        { name: 'navButtons', type: PropertyType.Object }
    ]
})
export class SeatUpgradeComponent implements OnInit {
    @Input() rendering: ComponentRendering;
    seatUpgradeAppBannerRendering: ComponentRendering;
    tripLoading$: Observable<boolean>;

    constructor(
      private tripSelectors: TripSelectors
    ) {
      this.tripLoading$ = this.tripSelectors.tripLoading$;
    }

    ngOnInit() {
        this.seatUpgradeAppBannerRendering = (this.rendering.components['SeatUpgradeAppBanner'] || [{}])[0];
    }
}

