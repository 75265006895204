import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { SessionSelectors, CartSelectors, CartDispatchers, SessionDispatchers, TripSelectors, AnalyticsDispatchers, OffersDispatchers, OffersSelectors } from '~app/store';
import { Observable, Subscription } from 'rxjs';
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { ICartState } from '~app/store/reducers/cart/cart.reducers';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { BagOffersState } from '~app/store/reducers/offers/offers.reducers';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

@ContentSchema({
  description: 'DashboardComponent',
  name: 'Dashboard',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'standbyUpgradeText', type: PropertyType.Text },
    { name: 'specialBagsText', type: PropertyType.Text},
    { name: 'militaryText', type: PropertyType.Text },
    { name: 'actions', type: PropertyType.Object },
    { name: 'navButtons', type: PropertyType.Object }
  ]
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  actions: string[] = [];
  cartStatus = { valid: false, loading: false };
  offersStatus = { valid: false, loading: false };
  session: ISessionState;
  isPaxIdsSelected = false;
  isActionChecked: boolean[] = [];
  dashboardCartActions = ['Seats', 'Bags'];
  isStandbyUpgradeable = false;

  subs = new Subscription();
  cartState$: Observable<ICartState>;
  bagOffersState$: Observable<BagOffersState>;
  tripLoading$: Observable<boolean>;
  session$: Observable<ISessionState>;
  sessionPassengerIds$: Observable<string[]>;

  constructor(
    private sessionSelectors: SessionSelectors,
    private cartSelectors: CartSelectors,
    private modalContentsService: ModalContentsService,
    private cartDispatchers: CartDispatchers,
    private sessionDispatchers: SessionDispatchers,
    private tripSelectors: TripSelectors,
    private dynamicContentService: DynamicContentService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private offersDispatchers: OffersDispatchers,
    private offersSelectors: OffersSelectors
  ) {
    this.cartState$ = this.cartSelectors.cartState$;
    this.bagOffersState$ = this.offersSelectors.bagOffersState$;
    this.tripLoading$ = this.tripSelectors.tripLoading$;
    this.session$ = this.sessionSelectors.session$;
    this.sessionPassengerIds$ = this.sessionSelectors.sessionPassengerIds$;
  }

  ngOnInit() {
    this.subs.add(this.cartState$.subscribe(state => this.cartStatus = { valid: !!state.cart, loading: state.loading }));
    this.subs.add(this.bagOffersState$.subscribe(state => this.offersStatus = { valid: !!state.bagOffers, loading: state.loading }));
    this.subs.add(this.session$.subscribe(session => {
      if (!!session) {
        this.session = session;
        this.isPaxIdsSelected = !!this.session.passengerIds.length;
        this.isStandbyUpgradeable = !!session.eliteStandByEligiblePassengers && !!session.eliteStandByEligiblePassengers.length;
      }
    }));
    if (!!this.session && !!this.session.selectedSegment) {
      this.sessionDispatchers.setSessionPassengerIds('passengerIds',
      this.session.selectedSegment.details.map(detail => detail.passengerId));
    }
    this.isActionChecked = Array(this.rendering.props['actions'].length).fill(false);

    const contentItems = this.rendering.components['cart-item-content'];
    if (!!contentItems) {
      const items = contentItems[0].props["cartItems"].map(contentItem => {
        return { code: contentItem.code, title: contentItem.title, icon: contentItem.icon, contentExists: true };
      });
      this.sessionDispatchers.addCartContent(items);
    }

    this.modalContentsService.addRendering('concurrency-modal', this.rendering.components['concurrency-modal']);
    this.rendering.props['actions'].find(action => action.action === 'Bags').description = this.dynamicContentService.getContentString(
      this.rendering.props['actions'].find(action => action.action === 'Bags').description,
      { bagCount: this.getTotalBagCount() }
    );
    this.rendering.props['actions'].find(action => action.action === 'Bags').title = this.dynamicContentService.getContentString(
      this.rendering.props['actions'].find(action => action.action === 'Bags').title,
      // TODO: Add back when functionality available
      // { specialBagsText: this.rendering.props['specialBagsText']  }
      { specialBagsText: ' ' }
    );
    this.rendering.props['actions'].find(action => action.action === 'Seats').description = this.dynamicContentService.getContentString(
      this.rendering.props['actions'].find(action => action.action === 'Seats').description,
      // TODO: Add back when functionality available
      // { standbyUpgradeText: this.isStandbyUpgradeable ? this.rendering.props['standbyUpgradeText'] : ' ' }
      { standbyUpgradeText: ' ' }
    );
    this.rendering.props['actions'].find(action => action.action === 'PassengerInformation').description = this.dynamicContentService.getContentString(
      this.rendering.props['actions'].find(action => action.action === 'PassengerInformation').description,
      // TODO: Add back when functionality available
      // { militaryText: this.rendering.props['militaryText'] }
      { militaryText: ' ' }
    );
    this.analyticsDispatchers.dashboardPageLoaded();
  }

  selectDashboardAction(selectedAction: string, index: number) {
    this.isActionChecked[index] = !this.isActionChecked[index];
    if (!!this.isActionChecked[index]) {
      this.actions = [...this.actions, selectedAction];
      this.callDispatchers(selectedAction);
    } else {
      this.actions = this.actions.filter(action => action !== selectedAction);
    }
  }

  callDispatchers(action: string) {
    if (action === this.dashboardCartActions[1] && !this.offersStatus.valid && !this.offersStatus.loading) {
      this.offersDispatchers.getBagOffers();
    }

    if (this.dashboardCartActions.includes(action) && !this.cartStatus.valid && !this.cartStatus.loading) {
      this.cartDispatchers.createCartPassengers(
        this.session.trip2Checkin.confirmationCode,
        this.session.selectedSegment.id,
        this.session.passengerIds.join(),
        CHECKIN_ROUTES.ROUTE_DASHBOARD.route
      );
    }
  }

  getTotalBagCount(): string {
    const segmentWithBags = this.session && this.session.selectedSegment &&
      this.session.selectedSegment.details.filter(detail => !!detail.bags);
    const bagPerPax = segmentWithBags && segmentWithBags.flatMap(det =>
      det.bags.filter(bag => bag.count).reduce((sum, bag) => sum + bag.count, 0));
    return !!bagPerPax ? bagPerPax.reduce((sum, bag) => sum + Number(bag), 0).toString() : '0';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
