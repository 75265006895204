import { AppSharedModule } from '~app/shared/app.shared.module';
import { NgModule } from '@angular/core';
import { CoreModule } from '@hawaiianair/core';
import { PaymentComponent } from './payment.component';
import { HaPaymentModule } from '@hawaiianair/payment';
import { MatCardModule } from '@angular/material/card';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatCardModule,
        CoreModule,
        HaPaymentModule,
        CoreModule.forChild({
          components: [
              PaymentComponent,
          ]
      }),
    ],
    exports: [
        // export Material Design components
        MatCardModule
    ],
    declarations: [PaymentComponent]
})
export class PaymentModule { }
