import { Action, createReducer, on } from '@ngrx/store';
import { SecurityInfo } from '~app/models/securityinfo.model';
import { TravelDocument } from '~app/models/traveldocument.model';
import { ContactInfo } from '~app/models/contactinfo.model';
import { PassengerInfo } from '~app/models/passengerinfo.model';
import { PassengerItinerary } from '~app/models/passengeritinerary.model';
import { Validation } from '~app/models/securityvalidation.model';
import { AddressInfo } from '~app/models/addressinfo.model';
import * as SecurityInfoActions from '~app/store/actions/security-info.actions';
import { PassengerSecurityInfo } from '~app/models/passengersecurityinfo.model';
import { Constants } from '~app/constants/ha-constants';
import { RequiredInfo } from "~app/models/requiredinfo.model";

export interface ISecurityInfoState {
  loading: boolean;
  securityInfo: SecurityInfo[];
  passengers: PassengerSecurityInfo[];
  travelDocuments: TravelDocument[];
  addressInfo: AddressInfo[];
  contactInfo: ContactInfo;
  passengerInfo: PassengerInfo;
  itinerary: PassengerItinerary;
  defaultPassenger: SecurityInfo;
  validations: Validation[];
  hasAllTravelDocs: boolean;
  requiredInfoSummary: RequiredInfo[];
}

export const initialSecurityInfoState: ISecurityInfoState = {
  loading: false,
  securityInfo: null,
  passengers: null,
  travelDocuments: null,
  addressInfo: [],
  contactInfo: null,
  passengerInfo: null,
  itinerary: null,
  defaultPassenger: null,
  validations: null,
  hasAllTravelDocs: false,
  requiredInfoSummary: []
};

export const securityInfoReducer = createReducer(
  initialSecurityInfoState,

  // Passengers Page
  on(SecurityInfoActions.passengersGetTripPassengers, state => ({ ...state, loading: true })),
  on(SecurityInfoActions.passengersGetTripPassengersSuccess, (state, { passengerInfo }) => ({
    ...state,
    loading: false,
    securityInfo: securityInfoPassengerItinerary(passengerInfo),
    passengers: passengerInfo
  })
  ),
  on(SecurityInfoActions.passengersGetTripPassengersError, state => ({ ...state, loading: false })),

  // International Traveler Selection
  on(SecurityInfoActions.travelerSelectionAddInfo, (state, { travelerId }) => ({
    ...state,
    ...getPassengerInfo(state, travelerId),
    travelDocuments: null,
    addressInfo: null,
    contactInfo: null,
  })),

  // International Traveler Information
  on(SecurityInfoActions.addAddressInfoSuccess, (state, { addressInfo }) => ({
    ...state,
    addressInfo,
    securityInfo: updateSecurityInfo('addressInfo', addressInfo, state)
  })),
  on(SecurityInfoActions.addEmergencyContactInfo, (state, { contactInfo }) => ({
    ...state,
    securityInfo: updateSecurityInfo('contactInfo', contactInfo, state),
    contactInfo: contactInfo,
  })),
  on(SecurityInfoActions.travelerInfoPostSecurity, state => ({
    ...state,
    loading: true,
    validations: [{
      validationType: Constants.validationTypeTimatic,
      countryCode: state.travelDocuments[0].nationality,
    }],
    securityInfo: updateSecurityInfo('validations', [{
      validationType: Constants.validationTypeTimatic,
      countryCode: state.travelDocuments[0].nationality,
    }], state),
  })),
  on(SecurityInfoActions.travelerInfoPostSecurityError, state => ({ ...state, loading: false })),
  on(SecurityInfoActions.travelerInfoGetPassenger, state => ({ ...state, loading: true })),
  on(SecurityInfoActions.travelerInfoGetPassengerSuccess, (state, { passengerInfo }) => ({
    ...state,
    loading: false,
    passengers: passengerInfo,
    defaultPassenger: (!state.defaultPassenger)
      ? getDefaultPassenger(passengerInfo, state.securityInfo) : state.defaultPassenger,
    hasAllTravelDocs: state.securityInfo.every(securityInfo => !!securityInfo.travelDocuments)
  })),
  on(SecurityInfoActions.travelerInfoGetPassengerError, state => ({ ...state, loading: false })),

  // Guidelines Page
  on(SecurityInfoActions.guidelinesPostSecurityInfo, state => ({
    ...state,
    loading: true,
    securityInfo: updateSecurityInfoDocV(state)
  })),
  on(SecurityInfoActions.guidelinesPostSecurityInfoError, state => ({ ...state, loading: false })),
  on(SecurityInfoActions.guidelinesGetTripPassengers, state => ({ ...state, loading: true })),
  on(SecurityInfoActions.guidelinesGetTripPassengersError, state => ({ ...state, loading: false })),
  on(SecurityInfoActions.guidelinesGetTripPassengersSuccess, (state, { passengerInfo }) => ({
    ...state,
    loading: false,
    passengers: passengerInfo,
  })),

  // Dashboard Page - Recheckin DOCV
  on(SecurityInfoActions.dashboardPostSecurityInfo, state => ({
    ...state,
    loading: true,
    securityInfo: updateSecurityInfoDocV(state)
  })),
  on(SecurityInfoActions.dashboardPostSecurityInfoError, state => ({ ...state, loading: false })),
  on(SecurityInfoActions.dashboardGetTripPassengers, state => ({ ...state, loading: true })),
  on(SecurityInfoActions.dashboardGetTripPassengersError, state => ({ ...state, loading: false })),
  on(SecurityInfoActions.dashboardGetTripPassengersSuccess, (state, { passengerInfo }) => ({
    ...state,
    loading: false,
    passengers: passengerInfo,
  })),
);

export function reducer(state: ISecurityInfoState | undefined, action: Action) {
  return securityInfoReducer(state, action);
}

function updateSecurityInfo(propertyName: string,
  data: AddressInfo[] | ContactInfo | TravelDocument[] | Validation[],
  state: ISecurityInfoState): SecurityInfo[] {
  return state.securityInfo.map(paxInfo => {
    if (paxInfo.passengerInfo.id === state.passengerInfo.id) {
      return {
        ...paxInfo,
        [propertyName]: data
      };
    } else {
      return {
        ...paxInfo
      };
    }
  });
}

function updateSecurityInfoDocV(state: ISecurityInfoState) {
  return state.securityInfo.map(paxInfo => {
    return {
      ...paxInfo,
      validations: [{
        validationType: Constants.validationTypeDocv,
        countryCode: state.travelDocuments[0].nationality
      }]
    };
  });
}

const securityInfoPassengerItinerary = (passengerSecurityInfo: PassengerSecurityInfo[]): SecurityInfo[] => {
  return passengerSecurityInfo.map(pax => (<SecurityInfo>{
    passengerInfo: {
      ...pax.passengerInfo,
      isInfant: (pax.passengerInfo.isInfant) ? (pax.passengerInfo.isInfant) : false
    },
    itinerary: {
      airlineCode: pax.passengerItinerary[0].airlineCode,
      bookingClass: pax.passengerItinerary[0].bookingClass,
      departureDate: pax.passengerItinerary[0].departureDate,
      flightNumber: pax.passengerItinerary[0].flightNumber,
      origin: pax.passengerItinerary[0].origin
    }
  }));
};

const getDefaultPassenger = (passengerSecurityInfo: PassengerSecurityInfo[], securityInfo: SecurityInfo[]): SecurityInfo => {
  let defaultPax = null;
  passengerSecurityInfo.find(pax => {
    // check if pax only needs DOCV & has existing address/contact info in securityInfo
    if (pax.requiredInfoSummary.every(verifyStatusCode => verifyStatusCode.additionalInfo.code === Constants.validationTypeDocv)
      && securityInfo.find(info => info.passengerInfo.id === pax.passengerInfo.id && info.addressInfo && info.contactInfo)) {
      defaultPax = securityInfo.find(info => info.passengerInfo.id === pax.passengerInfo.id);
    }
  }
  );
  return defaultPax;
};

const getPassengerInfo = (state: ISecurityInfoState, travelerId: string): Partial<ISecurityInfoState> => {
  return state.securityInfo
    .filter(paxInfo => paxInfo.passengerInfo.id === travelerId)
    .map(paxInfo => ({
      passengerInfo: paxInfo.passengerInfo,
      itinerary: paxInfo.itinerary,
      requiredInfoSummary: state.passengers ?
        state.passengers.find(passengerPaxInfo => passengerPaxInfo.passengerInfo.id === travelerId).requiredInfoSummary : []
    }))[0];
};
