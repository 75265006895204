import { createAction, props } from '@ngrx/store';
import { Response } from '~app/models/response.model';
import { PassportScanAttempts } from '~app/models/passportscanattempts.model';
import { PassportScanData, PassportScanDocument } from '~app/models/passportscandata.model';

export const initiatePassportScan = createAction(
  '[Jumio Passport Scan] Initiate Passport Scan',
  props<{ paxId: string }>()
);

export const initiatePassportScanSuccess = createAction(
  '[Jumio Passport Scan] Initiate Passport Scan Success',
  props<{ scanReference: string, redirectUrl: string }>()
);

export const initiatePassportScanError = createAction(
  '[Jumio Passport Scan] Initiate Passport Scan Error',
  props<{ error: string, requestData: any }>()
);

export const getPassportScanStatus = createAction(
  '[Jumio Passport Scan] Get Passport Scan Status',
  props<{ scanReference: string }>()
);

export const getPassportScanData = createAction(
  '[Jumio Passport Scan] Get Passport Scan Data',
  props<{ scanReference: string, passport?: { paxId: string, passportDocument: PassportScanDocument } }>()
);

export const getPassportScanDataSuccess = createAction(
  '[Jumio Passport Scan] Get Passport Scan Data Success',
  props<{ passportData: PassportScanData, passengerSegmentIds: string[] }>()
);

export const getPassportScanDataError = createAction(
  '[Jumio Passport Scan] Get Passport Scan Data Error',
  props<{ error: string, requestData: any }>()
);

export const getPassportScanAttempts = createAction(
  '[Jumio Passport Scan] Get Passport Scan Attempts',
);

export const getPassportScanAttemptsSuccess = createAction(
  '[Jumio Passport Scan] Get Passport Scan Attempts Success',
  props<{ scanAttempts: Response<PassportScanAttempts> }>()
);

export const getPassportScanAttemptsError = createAction(
  '[Jumio Passport Scan] Get Passport Scan Attempts Error',
  props<{ error: string, requestData: any }>()
);

export const postPassportScanAttempt = createAction(
  '[Jumio Passport Scan] Post Passport Scan Attempt',
);

export const postPassportScanAttemptSuccess = createAction(
  '[Jumio Passport Scan] Post Passport Scan Attempt Success',
  props<{ scanAttempts: Response<PassportScanAttempts> }>()
);

export const postPassportScanAttemptError = createAction(
  '[Jumio Passport Scan] Post Passport Scan Attempt Error',
  props<{ error: string, requestData: any }>()
);
