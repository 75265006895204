<div *ngIf="!!cartResultItems?.length" class="payment-container">
  <div [ngSwitch]="route">
    <div *ngSwitchCase="baggageRoute">
      <div *ngFor="let cartItem of cartResultItems; let idx = index; let last = last" [ngClass]="{last: last}" class="row  mx-0 pb-3">
        <div class="col-md-12 d-flex subtitle px-0 py-3" *ngIf="cartItem.catalogId === catalogIdBag">
          <span>
            <span id="bag-name-item-{{idx}}">{{cartItem?.title}} ({{cartItem?.quantity}})</span>
          </span>
          <span class="ml-auto">
            <span id="bag-price-item-{{idx}}">{{cartItem?.price | customCurrency }}</span>
          </span>
        </div>
      </div>
    </div>
    <div *ngSwitchDefault>
      <div>
        <div class="container item-container" *ngIf="hasSeatsSelected">
          <div class="row bold seat-fee">
            <div class="col-md-12 d-flex payment-cart-item">
              <span>
                <span class="body-copy-bold medium" id="seat-fees-header">{{'seatFees' | translate}}</span>
              </span>
              <span class="ml-auto">
                <span class="body-copy aloha-pink link" id="seat-fees-edit-link"><a (click)="routerDispatchers.routeToSeats()"
                  (keyup)="routerDispatchers.routeToSeats()">{{'edit' | translate}}</a></span>
              </span>
            </div>
          </div>
          <div *ngFor="let cartItem of cartResultItems; let idx = index" class="row">
            <div class="col-md-12 d-flex body-copy payment-cart-item" *ngIf="cartItem.catalogId === catalogIdSeat || cartItem.catalogId === catalogIdSeatAmadeus">
              <span>
                <span class="body-copy" id="seat-name-item-{{idx}}">{{cartItem?.title}} ({{cartItem?.quantity}})</span>
              </span>
              <span class="ml-auto">
                <span class="body-copy" id="seat-price-item-{{idx}}">{{ (!!cartItem.priceDifference.toString() ? cartItem?.priceDifference  : cartItem?.price) | customCurrency }}</span>
              </span>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="container item-container" *ngIf="hasBagsSelected">
          <div class="row bold bag-fee">
            <div class="col-md-12 d-flex payment-cart-item">
              <span>
                <span class="body-copy-bold medium" id="bag-fees-header">{{'bagFees' | translate}}</span>
              </span>
              <span class="ml-auto">
                <span class="body-copy aloha-pink link" id="bag-fees-edit-link">
                  <a (click)="routerDispatchers.routeToBaggage()"
                    (keydown.space)="routerDispatchers.routeToBaggage()"
                    (keydown.enter)="routerDispatchers.routeToBaggage()"
                    role="button" tabindex="0">{{'edit' | translate}}</a></span>
              </span>
            </div>
          </div>
          <div *ngFor="let cartItem of cartResultItems; let idx = index;" class="row">
            <div class="col-md-12 body-copy d-flex payment-cart-item" *ngIf="cartItem.catalogId === catalogIdBag">
              <span>
                <span id="bag-name-item-{{idx}}">{{cartItem?.title}} ({{cartItem?.quantity}})</span>
              </span>
              <span class="ml-auto">
                <span id="bag-price-item-{{idx}}">{{cartItem?.price | customCurrency }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

