import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ComponentRendering, ContentSchema, PropertyType } from "@hawaiianair/core";
import { Observable, Subscription } from "rxjs";
import { ModalContentsService } from "~app/modals/modal-contents.service";
import { SessionData } from "~app/models/sessiondata.model";
import { CartDispatchers } from "../../store/services/cart/cart.dispatchers";
import { RouterDispatchers } from "../../store/services/router/router.dispatchers";
import { SessionSelectors } from "../../store/services/session/session.selectors";

@Component({
  selector: 'app-offers-error-modal',
  templateUrl: './offers-error-modal.component.html',
  styleUrls: ['./offers-error-modal.component.scss']
})
@ContentSchema({
  name: 'OffersErrorModal',
  description: 'Offers Error Modal',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'content', type: PropertyType.Text },
    { name: 'checkInBtn', type: PropertyType.Text },
    { name: 'checkInBtnSubText', type: PropertyType.Text },
    { name: 'cancelBtn', type: PropertyType.Text },
    { name: 'subContent', type: PropertyType.Text },
  ]
})
export class OffersErrorModalComponent implements OnInit, OnDestroy {
  rendering: ComponentRendering;
  sessionData: SessionData;
  sessionData$: Observable<SessionData>;
  sessionSubscription = new Subscription();
  hasAlreadyCheckedIn$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<OffersErrorModalComponent>,
    private modalContentsService: ModalContentsService,
    private routerDispatchers: RouterDispatchers,
    private cartDispatchers: CartDispatchers,
    private sessionSelectors: SessionSelectors
  ) {
    this.hasAlreadyCheckedIn$ = this.sessionSelectors.sessionHasAlreadyCheckedIn$;
    this.sessionData$ = this.sessionSelectors.session$;
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsService.getRendering('offers-error-modal') || [{}];
    this.sessionSubscription.add(this.sessionData$.subscribe(session => {
      if (!!session) {
        this.sessionData = session;
      }
    }));
  }

  onCheckInClick() {
    this.routerDispatchers.routeToPayment('Offers Error Modal Component');
  }

  onCloseClick() {
    if (this.sessionData && this.sessionData.cartId) {
      this.cartDispatchers.deleteCart(this.sessionData.cartId);
    }
    this.routerDispatchers.routeToCheckin('Offers Error Modal Component');
  }

  ngOnDestroy() {
    this.sessionSubscription.unsubscribe();
  }
}
