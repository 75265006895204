import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@hawaiianair/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { EmergencyContactInfoComponent } from './traveler-information/emergency-contact-info/emergency-contact-info.component';
import { InternationalTravelerInformationComponent } from './traveler-information/international-traveler-information.component';
import { PassportScanComponent } from './passport-information/passport-scan/passport-scan.component';
import { InternationalTravelerSelectionComponent } from './traveler-selection/international-traveler-selection.component';
import { PassportInformationComponent } from './passport-information/passport-information.component';
import { ContactInfoFormComponent } from './traveler-information/contact-info-form/contact-info-form.component';
import { ResidenceInformationComponent } from './residence-information/residence-information.component';
import { TravelInformationComponent } from '~components/international/travel-information/travel-information.component';
import { DestinationInformationComponent } from '~components/international/travel-information/destination-information/destination-information.component';
import { TranslateModule } from '@ngx-translate/core';
import { ManualPassportFormComponent } from './passport-information/manual-passport-form/manual-passport-form.component';

@NgModule({
    imports: [
      AppSharedModule,
      // for Material Design
      // import Material Design Components
      MatButtonModule,
      MatDialogModule,
      MatCardModule,
      MatFormFieldModule,
      MatIconModule,
      FormsModule,
      MatCheckboxModule,
      ReactiveFormsModule,
      TranslateModule,
      CoreModule.forChild({
        components: [
          InternationalTravelerInformationComponent,
          EmergencyContactInfoComponent,
          PassportScanComponent,
          TravelInformationComponent,
          InternationalTravelerSelectionComponent,
          PassportInformationComponent,
          DestinationInformationComponent,
          ResidenceInformationComponent,
          ManualPassportFormComponent
        ]
      }),
    ],
    exports: [
      // export Material Design components
      MatButtonModule,
      MatDialogModule,
      MatCardModule,
      MatFormFieldModule,
      MatIconModule,
      RouterModule,
      TranslateModule
    ],
    declarations: [
      InternationalTravelerInformationComponent,
      EmergencyContactInfoComponent,
      PassportScanComponent,
      TravelInformationComponent,
      InternationalTravelerSelectionComponent,
      PassportInformationComponent,
      ContactInfoFormComponent,
      DestinationInformationComponent,
      ResidenceInformationComponent,
      ManualPassportFormComponent
    ],
  })
  export class InternationalTravelerInformationModule { }
