import { Component, Input } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';

@Component({
  selector: 'app-classic-web-login',
  templateUrl: './classic-web-login.component.html',
  styleUrls: ['./classic-web-login.component.scss']
})

@ContentSchema({
  name: 'ClassicWebLogin',
  description: "Classic Web Login",
  props: [
    { name: 'forgotConfirmation', type: PropertyType.Text },
    { name: 'hawaiianMilesMembers', type: PropertyType.Text },
    { name: 'loginMyAccountLink', type: PropertyType.Hyperlink }
  ]
})

export class ClassicWebLoginComponent {
  @Input() rendering: ComponentRendering;
  toggleInfoArea = false;

  toggleIcon(): void {
    this.toggleInfoArea = !this.toggleInfoArea;
  }
}
