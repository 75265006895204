import { ComponentRendering, Props } from '@hawaiianair/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalContentsService {
  modalProps: Props[] = [];

  public addRendering(componentId: string, renderingArr: ComponentRendering[]) {
    if (!!renderingArr && !!renderingArr[0]) {
      this.modalProps[componentId] = renderingArr[0].props;
    }
  }

  public getRendering(componentId: string) {
    return this.modalProps[componentId];
  }
}
