<div class="checkin-header pink-header d-none d-md-block">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="position-relative">
          <img id="header-wordmark" src="/media/images/logos/Wordmark.svg" alt="Hawaiian Airlines" class="Wordmark"
            (click)="quit()">
          <div id="header-quit" class="exit-btn position-absolute body-copy-bold"
          accesskey="q"
          [attr.aria-label]="'Quit' | translate"
          (click)="quit()"
          (keydown.enter)="quit()"
          (keydown.space)="quit()"
          role="button"
          tabindex="0"
          >
            <span class="align-middle exit-icon" aria-hidden="true">&times;</span>
            <span class="align-middle quit d-none d-md-block">{{ 'Quit' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="checkin-header-mobile d-sm-block d-md-none">
  <div class="container">
    <div class="row">
      <div id="header-quit" class="col-4 d-flex exit-btn-mobile" (click)="quit()" (keyup)="quit()">
        <span class="exit-icon-mobile" aria-hidden="true">&times;</span>
      </div>
      <div class="col-4 px-0">
        <img id="ha-logo" src="/media/images/logos/ha-logo-horizontal.png" alt="HA Logo" class="ha-logo-mobile d-flex justify-content-center"  (click)="quit()">
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div *ngIf="!hideHeaderInfo" id="checkin-header-info" class="row">
    <app-checkin-page-info class="col-md-12" [hideFlightStatus]="hideFlightStatus" [heading]="heading" [description]="description"></app-checkin-page-info>
  </div>
</div>
