import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {
    // constructor
  }

  log(message?: any, ...optionalParam: any) {
    // TODO
    console.log('WEB-CHECKIN: ', message, ...optionalParam);
  }
  error(message?: any, ...optionalParam: any) {
    // TODO
    console.error('WEB-CHECKIN: ', message, ...optionalParam);
  }
}
