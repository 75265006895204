<h6 class="bold medium" mat-dialog-title>
  <span>{{ header }}</span>
</h6>
<mat-dialog-content class="dlg-content mat-typography">
  <span class="body-copy">{{ content }}</span>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="seatmap-upgrade-all-passenger-ok" mat-raised-button [mat-dialog-close]="" class="btn btn-default">
    <span>{{ btnOk }}</span>
  </button>
</mat-dialog-actions>
