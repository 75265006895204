<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(savingLapInfantInfo$ | async)">
    <div id="remove-infant-modal-spinner" class="spinner-border" role="status">
    </div>
</div>
<div id="remove-infant-modal">
    <h6 *ngIf="!removeInfantConfirmationOption; else removeInfantConfirmationHeading" id="remove-infant-heading" class="bold medium" mat-dialog-title>
        <span *haText="rendering?.props['removeInfantHeading']"></span>
    </h6>
    <ng-template #removeInfantConfirmationHeading>
        <h6 id="remove-infant-confirmation-heading" class="bold medium" mat-dialog-title>
            <span *haText="rendering?.props['removeInfantTitle']"></span>
        </h6>
    </ng-template>
    <mat-dialog-content *ngIf="!removeInfantConfirmationOption; else removeInfantConfirmationContent" class="dlg-content mat-typography">
        <div id="remove-infant-description" class="body-copy" *haText="rendering?.props['removeInfantDescription']"></div>
        <div *ngFor='let passenger of data.results.passengers.entries; let infantIndex = index'>
            <div class="row" style="margin-top: 10px;" *ngIf='passenger.hasInfant'>
                <div class="col-12 col-sm-6 my-auto">
                    <span id="infant-name-{{infantIndex}}" class="infant-name-line-height body-copy-bold">
                        {{ passenger.lapInfant.infantName.firstName }} {{ passenger.lapInfant.infantName.lastName }}
                    </span>
                </div>
                <div class="col-12 col-sm-6">
                    <button *ngIf="removedPNRInfants[infantIndex]; else removeButtonAndLoader" id="disabled-infant-remove-button-{{infantIndex}}"
                        class="btn btn-secondary" mat-button disabled>
                        <span *haText="rendering?.props['removedInfant']"></span>
                    </button>
                    <ng-template #removeButtonAndLoader>
                        <button id="remove-infant-button-{{infantIndex}}" class="btn btn-secondary" mat-button (click)="openInfantRemovalConfirmation(passenger.id, infantIndex)">
                            <span *haText="rendering?.props['removeInfant']"></span>
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <ng-template #removeInfantConfirmationContent>
        <mat-dialog-content class="dlg-content mat-typography">
            <div id="remove-infant-confirmation-description" class="body-copy" *haText="rendering?.props['removeInfantConfirmation']"></div>
        </mat-dialog-content>
    </ng-template>
    <mat-dialog-actions *ngIf="!removeInfantConfirmationOption; else removeInfantConfirmationButtons" class="infant-removal-button">
        <button id="infant-removal-cancel-button" mat-button [mat-dialog-close]="true" (click)="onCancel()" class="btn btn-default" cdkFocusInitial>
            <span *haText="rendering?.props['done']"></span>
        </button>
    </mat-dialog-actions>
    <ng-template #removeInfantConfirmationButtons>
        <mat-dialog-actions>
            <button id="infant-removal-confirmation-button" mat-button (click)="removeInfant()" class="btn btn-default" accesskey="y" cdkFocusInitial>
                <span *haText="rendering?.props['yes']"></span>
            </button>
            <button id="infant-removal-confirmation-cancel-button" mat-button (click)="closeInfantRemovalConfirmation()" accesskey="n" class="btn btn-secondary">
                <span *haText="rendering?.props['no']"></span>
            </button>
        </mat-dialog-actions>
    </ng-template>
</div>
