<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"
  [hideHeaderInfo]="tripLoading$ | async"></app-checkin-header>
<div *ngIf="tripLoading$ | async; else upgradePage">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #upgradePage>
<div class="background container text-left">
<ul id="seat-upgrade-list" *ngIf="rendering?.props['list']">
    <li id="item-{{idx}}" *ngFor="let item of rendering.props['list']; let idx = index" class="call-out">{{ item.text }}</li>
</ul>
</div>
  <app-seat-upgrade-app-banner [rendering]="seatUpgradeAppBannerRendering"></app-seat-upgrade-app-banner>
  <app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
</ng-template>
