import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { services } from './';
import { entityReducers, sessionReducers, uiReducers } from './reducers';
import { effects } from './effects';
import { clearEntityState } from "./reducers/meta-reducers";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forFeature('entityCache', entityReducers, {metaReducers: [clearEntityState]}),
        StoreModule.forFeature('sessionCache', sessionReducers),
        StoreModule.forFeature('uiCache', uiReducers),
        EffectsModule.forFeature(effects),
    ],
    providers: [
        ...services
    ],
    exports: [
        StoreModule,
        EffectsModule
    ]
})
export class AppStoreModule {}
