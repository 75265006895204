import { UiState } from '~app/store/reducers';
import { Store } from '@ngrx/store';
import * as RouterActions from '~app/store/actions/router.actions';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable()
export class RouterDispatchers {
  constructor(private store: Store<UiState>) { }

  routeToPassenger(componentName: string, data?: any) {
    this.store.dispatch(RouterActions.routeToPassengers({ componentName, data }));
  }

  routeToGroupCheckin(componentName: string) {
    this.store.dispatch(RouterActions.routeToGroupCheckin({ componentName }));
  }

  routeToLinkMinor(componentName: string) {
    this.store.dispatch(RouterActions.routeToLinMinorToAdultPage({ componentName }));
  }

  routeToCheckin(componentName: string) {
    this.store.dispatch(RouterActions.routeToCheckin({ componentName }));
  }

  actionRouting(actions: string[]) {
    this.store.dispatch(RouterActions.actionRouting({ actions }));
  }

  routeToMilitary(componentName: string) {
    this.store.dispatch(RouterActions.routeToMilitary({ componentName }));
  }

  defaultNavButtonClick(route: string) {
    this.store.dispatch(RouterActions.defaultNavButtonClick(route)());
  }

  secondaryNavButtonClick(route: string) {
    this.store.dispatch(RouterActions.secondaryNavButtonClick(route)());
  }

  routeToGuidelines(route: string) {
    this.store.dispatch(RouterActions.routeToGuidelines({ componentName: route }));
  }

  routeToSeatMapById(componentName: string, queryParams: Params) {
    this.store.dispatch(RouterActions.routeToSeatMapById({ componentName, queryParams }));
  }

  routeToSeats(componentName: string) {
    this.store.dispatch(RouterActions.routeToSeats({ componentName }));
  }

  routeToSeatUpgradeList(componentName: string) {
    this.store.dispatch(RouterActions.routeToSeatUpgradeList({ componentName }));
  }

  routeToBaggage(componentName: string) {
    this.store.dispatch(RouterActions.routeToBaggage({ componentName }));
  }

  routeToBagAdvisory(componentName: string) {
    this.store.dispatch(RouterActions.routeToBagAdvisory({ componentName }));
  }

  routeToBagSpecialItems(componentName: string, queryParams: Params) {
    this.store.dispatch(RouterActions.routeToBagSpecialItems({ componentName, queryParams }));
  }

  routeToInfantAdditionalInfo(componentName: string, adultPaxId: string) {
    this.store.dispatch(RouterActions.routeToInfantAdditionalInfo({ componentName, data: adultPaxId }));
  }

  routeToInfant(componentName: string) {
    this.store.dispatch(RouterActions.routeToInfant({ componentName }));
  }

  routeToBoardingPass(componentName: string) {
    this.store.dispatch(RouterActions.routeToBoardingPass({ componentName }));
  }

  routeToSegmentSelector(componentName: string) {
    this.store.dispatch(RouterActions.routeToSegmentSelector({ componentName }));
  }

  routeToPayment(componentName: string) {
    this.store.dispatch(RouterActions.routeToPayment({ componentName }));
  }
}
