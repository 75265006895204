import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, tap, switchMap, catchError } from 'rxjs/operators';
import * as AuthTokenActions from '~app/store/actions/auth-token.actions';
import * as SessionActions from '~app/store/actions/session.actions';
import { AuthTokenService } from '~app/store/services/auth-token/auth-token.service';
import { of } from 'rxjs';
import { SessionTimeoutService } from '~app/services/session-timeout.service';
import { AuthTokenDispatchers } from '~app/store/services';
import { ModalsService } from "~app/modals/modals.service";

@Injectable()
export class AuthTokenEffects {
  constructor(
    private actions$: Actions,
    private authTokenService: AuthTokenService,
    private modalsService: ModalsService,
    private sessionTimeoutService: SessionTimeoutService,
    private authTokenDispatchers: AuthTokenDispatchers
  ) { }

  getAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthTokenActions.checkinGetAuthToken, AuthTokenActions.authTokenExpirationModalGetAuthToken),
      switchMap(action => {
        const actionDispatcher = `${action.type.split(']')[0]}]`;
        return this.authTokenService.get(action.tripSearch).pipe(
          tap(authToken => {
            if (!!action.tripSearch['hawaiianMilesNumber'] && authToken.count > 1) {
              throw { error: 'HawaiianMilesAuthTokenError', requestData: authToken };
            } else if (authToken.count === 0) {
              throw { error: 'InvalidTripSearch', requestData: authToken };
            }
              else if (!authToken.results || !authToken.results[0] || !authToken.results[0].accessToken) {
              throw { error: 'no data', requestData: authToken };
            }
          }),
          concatMap((authToken) => {
            return ([
              SessionActions.setSessionAuthToken({ name: 'authToken', value: authToken.results[0].accessToken }),
              SessionActions.setSessionTripParams({ tripSearch: action.tripSearch }),
              AuthTokenActions.authTokenApiSuccess(`${actionDispatcher} Get Auth Token Success`)
                ({ authToken: authToken, tripSearch: action.tripSearch })
            ]);
          }),
          catchError(error => {
            return of(AuthTokenActions.authTokenApiError(`${actionDispatcher} Get Auth Token Error`)(error));
          })
        );
      })
    )
  );

  getAuthTokenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthTokenActions.checkinGetAuthTokenSuccess,
        AuthTokenActions.authTokenExpirationGetAuthTokenSuccess),
      tap(_ => {
        this.sessionTimeoutService.startWatchingAuthTokenExpiration();
        this.authTokenDispatchers.startWatchingAuthTokenExpiration();
      })
    ), { dispatch: false }
  );

  getAuthTokenError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthTokenActions.checkinGetAuthTokenError,
        AuthTokenActions.authTokenExpirationGetAuthTokenError),
      tap(error => {
        if (error.error === 'InvalidTripSearch') {
          this.modalsService.openGenericErrorMessage({ contentProps: 'messageAuthTokenError', error: error.error });
        } else {
          this.modalsService.openGenericErrorMessage({ contentProps: ''});
        }
      })
    ), { dispatch: false }
  );
}
