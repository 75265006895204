import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '~app/services/helper.service';
import { TripSelectors } from '~app/store/services/trip/trip.selectors';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { Segment } from '~app/models/segment.model';
import { Flight } from '~app/models/flight.model';
import { ALERTS } from '~app/constants/ha-constants';
import { AlertCodeMap, AlertReasonCodeData } from '~app/models/alert-code-logging.model';
import { AnalyticsDispatchers } from '~app/store';
import { ModalsService } from '~app/modals/modals.service';

interface TripWithFlightNumber extends Segment {
  airlineCode: string;
  flightNumber: string;
  departureCity: string;
  arrivalCity: string;
}

@Component({
  selector: 'app-segment-selector',
  templateUrl: './segment-selector.component.html',
  styleUrls: ['./segment-selector.component.scss'],
})

@ContentSchema({
  name: 'SegmentSelector',
  description: 'Segment Selector Component',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text }
  ]
})

export class SegmentSelectorComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  tripData$: Observable<Response<Trip>>;
  subscriptions: Subscription = new Subscription();
  tripData: TripWithFlightNumber[];
  segment: Segment;
  flights: Flight[];
  segmentCardRendering: ComponentRendering;
  alertCodeDataBySegment: AlertCodeMap = {};
  allSegmentsCantCheckIn = true;
  isUnaccompaniedMinor = false;

  constructor(
    private tripSelectors: TripSelectors,
    public helperService: HelperService,
    private analyticsDispatchers: AnalyticsDispatchers,
    private modalsService: ModalsService,
    ) {
      this.tripData$ = this.tripSelectors.trip$;
  }


  ngOnInit(): void {
    this.segmentCardRendering = (this.rendering.components['segment-card'] || [{}])[0];
    this.subscriptions.add(this.tripData$.subscribe(trip => {
      if (trip) {
        const flightEntries = trip.results[0].flights.entries;
        this.tripData = trip.results[0].segments.entries.map(entry => ({
          ...entry,
          departureCity: this.helperService.getAirportName(entry.origin),
          arrivalCity: this.helperService.getAirportName(entry.destination),
          airlineCode: flightEntries.find(flight => flight.id === entry.details[0].flightDetails[0].flightId).airlineCode,
          flightNumber: flightEntries.find(flight => flight.id === entry.details[0].flightDetails[0].flightId).flightNumber,
        }));
        this.flights = trip.results[0].flights.entries;
        this.isUnaccompaniedMinor = trip.results[0].passengers.entries.every(pax => pax.isMinor === true);
      }
    }));
    this.analyticsDispatchers.segmentSelectorPageLoaded();
    if(this.isUnaccompaniedMinor) {
      this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToAgentForUnaccompaniedMinor });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addErrorCode(alertCodeData: AlertReasonCodeData): void {
    if (alertCodeData.code === "") {
      this.allSegmentsCantCheckIn = false;
    }

    this.alertCodeDataBySegment[alertCodeData.segmentId] = alertCodeData;
    // if we have gotten an error code for each segment and all segments are not eligible for checkin
    if ((Object.keys(this.alertCodeDataBySegment).length === this.tripData.length) && this.allSegmentsCantCheckIn) {
      this.analyticsDispatchers.referOutAlertCode(this.getErrorToLog());
    }
  }

  getErrorToLog(): AlertReasonCodeData {
    let defaultAlert: AlertReasonCodeData = null;
    const keys = Object.keys(this.alertCodeDataBySegment);
    for (const i in keys) {
      if (this.alertCodeDataBySegment[keys[i]].code === ALERTS.lateCheckInAlert[0]) {
        if (defaultAlert === null) {
          defaultAlert = this.alertCodeDataBySegment[keys[i]];
        }
      } else if (this.alertCodeDataBySegment[keys[i]].code === ALERTS.earlyCheckInAlert[0]) {
        defaultAlert = this.alertCodeDataBySegment[keys[i]];
      } else if (this.alertCodeDataBySegment[keys[i]].code !== null) {
        return this.alertCodeDataBySegment[keys[i]];
      }
    }

    return defaultAlert;
  }
}
