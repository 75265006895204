import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SessionDispatchers } from '~app/store/services/session/session.dispatchers';

@Injectable({
  providedIn: 'root',
})
export class DetectDeviceService {
  constructor(
    private deviceService: DeviceDetectorService,
    private sessionDispatchers: SessionDispatchers
  ) {}

  checkDeviceInfo() {
    this.checkDeviceMobile();
    this.checkDeviceOS();
    this.checkDeviceBrowser();
  }

  checkDeviceMobile() {
    this.sessionDispatchers.setMobileDevice(this.deviceService.isMobile());
  }

  checkDeviceOS() {
    this.sessionDispatchers.setOSDevice(this.deviceService.getDeviceInfo().os);
  }

  checkDeviceBrowser() {
    this.sessionDispatchers.setBrowserDevice(this.deviceService.getDeviceInfo().browser);
  }
}
