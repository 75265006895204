import { createAction, props } from '@ngrx/store';
import { Response } from '../../models/response.model';
import { Catalog } from '../../models/catalog.model';
import { DataServiceError } from '../config';

export const createCatalogAction = (actionType: string) =>
  createAction(actionType, props<{ catalog: Response<Catalog> }>());

export const createCatalogErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: DataServiceError<Catalog> }>());

export const bagsGetCatalog = createAction(
  '[Bags] Get Catalog'
);

export const seatsGetCatalog = createAction(
  '[Seats] Get Catalog'
);

export const itineraryGetCatalog = createAction(
  '[Passengers] Get Catalog'
);

export const dashboardGetCatalog = createAction(
  '[Dashboard] Get Catalog'
);

export const getCatalogError = createCatalogErrorAction(
  'Get Catalog Error'
);

export const upGradeAllPaxToSameCabinError = createCatalogErrorAction(
  '[Seat Map] Upgrade All Passengers to Same Cabin'
);

export const getCatalogSuccess = createCatalogAction(
  'Get Catalog Success'
);

export const initCatalogState = createAction(
  '[Catalog Effects] Init Catalog State'
);
