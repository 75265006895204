import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkin-page-info',
  templateUrl: './checkin-page-info.component.html',
  styleUrls: [ './checkin-page-info.component.scss' ],
})

export class CheckinPageInfoComponent {
  @Input() heading: string;
  @Input() description: string;
  @Input() hideFlightStatus = false;
}
