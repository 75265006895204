<h6 class="bold medium" mat-dialog-title>
  <span *haText="rendering.props['header']"></span>
</h6>
<mat-dialog-content class="seat-change-confirm-content mat-typography">
  <ul class="body-copy-bold medium">
    <li>
      <span *haText="rendering.props['oldSeat']"></span> : {{data.oldSeatInfo}}
    </li>
    <li>
      <span *haText="rendering.props['newSeat']"></span> : {{data.newSeatInfo}} {{data.newSeatClass}}
    </li>
    <li>
      <span *haText="rendering.props['cost']"></span> : {{data.costInfo | customCurrency }}
    </li>
  </ul>
  <div class="body-copy" *haText="rendering.props['description']"></div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button id="seatmap-seat-change-confirmation-undo" mat-button mat-dialog-close class="btn btn-secondary">
    <span *haText="rendering.props['btnUndo']"></span>
  </button>
  <button id="seatmap-seat-change-confirmation-accept" mat-button [mat-dialog-close]="true" class="btn btn-default" cdkFocusInitial>
    <span *haText="rendering.props['btnAccept']"></span>
  </button>
</mat-dialog-actions>
