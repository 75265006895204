import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalContentsService } from '../modal-contents.service';
import { RouterDispatchers } from '../../store/services/router/router.dispatchers';
import { ContentSchema, ComponentRendering, PropertyType } from '@hawaiianair/core';

@Component({
  selector: 'app-passenger-update-error-modal',
  templateUrl: './passenger-update-error-modal.component.html',
  styleUrls: ['./passenger-update-error-modal.component.scss'],
})
@ContentSchema({
  name: 'PassengerUpdateErrorModal',
  description: 'Passenger Update Error Modal Component',
  props: [
    { name: 'hawaiianMilesNumberErrorTitle', type: PropertyType.Text },
    { name: 'hawaiianMilesNumberError', type: PropertyType.Text },
    { name: 'redressNumberErrorTitle', type: PropertyType.Text },
    { name: 'redressNumberError', type: PropertyType.Text },
    { name: 'knownTravelerNumberErrorTitle', type: PropertyType.Text },
    { name: 'knownTravelerNumberError', type: PropertyType.Text },
    { name: 'okay', type: PropertyType.Text }
  ]
})
export class PassengerUpdateErrorModalComponent implements OnInit {
  rendering: ComponentRendering;
  dialogTitle: string;
  message: string;
  okay: string;

  constructor(
    public dialogRef: MatDialogRef<PassengerUpdateErrorModalComponent>,
    private modalContentsSvc: ModalContentsService,
    @Inject(MAT_DIALOG_DATA) public data: { error: any, from: string, routeTo: string},
    private routerDispatchers: RouterDispatchers
  ) { }

  ngOnInit() {
    // >>> load contents
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('passenger-update-error-modal');

    const inputNumberType = this.data.from;
    this.dialogTitle = this.rendering.props[`${inputNumberType}ErrorTitle`];
    this.message = this.rendering.props[`${inputNumberType}Error`];
    this.okay = this.rendering.props['okay'];
    // <<< load contents
  }

  goToNextPage() {
    this.data.routeTo === 'baggage'
      ? this.routerDispatchers.routeToBaggage('Passenger Update Error Modal')
      : this.routerDispatchers.routeToPassenger('Passenger Update Error Modal');
    this.dialogRef.close();
  }
}
