import { DynamicRoute } from '@hawaiianair/core';
import { AuthGuard } from '~app/guards/auth.guard';
import { SpaGuard } from './guards/spa.guard';

// add any dynamic routes your app may need here - variables in the URL segments,
// authentication or other guards, resolvers, canActivate, etc.
// always use the path property; do not  use children, loadChildren, or redirectTo.
export const dynamicRoutes: DynamicRoute[] = [

  /* Examples:
  {
    path: 'my-account',
    requiresAuth: true,
  },
  {
    path: 'resource/:resourceId',
    resolve: { 'resource': ResourceResolver },
  },
  {
    path: 'path/with/guard',
    canActivate: [ MyGuardClass ],
  },
  */
  {
    path: 'travel-information',
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
  },
  {
    path: 'health-attestation',
    canActivate: [AuthGuard],
  },
  {
    path: 'passenger',
    canActivate: [AuthGuard],
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
  },
  {
    path: 'international-traveler-selection',
    canActivate: [AuthGuard],
  },
  {
    path: 'passport-information',
    canActivate: [AuthGuard],
  },
  {
    path: 'residence-information',
    canActivate: [AuthGuard],
  },
  {
    path: 'seat',
    canActivate: [AuthGuard],
  },
  {
    path: 'baggage',
    canActivate: [AuthGuard],
  },
  {
    path: 'military',
    canActivate: [AuthGuard],
  },
  {
    path: 'infant',
    canActivate: [AuthGuard],
  },
  {
    path: 'infant-additional-info',
    canActivate: [AuthGuard],
  },
  {
    path: 'payment',
    canActivate: [AuthGuard],
  },
  {
    path: 'result',
    canActivate: [AuthGuard],
  },
  {
    path: 'bag-special-items',
    canActivate: [AuthGuard],
  },
  {
    path: 'bag-advisory-items',
    canActivate: [AuthGuard],
  },
  {
    path: 'segment-selector',
    canActivate: [AuthGuard],
  },
  {
    path: 'guidelines',
    canActivate: [AuthGuard],
  },
  {
    path: 'seatmap',
    canActivate: [AuthGuard],
  },
  {
    path: 'boarding-pass-page',
    canActivate: [AuthGuard],
  },
  {
    path: 'international-traveler-information',
    canActivate: [AuthGuard],
  },
  {
    path: 'emergency-contact',
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-tracing',
    canActivate: [AuthGuard],
  },
  {
    path: 'minor',
    canActivate: [AuthGuard],
  },
  {
    path: 'ko/:other',
    canActivate: [AuthGuard],
  },
  {
    path: 'ja/:other',
    canActivate: [AuthGuard],
  },
  {
    path: 'zh/:other',
    canActivate: [AuthGuard],
  },
  {
    path: 'en-au/:other',
    canActivate: [AuthGuard],
  },
  {
    path: 'en-nz/:other',
    canActivate: [AuthGuard],
  },
  {
    path: ':other',
    canActivate: [AuthGuard],
  },
  {
    path: '',
    canActivate: [SpaGuard],
  },
];
