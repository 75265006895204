
import { Component, OnInit } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-infant-exit-seat-unavailable-modal',
  templateUrl: './infant-exit-seat-unavailable-modal.component.html',
  styleUrls: [ './infant-exit-seat-unavailable-modal.component.scss' ],
})
@ContentSchema({
  name: 'InfantExitSeatUnavailableModal',
  description: 'InfantExitSeatUnavailableModalComponent',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'content', type: PropertyType.Text }
  ]
})
export class InfantExitSeatUnavailableModalComponent implements OnInit {
  rendering: ComponentRendering;

  constructor(
    public dialogRef: MatDialogRef<InfantExitSeatUnavailableModalComponent>,
    private modalContentsSvc: ModalContentsService
  ) {}

  ngOnInit() {
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('infant-exit-seat-unavailable-modal');
  }
}
