import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { LogMessage } from '@hawaiianair/common';
import * as CheckInActions from '~app/store/actions';
import * as AuthTokenActions from '~app/store/actions/auth-token.actions';
import * as ReferenceDataActions from '~app/store/actions/reference-data.actions';
import { environment } from '~environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { isPlatformBrowser } from '@angular/common';
import { Action } from '@ngrx/store';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { PassengerList } from '~app/models/passengerlist.model';
import { PaymentItem } from '~app/models/paymentitem.model';
import { AuthToken } from '~app/models/auth-token.model';
import { TripDataSource } from './data-source/trip-data-source.service';
import { SessionDataSource } from './data-source/session-data-source.service';
import { CurrentLocaleService } from '@hawaiianair/core';
import { LogLevel } from '@hawaiianair/common';
import { deepEncode } from './utils';
import { TaggingService } from '@hawaiianair/tagging';
import { CheckinTaggingDataService } from '../checkin-tagging-data.service';

export const restrictedProperties = [
  'dateofbirth',
  'dob',
  'birthday',
  'loyalty',
  'lastname',
  'middlename',
  'passport',
  'passengerid',
  'cardnumber',
  'card number',
  'credit',
  'debit',
  'binnumber',
  'expirationmonth',
  'expirationyear',
  'expirationdate',
  'hm',
  'hmmiles',
  'hawaiianmiles',
  'cardname',
  'access_token',
  'token',
  'authorization',
  'authorizationid',
  'knowntravelernumber',
  'cac',
  'ktn',
  'redress',
  'secret',
  'client',
  'key',
  'password',
  'stream',
  'passport',
  'passportnumber',
  'profileid',
  'bearer',
  'boardingpassdata',
  'pin',
  'documentNumber',
  'freeText',
  'textLines',
  'ccDetails'
];

@Injectable({ providedIn: 'root' })
export class CheckInAnalyticsService {
  sessionTime: number;
  appInsights: any;
  private appInsightsConfig = {
    config: {
      instrumentationKey: environment?.logging?.instrumentationKey
    }
  };
  constructor(
    private tripDataSource: TripDataSource,
    private sessionDataSource: SessionDataSource,
    private localeService: CurrentLocaleService,
    private taggingService: TaggingService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.appInsights = new ApplicationInsights(this.appInsightsConfig);
    this.sessionTime = new Date().getTime();
    if (!!this.appInsights.config.instrumentationKey) {
      this.appInsights.loadAppInsights();
    }
  }

  // Supported logging actions
  loggedActions = {
    [CheckInActions.getByPnrSuccess.type]: 'PnrSearchSuccessful',
    [CheckInActions.getByPnrError.type]: 'PnrSearchFailed',
    [CheckInActions.checkinError.type]: 'CheckinFailed',
    [CheckInActions.checkinSuccess.type]: 'CheckinCallSuccess',
    [CheckInActions.resultPageLoaded.type]: 'CheckinSuccessful',
    [CheckInActions.checkinSuccessAlreadyCheckedIn.type]: 'CheckinSuccessfulAlreadyCheckedIn',
    [CheckInActions.updatePassengerSuccess.type]: 'PassengersUpdateSuccessful',
    [CheckInActions.updatePassengerError.type]: 'PassengersUpdateFailed',
    [CheckInActions.updatePassengerMilitaryTypeError.type]: 'MiltaryInfoMatchUnsuccessful',
    [CheckInActions.updatePassengerMilitaryTypeSuccess.type]: 'MiltaryInfoMatchSuccessful',
    [AuthTokenActions.checkinGetAuthTokenSuccess.type]: 'CheckinGetAuthTokenSuccessful', // unlisted event*
    [AuthTokenActions.checkinGetAuthTokenError.type]: 'CheckinGetAuthTokenFailed', // unlisted event*
    [AuthTokenActions.authTokenExpirationGetAuthTokenSuccess.type]: 'TimeOutPageDisplayed',
    [AuthTokenActions.authTokenExpirationGetAuthTokenError.type]: 'TimeOutPageFailed', // unlisted event*
    [AuthTokenActions.authTokenExpired.type]: 'TimeOutPageTimeOut',
    [CheckInActions.getFlightError.type]: 'GetFlightFailed', // unlisted event*
    [CheckInActions.getFlightSuccess.type]: 'CheckinStarted',
    [CheckInActions.getFlightDelayedSuccess.type]: 'GetFlightDelayedSuccessful', // unlisted event*
    [CheckInActions.getAllFlightsSuccess.type]: 'GetAllFlightsSuccessful', // unlisted event*
    [CheckInActions.getCatalogError.type]: 'GetCatalogFailed',
    [CheckInActions.getCatalogSuccess.type]: 'GetCatalogSuccessful',
    [CheckInActions.getBagOffersSuccess.type]: 'GetBagOffersSuccessful',
    [CheckInActions.getBagOffersError.type]: 'GetBagOffersFailed',
    [CheckInActions.getAircraftImageSuccess.type]: 'SeatmapOpenSuccessful', // unlisted event*
    [CheckInActions.getAircraftImageError.type]: 'SeatmapOpenFailed',
    [CheckInActions.updateCartWithItemsSuccess.type]: 'UpdateCartWithItemsSuccessful', // unlisted event*
    [CheckInActions.updateCartWithItemsError.type]: 'UpdateCartWithItemsFailed', // unlisted event*
    // todo: map to account for both payment and free checkouts
    [CheckInActions.checkoutCartFromPaymentRouteSuccess.type]: 'PaymentSuccessful',
    [CheckInActions.checkoutCartFromPaymentRouteFailure.type]: 'PaymentFailed',
    [CheckInActions.createCartErrCheckinInProgress.type]: 'CreateCartCheckInProgressFailed', // unlisted event*
    [CheckInActions.createCartErrPaymentInProgress.type]: 'CreateCartPaymentProgressFailed', // unlisted event*
    [CheckInActions.createCartErrSessionEnded.type]: 'CreateCartSessionEnded', // unlisted event*
    [CheckInActions.createCartError.type]: 'CreateCartFailed', // unlisted event*
    [CheckInActions.createCartSuccessPassengers.type]: 'CreateCartSuccessful', // unlisted event*
    [CheckInActions.createCartSuccessDashboard.type]: 'DashboardCreateCartSuccessful', // unlisted event*
    [CheckInActions.recreateCartSuccessPassengers.type]: 'RecreateCartSuccessful', // unlisted event*
    [CheckInActions.recreateCartSuccessDashboard.type]: 'DashboardRecreateCartSuccessful', // unlisted event*
    [CheckInActions.recreateCartError.type]: 'RecreateCartFailed', // unlisted event*
    [CheckInActions.getCartSuccess.type]: 'GetCartSuccessful',
    [CheckInActions.getCartError.type]: 'GetCartFailed',
    [CheckInActions.deleteCartError.type]: 'DeleteCartFailed',
    [CheckInActions.deleteCartSuccess.type]: 'DeleteCartSuccessful',
    [CheckInActions.updateCartSuccess.type]: 'UpdateCartSuccessful', // unlisted event*
    [CheckInActions.updateCartError.type]: 'UpdateCartFailed', // unlisted event*
    [CheckInActions.logRequestData.type]: 'RequestStarted',
    [CheckInActions.logResponseData.type]: 'ResponseReceived',
    [CheckInActions.logAlertError.type]: 'ErrorPageDisplayed',
    [CheckInActions.seatsUpsellClick.type]: 'SeatsUpsellClicked',
    [CheckInActions.passengersGetTripPassengersSuccess.type]: 'PassengersGetTripPassengersSuccessful',
    [CheckInActions.passengersGetTripPassengersError.type]: 'PassengersGetTripPassengersFailed',
    [CheckInActions.initiatePassportScanSuccess.type]: "InitiatePassportScanSuccessful",
    [CheckInActions.initiatePassportScanError.type]: "InitiatePassportScanFailed",
    [CheckInActions.getPassportScanDataSuccess.type]: "GetPassportScanDataSuccessful",
    [CheckInActions.getPassportScanDataError.type]: "GetPassportScanDataFailed",
    [CheckInActions.travelerInfoGetPassengerSuccess.type]: "TravelerInfoGetPassengerSuccessful",
    [CheckInActions.travelerInfoGetPassengerError.type]: "TravelerInfoGetPassengerFailed",
    [CheckInActions.travelerInfoPostSecuritySuccess.type]: "TravelerInfoPostSecuritySuccessful",
    [CheckInActions.travelerInfoPostSecurityError.type]: "TravelerInfoPostSecurityFailed",
    [CheckInActions.guidelinesGetTripPassengersSuccess.type]: "GuidelinesGetTripPassengersSuccessful",
    [CheckInActions.guidelinesGetTripPassengersError.type]: "GuidelinesGetTripPassengersFailed",
    [CheckInActions.guidelinesPostSecurityInfoSuccess.type]: "GuidelinesPostSecurityInfoSuccessful",
    [CheckInActions.guidelinesPostSecurityInfoError.type]: "GuidelinesPostSecurityInfoFailed",
    [CheckInActions.dashboardGetTripPassengersSuccess.type]: "DashboardGetTripPassengersSuccessful",
    [CheckInActions.dashboardGetTripPassengersError.type]: "DashboardGetTripPassengersFailed",
    [CheckInActions.dashboardPostSecurityInfoSuccess.type]: "DashboardPostSecurityInfoSuccessful",
    [CheckInActions.dashboardPostSecurityInfoError.type]: "DashboardPostSecurityInfoFailed",
    [CheckInActions.getAirportsSuccess.type]: "GetAirportsSuccessful",
    [CheckInActions.getAirportsError.type]: "GetAirportsFailed",
    [ReferenceDataActions.getCountriesSuccess.type]: "GetCountriesSuccessful",
    [ReferenceDataActions.getCountriesError.type]: "GetCountriesFailed",
    [ReferenceDataActions.getStationCodeSuccess.type]: "GetStationCodeSuccessful",
    [ReferenceDataActions.getStationCodeError.type]: "GetStationCodeFailed",
    [CheckInActions.getPassportScanAttemptsSuccess.type]: "GetPassportScanAttemptsSuccess",
    [CheckInActions.getPassportScanAttemptsError.type]: "GetPassportScanAttemptsError",
    [CheckInActions.postPassportScanAttemptSuccess.type]: "PostPassportScanAttemptSuccess",
    [CheckInActions.postPassportScanAttemptError.type]: "GetPassportScanAttemptError",
    [CheckInActions.addLapInfantSuccess.type]: "AddLapInfantSuccess",
    [CheckInActions.addLapInfantError.type]: "AddLapInfantError",
    [CheckInActions.referOutAlertCode.type]: "ReferOutError",
    [CheckInActions.groupPassengerNotFoundError.type]: "GroupPassengerNotFound",
    [CheckInActions.updateGroupPassenger.type]: "UpdateGroupPassengerSuccessful",
    [CheckInActions.groupBookingCheckinError.type]: "GroupBookingCheckinFailed",
    [CheckInActions.addMinorToAdultSuccess.type]: "AddMinorToAdultSuccessful",
    [CheckInActions.addMinorToAdultError.type]: "AddMinorToAdultError",
    [CheckInActions.addToEliteStandByListError.type]: "AddToEliteStandByListFailed",
    [CheckInActions.addToEliteStandByListSuccess.type]: "AddToEliteStandByListSuccessful",
    [CheckInActions.boardingPassPageLoaded.type]: 'BoardingPassPageLoadedSuccess',
    [CheckInActions.getOrderStatusError.type]: 'GetOrderStatusFailed',
    [CheckInActions.orderFulfillFailure.type]: 'PostOrderFulfillFailed',
    [CheckInActions.getOrderStatusSuccess.type]: 'GetOrderStatusSuccess',
    [CheckInActions.orderFulfillSuccess.type]: 'PostOrderFulfillSuccess',
    [CheckInActions.getMissingRegulatoryDetailsSuccess.type]: 'GetMissingRegulatoryDetailsSuccess',
    [CheckInActions.getMissingRegulatoryDetailsError.type]: 'GetMissingRegulatoryDetailsError',
    [CheckInActions.postPassportInformationSuccess.type]: 'PostPassportSuccess',
    [CheckInActions.postPassportInformationError.type]: 'PostPassportFailed',
    [CheckInActions.postAddressInformationSuccess.type]: 'PostAddressSuccess',
    [CheckInActions.postAddressInformationError.type]: 'PostAddressFailed',
    [CheckInActions.postEmergencyContactInformationSuccess.type]: 'PostEmergencyContactSuccess',
    [CheckInActions.postEmergencyContactInformationError.type]: 'PostEmergencyContactFailed',
    [CheckInActions.addPersonalRegulatoryDetailsSuccess.type]: 'AddPersonalRegulatoryDetailsSuccess',
    [CheckInActions.addPersonalRegulatoryDetailsError.type]: 'AddPersonalRegulatoryDetailsError',
    [CheckInActions.addNationalityCodeSuccess.type]: 'AddNationalityCodeSuccess',
    [CheckInActions.addNationalityCodeError.type]: 'AddNationalityCodeError',
    [CheckInActions.setSessionSelectedSegmentSuccess.type]: 'SetSessionSelectedSegmentSuccess',
    [CheckInActions.setSessionSelectedSegmentError.type]: 'SetSessionSelectedSegmentError',
    [CheckInActions.postContactTracingInformationSuccess.type]: 'PostContactTracingSuccess',
    [CheckInActions.postContactTracingInformationError.type]: 'PostContactTracingError',
    [CheckInActions.postContactTracingInformationForAllPassengers.type]: 'postContactTracingInformationForAllPassengers',
    [CheckInActions.postContactTracingInformationForAllPassengersSuccessFinished.type]: 'postContactTracingInformationForAllPassengersSuccessFinished',
  };

  taggedActions = {
    [CheckInActions.checkoutCartFromPaymentRouteFailure.type]: 'PaymentFailed',
    [CheckInActions.getOrderStatusError.type]: 'BookingFailed',
    [CheckInActions.checkInPageLoaded.type]: 'CheckInPageLoadedSuccess',
    [CheckInActions.passengerPageLoaded.type]: 'PassengerPageLoadedSuccess',
    [CheckInActions.baggagePageLoaded.type]: 'BaggagePageLoadedSuccess',
    [CheckInActions.baggageAdvisoryItemsPageLoaded.type]: 'BaggageAdvisoryItemsPageLoadedSuccess',
    [CheckInActions.infantPageLoaded.type]: 'InfantPageLoadedSuccess',
    [CheckInActions.resultPageLoaded.type]: 'ResultPageLoadedSuccess',
    [CheckInActions.dashboardPageLoaded.type]: 'DashboardPageLoadedSuccess',
    [CheckInActions.guidelinesPageLoaded.type]: 'GuidelinesPageLoadedSuccess',
    [CheckInActions.infantInfoPageLoaded.type]: 'InfantInfoPageLoadedSuccess',
    [CheckInActions.boardingPassPageLoaded.type]: 'BoardingPassPageLoadedSuccess',
    [CheckInActions.minorPageLoaded.type]: 'MinorPageLoadedSuccess',
    [CheckInActions.paymentPageLoaded.type]: 'PaymentPageLoadedSuccess',
    [CheckInActions.seatPageLoaded.type]: 'SeatPageLoadedSuccess',
    [CheckInActions.seatmapPageLoaded.type]: 'SeatmapPageLoadedSuccess',
    [CheckInActions.segmentSelectorPageLoaded.type]: 'SegmentSelectorPageLoadedSuccess',
    [CheckInActions.groupPassengersPageLoaded.type]: 'GroupPassengersPageLoadedSuccess',
    [CheckInActions.healthAttestationPageLoaded.type]: 'HealthAttestationPageLoadedSuccess',
    [CheckInActions.internationalTravelerSelectionPageLoaded.type]: 'InternationalTravelerSelectionPageLoadedSuccess',
    [CheckInActions.internationalTravelerInformationPageLoaded.type]: 'InternationalTravelerInformationPageLoadedSuccess',
    [CheckInActions.paymentInformationSubmitted.type]: 'paymentInformationSubmitted',
    [CheckInActions.getByPnrSuccess.type]: 'PnrSearchSuccessful',
    [CheckInActions.trackAEPOrderSuccess.type]: 'trackAEPOrderSuccess',
    [CheckInActions.postContactTracingInformationForAllPassengers.type]: 'postContactTracingInformationForAllPassengers',
  };

  censorPassengerName = { firstName: "", lastName: "" };

  // currently implemented: log events listed in loggedActions
  logEventAction(action: Action) {
    try {
      const eventName = this.loggedActions[action.type];
      if (!!eventName) {
        let eventData = null;

        if (!!this[eventName]) {
          const data = this[eventName](action);
          eventData = data ? data : action.type;
        }

        const actionClone: Action = JSON.parse(JSON.stringify(action));

        if (actionClone['trip']) {
          this.censorTripResponse(actionClone['trip']);
        }

        if (actionClone['passengerList']) {
          this.censorPassengerList(actionClone['passengerList']);
        }

        if (actionClone['authToken']) {
          this.censorAccessToken(actionClone['authToken']);
        }

        if (actionClone['data'] && actionClone['data'].body && actionClone['data'].body.payments) {
          this.censorPaymentRequest(actionClone['data'].body.payments);
        }

        this.logWithUiInfo({
          description: this.loggedActions[actionClone.type],
          action: eventData ? eventData : actionClone
        });
      }
    } catch (err) {
      console.error(err);
    }

    // For Adobe Analytics Tagging
    try {
      if (!!this.taggedActions[action.type]) {
        this.taggingService.trackEvent(CheckinTaggingDataService.mapActionToEvent(action.type), action['data']);
      }
    } catch (err) {
      console.error(err);
    }
  }

  censorTripResponse(trip: Response<Trip>) {
    try {
      if (trip && trip.results && trip.results[0]) {
        if (trip.results[0].passengers && trip.results[0].passengers.entries) {
          trip.results[0].passengers.entries = trip.results[0].passengers.entries.map(passenger => {
            passenger.passengerName = this.censorPassengerName;
            passenger.passport = { passengerName: this.censorPassengerName };
            passenger.dateOfBirth = new Date(0).toString();
            return passenger;
          });
        }

        if (trip.results[0].segments && trip.results[0].segments.entries) {
          trip.results[0].segments.entries.forEach(entry => {
            entry.details = entry.details.map(detail => {
              detail.passengerName = this.censorPassengerName;
              detail.lapInfant = { infantName: this.censorPassengerName, gender: "" };
              return detail;
            });
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  censorAccessToken(authToken: Response<AuthToken>) {
    try {
      if (authToken && authToken.results && authToken.results[0]) {
        authToken.results[0].accessToken = "";
      }
    } catch (err) {
      console.error(err);
    }
  }

  censorPassengerList(passengerList: PassengerList) {
    try {
      if (passengerList && passengerList.entries) {
        passengerList.entries = passengerList.entries.map(passenger => {
          passenger.passengerName = this.censorPassengerName;
          passenger.passport = { passengerName: this.censorPassengerName };
          passenger.dateOfBirth = new Date(0).toString();
          return passenger;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  censorPaymentRequest(paymentItems: PaymentItem[]) {
    try {
      paymentItems.map(paymentItem => {
        paymentItem.firstName = "";
        paymentItem.lastName = "";
        paymentItem.paymentToken = "",
          paymentItem.expirationDate = "";
      });
    } catch (err) {
      console.error(err);
    }
  }

  logWithUiInfo(custom: any) {
    try {
      custom = deepEncode(custom, restrictedProperties);

      const event = {
        componentId: "",
        logLevel: LogLevel.Info,
        ...custom

      } as LogMessage;

      if (!!this.appInsights && !!this.appInsights.config.instrumentationKey) {
        this.appInsights.trackEvent({
          name: custom.description,
          properties: {
            ...(custom.action && custom.action.values),
            prop1: custom,
            isInternational: this.tripDataSource.isInternational(),
            isOA: this.tripDataSource.isOA(),
            isGroupBooking: this.tripDataSource.isGroupBooking(),
            confirmationCode: this.tripDataSource.confirmationCode(),
            locale: this.localeService.locale,
          }
        });
        this.appInsights.flush();
      } else if (environment?.logging?.logToConsole && !environment['production']) {
        console.log(event);
      }
    } catch (err) {
      console.error(err);
    }
  }

  applicationInit() {
    this.logWithUiInfo({
      description: 'Application Initialized',
    });
  }

  getTripPassengersSuccessful(action) {
    try {
      const passengerInfo = action.passengerInfo.map(info => {
        return {
          passengerId: info.passengerInfo.id,
          requiredInfoSummary: info.requiredInfoSummary
        };
      });

      return {
        passengerInfo,
        correlationId: action.correlationId
      };
    } catch (err) {
      console.error(err);
    }
  }

  PassengersGetTripPassengersSuccessful(action) {
    return this.getTripPassengersSuccessful(action);
  }

  TravelerInfoGetPassengerSuccessful(action) {
    return this.getTripPassengersSuccessful(action);
  }

  GuidelinesGetTripPassengersSuccessful(action) {
    return this.getTripPassengersSuccessful(action);
  }

  DashboardGetTripPassengersSuccessful(action) {
    return this.getTripPassengersSuccessful(action);
  }

  InitiatePassportScanSuccessful(action) {
    try {
      return {
        message: action.type
      };
    } catch (err) {
      console.error(err);
    }
  }

  GetPassportScanDataSuccessful(action) {
    try {
      return {
        status: action.passportData.document.status,
        verification: action.passportData.verification,
        source: action.passportData.transaction.source,
        scanReference: action.passportData.scanReference
      };
    } catch (err) {
      console.error(err);
    }
  }

  RequestStarted(action) {
    try {
      return {
        correlationId: action.data.headers.get('x-root-correlation-id'),
        method: action.data.method,
        url: action.data.url,
      };
    } catch (err) {
      console.error(err);
    }
  }

  ResponseReceived(action) {
    try {
      return {
        correlationId: action.data.body && action.data.body.correlationId,
        status: action.data.status,
        url: action.data.url,
        error: action.data.error
      };
    } catch (err) {
      console.error(err);
    }
  }

  CheckinSuccessful(action) {
    try {
      return {
        ...action.data,
        values: {
          numberOfSelectedPax: this.sessionDataSource.numberOfSelectedPax()
        }
      };
    } catch (err) {
      console.error(err);
    }
  }

  CheckinSuccessfulAlreadyCheckedIn(action) {
    try {
      return {
        ...action,
        values: {
          numberOfSelectedPax: this.sessionDataSource.numberOfSelectedPax()
        }
      };
    } catch (err) {
      console.error(err);
    }
  }

  GetPassportScanDataFailed(action) {
    try {
      return {
        error: action && action.error
      };
    } catch (err) {
      console.error(err);
    }
  }

  ReferOutError(action) {
    try {
      return {
        values: { ...action.data }
      };
    } catch (err) {
      console.error(err);
    }
  }

  GroupPassengerNotFound(action) {
    try {
      return {
        values: { ...action }
      };
    } catch (err) {
      console.error(err);
    }
  }
}
