import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SessionState } from '../../reducers';
import { Store } from '@ngrx/store';
import { IAuthTokenState } from '../../reducers/auth-token/auth-token.reducers';

const getSessionState = createFeatureSelector<SessionState>('sessionCache');

export const authTokenState = createSelector(
    getSessionState,
    (state: SessionState) => state.authToken
);

export const getAuthToken = createSelector(
    authTokenState,
    (state: IAuthTokenState) => state.accessToken
);

export const authTokenLoading = createSelector(
    authTokenState,
    (state: IAuthTokenState) => state.loading
);

export const authTokenError = createSelector(
    authTokenState,
    (state: IAuthTokenState) => state.error
);


@Injectable()
export class AuthTokenSelectors {
    constructor(private store: Store<SessionState>) {}

    authTokenState$ = this.store.select(authTokenState);
    authToken$ = this.store.select(getAuthToken);
    authTokenLoading$ = this.store.select(authTokenLoading);
    authTokenError$ = this.store.select(authTokenError);
}
