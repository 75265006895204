<app-checkin-header [heading]="rendering?.props?.heading" [description]="rendering?.props?.description" [hideHeaderInfo]="loading$ | async">
</app-checkin-header>
<div *ngIf="loading$ | async; else emergencyContactInfo">
  <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #emergencyContactInfo>
  <div class="container text-left">
    <mat-card *ngFor='let pax of passengers; let first = first; let i = index'>
      <h6 class="col medium d-flex gx-0">
        <div class="d-flex flex-column flex-lg-row">
          <div>{{rendering.props['contactInformationTitle']}} </div>
          <div class="name-pad">
            {{pax?.passengerName?.firstName | titlecase}} {{pax?.passengerName?.lastName | titlecase}}
          </div>
        </div>
      </h6>
      <div *ngIf="!first" class="col d-flex pt-5 pt-sm-2">
        <mat-checkbox class="aloha-pink checkbox-box" id="emergency-contact-checkbox-{{i}}" name="emergency-contact-{{i}}"
                      [checked]="pax?.useFirstPaxInfo"
                      (change)="pax.useFirstPaxInfo = !pax?.useFirstPaxInfo; pax?.contactInfoForm.reset()">
        </mat-checkbox>
        <div class="body-copy pl-3" *haText="sameContactString"></div>
      </div>
      <div *ngIf="!pax.useFirstPaxInfo">
        <app-contact-info-form [rendering]="rendering.components?.contactInfoForm?.[0]"
                               [contactInfoForm]="pax?.contactInfoForm" [countriesList]="countriesList">
        </app-contact-info-form>
      </div>
    </mat-card>
  </div>
  <app-checkin-footer [navButtons]="rendering?.props['navButtons']"
                      [disableDefaultNavButton]="!areAllEmergencyContactInfoFormsValid"
                      (defaultNavButtonClicked)="submitEmergencyContacts()">
  </app-checkin-footer>
</ng-template>
