<div id="bags-price-confirmation-modal">
  <h6 class="bold medium" mat-dialog-title *haText="rendering?.props['bagsPricesDetailsTitle']"></h6>
  <mat-dialog-content>
    <app-payment-cart [route]='baggageRoute'></app-payment-cart>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="bagsTotal" class="d-flex justify-content-between">
    <span id="total-bags-fee-text" class="subtitle-bold" *haText="rendering?.props['totalBagFeesHeader']"></span>
    <span id="total-bags-amount" class="subtitle-bold">{{ bagsTotal | customCurrency }}</span>
  </mat-dialog-content>
  <mat-dialog-content class="my-5 body-copy" id="bag-disclaimer-text">
    <span *haText="rendering.props['bagsDisclaimer']"></span>
  </mat-dialog-content>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <button id="bags-price-undo-bags-confirmation" mat-button [mat-dialog-close]="true" class="btn btn-secondary"
          (click)="cancelBagsPriceConfirmation()" *haText="rendering?.props['btnUndo']"></button>
      </div>
      <div class="col-sm-6">
        <button id="bags-price-confirmation-button" mat-button cdkFocusInitial class="btn btn-default"
          (click)="confirmBagsPrices()" *haText="rendering?.props['btnContinue']"></button>
      </div>
    </div>
  </div>
</div>
