<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(militaryLoading$ | async)">
    <div id="military-save-type-spinner" class="spinner-border" role="status">
    </div>
</div>
<h6 id="save-changes-title" class="bold medium" mat-dialog-title>
    <span>{{ title }}</span>
</h6>
<mat-dialog-content id="save-changes-content">
    <div>
        <span id="description" class="body-copy">{{ description }} </span>
    </div>
    <div *ngFor="let pax of paxData; let paxIndex = index" class="passenger-names"> 
            <div class="body-copy-bold d-flex justify-content-center">
                <div *ngIf="pax.militaryTravelType === militaryTravelType.ORDERS || pax.militaryTravelType === militaryTravelType.LEISURE">
                    <span id="passenger-first-name-{{ paxIndex }}">{{ pax.passengerName.firstName | titlecase}}</span>
                    <span id="passenger-last-name-{{ paxIndex}}" class="pl-2">{{ pax.passengerName.lastName | titlecase}}</span>
                    <span> - </span>
                </div>
              <ng-container [ngSwitch]="pax.militaryTravelType">
                  <div *ngSwitchCase="militaryTravelType.ORDERS">
                    <span id="passenger-military-type-orders-{{ paxIndex }}">{{ordersTypeDescription}}</span>
                  </div>
                  <div *ngSwitchCase="militaryTravelType.LEISURE">
                    <span id="passenger-military-type-leisure-{{ paxIndex }}">{{leisureTypeDescription}}</span>
                  </div>
                  <div *ngSwitchDefault></div>
              </ng-container>
            </div>
    </div>
    <div class="id-description">
        <span id="id-required-description" class="body-copy">{{ validIdDescription }}</span>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
    <button id="save-changes-no-button" mat-button [mat-dialog-close]="true" class="btn btn-secondary">
      <span>{{ no }}</span>
    </button>
    <button id="save-changes-yes-button" mat-button class="btn btn-default" (click)="clickYes()" cdkFocusInitial>
        <span>{{ yes }}</span>
    </button>
</mat-dialog-actions>