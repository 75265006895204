import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, switchMap, catchError, withLatestFrom } from 'rxjs/operators';
import * as PassportScanActions from '~app/store/actions/passport-scan.actions';
import { PassportScanService } from '~app/store/services/passport-scan/passport-scan.service';
import { ModalsService } from '~app/modals/modals.service';
import { of } from 'rxjs';
import { ALERTS } from '~app/constants/ha-constants';
import { select, Store } from '@ngrx/store';
import { SessionState } from '~app/store';
import * as fromSession from '~app/store/services/session/session.selectors'
import { ISessionState } from '~app/store/reducers/session/session.reducers';
import * as fromInternationalDetails from '~app/store/services/international-details/international-details.selectors';

@Injectable()
export class PassportScanEffects {
  constructor(
    private actions$: Actions,
    private passportScanService: PassportScanService,
    private modalsService: ModalsService,
    private store: Store<SessionState>,
  ) { }

  initiatePassportScan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.initiatePassportScan),
      switchMap(({ paxId }) =>
        this.passportScanService.initiate(paxId).pipe(
          map(response => PassportScanActions.initiatePassportScanSuccess({
            scanReference: response.results[0].transactionReference,
            redirectUrl: response.results[0].redirectUrl
          })),
          catchError(error => of(PassportScanActions.initiatePassportScanError(error)))
        )
      )
    )
  );

  initiatePassportScanError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.initiatePassportScanError),
      tap(_ => {
        this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
      })
    ), { dispatch: false }
  );

  getPassportScanStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.getPassportScanStatus),
      switchMap(action =>
        this.passportScanService.getPassportScanStatus(action.scanReference).pipe(
          map(response => PassportScanActions.getPassportScanData({ scanReference: response.results[0].scanReference })),
          catchError(error => of(PassportScanActions.getPassportScanDataError(error)))
        )
      )
    )
  );

  getPassportScanData$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PassportScanActions.getPassportScanData),
    withLatestFrom(
      this.store.pipe(select(fromSession.getSessionState)),
      this.store.pipe(select(fromInternationalDetails.getPassportPaxId))),
    switchMap(([action, sessionState, paxId]) =>
      (action?.passport ? this.passportScanService.getMockScanData(action.passport.passportDocument)
        : this.passportScanService.getScanData(action.scanReference)
      ).pipe(
        map(passportData =>
          PassportScanActions.getPassportScanDataSuccess({
            passportData: passportData.results[0],
            passengerSegmentIds: this.getPassengerSegmentIds(sessionState, paxId),
          })
        ),
        catchError(error => of(PassportScanActions.getPassportScanDataError(error)))
      )
    )
  )
);

  getPassportScanDataError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.getPassportScanDataError),
      tap(error => {
        if (error.error === 'wrong pax passport') {
          this.modalsService.openPassportMatchErrorModal(error);
        } else if (error.error === 'scan status not done') {
          this.modalsService.openRescanPassportModal({ error: ALERTS.passportScanError });
        } else {
          // cases: max jumio attempts, unapproved passport, no data, etc.
          this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true);
        }
      }),
      map(_ => PassportScanActions.postPassportScanAttempt()),
    )
  );

  getPassportScanAttempts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.getPassportScanAttempts),
      switchMap(_ =>
        this.passportScanService.getScanAttempts().pipe(
          map(res => PassportScanActions.getPassportScanAttemptsSuccess({ scanAttempts: res })),
          catchError(error => of(PassportScanActions.getPassportScanAttemptsError(error)))
        )
      )
    )
  );

  postPassportScanAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PassportScanActions.postPassportScanAttempt),
      switchMap(_ =>
        this.passportScanService.postScanAttempt().pipe(
          map(res => PassportScanActions.postPassportScanAttemptSuccess({ scanAttempts: res })),
          catchError(error => of(PassportScanActions.postPassportScanAttemptError(error)))
        )
      )
    )
  );

  passportScanAttemptError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        PassportScanActions.getPassportScanAttemptsError,
        PassportScanActions.postPassportScanAttemptError
      ),
      tap(_ => this.modalsService.openGenericErrorMessage({ error: ALERTS.referOutToKiosk }, true))
    ), { dispatch: false }
  );

  private getPassengerSegmentIds(sessionState: ISessionState, paxId: string): string[] {
    const associatedPaxIDWithInfant = sessionState?.selectedPassengers?.find(pax => pax?.lapInfant?.id === paxId)?.id;
    const matchedPaxDetails = sessionState?.selectedSegment?.details?.find(pax => 
      pax?.passengerId === (associatedPaxIDWithInfant ?? paxId)
    );
    return matchedPaxDetails?.flightDetails?.map(paxDetail => 
      associatedPaxIDWithInfant ? paxDetail?.lapInfantPassengerSegmentId : paxDetail?.passengerSegmentId
    );
  }

}
