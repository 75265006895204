import { SeatUpgradeComponent } from './seat-upgrade/seat-upgrade.component';
import { SeatUpgradeAppBannerComponent } from './seat-upgrade-app-banner/seat-upgrade-app-banner.component';
import { SeatUpgradePassengerListComponent } from './seat-upgrade-passenger-list/seat-upgrade-passenger-list.component';
import { SeatUpgradePassengerCardComponent } from './seat-upgrade-passenger-card/seat-upgrade-passenger-card.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { BasicSeatMapModule } from '@hawaiianair/basic-seat-map'
import { ExitRowModalComponent } from './exit-row-modal/exit-row-modal.component';
import { SeatChangeConfirmationComponent } from './seat-change-confirmation/seat-change-confirmation';
import { SeatMapComponent } from './seat-map/seat-map.component';
import { SeatUpsellGridComponent } from './seat-upsell-grid/seat-upsell-grid.component';
import { SeatUpsellComponent } from './seat-upsell/seat-upsell.component';
import { SeatmapHeaderComponent } from './seatmap-header/seatmap-header.component';
import { SeatmapLegendComponent } from './seatmap-legend/seatmap-legend.component';
import { NgModule } from '@angular/core';
import { CoreModule, HaCurrencyPipe } from '@hawaiianair/core';
import { SeatsComponent } from './seats/seats.component';
import { SeatToutComponent } from './seat-tout/seat-tout.component';

@NgModule({
    imports: [
        AppSharedModule,
        // for Material Design
        // import Material Design Components
        MatCheckboxModule,
        MatButtonModule,
        MatRadioModule,
        MatIconModule,
        BasicSeatMapModule,
        CoreModule.forChild({
            components: [
                ExitRowModalComponent,
                SeatChangeConfirmationComponent,
                SeatMapComponent,
                SeatsComponent,
                SeatUpsellComponent,
                SeatUpsellGridComponent,
                SeatmapHeaderComponent,
                SeatmapLegendComponent,
                SeatUpgradeComponent,
                SeatUpgradeAppBannerComponent,
                SeatUpgradePassengerListComponent,
                SeatUpgradePassengerCardComponent,
                SeatToutComponent,
            ]
        }),
    ],
    exports: [
        // export Material Design components
        MatButtonModule,
        MatIconModule,
        RouterModule
    ],
    declarations: [
        ExitRowModalComponent,
        SeatChangeConfirmationComponent,
        SeatMapComponent,
        SeatsComponent,
        SeatUpsellComponent,
        SeatUpsellGridComponent,
        SeatmapHeaderComponent,
        SeatmapLegendComponent,
        SeatUpgradeComponent,
        SeatUpgradeAppBannerComponent,
        SeatUpgradePassengerListComponent,
        SeatUpgradePassengerCardComponent,
        SeatToutComponent,
    ],
    providers: [
      HaCurrencyPipe,
    ]
})
export class SeatModule { }
