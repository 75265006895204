import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { RouterDispatchers } from '../../store/services/router/router.dispatchers';
import { ModalContentsService } from '../modal-contents.service';

@Component({
  selector: 'app-minor-traveling-alone-modal',
  templateUrl: './minor-traveling-alone-modal.component.html',
  styleUrls: ['./minor-traveling-alone-modal.component.scss']
})
@ContentSchema({
  description: 'Minor Traveling Alone Modal Component',
  name: 'MinorTravelingAloneModal',
  props: [
    { name: 'minorTravelingAloneHeading', type: PropertyType.Text },
    { name: 'minorTravelingAloneDescription', type: PropertyType.Text },
  ]
})
export class MinorTravelingAloneModalComponent implements OnInit {
  rendering: ComponentRendering;
  
  constructor(
    public dialogRef: MatDialogRef<MinorTravelingAloneModalComponent>,
    private modalContentsSvc: ModalContentsService,
    private routerDispatchers: RouterDispatchers,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('minor-traveling-alone-modal');
  }

  clickOkayButton() {
    this.dialogRef.close();
    this.routerDispatchers.routeToCheckin(CHECKIN_ROUTES.ROUTE_PASSENGER.component);
  }
}
