import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentSchema, ComponentRendering } from '@hawaiianair/core';
import { Flight } from '~app/models/flight.model';
import { Observable, Subscription } from 'rxjs';
import { FlightSelectors} from '~app/store';
import { AirportMap } from '~app/models/airport.model';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { DynamicContentService } from '~app/services/dynamic-content.service';


@Component({
  selector: 'app-flight-information',
  templateUrl: './flight-information.component.html',
  styleUrls: [ './flight-information.component.scss' ],
})
@ContentSchema({
  name: 'FlightInformation',
  description: 'Flight Information Component',
})
export class FlightInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  allFlights$: Observable<Flight[]>;
  airports$: Observable<AirportMap>;
  subscriptions: Subscription = new Subscription();
  flightCounter: Array<string>;

  constructor(
    private flightSelectors: FlightSelectors,
    private airportSelectors: ReferenceDataSelectors,
    private modalContentsSvc: ModalContentsService,
    private dynamicContentService: DynamicContentService
  ) {
    this.allFlights$ = this.flightSelectors.allFlights$;
    this.airports$ = this.airportSelectors.airports$;
  }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('flight-status')  || [{}];
    this.subscriptions.add(this.allFlights$.subscribe(allFlights => {
      if (allFlights) {
        this.flightCounter = allFlights.map((_, index) =>
          this.dynamicContentService.getContentString(
            this.rendering.props['flightCounter'],
            { flightIndex: index + 1, totalFlights: allFlights.length }
          )
        );
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
