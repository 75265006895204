<div class="spinner-wrapper background-white" [hidden]="!!passportScanSetup?.redirectUrl">
  <div id="scan-passport-spinner" class="spinner-border" role="status"></div>
</div>
<div *ngIf="!!passportScanSetup?.redirectUrl">
  <button mat-dialog-close id="passport-scan-close" class="close-button btn btn-secondary px-4">
    <span class="ha-icon icon-16 icon-close mr-2 align-middle"></span>
    {{ 'Close' | translate }}
  </button>
  <div class="container px-0 px-md-4">
    <iframe id="passport-scan-iframe" [src]="iframeSrc" width="100%" allow="camera" style="border: none; overflow: hidden; height: calc(100vh - 103px)"></iframe>
  </div>
</div>