<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']">
</app-checkin-header>
<div class="container px-0 px-md-4">
    <div *ngFor="let pax of passengersWithoutInfant; let idx = index">
        <mat-card id="add-infant-{{idx}}" class="d-flex add-infant-card" tabindex="0" (click)="goToInfantInfo(pax.id)"
            (keyup.space)="goToInfantInfo(pax.id)" (keyup.enter)="goToInfantInfo(pax.id)" role="button" cdkFocusInitial>
            <span class="subtitle-bold text-left mr-auto">
                {{ pax.passengerName.firstName | titlecase }} {{ pax.passengerName.lastName | titlecase }}
            </span>
            <i id="circle-icon-{{idx}}" class="p-0 icon-circleplus icon-desc"></i>
        </mat-card>
    </div>
</div>
<app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>