import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { SessionData } from '~app/models/sessiondata.model';
import { Observable, Subscription } from 'rxjs';
import { SessionSelectors, TripSelectors, CartSelectors, SecurityInfoSelectors, CatalogSelectors, AnalyticsDispatchers } from '~app/store';
import { ModalContentsService } from '~app/modals/modal-contents.service';
@Component({
  selector: 'app-guidelines-page',
  templateUrl: './guidelines-page.component.html',
  styleUrls: ['./guidelines-page.component.scss'],
})

@ContentSchema({
  description: 'GuidelinesPageComponent',
  name: 'GuidelinesPage',
  props: [
    { name: 'guidelinesHeader', type: PropertyType.Text },
    { name: 'prohibitedHazardousMaterialsHeader', type: PropertyType.Text },
    { name: 'prohibitedHazardousMaterialsBody', type: PropertyType.Text },
    { name: 'hazardousMaterialsIncludeHeader', type: PropertyType.Text },
    { name: 'hazardousMaterialsList', type: PropertyType.Object },
    { name: 'safetyRestrictionsText', type: PropertyType.Text },
    { name: 'restrictedItems', type: PropertyType.Object },
    { name: 'fragileItemsText', type: PropertyType.Text },
    { name: 'fragileItemsExampleTitle', type: PropertyType.Text },
    { name: 'fragileItemsExampleList', type: PropertyType.Object },
    { name: 'fragileItemsAndRestrictionsDescription', type: PropertyType.RichText },
    { name: 'carryOnThreeOneOneTitle', type: PropertyType.Text },
    { name: 'carryOnThreeOneOneDescription', type: PropertyType.Text },
    { name: 'carryOnThreeOneOneItemList', type: PropertyType.Object },
    { name: 'carryOnThreeOneOneDescription', type: PropertyType.Text },
    { name: 'carryOnItemsAdvisoryDescription', type: PropertyType.Text },
    { name: 'carryOnItemsAdvisoryExampleList', type: PropertyType.Object },
    { name: 'navButtons', type: PropertyType.Object },
  ]
})

export class GuidelinesPageComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  sessionData: SessionData;
  sessionData$: Observable<SessionData>;
  subscriptions: Subscription = new Subscription();
  tripLoading$: Observable<boolean>;
  cartLoading$: Observable<boolean>;
  catalogLoading$: Observable<boolean>;
  securityInfoLoading$: Observable<boolean>;

  constructor(
    private sessionSelectors: SessionSelectors,
    private modalContentsService: ModalContentsService,
    private tripSelectors: TripSelectors,
    private cartSelectors: CartSelectors,
    private securityInfoSelectors: SecurityInfoSelectors,
    private catalogSelectors: CatalogSelectors,
    private analyticsDispatcher: AnalyticsDispatchers,
  ) {
    this.sessionData$ = this.sessionSelectors.session$;
    this.tripLoading$ = this.tripSelectors.tripLoading$;
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.catalogLoading$ = this.catalogSelectors.catalogLoading$;
    this.securityInfoLoading$ = this.securityInfoSelectors.securityInfoLoading$;
  }

  ngOnInit() {
    this.modalContentsService.addRendering('partial-cart-modal', this.rendering.components['partial-cart-modal']);
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.sessionData$
      .subscribe(session => {
        if (session) {
          this.sessionData = session;
        }
      }
      ));
    this.analyticsDispatcher.guidelinesPageLoaded();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
