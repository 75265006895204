<app-checkin-header [heading]="rendering.props['guidelinesHeader']"
    [hideHeaderInfo]="(tripLoading$ | async) || (cartLoading$ | async) || (securityInfoLoading$ | async) || (catalogLoading$ | async)">
</app-checkin-header>
<div *ngIf="(tripLoading$ | async) || (catalogLoading$ | async)
|| (cartLoading$ | async) || (securityInfoLoading$ | async); else guidelines">
    <app-checkin-loader></app-checkin-loader>
</div>
<ng-template #guidelines class="container">
    <div class="class container background text-left">
        <div class="row">
            <div class="col-md-12 body-copy-bold">
                <span id="prohibited-hazardous-materials-header"
                    *haText="rendering.props['prohibitedHazardousMaterialsHeader']"></span>
            </div>
            <div class="col-md-12 body-copy">
                <span id="prohibited-hazardous-materials-body"
                    *haText="rendering.props['prohibitedHazardousMaterialsBody']"></span>
            </div>
            <div class="col-md-12 body-copy-bold mt-4">
                <span id="hazardous-materials-include-header"
                    *haText="rendering.props['hazardousMaterialsIncludeHeader']"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 d-flex align-items-center py-3 py-md-0 hazmat-listitem"
                *ngFor="let hazardousMaterialItem of rendering?.props['hazardousMaterialsList']; let idx = index">
                <div id="hazardous-material-item-icon-{{idx}}" class="mr-4 icon-64 st-icon {{ hazardousMaterialItem.icon }}"></div>
                <div class="d-flex flex-column">
                    <span id="hazardous-material-item-title-{{idx}}" class="body-copy-bold">{{ hazardousMaterialItem.title }}</span>
                    <span id="hazardous-material-item-text-{{idx}}" class="foot-note">{{ hazardousMaterialItem.text }}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 body-copy-bold py-5">
                <span id="safety-restrictions-text" *haText="rendering.props['safetyRestrictionsText']"></span>
            </div>
        </div>
        <div class="row">
            <div id="restrictedItems-examples-{{idx}}" class="col-md-3 d-flex flex-md-column text-left text-md-center align-items-center py-3 py-md-0"
                *ngFor="let restrictedItem of rendering?.props['restrictedItems']; let idx = index">
                <div>
                    <div class="restrictIcon mr-4 mr-md-0 icon-64 st-icon {{ restrictedItem.icon }}"></div>
                </div>
                <div class="d-flex flex-column">
                    <span class="body-copy-bold">{{ restrictedItem.title }}</span>
                    <span class="foot-note">{{ restrictedItem.text }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 body-copy py-5">
                <span id="fragile-items-text" *haText="rendering.props['fragileItemsText']"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 body-copy-bold">
                <span *haText="rendering.props['fragileItemsExampleTitle']"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 d-flex body-copy fragile-items-list"
                *ngFor="let fragileItemExample of rendering?.props['fragileItemsExampleList']; let idx = index">
                <ul>
                    <li id="fragile-item-example-{{idx}}">{{ fragileItemExample.description }}</li>
                </ul>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-12 body-copy">
                <span id="fragile-items-and-restrictions-description"
                    *haRichText="rendering.props['fragileItemsAndRestrictionsDescription']"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 body-copy-bold">
                <span id="carry-on-three-one-one-title" *haText="rendering.props['carryOnThreeOneOneTitle']"></span>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-md-4 d-flex align-items-center"
                *ngFor="let carryOnThreeOneOneItem of rendering?.props['carryOnThreeOneOneItemList']; let idx = index">
                <span id="carry-on-three-one-one-item-number-{{idx}}"
                    class="largeOunceNumber body-copy-bold mr-4">{{ carryOnThreeOneOneItem.number }}</span>
                <div id="carry-on-three-one-one-item-icon-{{idx}}" class="icon-64 {{ carryOnThreeOneOneItem.icon }} mr-4"></div>
                <span id="carry-on-three-one-one-item-text-{{idx}}"
                    class="foot-note">{{ carryOnThreeOneOneItem.text }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <span id="carry-on-three-one-one-description"
                    *haText="rendering.props['carryOnThreeOneOneDescription']">
                </span>
            </div>
        </div>
        <hr class="my-5">
        <div class="row mb-5">
            <div class="col-md-12">
                <span id="carry-on-items-advisory-description"
                    *haText="rendering.props['carryOnItemsAdvisoryDescription']">
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex align-items-center py-3 py-md-0"
                *ngFor="let carryOnAdvisoryExampleItem of rendering?.props['carryOnItemsAdvisoryExampleList']; let idx = index">
                <div class="mr-4">
                    <div id="carry-on-advisory-example-item-icon-{{ idx }}"
                        class="icon-64 {{ carryOnAdvisoryExampleItem.icon }}"></div>
                </div>
                <div class="d-flex flex-column">
                    <span id="carry-on-advisory-example-item-title-{{ idx }}"
                        class="body-copy-bold">{{ carryOnAdvisoryExampleItem.title }}</span>
                    <span id="carry-on-advisory-example-item-text-{{ idx }}"
                        class="foot-note"*haRichText="carryOnAdvisoryExampleItem.text"></span>
                </div>
            </div>
        </div>
    </div>
    <app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
</ng-template>
