import { Injectable } from "@angular/core";
import { Actions, createEffect } from '@ngrx/effects';
import { CheckInAnalyticsService } from '~app/services/logging/checkin-analytics.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class AnalyticsEffects {
  logAnalytics$ = createEffect(() =>
    this.actions$.pipe(
      tap(action => {
        this.checkInAnalyticsService.logEventAction(action);
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private checkInAnalyticsService: CheckInAnalyticsService
  ) { }
}

