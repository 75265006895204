import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { FlightService } from '~app/store/services/flight/flight.service';
import * as FlightActions from '~app/store/actions';
import { LogService } from '~app/services/log-service';
import { FLIGHT_STATUS_DELAYED } from '~app/models/status.model';
import { ModalsService } from '~app/modals/modals.service';
import { select, Store } from '@ngrx/store';
import { TripState } from '~app/store/reducers/trip/trip.reducers';
import * as fromSession from '~app/store/services/session/session.selectors';
import * as fromReferenceData from '~app/store/services/reference-data/reference-data.selectors';
import { HelperService } from '~app/services/helper.service';
import { Flight } from "~app/models/flight.model";


@Injectable()
export class FlightEffects {
  getFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightActions.seatMapModalGetFlightById, FlightActions.itineraryDetailGetFlightById),
      concatMap(action =>
        this.flightService.getFlightsById(action.flightId).pipe(
          tap(flight => {
            if (!flight || !flight.results || !flight.results.length) {
              this.logService.error("Flight has no data");
              throw { error: 'no data', requestData: flight };
            }
            if ((action.type === FlightActions.itineraryDetailGetFlightById.type) && flight.results[0].status.flightStatus &&
              flight.results[0].status.flightStatus.toLowerCase() === FLIGHT_STATUS_DELAYED.toLowerCase()) {
              this.modalsService.openFlightDelayed(
                flight.results[0].origin,
                flight.results[0].scheduledDestination,
                !!flight.results[0].status.boardingStartTime && !!flight.results[0].status.boardingStartTime.airportDateTimeString ?
                  new Date(flight.results[0].status.boardingStartTime.airportDateTimeString) : null,
              );
              throw { error: 'flight delayed', requestData: flight };
            } else if (action.type === FlightActions.seatMapModalGetFlightById.type) {
              if (!flight.results[0].seatMapCode) {
                throw { error: `Seatmap Code is undefined on getFlightsById with ${action.flightId}` };
              }
            }
          }),
          map(flight => FlightActions.getFlightSuccess({ flight, flightIdx: action.flightIdx })),
          catchError(error => {
            if (error.error === 'flight delayed') {
              return of(FlightActions.getFlightDelayedSuccess({ flight: error.requestData, flightIdx: action.flightIdx }));
            } else {
              return of(FlightActions.getFlightError(error));
            }
          }))
      )
    )
  );

  getAllFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightActions.boardingPassGetAllFlights, FlightActions.passengerGetAllFlights, FlightActions.seatsGetAllFlights),
      withLatestFrom(this.store.pipe(select(fromSession.sessionSelectedSegmentFlights))),
      concatMap(([action, segmentFlights]) =>
        this.flightService.getAllFlights(segmentFlights).pipe(
          withLatestFrom(
            this.store.pipe(select(fromSession.getSessionFirstFlightId)),
            this.store.pipe(select(fromReferenceData.getAirportMap))),
          concatMap(([allFlights, firstFlightId, airports]) => {
            const actions = [];
            const flights = allFlights.map(flight => ({
              ...flight.results[0]
            }));
            const allFlightsAdditionalInformation = flights.map((flight, index, arrayFlights) => {
              const originAirportData = airports[flight.origin];
              const destinationAirportData = airports[flight.scheduledDestination];
              return {
                ...flight,
                originLongName: originAirportData && originAirportData.cityNm,
                destinationLongName: destinationAirportData && destinationAirportData.cityNm,
                layoverTime: (arrayFlights.length > 1 && arrayFlights.indexOf(flight) !== arrayFlights.length - 1)
                  ? this.helperService.getLayoverTime(
                    flight.scheduledArrival.airportDateTimeString,
                    arrayFlights[index + 1].scheduledDeparture.airportDateTimeString) : false,
                travelDays: this.helperService.getDateDifferenceInDays(
                  flight.scheduledArrival.airportDateTimeString,
                  flight.scheduledDeparture.airportDateTimeString
                )
              };
            });

            const firstFlight = allFlights.find(flight => flight.results[0].id === firstFlightId);
            actions.push(FlightActions.getAllFlightsSuccess({ allFlights: allFlightsAdditionalInformation, firstFlight }));
            if (action.type === FlightActions.passengerGetAllFlights.type) {
              actions.push(FlightActions.getDelayedFlights({ allFlightsAdditionalInformation: allFlightsAdditionalInformation }));
            }
            return actions;
          }),
          catchError(error => {
            return of(FlightActions.getFlightError(error));
          })
        )
      )
    )
  );

  showDelayedFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightActions.getDelayedFlights),
      tap(action => {
        const delayedFlights: Flight[] = action.allFlightsAdditionalInformation?.filter(flight => flight?.status?.flightStatus === FLIGHT_STATUS_DELAYED) || [];
        if (delayedFlights.length) {
          this.modalsService.openFlightDelayed(
            delayedFlights[0].origin,
            delayedFlights[0].scheduledDestination,
            !!delayedFlights[0].status.departure && !!delayedFlights[0].status.departure.airportDateTimeString ?
              new Date(delayedFlights[0].status.departure.airportDateTimeString) : null,
          );
        }
      })
    ), { dispatch: false }
  );

  getFlightError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FlightActions.getFlightError),
      tap()
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private flightService: FlightService,
    private logService: LogService,
    private store: Store<TripState>,
    private helperService: HelperService,
    private modalsService: ModalsService,
  ) { }

}
