import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntityState } from '../../reducers';
import { Store } from '@ngrx/store';
import { AircraftImageState } from '~app/store/reducers/aircraft-image/aircraft-image.reducers';

const getEntityState = createFeatureSelector<EntityState>('entityCache');

export const getAircraftImageState = createSelector(
    getEntityState,
    (state: EntityState) => state.aircraftImage
);

export const getAircraftImageLoading = createSelector(
    getAircraftImageState,
    (aircraftState: AircraftImageState) => aircraftState.loading
);

export const getAircraftImageSeatList = createSelector(
  getAircraftImageState,
  (aircraftState: AircraftImageState) => aircraftState.aircraftSeatList
);

@Injectable()
export class AircraftImageSelectors {
    constructor(private store: Store<EntityState>) {}

    aircraftImageState$ = this.store.select(getAircraftImageState);
    aircraftImageLoading$ = this.store.select(getAircraftImageLoading);
    aircraftImageSeatList$ = this.store.select(getAircraftImageSeatList);
}
