import { Action, createReducer, on } from '@ngrx/store';
import * as AuthTokenActions from '../../actions/auth-token.actions';
import * as SessionActions from '../../actions/session.actions';

export interface IAuthTokenState {
  accessToken: string;
  clientID: string;
  expiration: string;
  expiresIn: string;
  tokenType: string;
  loading: boolean;
  error: boolean;
  expired: boolean;
}

export const initialAuthTokenState: IAuthTokenState = {
  accessToken: null,
  clientID: null,
  expiration: null,
  expiresIn: null,
  tokenType: null,
  loading: false,
  error: false,
  expired: true
};

export const authTokenReducer = createReducer(
  initialAuthTokenState,
  // get auth token
  on(AuthTokenActions.checkinGetAuthToken, (state) => ({...state, authToken: null, loading: true, error: false})),
  on(AuthTokenActions.checkinGetAuthTokenError, (state) => ({...state, loading: false, error: true})),
  on(AuthTokenActions.checkinGetAuthTokenSuccess, (state, {authToken}) => ({
    ...state,
    ...authToken.results[0],
    loading: false,
    error: false,
    expired: false,
  })),
  on(AuthTokenActions.authTokenExpirationModalGetAuthToken, (state) => ({...state, authToken: null, loading: true, error: false})),
  on(AuthTokenActions.authTokenExpirationGetAuthTokenError, (state) => ({...state, loading: false, error: true})),
  on(AuthTokenActions.authTokenExpirationGetAuthTokenSuccess, (state, {authToken}) => ({
    ...state,
    ...authToken.results[0],
    loading: false,
    error: false,
    expired: false,
    expiresIn: authToken.results[0].expiration
  })),
  on(AuthTokenActions.authTokenExpired, _ => ({
    ...initialAuthTokenState
  })),
  on(SessionActions.clearSessionDataCheckinStart, _ => ({
    ...initialAuthTokenState
  }))
);

export function reducer(state: IAuthTokenState | undefined, action: Action) {
    return authTokenReducer(state, action);
}
