import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { ModalContentsService } from '~app/modals/modal-contents.service';

@Component({
  selector: 'app-upgrade-all-pax-modal',
  templateUrl: './upgrade-all-pax-modal.component.html',
  styleUrls: [ './upgrade-all-pax-modal.component.scss' ],
})
@ContentSchema({
  name: 'UpgradeAllPaxModal',
  description: 'UpgradeAllPaxModalComponent',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'content', type: PropertyType.Text },
    { name: 'btnOk', type: PropertyType.Text },
  ]
})
export class UpgradeAllPaxModalComponent implements OnInit {
  rendering: ComponentRendering;
  header: string;
  content: string;
  btnOk: string;

  constructor(
    public dialogRef: MatDialogRef<UpgradeAllPaxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ComponentRendering,
    private modalContentsSvc: ModalContentsService
  ) {}

  ngOnInit() {
    this.rendering = {};
    this.rendering.props = this.modalContentsSvc.getRendering('upgrade-all-pax-modal');

    this.header = this.rendering.props['header'];
    this.content = this.rendering.props['content'];
    this.btnOk = this.rendering.props['btnOk'];
  }
}
