<h6 id="attention-title" class="bold medium" mat-dialog-title>
    <span>{{ dialogTitle }}</span>
</h6>
<mat-dialog-content class="dlg-content mat-typography">
    <span id="despcription" class="body-copy">
        {{ message }}
    </span>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button id="okay-button" mat-button [mat-dialog-close]="true" class="btn btn-default" (click)="goToNextPage()">
        {{ 'Ok' | translate }}
    </button>
</mat-dialog-actions>