import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as AircraftImageAction from '../../actions';
import { EntityState } from '../../reducers';

@Injectable()
export class AircraftImageDispatchers {
    constructor(private store: Store<EntityState>) {}

    getAircraftImage(flightIndex: number) {
        this.store.dispatch(AircraftImageAction.getAircraftImage({flightIndex: flightIndex}));
    }

    resetAircraftImageState() {
        this.store.dispatch(AircraftImageAction.resetAircraftImageState());
    }
}
