import { LoadingAnimationService, CoreModule} from '@hawaiianair/core';
import { A11yModule } from '@angular/cdk/a11y';
import { CheckinHeaderComponent } from './checkin-header/checkin-header.component';
import { CheckinLoaderComponent } from './checkin-loader/checkin-loader.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckinCartComponent } from './cart/checkin-cart/checkin-cart.component';
import { PaxCartComponent } from './cart/pax-cart/pax-cart.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { CheckinPageInfoComponent } from './checkin-page-info/checkin-page-info.component';
import { PaymentCartComponent } from './cart/payment-cart/payment-cart.component';
import { FlightStatusComponent } from './flight-status/flight-status.component';
import { FlightInformationComponent } from './flight-status/flight-information/flight-information.component';
import { UpgradeAllPaxModalComponent } from './upgrade-all-pax-modal/upgrade-all-pax-modal.component';
import { CheckinFooterComponent } from './checkin-footer/checkin-footer.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { InfoLinksComponent } from './info-links/info-links.component';
import { GetOurAppComponent } from './get-our-app/get-our-app.component';
import { NumberMaskPipe } from '~app/pipes/number-mask.pipe';
import { CustomCurrencyPipe } from '~app/pipes/custom-currency.pipe';
import { HaDatalistComponent } from './ha-datalist/ha-datalist.component';
import { HaInputComponent } from "./ha-input/ha-input.component";
import { HaValidationComponent } from "./ha-validation/ha-validation.component";
import { ImageComponent } from './image/image.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SeatmapNavComponent } from '~app/components/seat/seatmap-nav/seatmap-nav.component';
import { CountryNamePipe } from '~app/pipes/country-name.pipe';
import { HaInputModule } from '@hawaiianair/input-field';
import { HaDropDownModule } from '@hawaiianair/drop-down';
import { HaButtonModule } from '@hawaiianair/button';
import { PortalModule } from '@angular/cdk/portal';
import { HaModalModule } from '@hawaiianair/modal';

/*
  This module is imported by the generated app-components.module.ts.
  You can use this module to provide shared Angular components that are not
  app components, etc to the generated module.
*/

// Feel like this whole module should go bye bye
@NgModule({
  imports: [
    CoreModule,
    A11yModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    HaInputModule,
    HaDropDownModule,
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatListModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatCheckboxModule,
    PortalModule,
    HaButtonModule,
    HaModalModule,
  ],
  declarations: [
    // add declared classes here
    CheckinLoaderComponent,
    CheckinHeaderComponent,
    CheckinPageInfoComponent,
    DialogModalComponent,
    UpgradeAllPaxModalComponent,
    CheckinCartComponent,
    PaxCartComponent,
    PaymentCartComponent,
    FlightStatusComponent,
    FlightInformationComponent,
    CheckinFooterComponent,
    InfoLinksComponent,
    GetOurAppComponent,
    NumberMaskPipe,
    CustomCurrencyPipe,
    CountryNamePipe,
    HaInputComponent,
    HaDatalistComponent,
    HaValidationComponent,
    ImageComponent,
    SeatmapNavComponent
  ],
  exports: [
    A11yModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    // should also add declared classes here - anything that components should be able to reference
    CheckinLoaderComponent,
    CheckinHeaderComponent,
    CheckinFooterComponent,
    CheckinPageInfoComponent,
    DialogModalComponent,
    UpgradeAllPaxModalComponent,
    CheckinCartComponent,
    PaxCartComponent,
    PaymentCartComponent,
    FlightStatusComponent,
    FlightInformationComponent,
    InfoLinksComponent,
    GetOurAppComponent,
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatOptionModule,
    NumberMaskPipe,
    CustomCurrencyPipe,
    CountryNamePipe,
    HaInputComponent,
    HaDatalistComponent,
    HaValidationComponent,
    HaInputModule,
    HaDropDownModule,
    SeatmapNavComponent,
    PortalModule,
    HaButtonModule,
    HaModalModule
  ],
  providers: [
    // only add providers here that are scoped to all imported instances of this module
    MatCardModule,
    MatDividerModule,
    MatBadgeModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatChipsModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    CustomCurrencyPipe,
    CountryNamePipe,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
        autoFocus: true,
        disableClose: false,
        hasBackdrop: true,
        height: 'auto',
        maxHeight: '90vh',
        maxWidth: '90vw',
        restoreFocus: true,
        width: '624px',
        data: null
      }
    },
  ]
})
export class AppSharedModule {
  static forRoot(): ModuleWithProviders<AppSharedModule> {
    return {
      ngModule: AppSharedModule,

      providers: [
        // add any ROOT providers (services, etc) to this list
        DeviceDetectorService,
        CurrencyPipe,
        LoadingAnimationService
      ]
    };
  }
}
