import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { TripDispatchers } from '../../store/services/trip/trip.dispatchers';
import { ModalContentsService } from '../modal-contents.service';
import { DateContent } from '@hawaiianair/date';

@Component({
  selector: 'app-group-dob-modal',
  templateUrl: './group-dob-modal.component.html',
  styleUrls: ['./group-dob-modal.component.scss'],
})
@ContentSchema({
  name: 'GroupDOBModal',
  description: 'Group DOB Modal Component',
  props: [
    { name: 'header', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'dob', type: PropertyType.Text },
    { name: 'year', type: PropertyType.Text },
    { name: 'month', type: PropertyType.Text },
    { name: 'day', type: PropertyType.Text },
  ]
})
export class GroupDOBModalComponent implements OnInit {
  rendering: ComponentRendering;
  dobForm = this.fb.group({
    dateOfBirth: ['', Validators.required]
  });
  dateContent: DateContent;

  constructor(
    private modalContentsSvc: ModalContentsService,
    private tripDispatchers: TripDispatchers,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.rendering.props = this.modalContentsSvc.getRendering('group-dob-modal');
    this.initContent();
  }

  initContent(): void {
    this.dateContent = {
      day: {
        inputId: 'day',
        label: this.rendering?.props?.day
      },
      month: {
        inputId: 'month',
        label: this.rendering?.props?.month
      },
      year: {
        inputId: 'year',
        label: this.rendering?.props?.year
      },
    };
  }

  onSubmit() {
    const dateOfBirth = this.dobForm.get('dateOfBirth').value;
    this.tripDispatchers.getGroupPassengerByName(
      this.data.tripId, this.data.confirmationCode, this.data.passengerName, dateOfBirth);
  }
}
