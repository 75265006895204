<!-- eslint-disable-next-line @angular-eslint/template/no-negated-async -->
<div class="spinner-wrapper" [hidden]="!(militaryLoading$ | async)">
    <div id="military-save-type-spinner" class="spinner-border" role="status"></div>
</div>
<h6 id="no-military-type-selection-title" class="bold medium" mat-dialog-title>
    <span>{{ heading }}</span>
</h6>
<mat-dialog-content id="no-military-type-selection-content">
    <span id="despcription" class="body-copy">{{ description }} </span>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center">
    <button id="no-button" mat-button [mat-dialog-close]="true" class="btn btn-secondary">
      <span>{{ no }}</span>
    </button>
    <button id="yes-button" mat-button class="btn btn-default" (click)="clickYes()">
        <span>{{ yes }}</span>
    </button>
</mat-dialog-actions>