<form [formGroup]="contactInfoForm" class="col form">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column">
        <app-ha-input formControlName="firstName" inputId="firstName" [label]="rendering?.props?.firstName"
          [placeholder]="rendering?.props?.firstName" [control]="contactInfoForm?.get('firstName')">
        </app-ha-input>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column">
        <app-ha-input formControlName="lastName" inputId="lastName" [label]="rendering?.props?.lastName"
          [placeholder]="rendering?.props?.lastName" [control]="contactInfoForm?.get('lastName')">
        </app-ha-input>
      </div>
      <h6 class="col-12 medium mb-0 d-block d-md-none" *haText="rendering?.props?.phoneNumber"></h6>
      <div class="col-lg-3 col-md-6 col-sm-6 col-4 d-flex flex-column traveler-country-emergency">
        <mat-form-field class="full-width">
          <mat-select [formControl]="contactInfoForm?.get('country')" id="countryCode"
                      class="mat-select-text"
                      [placeholder]="rendering?.props?.countryCode" required>
            <mat-select-trigger>
                 {{ (contactInfoForm?.get('country')?.value)?.country3LetterCode }} +{{ (contactInfoForm?.get('country')?.value)?.countryPhonePrefix }}
            </mat-select-trigger>
            <mat-option *ngFor="let countries of countriesList; index as index" id="select-country-{{index}}"
                        class="mat-select-text" [value]="countries">
              +{{ countries.countryPhonePrefix }} {{ countries.countryNm }}
            </mat-option>
          </mat-select>
          <mat-error>{{ 'required' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-8 d-flex flex-column">
        <app-ha-input formControlName="number" inputId="number" [label]="rendering?.props?.number"
          [placeholder]="rendering?.props?.number" maxlength="20"
          [control]="contactInfoForm?.get('number')">
        </app-ha-input>
      </div>
    </div>
  </form>
