<div class="text-left">
  <a id="toggle-form" class="d-inline-flex link body-copy-bold medium mt-3" (click)="setTogglePassportForm()">
    <i class="icon-circle{{ togglePassportForm ? 'minus' : 'plus'}}"></i>
    <div class="pl-3 text-left" *haText="rendering.props?.testEnvironmentOnly | uppercase"></div>
  </a>
</div>
<form *ngIf="togglePassportForm" [formGroup]="manualPassportForm">
  <div class="row">
    <app-ha-input inputId="number-{{passenger.id}}" formControlName="number"
      [control]="manualPassportForm?.get('number')" [label]="rendering.props?.passportNumber"
      [placeholder]="rendering.props?.passportNumber" maxlength="8" class="col-md-6">
    </app-ha-input>
    <app-ha-input class="col-md-6" formControlName="expiry" inputId="expiry-{{passenger.id}}" type="date"
      [control]="manualPassportForm.get('expiry')" [label]="rendering.props?.expirationDate"></app-ha-input>
    <app-ha-datalist class="col-md-6" list="issuingCountry-datalist-{{passenger.id}}"
      inputId="issuingCountry-{{passenger.id}}" [control]="manualPassportForm?.get('issuingCountry')"
      [label]="rendering?.props?.issuanceCountry" [placeholder]="rendering?.props?.issuanceCountry"
      [options]="countriesDisplayNames">
    </app-ha-datalist>
  </div>
  <div class="row">
    <app-ha-input class="col-md-6" inputId="firstName-{{passenger.id}}" formControlName="firstName"
      [label]="rendering.props?.firstName" [placeholder]="rendering.props?.firstName"
      [control]="manualPassportForm.get('firstName')">
    </app-ha-input>
    <app-ha-input class="col-md-6" inputId="lastName-{{passenger.id}}" formControlName="lastName"
      [label]="rendering.props?.lastName" [placeholder]="rendering.props?.lastName"
      [control]="manualPassportForm.get('lastName')">
    </app-ha-input>
    <app-ha-input class="col-md-6" formControlName="dob" inputId="dob-{{passenger.id}}" type="date"
      [control]="manualPassportForm.get('dob')" [label]="rendering.props?.dateOfBirth">
    </app-ha-input>
    <mat-form-field class="col-md-6" floatLabel="never" appearance="standard">
      <mat-label *haText="rendering.props?.gender"></mat-label>
      <mat-select id="gender-{{passenger.id}}" formControlName="gender" disableRipple>
        <mat-option [value]="gender.value" *ngFor="let gender of rendering.props?.genders">{{gender.text}}</mat-option>
      </mat-select>
      <mat-error>{{'required' | translate}} {{'noMatch' | translate}}</mat-error>
    </mat-form-field>
    <app-ha-datalist class="col-md-6" list="nationality-datalist-{{passenger.id}}"
      inputId="nationality-{{passenger.id}}" [control]="manualPassportForm?.get('nationality')"
      [label]="rendering?.props?.nationality" [placeholder]="rendering?.props?.nationality" [options]="countriesDisplayNames">
    </app-ha-datalist>
  </div>
  <button id="passport-form-save-button" class="btn btn-secondary body-copy-bold medium passport-form-btn"
    *haText="rendering.props?.save" [disabled]="manualPassportForm.invalid" (click)="savePassportForm()">
  </button>
</form>