<div class="row textlink-withIcon">
  <button type="button" id="toggle-forgot-code" class="col-auto mt-5 d-flex" (click)="toggleIcon()">
    <i class="icon-circle{{ toggleInfoArea ? 'minus' : 'plus'}}"></i>
    <div class="pl-3 text-left" *haText="rendering.props['forgotConfirmation']"></div>
  </button>
</div>
<div class="row mr-0" *ngIf="toggleInfoArea">
  <div class="col-md-6 ha-tooltip">
    <div class="row">
      <i class="col-md-2 icon-help-font help-icon"></i>
      <div class="col-md-10">
        <div *haText="rendering.props['hawaiianMilesMembers']"></div>
        <div class="call-out-bold mt-4">
          <a id="login-account-link" *haLink="rendering.props['loginMyAccountLink']"></a>
        </div>
      </div>
    </div>
  </div>
</div>