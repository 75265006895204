import { Injectable } from '@angular/core';
import { BaseService } from '~app/store/services/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from '~environments/environment';
import { SessionSelectors } from '../session/session.selectors';
import { BagOffers } from '~app/models/offer.model';


@Injectable({
    providedIn: 'root'
})

export class OffersService extends BaseService{
    private authenticationString = '';
    passengerIds$: Observable<string[]>;

    constructor(
        http: HttpClient,
        sessionSelectors: SessionSelectors
    ) {
        super(http, sessionSelectors);
        this.sessionConfirmationCode$ = sessionSelectors.sessionConfirmationCode$;
        this.passengerIds$ = sessionSelectors.sessionPassengerIds$;
    }

    getBagOffers(): Observable<BagOffers> {
        this.authenticationString = this.getAuthCredentials(this.sessionConfirmationCode$);
        let paxIds: string;
        this.passengerIds$.pipe(take(1)).subscribe(ids => paxIds = ids?.join(','));
        let URL_OFFERS = `${environment["exp-web-checkin-v2-api"]}/catalogs${this.authenticationString}`;
        URL_OFFERS += `&includeCatalogs=bags&passengerIds=${paxIds}`;
        return this.http.get<BagOffers>(URL_OFFERS);
    }
}