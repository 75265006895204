import { ModalsService } from '~app/modals/modals.service';
import { Injectable, Inject, Renderer2, RendererFactory2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { interval, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { takeWhile, switchMap, map } from 'rxjs/operators';
import { AuthTokenSelectors } from '../store/services/auth-token/auth-token.selectors';
import { AuthTokenDispatchers } from '../store/services/auth-token/auth-token.dispatchers';
import { CartSelectors } from '../store/services/cart/cart.selectors';
import { CartDispatchers } from '../store/services/cart/cart.dispatchers';

export interface UserIdle {
    defaultDialogContent?: string;
    idleTime?: number;
    additionalTime?: number;
    expiredDialogContent?: string;
}

@Injectable({
    providedIn: 'root'
})

export class SessionTimeoutService {
    private renderer: Renderer2;
    private defineInactivityPeriod: number;
    private sessionExpired: BehaviorSubject<boolean>;
    public additionalTime = 6000;
    private lastInteraction: Date = new Date();
    private defaultInactivityPeriod = 540000;
    private listeners = [];
    private idleSubscription: Subscription;
    private cartExpirationSubscription: Subscription;
    private authTokenExpirationSubscription: Subscription;
    private authTokenExpirationTime: number;

    constructor(
        private rendererFactory2: RendererFactory2,
        private cartSelectors: CartSelectors,
        private authTokenSelectors: AuthTokenSelectors,
        private cartDispatchers: CartDispatchers,
        private authTokenDispatchers: AuthTokenDispatchers,
        private modalsService: ModalsService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        this.sessionExpired = new BehaviorSubject<boolean>(false);
    }

    public startWatchingUserIdle(value: UserIdle) {
        // If the app is not running on browser,
        // i.e, window is undefined, just return
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.defineInactivityPeriod = (value.idleTime || this.defaultInactivityPeriod);
        this.additionalTime = (value.additionalTime || this.additionalTime);
        this.renderer = this.rendererFactory2.createRenderer(null, null);

        this.listeners.push(this.renderer.listen('document', 'mousemove', evt => {
            this.lastInteraction = new Date();
        }));

        this.listeners.push(this.renderer.listen('document', 'keypress', evt => {
            this.lastInteraction = new Date();
        }));

        this.idleSubscription = this.watch().subscribe();
    }

    public stopWatchingUserIdle() {
        if (!!this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    public addTime(additionalTime: number) {
        this.defineInactivityPeriod = additionalTime;
        this.lastInteraction = new Date();
        this.idleSubscription = this.watch().subscribe();
    }

    public hasSessionExpired(): Observable<boolean> {
        return this.sessionExpired.asObservable();
    }

    private setSessionExpiration(value: boolean): void {
        this.sessionExpired.next(value);
    }

    private watch() {
        return interval(1000)
            .pipe(
                // leaving this here for testing purposes
                // tap(() => console.log('here', new Date().getTime() - this.lastInteraction.getTime())),
                takeWhile(() => {
                    if ((new Date().getTime() - this.lastInteraction.getTime()) > this.defineInactivityPeriod) {
                        this.setSessionExpiration(true);
                        this.stopWatchingUserIdle();
                    }
                    return (new Date().getTime() - this.lastInteraction.getTime()) < this.defineInactivityPeriod;
                })
            );
    }

    public startWatchingAuthTokenExpiration() {
        // If the app is not running on browser,
        // i.e, window is undefined, just return
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        this.authTokenExpirationSubscription = this.authTokenSelectors.authTokenState$.pipe(
            map(authToken => {
                if (!!authToken && !!authToken.expiration) {
                    this.authTokenExpirationTime = new Date(authToken.expiration).getTime();
                    return authToken;
                } else {
                    return null;
                }
            }),
            switchMap(
                _ => interval(1000),
                (authToken) => {
                    const nowMilliseconds = new Date().getTime();
                    if (nowMilliseconds >= this.authTokenExpirationTime && !authToken.expired) {
                        this.modalsService.openSessionEndedDialog();
                        this.authTokenDispatchers.setAuthTokenExpirationFlag();
                        this.stopWatchingAuthTokenExpiration();
                    }
                }
            )
        ).subscribe();

    }

    public stopWatchingAuthTokenExpiration() {
        if (!!this.authTokenExpirationSubscription) {
            this.authTokenExpirationSubscription.unsubscribe();
        }
    }

    public startWatchingCartExpiration() {
        // If the app is not running on browser,
        // i.e, window is undefined, just return
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.cartExpirationSubscription = this.cartSelectors.cart$.pipe(
            map(cart => {
                if (!!cart && !!cart.results && !!cart.results.length && !!cart.results[0].expiration) {
                    let expiration = 0;
                    try {
                        expiration = new Date(cart.results[0].expiration).getTime();
                    } catch {
                        return null;
                    }
                    return [cart.results[0].id, expiration];
                } else {
                    return null;
                }
            }),
            switchMap(
                _ => interval(1000),
                (cartExpirationInfoArr, sec) => {
                    if (Array.isArray(cartExpirationInfoArr) && cartExpirationInfoArr.length === 2) {
                        // expiration exists
                        const nowMilliseconds = new Date().getTime();
                        if (nowMilliseconds >= cartExpirationInfoArr[1]) {
                            // expired! delete cart
                            this.cartDispatchers.deleteCart(cartExpirationInfoArr[0] as string);
                            // alert, then the user will be redirected to the first step
                            this.modalsService.openSessionEndedDialog();
                        }
                    }

                    return sec;
                }
            )
        ).subscribe();
    }

    public stopWatchingCartExpiration() {
        if (!!this.cartExpirationSubscription) {
            this.cartExpirationSubscription.unsubscribe();
        }
    }
}
