<div class="ha-input">
  <div class="ha-input__label-container" *ngIf="label">
    <label class="ha-input__label" *ngIf="showLabel || control.value" [for]="inputId">{{label}}</label>
  </div>
  <input class="ha-input__input" #haInput
         [attr.aria-describedby]="hintId"
         [name]="inputId"
         [id]="inputId"
         [attr.list]="list?list:null"
         [ngClass]="{'ha-input__input--error': hasValidationErrors()}"
         [formControl]="control"
         [type]="type"
         [placeholder]="placeholder"
         [attr.aria-label]="ariaLabel?ariaLabel:placeholder"
         [maxlength]="maxlength"
         [attr.accesskey]="accessKey"
         (change)="writeValue($event.target.value)"
         (focus)="showLabel = true"
         (focusout)="showLabel = false"/>
  <i *ngIf="icon" tabindex="0" [id]="iconId" class="ha-input__icon {{icon}}"
    (click)="iconClicked.emit()" (keydown.enter)="iconClicked.emit()" (keydown.space)="iconClicked.emit()" role="button" aria-label="more info"></i>
  <span class="ha-input__hint" *ngIf="hint" [id]="hintId">{{hint}}</span>
  <app-ha-validation [control]="control"></app-ha-validation>
</div>
