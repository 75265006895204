import { Action, createReducer, on } from '@ngrx/store';
import * as AircraftImageActions from '~app/store/actions/aircraft-image.actions';
import * as SessionActions from '~app/store/actions/session.actions';

export interface AircraftImageMap {
  flightIndex: number;
  aircraftImage: string;
}

export interface AircraftImageSeatMap {
  flightIndex: number;
  aircraftSeatList: any;
}

export interface AircraftImageState {
  loading: boolean;
  error: boolean;
  aircraftImages: AircraftImageMap[];
  aircraftSeatList: AircraftImageSeatMap[];
}

export const initialAircraftImageState: AircraftImageState = {
  loading: false,
  error: false,
  aircraftImages: [],
  aircraftSeatList: [],
};

export const aircraftImageReducer = createReducer(
  initialAircraftImageState,
  on(AircraftImageActions.getAircraftImage, state => ({ ...state, loading: true, error: false })),
  on(AircraftImageActions.getAircraftImageSuccess, (state, { flightIndex, aircraftImage, aircraftSeatList }) => ({
    ...state,
    loading: false,
    aircraftImages: [...state.aircraftImages.filter(imageMap => imageMap.flightIndex !== flightIndex), { flightIndex, aircraftImage }],
    aircraftSeatList: [...state.aircraftSeatList.filter(imageMap => imageMap.flightIndex !== flightIndex), { flightIndex, aircraftSeatList }]
  })),
  on(AircraftImageActions.getAircraftImageError, state => ({ ...state, loading: false, error: true })),
  on(AircraftImageActions.resetAircraftImageState, SessionActions.clearSessionDataCheckinStart, _ => ({
    ...initialAircraftImageState
  })),
);

export function reducer(state: AircraftImageState | undefined, action: Action) {
  return aircraftImageReducer(state, action);
}
