import { Injectable } from "@angular/core";
import { SessionState } from '../../reducers';
import { Store } from '@ngrx/store';
import * as SessionActions from '../../actions/session.actions';
import { Trip } from '~app/models/trip.model';
import { PassengerList } from '~app/models/passengerlist.model';
import { Terms } from '../../../models/terms.model';
import { CartContentItems } from '~app/models/cartcontentItems.model';
import { TripSearch } from "~app/models/trip-search.model";
import { EliteStandByPassenger } from "~app/components/seat/seat-upgrade-passenger-list/seat-upgrade-passenger-list.component";

@Injectable()
export class SessionDispatchers {
    constructor(private store: Store<SessionState>) { }

    getState() {
        this.store.dispatch(SessionActions.getSession());
    }

    setSessionTripId(name: any, tripId: any) {
        this.store.dispatch(SessionActions.setSessionTripId({ name: name, value: tripId }));
    }

    setSessionSelectedSegment(name: any, segment: {}, errorCode: {}) {
        this.store.dispatch(SessionActions.setSessionSelectedSegment({ name: name, value: segment, errorCode: errorCode }));
    }

    setSessionPassengerIds(name: any, passengerIds: {}) {
        this.store.dispatch(SessionActions.setSessionPassengerIds({ name: name, value: passengerIds }));
    }

    addSessionPassengerIds(name: any, passengerIds: string) {
        this.store.dispatch(SessionActions.addSessionPassengerIds({ name: name, passengerIds: passengerIds }));
    }

    setSessionTrip2Checkin(name: any, trip2Checkin: Trip) {
        this.store.dispatch(SessionActions.setSessionTrip2Checkin({ name: name, value: trip2Checkin }));
    }

    setSessionAuthToken(name: any, authToken: string) {
        this.store.dispatch(SessionActions.setSessionAuthToken({ name: name, value: authToken }));
    }

    setSessionCartId(name: string, cartId: string) {
        this.store.dispatch(SessionActions.setSessionCartId({ name: name, value: cartId }));
    }

    setSessionTrip2CheckinPassengers(name: string, passengers: PassengerList) {
        this.store.dispatch(SessionActions.setSessionTrip2CheckinPassengers({ name: name, passengers: passengers }));
    }

    setSessionFullTripData(name: string, fullTripData: {}) {
        this.store.dispatch(SessionActions.setSessionFullTripData({ name: name, value: fullTripData }));
    }

    setSessionCorrelationId(correlationId: string) {
        this.store.dispatch(SessionActions.setSessionCorrelationId({ correlationId }));
    }

    setSessionOAFlightFlag(isOAFlight: boolean) {
        this.store.dispatch(SessionActions.setSessionOAFlightFlag({ isOAFlight }));
    }

    setSessionTermsAndAgreement(terms: Terms) {
        this.store.dispatch(SessionActions.setSessionTermsAndAgreement({ terms }));
    }

    setSessionInfantConfirmation(confirmedInfant: boolean) {
        this.store.dispatch(SessionActions.setSessionInfantConfirmation({ confirmedInfant }));
    }

    getSessionFullTripData() {
        this.store.dispatch(SessionActions.getSessionFullTripData());
    }

    getSessionResultData() {
        this.store.dispatch(SessionActions.getSessionResultData());
    }

    clearFullTripData() {
        this.store.dispatch(SessionActions.clearFullTripData());
    }

    clearSessionDataCheckinStart() {
        this.store.dispatch(SessionActions.clearSessionDataCheckinStart());
    }

    setSessionTripParams(tripSearch: TripSearch, confirmationCode?: string) {
        this.store.dispatch(SessionActions.setSessionTripParams({ tripSearch, confirmationCode }));
    }

    setSessionAppBannerClose(appBannerClose: boolean) {
        this.store.dispatch(SessionActions.setSessionAppBannerClose({ appBannerClose }));
    }

    setMobileDevice(isMobile: boolean) {
        this.store.dispatch(SessionActions.setMobileDevice({ isMobile }));
    }

    setOSDevice(os: string) {
        this.store.dispatch(SessionActions.setOSDevice({ os }));
    }

    setBrowserDevice(browser: string) {
        this.store.dispatch(SessionActions.setBrowserDevice({ browser }));
    }

    addCartContent(cartContent: CartContentItems[]) {
        this.store.dispatch(SessionActions.addCartContent({ cartContentItems: cartContent }));
    }

    getEliteStandByData() {
      this.store.dispatch(SessionActions.getEliteStandByData());
    }

    updateEliteStandByData(selectedEliteStandByPassengers: EliteStandByPassenger[]) {
      this.store.dispatch(SessionActions.updateEliteStandByData({selectedEliteStandByPassengers}));
    }
}
