import { NgModule } from '@angular/core';
import { AppSharedModule } from '~app/shared/app.shared.module';
import { CoreModule } from '@hawaiianair/core';
import { SegmentSelectorComponent } from './segment-selector.component';
import { SegmentCardComponent } from './segment-card/segment-card.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  imports: [
    AppSharedModule,
    // for Material Design
    // import Material Design Components
    MatButtonModule,
    MatCardModule,
    CoreModule.forChild({
      components: [
        SegmentSelectorComponent,
        SegmentCardComponent
      ] 
    }),
  ],
  exports: [
    // export Material Design components
    MatButtonModule,
    MatCardModule,
    RouterModule
  ],
  declarations: [
      SegmentSelectorComponent,
      SegmentCardComponent
  ],
})
export class SegmentSelectorModule { }
